import React, { useEffect, useState } from 'react';
import Button from '../../components/utils/Button';
import Box from '../../components/utils/Box';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cartAction from '../../redux/cart/actions';
import Message from '../../components/utils/Message';
import CheckedImage from '../../assets/images/checked.png'
import SimPurchaseActions from '../../redux/simPurchase/actions'

const { getSimTypeData } = SimPurchaseActions;


export default function CartInvoice({ customerEmail }) {
    const dispatch = useDispatch()
    const { viewCartData, resetViewPurchaseInvoice, purchasePlanPayment, resetWholePage } = cartAction;
    const { cartResult, loading } = useSelector((state) => state.cart);
    const { simTypeList } = useSelector((state) => state.simPurchase);
    const [showMessage, setShowMessage] = useState(false)
    var totalPrice = 0;
    var totalQuantity = 0;
    var totalSumCost = 0;
    var priceCalculation = 0;

    const sendPlanSimType = {
        "tableName": "sim",
        "fieldName": "SIMType"
    };

    useEffect(() => {
        dispatch(viewCartData());
        dispatch(getSimTypeData(sendPlanSimType))
    }, [])

    const handleBackPurchaseInvoice = () => {
        dispatch(resetViewPurchaseInvoice());
    }
    const handlePayAmount = () => {
        const provideCustomerEmail = {
            "customerEmail": customerEmail
        }
        dispatch(purchasePlanPayment(provideCustomerEmail))
    }

    return (
        <>
            <div className='confirm-order-container'>
                <Box >
                    {/* <div className='invoice-header-container'>
                    <div className='header-left'>
                        <p>Invoice to:</p>
                        <h4>XYZ</h4>
                        <p>123-456-789</p>
                        <p>27 abc street , America</p>
                        <p>abc@gmail.com</p>
                    </div>
                    <div className='header-right'>
                        <h1>INVOICE</h1>
                        <p>5 January 2024</p>
                    </div>
                </div> */}
                    <h3 className='text-center'>Purchase Summary</h3>
                    <div class="content">
                        <div className='table-over-flow'>
                            <table class="ui celled table">

                                <thead className='table-head' >
                                    <tr >
                                        <th>Variation</th>
                                        <th>SIM TYPE</th>
                                        <th class="text-center colfix">Unit</th>
                                        <th class="text-center colfix">Per Cost</th>
                                        <th class="text-center colfix">Total</th>
                                    </tr>
                                </thead>

                                <tbody className='table-body'>
                                    {cartResult && cartResult.length > 0 && cartResult.map((data, key) => {
                                        priceCalculation = Number(data.PlanPrice) * Number(data.Quantity);
                                        totalQuantity = totalQuantity + Number(data.Quantity)
                                        totalPrice = totalPrice + priceCalculation;
                                        totalSumCost = totalSumCost + Number(data.PlanPrice);
                                        return (
                                            <tr>
                                                <td>{data.VariantName}</td>
                                                {simTypeList && simTypeList.length > 0 && simTypeList.filter(item => item.ID == data.PlanSIMType).map(simType => (
                                                                    <td key={simType.ID}>{simType.Value}</td>
                                                                ))}
                                                <td className='text-center' >{data.Quantity}</td>
                                                <td class="text-center">£{parseFloat(data.PlanPrice).toFixed(2)}</td>
                                                <td class="text-center">£{parseFloat(data.Quantity * data.PlanPrice).toFixed(2)}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>

                            </table>
                        </div>
                        <div class="ui-amount-due">
                            <div className='row justify-content-between py-2 mx-2'>
                                <div className='col-lg-4 col-md-2'>
                                    <p className='cart-align'> {`Total: £${totalPrice && parseFloat(totalPrice).toFixed(2)}`} </p>
                                </div>
                                <div className='col-lg-4 col-md-1 text-center'>
                                    <p class="text-center">{`Qty : ${totalQuantity && totalQuantity}`}</p>
                                </div>
                                <div className='col-lg-4 col-md-5'>
                                    <div class="content center aligned text segment " >
                                        <p class="ui sub header mr-3 amount-para-due  m-0"> Amount Due (USD): <span style={{ color: "#f14336" }}>{`£${totalPrice && parseFloat(totalPrice).toFixed(2)}  `}</span> </p>

                                    </div>
                                </div>
                            </div>


                        </div>
                        {/* <div class="ui card mt-3" style={{ 'padding': '10px' }}>
                            <div class="content">
                                <div class="header">Notes</div>
                            </div>
                            <div class="content">
                                This Note Purchase Agreement.
                            </div>
                        </div> */}
                        <div className='confirm-order-footer my-4'>
                            <Button title="Back" buttonType='button' style={{ minWidth: "80px" }} buttonClass="btn btn-secondary purchase-cart-b  mx-2" onClick={handleBackPurchaseInvoice} />
                            <Button title="Pay Amount" loading={loading} buttonType='button' style={{ minWidth: "80px" }} buttonClass="btn btn-primary purchase-cart-b " onClick={handlePayAmount} />
                        </div>
                    </div>
                </Box>
            </div>

        </>
    )
}
