import actions from "./actions";

const initState = {
    bannerResult: [],
    loading: false,
    error: null,
    message: null,
    buttonLoading: false,
    bannerFormModal: false,
    bannerConfirmationModal: false,
    bannerDeleteConfirmationModal: false,
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.OPEN_DELETE_BANNER_CONFIRMATION:
            return {
                ...state,
                bannerDeleteConfirmationModal: true,
            };
        case actions.CLOSE_DELETE_BANNER_CONFIRMATION:
            return {
                ...state,
                bannerDeleteConfirmationModal: false,
            };
        
        case actions.OPEN_BANNER_CONFIRMATION:
            return {
                ...state,
                bannerConfirmationModal: true,
            };
        case actions.CLOSE_BANNER_CONFIRMATION:
            return {
                ...state,
                bannerConfirmationModal: false,
            };

        case actions.GET_BANNER_DATA:
            return {
                ...state,
                loading: true,
            };
        case actions.GET_BANNER_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                bannerResult: action.bannerResult,
            };
        case actions.GET_BANNER_DATA_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case actions.ADD_BANNER:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                buttonLoading: true,
            };
        case actions.ADD_BANNER_SUCCESS:
            return {
                ...state,
                loading: false,
                bannerResult: action.bannerResult,
                message: action.message,
                error: null,
                buttonLoading: false,
                bannerFormModal: false
            };
        case actions.ADD_BANNER_FAILED:
            return {
                ...state,
                loading: false,
                message: null,
                error: action.error,
                buttonLoading: false,
                bannerFormModal: false
            };
        case actions.CHANGE_BANNER_STATUS:
            return {
                ...state,
                error: null,
                message: null,
                loading: true,
            };
        case actions.CHANGE_BANNER_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.message,
                bannerResult: action.bannerResult,
                bannerConfirmationModal: false,
            };
        case actions.CHANGE_BANNER_STATUS_FAILED:
            return {
                ...state,
                error: action.error,
            };
        case actions.DELETE_BANNER:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                buttonLoading: true,
            };
        case actions.DELETE_BANNER_SUCCESS:
            return {
                ...state,
                loading: false,
                bannerResult: action.bannerResult,
                message: action.message,
                error: null,
                buttonLoading: false,
                bannerDeleteConfirmationModal: false,
            };
        case actions.DELETE_BANNER_FAILED:
            return {
                ...state,
                loading: false,
                message: null,
                error: action.error,
                buttonLoading: false,
            };
        case actions.PREPARE_BANNER_FORM:
            return {
                ...state,
                loading: false,
                message: null,
                error: null,
                buttonLoading: false,
                bannerFormModal: true
            };
        case actions.RESET_BANNER:
            return {
                ...state,
                loading: false,
                message: null,
                error: null,
                buttonLoading: false,
                bannerFormModal: false
            };
        case actions.RESET_BANNER_MESSAGE:
            return {
                ...state,
                loading: false,
                message: null,
                error: null,
                buttonLoading: false,
            };

        default:
            return state;
    }
}