import React from 'react';

function SearchBar({ onFilter, filterText, style }) {
    const handleInputChange = (e) => {
        onFilter(e.target.value);
    };

    return (
        <div className="iq-search-bar">
            <form action="#" className="searchbox">
                <input
                    type="text"
                    className="text search-input"
                    style={style}
                    placeholder="Type here to search..."
                    value={filterText} // Set value to maintain controlled input
                    onChange={handleInputChange} // Call onFilter when input changes
                />
                <a className="search-link" href="#">
                    <i className="ri-search-line"></i>
                </a>
            </form>
        </div>
    );
}

export default SearchBar;
