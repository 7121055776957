import React from 'react'
import { Navigate } from 'react-router-dom';
import { isAdmin, isAdminAgent } from '../../helpers/commonFunction'
import { useSelector } from 'react-redux';
import Loader from './Loader';

const Admin = ({ children }) => {
    const tokenData = useSelector((state) => state.auth.tokenData);
    const getUserDetailsLoading = useSelector((state) => state.auth.getUserDetailsLoading);

    if (getUserDetailsLoading) {
        return <Loader />
    } else {
        if (tokenData.length > 0) {
            if (isAdmin(tokenData) || isAdminAgent(tokenData)) {
                return <>{children}</>
            } else {
                return <Navigate to='/404' />
            }
        } else {
            return <Loader />
        }
    }

}

export default Admin