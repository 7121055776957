import React, { useState, useEffect } from 'react'
import CardLayout from '../../../../components/utils/CardLayout'
import SideButtons from '../../../../components/utils/SideButtons'
import Modal from '../../../../components/utils/Model'
import { useForm, useFieldArray } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/utils/Button';
import AddCircleFillIcon from 'remixicon-react/AddCircleFillIcon';
import CloseCircleFill from 'remixicon-react/CloseCircleFillIcon';
import Table from '../../../../components/utils/Table';
import Tag from '../../../../components/utils/Tag';
import SearchBar from '../../../../components/utils/SearchBar';
import EyeFill from 'remixicon-react/EyeFillIcon';
import PencilFill from 'remixicon-react/PencilFillIcon';
import { Link } from 'react-router-dom';
import AddFillIcon from 'remixicon-react/AddFillIcon';
import Alert from '../../../../components/utils/Alert';
import aggregatorActions from '../../../../redux/aggregator/actions';

const { openAddAggregatorModal, previewAggregatorModal, resetAggregator, addAggregator, getAggregator, setPreviewData, updateAggregator } = aggregatorActions;

const Aggregator = () => {
    const dispatch = useDispatch();
    const { list, openAggregatorModel, previewModel, loading, message, error } = useSelector((state) => state.aggregator);
    const [formTitle, setFormTitle] = useState('')
    const [addClicked, setIsAddClicked] = useState(false);
    const [filterText, setFilterText] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');
    const [previewAggregatorData, setPreviewAggregatorData] = useState("");
    const [editFlag, setEditFlag] = useState(false);
    const [aggregatorId, setAggregatorId] = useState(0);
    const [aggregatorFrom, setAggregatorFrom] = useState({
        aggregatorName: "",
        inventory: "",
        metaData: [
            {
                key: "",
                value: "",
            }
        ]
    })

    const [formButton, setFormButton] = useState('');
    const {
        register,
        handleSubmit,
        control,
        formState: { errors }, reset
    } = useForm({
        defaultValues: aggregatorFrom,
    });


    useEffect(() => {
        dispatch(getAggregator());
    }, []);

    useEffect(() => {
        reset(aggregatorFrom);
    }, [aggregatorFrom]);

    useEffect(() => {
        if (error !== null && error !== undefined) {
            displayAlert(error, 'alert-danger');
        }
        if (message !== null && message !== undefined) {
            displayAlert(message, 'alert-success');
        }
    }, [error, message]);

    const { fields = [], append, remove } = useFieldArray({
        name: "metaData",
        control

    })

    const handleFilterChange = (value) => {
        setFilterText(value);
    };

    const columns = [{ displayName: '#', key: 'key' },
    { displayName: 'AGGREGATOR NAME', key: 'aggregatorName' },
    { displayName: 'INVENTORY', key: 'inventory' },
    { displayName: 'PHYSICAL SIM', key: 'physical_sim' },
    { displayName: 'E-SIM', key: 'esim' },
    { displayName: 'SKU', key: 'sku' },
    { displayName: 'STATUS', key: 'status' },
    { displayName: 'ACTION', key: 'action' },
    ]

    const onSubmit = (data) => {
        const newData = {
            ...data,
            inventory: data.inventory === true ? 1 : 0,
            physical_sim: data.physical_sim === true ? 1 : 0,
            esim: data.esim === true ? 1 : 0,
            sku: data.sku === true ? 1 : 0,
        }
        dispatch(addAggregator(newData));
    };


    const handleAddAggregator = () => {
        setAggregatorFrom(prevState => ({
            ...prevState,
            aggregatorName: "",
            inventory: "",
            physical_sim: "",
            esim: "",
            sku: "",
            metaData: [
                {
                    key: "",
                    value: "",
                }
            ]
        }));
        dispatch(openAddAggregatorModal());
        setFormTitle('Add Aggregator');
        setFormButton('Submit');
        setEditFlag(false);
        reset();            
    };

    const handleCancel = () => {
        setAggregatorFrom(prevState => ({
            ...prevState,
            aggregatorName: "",
            inventory: "",
            metaData: [
                {
                    key: "",
                    value: "",
                }
            ]
        }));
        dispatch(resetAggregator());

    };

    const handleShowAggregatormodal = (aggregatorData) => {
        dispatch(previewAggregatorModal());
        setFormTitle('Aggregator');
        setPreviewAggregatorData(JSON.parse(aggregatorData.meta_data));
    }

    const handleEditAggregatormodal = (id) => {
        setFormTitle('Edit Aggregator');
        setFormButton('Update');
        dispatch(openAddAggregatorModal());
        setAggregatorId(id)

        const selectedAggregator = list.filter(item => item.ID === id);

        setAggregatorFrom(prev => ({
            ...prev,
            aggregatorName: selectedAggregator[0].AggregatorName,
            inventory: selectedAggregator[0].Inventory == 1 ? true : false,
            physical_sim: selectedAggregator[0].PhysicalSim == 1 ? true : false,
            esim: selectedAggregator[0].Esim == 1 ? true : false,
            sku: selectedAggregator[0].SKURequired == 1 ? true : false,
            metaData: JSON.parse(selectedAggregator[0].meta_data)

        }))
        setEditFlag(true)
    }



    const filteredItems = list && list.length > 0 && list.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    var dataSource = filteredItems && filteredItems.length > 0 && filteredItems.map((item, key) => {
        return {
            key: key + 1,
            aggregatorName: item.AggregatorName,
            inventory: <Tag color={`${item.Inventory === 1 ? "badge-success" : "badge-danger"}`} title={item.Inventory === 1 ? 'Yes' : 'No'} />,

            physical_sim: <Tag color={`${item.physical_sim === 1 ? "badge-success" : "badge-danger"}`} title={item.physical_sim === 1 ? 'Yes' : 'No'} />,
            esim: <Tag color={`${item.esim === 1 ? "badge-success" : "badge-danger"}`} title={item.esim === 1 ? 'Yes' : 'No'} />,
            sku: <Tag color={`${item.sku === 1 ? "badge-success" : "badge-danger"}`} title={item.sku === 1 ? 'Yes' : 'No'} />,

            status: <Tag color={`${item.IsActive === 1 ? "badge-success" : "badge-danger"}`} title={item.IsActive === 1 ? 'Active' : 'Inactive'} />,
            action: (
                item.MetaCount > 0 ?
                <div>
                    <Link to="#" onClick={() => handleEditAggregatormodal(item.ID)}>
                    <span data-toggle="tooltip" data-placement="top" title="Edit"><PencilFill size="1.2em" style={{ color: "#888b8e", marginRight: "3px" }} /></span>
                    </Link>
                    <Link to="#" onClick={() => handleShowAggregatormodal(item)}>
                    <span data-toggle="tooltip" data-placement="top" title="View"><EyeFill size="1.2em" style={{ color: "#0094b3", marginRight: "3px" }} /></span>
                    </Link>

                </div> : ''
            )

        }
    })

    const handleUpdate = (data) => {

        const newData = {
            inventory: data.inventory == true ? 1 : 0,
            physical_sim: data.physical_sim === true ? 1 : 0,
            esim: data.esim === true ? 1 : 0,
            sku: data.sku === true ? 1 : 0,
            updatedData: data.metaData
        }
        // console.log("newData", newData)
        dispatch(updateAggregator(aggregatorId, newData));
    }

    const displayAlert = (text, type) => {
        setAlertText(text);
        setAlertType(type);
        setShowAlert(true);
        dispatch(resetAggregator());
    };

    const alertCancel = () => {
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
        dispatch(resetAggregator());
    }

    return (
        <CardLayout title="Aggregator">
            {showAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}
            <div className="row">
                <div className="col-lg-6 col-md-6  od-2">
                    <SearchBar onFilter={handleFilterChange} filterText={filterText} /></div>
                <div className="col-lg-6 col-md-6 text-right">
                    <SideButtons title="Add Aggregator" buttonStyle="primary" onClick={handleAddAggregator} /></div>
            </div>
            <Table data={dataSource} loading={loading} columns={columns} pagination={true} />

            <Modal
                showModal={openAggregatorModel}
                title={formTitle}
                onHide={handleCancel}
                width={600}
                showFooter={false}
            >
                <form onSubmit={handleSubmit(editFlag ? handleUpdate : onSubmit)}>
                    <div className="form-row">
                        <div className="col-md-12 mb-3">
                            <label>Aggregator Name</label>
                            <input type="text" disabled={editFlag} placeholder="Aggregator Name" class="form-control" name="aggregatorName" {...register("aggregatorName", {
                                required: true,
                            })} />
                            {errors.aggregatorName?.type === "required" && (
                                <div class="invalid-feedback">Please input aggregator name !</div>
                            )}
                        </div>

                        <div className="col-md-3 mb-3">
                            <div className='custom-control custom-checkbox custom-control-inline'>
                                <input type="checkbox" disabled={editFlag} {...register(`inventory`)} className="custom-control-input" id='inventory' style={{ height: "15px", width: "15px" }} />
                                <label className="custom-control-label" for="inventory"> Inventory </label>
                            </div>
                        </div>
                        <div className="col-md-3 mb-3">
                            <div className='custom-control custom-checkbox custom-control-inline'>
                                <input type="checkbox" disabled={editFlag} {...register(`physical_sim`)} className="custom-control-input" id='physical_sim' style={{ height: "15px", width: "15px" }} />
                                <label className="custom-control-label" for="physical_sim">Physical Sim</label>
                            </div>
                        </div>
                        <div className="col-md-3 mb-3">
                            <div className='custom-control custom-checkbox custom-control-inline'>
                                <input type="checkbox" disabled={editFlag} {...register(`esim`)} className="custom-control-input" id='esim' style={{ height: "15px", width: "15px" }} />
                                <label className="custom-control-label" for="esim">E-Sim</label>
                            </div>
                        </div>
                        <div className="col-md-3 mb-3">
                            <div className='custom-control custom-checkbox custom-control-inline'>
                                <input type="checkbox" disabled={editFlag} {...register(`sku`)} className="custom-control-input" id='sku' style={{ height: "15px", width: "15px" }} />
                                <label className="custom-control-label" for="sku">SKU</label>
                            </div>
                        </div>

                    </div>
                    {fields && fields.length > 0 && fields.map((fieldData, index) => {
                        return (
                            <div className="form-row align-items-center" key={fieldData.id}>
                                <div className="col mb-3">
                                    <label>Key</label>
                                    <input type="text" class="form-control input-width" placeholder="Key" name="key" {...register(`metaData.${index}.key`, {
                                        required: 'Please input Key !',
                                    })} />
                                    {errors && errors.metaData && errors.metaData[index] && errors.metaData[index].key && (
                                        <div className="invalid-feedback">{errors.metaData[index].key.message}</div>
                                    )}
                                </div>
                                <div className="col mb-3">
                                    <label>value</label>
                                    <input type="text" class="form-control input-width" placeholder="Value" name="value" {...register(`metaData.${index}.value`, {
                                        required: 'Please input value !',
                                    })} />
                                    {errors && errors.metaData && errors.metaData[index] && errors.metaData[index].value && (
                                        <div className="invalid-feedback">{errors.metaData[index].value.message}</div>
                                    )}
                                </div>

                                {fields.length > 1 && (
                                    <div className='remove-btnagg ' onClick={() => remove(index)}>
                                        <CloseCircleFill size={18} style={{ fontWeight: "bold", color: '#fff' }} />
                                    </div>
                                )}
                            </div>
                        );
                    })}

                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-sm-12 text-right">
                            <button type="button" className='btn btn-outline-primary font-weight-bold' onClick={() => {
                                append({
                                    key: "",
                                    value: "",
                                });
                                setIsAddClicked(true);
                            }}><AddFillIcon size={16} style={{ fontWeight: "bold" }} />Add More</button>
                        </div>
                    </div>
                    <div className='text-right row pt-4'>
                        <div className="col-md-12">
                            <button type="button" class="btn btn-secondary mr-2" onClick={handleCancel}>Close</button>
                            <Button title={formButton} style="minWidth:80px" buttonClass="btn btn-primary" buttonType='submit' />
                        </div>
                    </div>
                </form>

            </Modal>

            <Modal
                showModal={previewModel}
                title="Aggregator Details"
                onHide={handleCancel}
                width={800}
                showFooter={false}
            >


                <table class="table table table-striped table-bordered ">

                    <thead className='t-thead'>
                        <tr>
                            <th className='t-th' scope="col-6">KEY</th>
                            <th className='t-th' scope="col-6">VALUE</th>

                        </tr>
                    </thead>
                    <tbody>
                        {previewAggregatorData && previewAggregatorData.length > 0 &&
                            previewAggregatorData.map((myData, index) => (
                                <tr>
                                    <td className='t-td' ><p>{myData.key}</p></td>
                                    <td className='t-td' ><p>{myData.value}</p></td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </Modal>

        </CardLayout>
    )
}

export default Aggregator