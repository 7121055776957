import React, { useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import ArrowLeftLineIcon from 'remixicon-react/ArrowLeftLineIcon';
import { Link } from "react-router-dom";
import AuthActions from '../redux/auth/actions';
import Button from "../components/utils/Button";
import Alert from '../components/utils/Alert';
import sideImage from '../assets/images/slide-1.png';
import sideImagetwo from '../assets/images/slide-2.png';
import sideImagethree from '../assets/images/slide-3.png';
import logo from '../assets/images/logo-white.png'
import mailIcon from '../assets/images/mail.png'

const { forgetPassword, authReset } = AuthActions;

const ForgetPassword = () => {
    const dispatch = useDispatch();
    const Auth = useSelector((state) => state.auth);
    const { register, handleSubmit, formState: { errors }, } = useForm();
    useEffect(() => {
        dispatch(authReset());
    }, []);

    const handleForgetPassword = (data) => {
        dispatch(forgetPassword(data));
    }

    return (
        <>
            <section className="sign-in-page mt-lg-5 mt-md-5 pt-4">
                <div className="container  p-0 bg-white">
                    <Link to={'/sign-in'} style={style.backbtn}>
                        <ArrowLeftLineIcon color="#0094b3" size={26} />
                    </Link>
                    <div className="row no-gutters">
                        <div className="col-md-6 align-self-center">
                            {
                                Auth.forgetPasswordMessage ?

                                    <div class="sign-in-from">
                                        <img src={mailIcon} width="80" alt="" />
                                        <h1 class="mt-3 mb-0">Success !</h1>
                                        <p>Please check your email to reset your password</p>
                                        <div class="d-inline-block w-100">
                                            <Link to='/sign-in' class="btn btn-primary mt-3 text-white">Back to Home</Link>
                                        </div>
                                    </div>
                                    :

                                    <div className="sign-in-from">
                                        <a className="sign-in-logo mb-2 text-center d-lg-none d-md-none d-sm-block" href="#"><img src="./assets/images/logo-full2.png" className="img-fluid" alt="logo" /></a>
                                        <h1 className="mb-0">Forget Password</h1>
                                        <p>Enter your email address and we'll send you an email with instructions to reset your password.</p>
                                        {Auth.forgetPasswordError ? <Alert type="alert-danger" text={Auth.forgetPasswordError} /> : ''}
                                        <form className="mt-4" onSubmit={handleSubmit(handleForgetPassword)}>
                                            <div className="form-group">
                                                <label htmlFor="username">Username</label>
                                                <input type="text" className="form-control" name='Username' {...register('Username', {
                                                    required: true
                                                })} id="username" placeholder="Enter username" />
                                                {errors.Username && errors.Username.type === "required" != '' ? <div className="invalid-feedback">Username is required</div> : ''}
                                            </div>

                                            <div className="d-inline-block w-100">
                                                <div className="custom-control custom-checkbox d-inline-block mt-2" style={{ padding: 'unset', cursor: 'pointer' }}>
                                                    {/* <Link to={'/sign-in'}>
                                                        <ArrowLeftLineIcon color="#0094b3" size={24} />
                                                    </Link> */}
                                                </div>
                                                <Button title='Reset Password' buttonClass="btn btn-primary float-right" loading={Auth.forgetPasswordLoading} buttonType='submit' />
                                            </div>

                                        </form>
                                    </div>


                            }
                        </div>
                        <div className="col-md-6 text-center d-none d-lg-block d-md-block d-sm-none">
                            <div className="sign-in-detail text-white">
                                <a className="sign-in-logo mb-5" href="#"><img src={logo} className="img-fluid" alt="logo" /></a>

                                <OwlCarousel items={1}>
                                    <div className="item">
                                        <img src={sideImage} className="img-fluid mb-4" alt="logo" />

                                    </div>
                                    <div className="item">
                                        <img src={sideImagetwo} className="img-fluid mb-4" alt="logo" />

                                    </div>
                                    <div className="item">
                                        <img src={sideImagethree} className="img-fluid mb-4" alt="logo" />

                                    </div>
                                </OwlCarousel>

                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

const style = {
    backbtn: {
        position: 'absolute',
        top: '85px',
        left: '208px',
    }
}

export default ForgetPassword