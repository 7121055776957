import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import CheckboxCircleLine from 'remixicon-react/CheckboxCircleLineIcon';
import CloseCircleLineIcon from 'remixicon-react/CloseCircleLineIcon';
import EyeLineIcon from 'remixicon-react/EyeLineIcon';
import EyeOffLineIcon from 'remixicon-react/EyeOffLineIcon';
import { PASSWORD_VALIDATE } from '../../helpers/constant';
import AuthActions from '../../redux/auth/actions'
import countryAction from '../../redux/country/actions'
import profileAction from '../../redux/profile/actions'
import authAction from '../../redux/auth/actions';
import Modal from './Model';
import Button from './Button';
import Alert from './Alert';

const { openChangePasswordModal, closeChangePasswordModal, changePasswordPortal, changePasswordPortalSuccessModalClose, getUserDetails } = AuthActions;
const { getCountry } = countryAction;
const { getUserDataByToken } = authAction;
const { prepareEditFormModel, resetProfile, getProfileDataList, editProfileList } = profileAction;

const Style = {
    modalContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: 'center'
    }
}


function Profile() {
    const dispatch = useDispatch();
    const ProfileState = useSelector((state) => state.profile);
    const AuthState = useSelector((state) => state.auth);
    const CountryState = useSelector((state) => state.country);
    const tokenData = useSelector((state) => state.auth.tokenData);
    const userDetails = AuthState.userDetails && AuthState.userDetails.length > 0 ? AuthState.userDetails[0] : null;
    const { register, handleSubmit, formState: { errors }, reset, watch } = useForm();
    const [accountBalance, setAccountBalance] = React.useState('0.00');
    const [editedData, setEditedData] = useState({
        name: '',
        contactEmailID: '',
        contactNumber: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
        countryID: 0
    });
    const password = watch('newPassword', '');
    const confirmPassword = watch('confirmPassword', '');
    const [oldPasswordShow, setOldPasswordShow] = useState(false);
    const [newPasswordShow, setNewPasswordShow] = useState(false);
    const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);

    const {
        register: registerEditProfile,
        handleSubmit: handleSubmitEditProfile,
        formState: {
            errors: errorsEditProfile
        },
        reset: resetEditProfile,
        watch: watchEditProfile
    } = useForm({
        defaultValues: editedData
    });


    useEffect(() => {
        dispatch(getCountry());
        dispatch(getProfileDataList());
        dispatch(getUserDetails());
        dispatch(getUserDataByToken());
    }, [dispatch]);



    useEffect(() => {
        if (tokenData && tokenData !== undefined && tokenData.length > 0) {
            const balance = tokenData[0].AccountBalance !== null ? tokenData[0].AccountBalance : 0.00;
            const newBalance = balance; // .toFixed(2)
            setAccountBalance(newBalance.toFixed(2));
        }
    }, [tokenData]);


    useEffect(() => {
        if (ProfileState.profileListResult.length > 0) {
            setEditedData({
                name: ProfileState.profileListResult[0].Name,
                contactEmailID: ProfileState.profileListResult[0].ContactEmailID,
                contactNumber: ProfileState.profileListResult[0].ContactNumber,
                address: ProfileState.profileListResult[0].Address,
                city: ProfileState.profileListResult[0].City,
                state: ProfileState.profileListResult[0].State,
                zipCode: ProfileState.profileListResult[0].ZipCode,
                countryID: ProfileState.profileListResult[0].CountryID,
            });
        }
    }, [ProfileState.profileListResult]);

    const logout = () => {
        localStorage.clear();
        window.location.href = '/sign-in';
    }


    const handleChangePasswordModal = () => {
        reset();
        dispatch(openChangePasswordModal());
    }

    const handleCloseChangePasswordModal = () => {
        setOldPasswordShow(false);
        setNewPasswordShow(false);
        setConfirmPasswordShow(false);
        dispatch(closeChangePasswordModal());
    }

    const handlePasswordChange = (data) => {
        dispatch(changePasswordPortal(data));
    }

    const handleEditProfileModal = () => {
        resetEditProfile(editedData);
        dispatch(prepareEditFormModel());
    }

    const handleCloseEditProfileModal = () => {
        dispatch(resetProfile());
    }

    const handleEditProfile = (data) => {
        dispatch(editProfileList(ProfileState.profileListResult[0].UserID, data));
    }

    const handleSuccessClose = () => {
        dispatch(changePasswordPortalSuccessModalClose());
        dispatch(resetProfile());
    }

    return (
        <>
            {/* edit profile success */}
            <Modal
                showModal={ProfileState.profileMessage}
                title="Success"
                onHide={handleSuccessClose}
                onSaveChanges={handleSuccessClose}
                showFooter={false}
                alignment='modal-dialog-centered'
            >
                <div style={Style.modalContent}>
                    <CheckboxCircleLine size="4em" color='#00b894' />
                    <p className='text-dark'>{ProfileState.profileMessage}</p>
                </div>

            </Modal>

            {/* edit profile */}
            <Modal
                showModal={ProfileState.prepareEditFormModel}
                title={'EDIT PROFILE'}
                onHide={() => handleCloseEditProfileModal()}
                width={700}
                showFooter={false}
            >

                {AuthState.changePasswordPortalError ? <Alert type="alert-danger" text={AuthState.changePasswordPortalError} /> : ''}

                <form onSubmit={handleSubmitEditProfile(handleEditProfile)}>
                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <label>Name</label>
                            <input type="text" placeholder="Name" className="form-control" name="name" {...registerEditProfile("name", {
                                required: true,
                            })} />
                            {errorsEditProfile.name?.type === "required" && (
                                <div className="invalid-feedback">Name is required !</div>
                            )}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>E-Mail</label>
                            <input type="text" placeholder="E-Mail" className="form-control" name="contactEmailID" {...registerEditProfile("contactEmailID", {
                                required: true,
                                pattern: /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/
                            })} />
                            {errorsEditProfile.contactEmailID?.type === "required" && (<div className="invalid-feedback">E-Mail is required !</div>)}
                            {errorsEditProfile.contactEmailID && errorsEditProfile.contactEmailID.type === "pattern" != '' ? <div className="invalid-feedback">Email is not valid !</div> : ''}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Phone Number</label>
                            <input type="text" placeholder="Phone Number" className="form-control" name="contactNumber" {...registerEditProfile("contactNumber", {
                                required: true,
                                pattern: /\d{10}/,
                                maxLength: 10
                            })} />
                            {errorsEditProfile.contactNumber?.type === "required" && (<div className="invalid-feedback">Phone Number is required !</div>)}
                            {errorsEditProfile.contactNumber?.type === "pattern" && (<div className="invalid-feedback">Invalid phone number !</div>)}
                            {errorsEditProfile.contactNumber?.type === "maxLength" && (<div className="invalid-feedback">Invalid phone number !</div>)}
                        </div>
                        {/* <div className="col-md-6 mb-3">
                            <label>Website</label>
                            <input type="text" placeholder="Website" className="form-control" name="website" {...registerEditProfile("website", {
                                required: true,
                            })} />
                            {errorsEditProfile.website?.type === "required" && (<div className="invalid-feedback">Website is required !</div>)}
                        </div> */}
                        <div className="col-md-6 mb-3">
                            <label>Address</label>
                            <input type="text" placeholder="Address" className="form-control" name="address" {...registerEditProfile("address", {
                                required: true,
                            })} />
                            {errorsEditProfile.address?.type === "required" && (<div className="invalid-feedback">Address is required !</div>)}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>City</label>
                            <input type="text" placeholder="City" className="form-control" name="city" {...registerEditProfile("city", {
                                required: true,
                            })} />
                            {errorsEditProfile.city?.type === "required" && (<div className="invalid-feedback">City is required !</div>)}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>State</label>
                            <input type="text" placeholder="State" className="form-control" name="state" {...registerEditProfile("state", {
                                required: true,
                            })} />
                            {errorsEditProfile.state?.type === "required" && (<div className="invalid-feedback">State is required !</div>)}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>ZipCode</label>
                            <input type="text" placeholder="ZipCode" className="form-control" name="zipCode" {...registerEditProfile("zipCode", {
                                required: true,
                            })} />
                            {errorsEditProfile.zipCode?.type === "required" && (<div className="invalid-feedback">ZipCode is required !</div>)}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Country</label>
                            <select className='form-control' name='countryID' {...registerEditProfile("countryID", {
                                required: true,
                            })}>
                                {
                                    CountryState.countryResult && CountryState.countryResult.length > 0 ? CountryState.countryResult.map((item, index) => {
                                        return <>
                                            {/* <ReactCountryFlag countryCode="US" svg /> */}
                                            <option key={index} value={item.ID}> {item.CountryName}</option>
                                        </>
                                    }) : <option disabled={true}>Select Country</option>
                                }
                            </select>
                            {errorsEditProfile.countryID?.type === "required" && (<div className="invalid-feedback">Country is required !</div>)}
                        </div>
                    </div>

                    <div className='text-right row'>
                        <div className="col-md-12">
                            <button type="button" className="btn btn-secondary mr-2" onClick={() => handleCloseEditProfileModal()}>Close</button>
                            <Button title={'Submit'} buttonClass="btn btn-primary" loading={ProfileState.loading} buttonType='submit' />
                        </div>
                    </div>
                </form>

            </Modal>

            {/* change password success */}
            <Modal
                showModal={AuthState.changePasswordPortalSuccessModal}
                title="Success"
                onHide={handleSuccessClose}
                onSaveChanges={handleSuccessClose}
                showFooter={false}
                alignment='modal-dialog-centered'
            >
                <div style={Style.modalContent}>
                    <CheckboxCircleLine size="4em" color='#00b894' />
                    <p className='text-dark'>{AuthState.changePasswordPortalMessage}</p>
                </div>

            </Modal>

            {/* change password */}
            <Modal
                showModal={AuthState.changePasswordPortalModal}
                title={'CHANGE PASSWORD'}
                onHide={() => handleCloseChangePasswordModal()}
                width={500}
                showFooter={false}
            >

                {AuthState.changePasswordPortalError ? <Alert type="alert-danger" text={AuthState.changePasswordPortalError} /> : ''}

                <form onSubmit={handleSubmit(handlePasswordChange)}>
                    <div className="form-row">
                        <div className="col-md-12 mb-3" style={{ position: 'relative' }}>
                            <label>Old Password</label>
                            <input type={oldPasswordShow ? 'text' : 'password'} placeholder="Old Password" className="form-control" name="oldPassword" {...register("oldPassword", {
                                required: true,
                            })} />
                            {
                                oldPasswordShow ? <EyeLineIcon size="1.1em" style={Styles.eyeIcon} onClick={() => setOldPasswordShow((pre) => !pre)} /> : <EyeOffLineIcon size="1.1em" style={Styles.eyeIcon} onClick={() => setOldPasswordShow((pre) => !pre)} />
                            }
                            {errors.oldPassword?.type === "required" && (
                                <div className="invalid-feedback">Old password is required !</div>
                            )}
                        </div>

                        <div className="col-md-12 mb-3">
                            <label>New Password</label>
                            <input type={newPasswordShow ? 'text' : 'password'} placeholder="New Password" className="form-control" name="newPassword" {...register("newPassword", {
                                required: true,
                                // minLength: 4,
                                validate: (value) => {
                                    return PASSWORD_VALIDATE.test(value)
                                }
                            })} />
                            <div style={Styles.iconContainer}>
                                {
                                    newPasswordShow ? <EyeLineIcon style={{ cursor: 'pointer' }} size="1.1em" onClick={() => setNewPasswordShow((pre) => !pre)} /> : <EyeOffLineIcon size="1.1em" style={{ cursor: 'pointer' }} onClick={() => setNewPasswordShow((pre) => !pre)} />
                                }
                                {errors.newPassword ? <CloseCircleLineIcon size="1.1em" color='#e74c3c' /> : ''}
                                {password !== '' && !errors.newPassword ? <CheckboxCircleLine size="1.1em" color='#00b894' /> : ''}
                            </div>

                            {errors.newPassword?.type === "required" && (<div className="invalid-feedback">New password is required !</div>)}
                            {/* {errors.newPassword && errors.newPassword.type === "minLength" != '' ? <div className="invalid-feedback">Confirm password should contain at least 4 characters !</div> : ''} */}
                            {errors.newPassword && errors.newPassword.type === "validate" != '' ? <div className="invalid-feedback">Invalid password format !</div> : ''}
                        </div>

                        <div className="col-md-12 mb-3">
                            <label>Confirm Password</label>
                            <input type={confirmPasswordShow ? 'text' : 'password'} placeholder="Confirm Password" className="form-control" name="confirmPassword" {...register("confirmPassword", {
                                required: true,
                                minLength: 4,
                                validate: (value) => {
                                    return value === password;
                                }
                            })} />
                            <div style={Styles.iconContainer}>
                                {
                                    confirmPasswordShow ? <EyeLineIcon style={{ cursor: 'pointer' }} size="1.1em" onClick={() => setConfirmPasswordShow((pre) => !pre)} /> : <EyeOffLineIcon size="1.1em" style={{ cursor: 'pointer' }} onClick={() => setConfirmPasswordShow((pre) => !pre)} />
                                }
                                {
                                    errors.confirmPassword ? <CloseCircleLineIcon size="1.1em" color='#e74c3c' /> : ''
                                }
                                {confirmPassword !== '' && !errors.confirmPassword ? <CheckboxCircleLine size="1.1em" color='#00b894' /> : ''}

                            </div>

                            {errors.confirmPassword?.type === "required" && (<div className="invalid-feedback">Confirm password is required !</div>)}
                            {errors.confirmPassword && errors.confirmPassword.type === "minLength" != '' ? <div className="invalid-feedback">Confirm password should contain at least 4 characters !</div> : ''}
                            {errors.confirmPassword && errors.confirmPassword.type === "validate" != '' ? <div className="invalid-feedback">Password and confirm password not match !</div> : ''}
                        </div>
                    </div>

                    <div className='text-right row'>
                        <div className="col-md-12">
                            <button type="button" className="btn btn-secondary mr-2" onClick={() => handleCloseChangePasswordModal()}>Close</button>
                            <Button title={'Submit'} buttonClass="btn btn-primary" loading={AuthState.changePasswordPortalLoading} buttonType='submit' />
                        </div>
                    </div>
                </form>
            </Modal>

            <ul className="navbar-list">
                <li>
                    <a href="#" className="search-toggle iq-waves-effect d-flex align-items-center">
                        {/* <img src="./assets/images/user/1.jpg" className="img-fluid rounded mr-3" alt="user" /> */}
                        <div className="img-fluid rounded-circle">
                            <span>{userDetails ? userDetails.Username.charAt(0).toUpperCase() : ''}</span>
                        </div>
                    </a>
                    <div className="iq-sub-dropdown iq-user-dropdown">
                        <div className="iq-card shadow-none m-0">
                            <div className="iq-card-body p-2 ">
                                <div className="bg-light p-2 mb-2" style={{ height: "86px" }}>
                                    <h5 className="mb-0 text-dark line-height font-weight-bold text-capitalize">{userDetails ? userDetails.Username : ''}</h5>
                                    <div className='d-flex align-items-baseline justify-content-between' >
                                        <span className="text-dark font-size-12">{userDetails ? userDetails.UserRole : ''}</span>
                                        <p className="text-dark font-size-18 font-weight-bold">
                                            <span style={{ color: accountBalance && accountBalance < 0 ? "#f14336" : "#0aa06e" }}>{accountBalance ? accountBalance < 0 ? `-£${Math.abs(accountBalance)}` : `£${accountBalance}` : '£0.00'}</span>
                                        </p>
                                    </div>


                                </div>
                                <Link to="/my-profile" className="iq-sub-card iq-bg-primary-hover" style={{ padding: "7px 5px" }}>
                                    <div className="media align-items-center">
                                        <div className="iq-card-icon iq-bg-primary" style={{ width: "30px", height: "30px", borderRadius: "8px", fontSize: "1.2rem", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <i className="ri-file-user-line text-dark"></i>
                                        </div>
                                        <div className="media-body ml-3">
                                            <h6 className="mb-1 ">My Profile</h6>
                                            {/* <p className="mb-0 font-size-12">View personal profile details.</p> */}
                                        </div>
                                    </div>
                                </Link>
                                <a href="javascript:void(0)" onClick={() => handleEditProfileModal()} className="iq-sub-card iq-bg-primary-hover" style={{ padding: "7px 5px" }}>
                                    <div className="media align-items-center">
                                        <div className="iq-card-icon iq-bg-primary" style={{ width: "30px", height: "30px", borderRadius: "8px", fontSize: "1.2rem", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <i className="ri-profile-line text-dark"></i>
                                        </div>
                                        <div className="media-body ml-3">
                                            <h6 className="mb-1 ">Edit Profile</h6>
                                            {/* <p className="mb-0 font-size-12">Modify your personal details.</p> */}
                                        </div>
                                    </div>
                                </a>
                                <a href='javascript:void(0)' onClick={() => handleChangePasswordModal()} className="iq-sub-card iq-bg-primary-hover" style={{ padding: "7px 5px" }}>
                                    <div className="media align-items-center">
                                        <div className="iq-card-icon iq-bg-primary" style={{ width: "30px", height: "30px", borderRadius: "8px", fontSize: "1.2rem", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <i className="ri-lock-line text-dark"></i>
                                        </div>
                                        <div className="media-body ml-3">
                                            <h6 className="mb-1 ">Change password</h6>
                                            {/* <p className="mb-0 font-size-12">Change password.</p> */}
                                        </div>
                                    </div>
                                </a>
                                <div className="d-inline-block w-100 text-center pt-3 pb-1 pl-1 pr-1">
                                    <button className="bg-primary iq-sign-btn" style={{ width: "100%", borderRadius: "8px" }} role="button" onClick={() => logout()}>Sign out<i className="ri-login-box-line ml-2"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </>
    )
}

const Styles = {
    eyeIcon: {
        position: 'absolute',
        right: '10px',
        top: '40px',
        cursor: 'pointer'
    },
    iconContainer: {
        position: 'absolute',
        top: '35px',
        right: '10px'
    }
}

export default Profile