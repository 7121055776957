import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import AuthActions from '../../redux/auth/actions';
import Modal from './Model';
import ErrorWarningLine from 'remixicon-react/ErrorWarningLineIcon';

const { getUserDetails } = AuthActions;

const NonVerifyDealerModel = () => {
    const dispatch = useDispatch();
    const AuthState = useSelector((state) => state.auth);
    const userDetails = AuthState.userDetails && AuthState.userDetails.length > 0 ? AuthState.userDetails[0] : null;
    const [openModel, setOpenModel] = useState()

    useEffect(() => {
        dispatch(getUserDetails());
    }, []);

    useEffect(() => {
        if (userDetails && localStorage.getItem('user') && (userDetails.Address =='null' || 0)) {
            setOpenModel(true)
        }
    })

    const handleClose = () => {
        setOpenModel(false)
        localStorage.removeItem('user');
    }

    return (
        <>
            <div className="form-col ml-3 mr-3">
                <Modal
                    showModal={openModel}
                    title={'INFO'}
                    onHide={handleClose}
                    width={550}
                    showFooter={false}
                >
                    <div className='d-flex flex-column mb-3 justify-content-center align-items-center'>
                        <ErrorWarningLine size={38} className='text-info' />
                        <p >Please Update Your Address !</p>
                    </div>
                </Modal>
            </div>

        </>
    )
}

export default NonVerifyDealerModel
