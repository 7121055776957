import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { getList, addData, editData } from '../../helpers/fackBackend_Helper';

function* getBannerDataResponse() {
    try {
        const response = yield call(getList, '/banner');
        if (response.response.status = 1) {
            yield put(
                actions.getBannerSuccess(
                    response.response.Data
                )
            );
        } else {
            yield put(actions.getBannerFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getBannerFailed(error.Message));
    }
}

function* addBannerResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/banner', data);
        if (response.response.Status) {
            const responseList = yield call(getList, '/banner');
            if (responseList.response.Status) {
                yield put(
                    actions.addBannerSuccess(
                        response.response.Message, responseList.response.Data
                    )
                );
            } else {
                yield put(actions.addBannerFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.addBannerFailed(
                response.response.Message
            ));
        }
    } catch (error) {
        yield put(actions.addBannerFailed(error));
    }
}

function* changeBannerStatusResponse({ payload: { data } }) {
    try {
        const response = yield call(editData, '/banner', data);
        if (response.response.Status) {
            const responseList = yield call(getList, '/banner');
            if (responseList.response.Status) {

                yield put(
                    actions.changeBannerStatusSuccess(
                        response.response.Data.Message, responseList.response.Data
                    )
                );
            } else {
                yield put(actions.changeBannerStatusFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.changeBannerStatusFailed(
                response.response.Message
            ));
        }
    } catch (error) {
        yield put(actions.changeBannerStatusFailed(error));
    }
}

function* deleteBannerResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/banner/delete', data);
        if (response.response.Status) {
            const responseList = yield call(getList, '/banner');
            if (responseList.response.Status) {
                yield put(
                    actions.deleteBannerSuccess(
                        response.response.Data.Message, responseList.response.Data
                    )
                );
            } else {
                yield put(actions.deleteBannerFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.deleteBannerFailed(
                response.response.Message
            ));
        }
    } catch (error) {
        yield put(actions.deleteBannerFailed(error));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.GET_BANNER_DATA, getBannerDataResponse)]);
    yield all([takeEvery(actions.ADD_BANNER, addBannerResponse)]);
    yield all([takeEvery(actions.CHANGE_BANNER_STATUS, changeBannerStatusResponse)]);
    yield all([takeEvery(actions.DELETE_BANNER, deleteBannerResponse)]);
}