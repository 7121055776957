import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { getList, addData, editData } from '../../helpers/fackBackend_Helper';

function* getActivityResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/getActivity', data);
        if (response.Status) {
            yield put(actions.getActivitySuccess(response.Data));
        } else {
            yield put(actions.getActivityFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.getActivityFailed(error.Message));
    }
}


export default function* rootSaga() {
    yield all([takeEvery(actions.GET_ACTIVITY, getActivityResponse)]);
}