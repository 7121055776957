import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Refund2FillIcon from 'remixicon-react/Refund2FillIcon';
import RefundLine from 'remixicon-react/RefundLineIcon'
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { useForm } from 'react-hook-form';
import Calendar2LineIcon from 'remixicon-react/Calendar2LineIcon';
import DatePicker from "react-datepicker";
import CardLayout from '../../../components/utils/CardLayout';
import SearchBar from '../../../components/utils/SearchBar';
import Table from '../../../components/utils/Table';
import Tag from '../../../components/utils/Tag'
import Modal from '../../../components/utils/Model'
import Button from '../../../components/utils/Button'
import RefundRequestSagas from '../../../redux/refundRequest/actions';
import Styles from './RefundRequest.module.css'

const { getRefundRequest, openRefundRequestModal, closeRefundRequestModal, toRefundApproved, toRefundCancelled } = RefundRequestSagas;
const dateFormat = process.env.REACT_APP_DATE_FORMAT;
const TIMEZONE = process.env.REACT_APP_TIMEZONE;

const RefundRequest = () => {
    const dispatch = useDispatch();
    const refundRequestState = useSelector((state) => state.refundRequest);
    useEffect(() => {
        if (refundRequestState.refundMessage) {
            dispatch(getRefundRequest(searchData));
        }
    }, [refundRequestState.refundMessage]);


    const [filterText, setFilterText] = useState("");
    const [cancelState, setCancelState] = useState(false);
    const [orderRefundId, setOrderRefundId] = useState(0);
    const [toDate, setToDate] = useState(moment().startOf(2,'days').toDate());
    const [fromDate, setFromDate] = useState(moment().subtract(2, 'days').toDate());
    const datePickerRef = useRef(null);
    const datePickerRef2 = useRef(null);
    const [searchData, setSearchData] = useState({
        status: 37,
        toDate,
        fromDate
    });

    const selectPeriodList = [
        { value: 'today', title: 'Today' },
        { value: 'month', title: 'Last 30 Days' },
        { value: 'quarter', title: 'Quarter' },
        { value: 'year', title: 'One Year' }
    ]

    const predefinedPeriods = {
        today: moment().startOf('day').toDate(),
        yesterday: moment().subtract(1, 'days').startOf('day').toDate(),
        '15days': moment().subtract(15, 'days').startOf('day').toDate(),
        month: moment().subtract(30, 'days').startOf('day').toDate(),
        quarter: moment().subtract(3, 'months').startOf('day').toDate(),
        year: moment().subtract(1, 'year').startOf('day').toDate(),
    };

    const changeDateHandler = (item) => {
        setFromDate(predefinedPeriods[item]);
        const formattedDate = moment(predefinedPeriods[item]).format("YYYY-MM-DD");
        const currentDate = moment().format("YYYY-MM-DD");
        const apiData = {
            fromDate: formattedDate,
            toDate: currentDate,
            dealerId: "",
            planId: "",
            availableForFlag: "18",
            availableForID: ""
        }
        dispatch(getRefundRequest(apiData))
    }

    const { register, handleSubmit, formState: { errors }, reset, watch } = useForm();

    const openDatePicker = () => {
        datePickerRef.current.setOpen(true);
    };

    const openDatePicker2 = () => {
        datePickerRef2.current.setOpen(true);
    };

    const onSubmit = (data) => {
        data.fromDate = moment(new Date(fromDate)).tz(TIMEZONE).format("YYYY-MM-DD HH:mm:ss")
        data.toDate = moment(new Date(toDate)).tz(TIMEZONE).format("YYYY-MM-DD HH:mm:ss")
        setSearchData(data);
        dispatch(getRefundRequest(data));
    }

    const handleFilterChange = (value) => {
        setFilterText(value);
    };

    const handleOpenRefundRequestModal = (refundId) => {
        setOrderRefundId(refundId);
        setCancelState(false);
        reset();
        dispatch(openRefundRequestModal());
    }

    const handleCloseRefundRequestModal = () => {
        dispatch(closeRefundRequestModal());
    }

    const handleRequestAction = (data) => {
        if (typeof (data.remark) === 'undefined') {
            dispatch(toRefundApproved(orderRefundId));
        } else {
            if (data.remark !== '') {
                data.orderRefundID = orderRefundId;
                dispatch(toRefundCancelled(data));
            } else {
                dispatch(toRefundApproved(orderRefundId));
            }
        }
    }

    const handleCancelRefund = () => {
        setCancelState(true);
    }

    const handleAcceptRefund = () => {
        setCancelState(false);
        reset();
    }

    const columns = [
        { displayName: '#', key: 'key' },
        { displayName: 'ICCID', key: 'IccId' },
        { displayName: 'PLAN NAME', key: 'PlanName' },
        { displayName: 'AMOUNT', key: 'Amount' },
        { displayName: 'ORDER REFUND NUMBER', key: 'OrderRefundNumber' },
        { displayName: 'REQUEST DATE', key: 'RequestDate' },
        { displayName: 'STATUS', key: 'Status' },
        { displayName: 'ACTION', key: 'Action' }
    ];

    let Refunddata = useMemo(() => {
        if (refundRequestState.refundRequestResult.length > 0) {
            return refundRequestState.refundRequestResult;
        }
        return [];
    }, [refundRequestState.refundRequestResult]);

    const filteredItems = Refunddata && Refunddata.length > 0 && Refunddata.filter(
        item => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1
    );
 
    var dataSource = filteredItems && filteredItems.length > 0 && filteredItems.map((RefundItem, key) => {
        return {
            key: key + 1,
            IccId: RefundItem.ICCID,
            PlanName: RefundItem.PlanName,
            Amount: `£${RefundItem.Amount ? RefundItem.Amount.toFixed(2) :'0.00'}`,
            OrderRefundNumber: RefundItem.OrderRefundNumber,
            RequestDate: moment(RefundItem.CreatedDtTm).format(dateFormat),
            Status: <Tag color={`${RefundItem.RefundStatusValue == 'Pending' ? "badge-warning" : "badge-success"}`} title={RefundItem.RefundStatusValue} />,
            Action: RefundItem.RefundStatusValue == 'Pending' ? <span data-toggle="tooltip" data-placement="top" title="Refund request"><RefundLine size="1.2rem" style={{ color: "#0094b3" }} onClick={() => handleOpenRefundRequestModal(RefundItem.ID)} /></span> : ''
        }
    });
    return (
        <CardLayout title="Refund Request">

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-row">

                    <div className="col-md-3 col-lg-2 mb-2 d-flex flex-column">
                        <label>From Date</label>
                        <Calendar2LineIcon onClick={openDatePicker} size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', 'top': '36px', right: '12px', zIndex: 1 }} />
                        <DatePicker
                            ref={datePickerRef}
                            selected={fromDate}
                            className="form-control"
                            onChange={(date) => setFromDate(date)}
                            dateFormat="yyyy-MM-dd"
                            name="fromDate"
                            showMonthDropdown
                            showYearDropdown
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                            value={fromDate}
                        />
                    </div>

                    <div className="col-md-3 col-lg-2 mb-2 d-flex flex-column">
                        <label>To Date</label>
                        <Calendar2LineIcon onClick={openDatePicker2} size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', 'top': '36px', right: '12px', zIndex: 1 }} />
                        <DatePicker
                            ref={datePickerRef2}
                            selected={toDate}
                            className="form-control center"
                            onChange={(date) => setToDate(date)}
                            dateFormat="yyyy-MM-dd"
                            name="todate"
                            showMonthDropdown
                            showYearDropdown
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                            value={toDate}
                        />
                        {errors.dealerName?.type === "required" && (
                            <div className="invalid-feedback">Date is required !</div>
                        )}
                    </div>

                    <div className="col-md-3 col-lg-2 mb-2">
                        <label>Request Status</label>
                        <select className="form-control" placeholder="Select" name="status"  {...register("status", {
                        })} >
                            <option value="37" selected>Pending</option>
                            <option value="40">Approved</option>
                            <option value="41">Rejected</option>
                        </select>
                    </div>

                    <div className="col-md-3 col-lg-2 mb-2  mt-md-4">
                      
                                <Button title="Search" loading={false}  buttonClass={`btn btn-primary Activity_searchBtn__GlBtC mt-1 btn-md ${Styles.searchBtn}`} buttonType='submit' />
                           
                    </div>

                </div>
            </form>
            <div className="col-md-6 mb-3 p-0">
                <div className="form-row">
                    {selectPeriodList.map((item, index) =>
                        <ul className='p-1'>
                            <li className={Styles.selectUl} key={index} onClick={() => changeDateHandler(item.value, index)}
                            >{item.title}</li>
                        </ul>
                    )}
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-md-6">
                    <SearchBar onFilter={handleFilterChange} filterText={filterText} /></div></div>
            <Table columns={columns} data={dataSource} pagination={false} loading={refundRequestState.loading} />

            <Modal
                title="REFUND REQUEST"
                showModal={refundRequestState.refundModal}
                onHide={handleCloseRefundRequestModal}
                showFooter={false}
                width={500}
            >
                <div className='text-center row'>
                    <div className="col-md-6  mb-4 d-flex justify-content-center align-items-center">
                        <button onClick={handleCancelRefund} style={cancelState ? Style.cancelRefundButtonActive : Style.cancelRefundButton} className='btn w-100'>CANCEL REFUND</button>
                    </div>
                    <div className="col-md-6  mb-4 d-flex justify-content-center align-items-center">
                        <button onClick={handleAcceptRefund} style={!cancelState ? Style.acceptRefundButtonActive : Style.acceptRefundButton} className='btn w-100'>ACCEPT REFUND</button>
                    </div>
                </div>

                <form onSubmit={handleSubmit(handleRequestAction)}>
                    {cancelState && (
                        <div className="col-md-12  mb-4 ">
                            <label htmlFor="remark">Remark:</label>
                            <textarea
                                name="remark"
                                {...register("remark", {
                                    required: true,
                                })}
                                style={{ width: "100%", height: "100px" }}
                            />
                            {errors.remark?.type === "required" && (<div className="invalid-feedback">Remark is required !</div>)}
                        </div>
                    )}
                    <div className='text-right row'>
                        <div className="col-md-12" mb-4>
                            <Button title='Cancel' onClick={handleCloseRefundRequestModal} style={{ minWidth: '80px' }} buttonClass="btn btn-secondary btn-sm mr-2" buttonType="button" />
                            <Button title='Submit' loading={refundRequestState.buttonLoading} style={{ minWidth: '80px' }} buttonClass="btn btn-primary btn-sm" buttonType="submit" />
                        </div>
                    </div>

                </form>
            </Modal>

        </CardLayout>
    );
};

const Style = {
    cancelRefundButtonActive: {
        backgroundColor: '#f14336',
        color: '#fff',
        borderColor: '#f14336',
        borderRadius: '3px',
        margin: '0 10px'
    },
    cancelRefundButton: {
        backgroundColor: '#fff',
        color: '#000',
        borderColor: '#f14336',
        borderRadius: '3px',
        margin: '0 10px'
    },
    acceptRefundButtonActive: {
        backgroundColor: '#27b345',
        color: '#fff',
        borderColor: '#27b345',
        borderRadius: '3px',
        margin: '0 10px'
    },
    acceptRefundButton: {
        backgroundColor: '#fff',
        color: '#000',
        borderColor: '#27b345',
        borderRadius: '3px',
        margin: '0 10px'
    },
}

export default RefundRequest;
