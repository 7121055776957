import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { getList, addData } from '../../helpers/fackBackend_Helper';

function* addCategoryResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/addEmailTemplateCategory', data);
        if (response.Status) {
            yield put(actions.addCategorySuccess(response.Message));
            yield put(actions.getTemplateCategory());
        }
    } catch (error) {
        yield put(actions.addCategoryFailed(error));
    }
}

function* getTemplateCategoryResponse() {
    try {
        const response = yield call(getList, '/getEmailTemplateCategory');
        if (response.Status) {
            yield put(actions.getTemplateCategorySuccess(response.EmailTemplateCategory));
        }
    } catch (error) {
        yield put(actions.getTemplateCategoryFailed(error));
    }
}
function* updateEmailTemplateCategoryResponse({ payload: { id, data } }) {
    try {
        const response = yield call(addData, '/updateEmailTemplateCategory/' + id, data);
        if (response.Status) {
            yield put(actions.updateTemplateCategorySuccess(response.Message));
            yield put(actions.getTemplateCategory());
        }
    } catch (error) {
        yield put(actions.updateTemplateCategoryFailed(error));
    }
}
function* updateCategoryStatusResponse({ payload: { id, flag } }) {
    try {
        const response = yield call(getList, `/updateEmailTemplateCategoryStatus/${id}/${flag}`);
        if (response.Status) {
            yield put(actions.updateCategoryStatusSuccess(response.Message));
            yield put(actions.getTemplateCategory());
        } else {
            yield put(actions.updateCategoryStatusFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.updateCategoryStatusFailed(error));
    }
}
function* addEmailTemplateResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/addEmailTemplate', data);
        if (response.Status) {
            yield put(actions.addEmailTemplateSuccess(response.Message));
            yield put(actions.getTemplate());
        }
    } catch (error) {
        yield put(actions.addEmailTemplateFailed(error));
    }
}
function* getTemplateResponse() {
    try {
        const response = yield call(getList, '/getEmailTemplate');
        if (response.Status) {
            yield put(actions.getTemplateSuccess(response.EmailTemplate));
        }
    } catch (error) {
        yield put(actions.getTemplateFailed(error));
    }
}

function* updateTemplateStatusResponse({ payload: { id, flag } }) {
    try {
        const response = yield call(getList, `/updateEmailTemplateStatus/${id}/${flag}`);
        if (response.Status) {
            yield put(actions.updateTemplateStatusSuccess(response.Message));
            yield put(actions.getTemplate());
        } else {
            yield put(actions.updateTemplateStatusFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.updateTemplateStatusFailed(error));
    }
}

function* updateEmailTemplateResponse({ payload: { id, data } }) {
    try {
        const response = yield call(addData, '/updateEmailTemplate/' + id, data);
        if (response.Status) {
            yield put(actions.updateTemplateSuccess(response.Message));
            yield put(actions.getTemplate());
        }
    } catch (error) {
        yield put(actions.updateTemplateFailed(error));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.ADD_CATEGORY, addCategoryResponse)]);
    yield all([takeEvery(actions.GET_TEMPLATE_CATEGORY, getTemplateCategoryResponse)]);
    yield all([takeEvery(actions.UPDATE_TEMPLATE_CATEGORY, updateEmailTemplateCategoryResponse)]);
    yield all([takeEvery(actions.UPDATE_CATEGORY_STATUS, updateCategoryStatusResponse)]);
    yield all([takeEvery(actions.ADD_EMAIL_TEMPLATE, addEmailTemplateResponse)]);
    yield all([takeEvery(actions.GET_TEMPLATE, getTemplateResponse)]);
    yield all([takeEvery(actions.UPDATE_TEMPLATE_STATUS, updateTemplateStatusResponse)]);
    yield all([takeEvery(actions.UPDATE_TEMPLATE, updateEmailTemplateResponse)]);
}