import actions from './actions';

const initState = {
  getSimListLoading: false,
  getSimListError: null,
  getSimListMessage: null,
  getSimList: [],
  getSimDetailLoading: false,
  getSimDetailError: null,
  getSimDetailMessage: null,
  getSimDetail: [],
  getSimDetailModal: false,
};


export default function authReducer(state = initState, action) {
  switch (action.type) {

    case actions.GET_SIM_DETAIL:

      state.getSimList.map((item) => {
        let temp = item;
        if (temp.ID == action.payload.simId) {
          temp.loading = !temp.loading;
        }
        return temp;
      });

      return {
        ...state,
        getSimDetailLoading: true,
        getSimDetailError: null,
        getSimDetailMessage: null,
      };

    case actions.GET_SIM_DETAIL_SUCCESS:

      state.getSimList.map((item) => {
        let temp = item;
        if (temp.ID == action.payload.simId) {
          temp.loading = !temp.loading;
        }
        return temp;
      });

      return {
        ...state,
        getSimDetailLoading: false,
        getSimDetailError: null,
        getSimDetailMessage: action.payload.message,
        getSimDetail: action.payload.data,
        getSimDetailModal: true,
      };

    case actions.GET_SIM_DETAIL_FAILED:

      state.getSimList.map((item) => {
        let temp = item;
        if (temp.ID == action.payload.simId) {
          temp.loading = !temp.loading;
        }
        return temp;
      });

      return {
        ...state,
        getSimDetailLoading: false,
        getSimDetailError: action.payload.error,
        getSimDetailMessage: null,
        getSimDetailModal: true,
      };


    case actions.GET_SIM_LIST:
      return {
        ...state,
        getSimListLoading: true,
        getSimListError: null,
        getSimListMessage: null,
      };
    case actions.GET_SIM_LIST_SUCCESS:
      return {
        ...state,
        getSimListLoading: false,
        getSimListError: null,
        getSimListMessage: action.payload.message,
        getSimList: action.payload.data,
      };
    case actions.GET_SIM_LIST_FAILED:
      return {
        ...state,
        getSimListLoading: false,
        getSimListError: action.payload.error,
        getSimListMessage: null,
      };

    case actions.RESET_LIST_SIM:
      return {
        ...state,
        getSimDetail: [],
        getSimDetailModal: false,
      };

    default:
      return state;
  }

}
