import React from 'react';
import Modal from './Model';

const DealerViewModel = ({ showViewModal, title, handleCancel, responseData, responsePlanDetailsHeading,
    responsePlanNameHeading, responseDetailsData }) => {
 
    return (
        <>
            <Modal
                title={title}
                showModal={showViewModal}
                onHide={handleCancel}
                showFooter={false}
                width={800}
            >
                {/* <div className="form-row">
                    <div className={'form-row col-md-12'}>
                        {responseData && responseData.length > 0 && responseData.map((item, index) => (
                            <div className="col-md-3 mb-3" key={index}>
                                <div>
                                    <a style={{ fontSize: '16px', color: '#2B3038', fontWeight: 'normal' }}>{item.name}</a>
                                    <p>{item.value}</p>
                                </div>
                            </div>
                        ))}
                    </div>

                </div> */}

                {/* <div className="form-column">
                    {responsePlanDetailsHeading && responsePlanDetailsHeading.length > 0 && responsePlanDetailsHeading.map((item, index) => (<>
                        <hr style={styles.hr} />
                        <div key={index} className="col-md-12 mb-2 mt-2">
                            <p style={{ fontSize: '18px', color: '#2B3038', fontWeight: 'normal', }}>{item.name}</p>
                        </div>
                        <hr style={styles.hr} />
                    </>

                    ))}
                </div> */}

                <div className="form-column" >
                    {responsePlanNameHeading && responsePlanNameHeading.length > 0 && responsePlanNameHeading.map((item, index) => (
                        <div className="col-md-12 mb-3" key={index}>
                            <p style={{ fontSize: '16px', color: '#2B3038' }}>{item.name}</p>
                            <p >{item.value}</p>
                        </div>
                    ))}
                </div>

                <div className="form-row">
                    {responseDetailsData && responseDetailsData.length > 0 && responseDetailsData.map((item, index) => (
                        <div className="col-md-3 mb-3" key={index}>
                            <div className='card text-center text-primary py-1 rounded-sm shadow-sm'>
                                <p  className='mb-0 text-dark font-size-14' style={ { fontWeight: '500'} }>{item.name}</p>
                                <p className='font-size-12'>{item.value}</p>
                            </div>
                        </div>
                    ))}
                </div>

            </Modal>
        </>
    )
}

const styles = {
    hr: {
        border: '1px solid',
        margin: 0
    }
}
export default DealerViewModel