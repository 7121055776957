import { useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import CardLayout from '../../../components/utils/CardLayout';
import SearchBar from '../../../components/utils/SearchBar';
import Table from '../../../components/utils/Table';
import Button from '../../../components/utils/Button';
import SideButtons from '../../../components/utils/SideButtons';
import tariffActions from '../../../redux/tariffGroup/actions';
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import { Link } from 'react-router-dom';
import DeleteBin5Fill from 'remixicon-react/DeleteBin5FillIcon';
import CheckboxCircleFill from 'remixicon-react/CheckboxCircleFillIcon';
import PencilFill from 'remixicon-react/PencilFillIcon';
import Tag from '../../../components/utils/Tag'
import Popconfirm from '../../../components/utils/Popover';
import Alert from '../../../components/utils/Alert';
import planAction from '../../../redux/plan/actions';
import EyeFill from 'remixicon-react/EyeFillIcon';
import TariffViewModal from '../../../components/utils/TariffViewModal';

function TariffGroup() {
    const { getTariff, deleteTariff, resetTariffMessage, prepareTariffForm, prepareTariffFormModal, resetTariffFormModal, addTariff, getTariffDataById, resetTariff, editTariff } = tariffActions;
    const { getPlan } = planAction;
    const dispatch = useDispatch()
    const [filterText, setFilterText] = useState("");
    const [editFlag, setEditFlag] = useState(false);
    const [editID, seteditID] = useState('')
    const [formTitle, setFormTitle] = useState('');
    const [formButton, setFormButton] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');
    const [showMappingData, setShowMappingData] = useState([]);
    const [tariffData, setTariffData] = useState({
        tariffGroup: "",
    })
    const [tariffMapedData, setTariffMapedData] = useState([]);
    const [tariffViewData, setTariffViewData] = useState();
    const [tariffVariantViewData, settariffVariantViewData] = useState()
    const [showViewModal, setShowViewModal] = useState(false)

    const { tariffResult, showAddTariff, showModal, loading, buttonLoading } = useSelector((state) => state.tariffGroup);
    const tariffDatanew = useSelector((state) => state.tariffGroup.tariffMapedData);
    const successMessage = useSelector((state) => state.tariffGroup.tariffMessage);
    const errorMessage = useSelector((state) => state.tariffGroup.tariffError);
    const planResult = useSelector((state) => state.plan.planResult);
    const tokenData = useSelector((state) => state.auth.tokenData);
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }, reset: resetForm
    } = useForm({ defaultValues: tariffData });

    useEffect(() => {
        setTariffMapedData(tariffDatanew);
    }, [tariffDatanew]);

    useEffect(() => {
        resetForm(tariffData);
    }, [tariffData,]);


    useEffect(() => {
        dispatch(getTariff());
        dispatch(getPlan({
            "countryID": "[]"
        }))
    }, [])


    const displayAlert = (text, type) => {
        setAlertText(text);
        setAlertType(type);
        setShowAlert(true);
        dispatch(resetTariffMessage());
    };

    const alertCancel = () => {
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
        dispatch(resetTariffMessage());
    }


    useEffect(() => {
        if (errorMessage !== null && errorMessage !== undefined) {
            displayAlert(errorMessage, 'alert-danger');
        }
        if (successMessage !== null && successMessage !== undefined) {
            displayAlert(successMessage, 'alert-success');
        }

    }, [errorMessage, successMessage]);

    const handleFilterChange = (value) => {
        setFilterText(value);
    };

    const onSubmit = (data) => {
        let isValid = true;
        const newSubmitArray = []
        const submitEntries = Object.entries(showMappingData);
        const userData = tokenData[0];

        for (const key of showMappingData) {
            for (const variation of key.planVariation) {
                const basePrice = variation.basePrice;
                const adminBasePrice = variation.adminBasePrice;
                const planPrice = parseInt(variation.planPrice);

                if (planPrice !== 0 && !variation.planPrice || isNaN(planPrice)) {
                    displayAlert('Plan price should not be blank or invalid.', 'alert-danger');
                    isValid = false;
                    break;
                }

                if (userData.ParentID !== 0) {
                    if (planPrice !== 0 && basePrice > planPrice) {
                        displayAlert('Plan price should be greater than Base price.', 'alert-danger')
                        isValid = false;
                        break;
                    } else if (planPrice !== 0 && planPrice > adminBasePrice) {
                        displayAlert('Plan price should not be greater than Base price.', 'alert-danger')
                        isValid = false;
                        break;
                    }
                } else if (userData.ParentID === 0) {
                    if (planPrice !== 0 && basePrice <= planPrice) {
                        displayAlert('Plan price should not be greater than Base price.', 'alert-danger')
                        isValid = false;
                        break;
                    } else {

                    }
                }
            }

        }
        if (isValid) {
            submitEntries.forEach(([key, val]) => {
                val.planVariation.forEach(variation => {
                    const dataSubmitObj = {
                        planID: variation.variationPlanId,
                        tariffPrice: variation.planPrice,
                        planVariatID: variation.variationId
                    };
                    newSubmitArray.push(dataSubmitObj);
                });
            });

            const submitData = {
                tariffGroup: data.tariffGroup,
                tariffGroupData: newSubmitArray,
            }
            dispatch(addTariff(submitData))
        }

    }

    const onUpdate = (data) => {
        let isValid = true;
        const newUpdateArray = []
        const updateEntries = Object.entries(tariffMapedData);
        const userData = tokenData[0];

        for (const item of tariffMapedData) {
            const planVariantsData = JSON.parse(item.PlanVariant);
            for (const variation of planVariantsData) {
                const basePrice = variation.BasePrice;
                const adminBasePrice = variation.PlanPrice;
                const planPrice = parseInt(variation.TariffPrice);

                if (planPrice !== 0 && !variation.TariffPrice || isNaN(planPrice)) {
                    displayAlert('Plan price should not be blank or invalid.', 'alert-danger');
                    isValid = false;
                    break;
                }

                if (userData.ParentID !== 0) {
                    if (planPrice !== 0 && basePrice > planPrice) {
                        displayAlert('plan price should be greater than Base price.', 'alert-danger')
                        isValid = false;
                        break;
                    } else if (planPrice !== 0 && planPrice > adminBasePrice) {
                        displayAlert('plan price should not be greater than Base price.', 'alert-danger')
                        isValid = false;
                        break;
                    }
                } else if (userData.ParentID === 0) {
                    if (planPrice !== 0 && basePrice <= planPrice) {
                        displayAlert('plan price should not be greater than Base price.', 'alert-danger')
                        isValid = false;
                        break;
                    } else {

                    }
                }
            }
        }
        if (isValid) {
            updateEntries.forEach(([key, val]) => {
                const planVariantsData = JSON.parse(val.PlanVariant);
                planVariantsData.forEach(variation => {
                    const dataUpdateObj = {
                        "planID": variation.PlanID,
                        "tariffID": editID,
                        "tariffGroupID": editID,
                        "ID": variation.ID, // Assuming you have an ID property in your original data
                        "tariffPrice": variation.TariffPrice,
                        "planVariantID": variation.PlanVariantID,
                    };
                    newUpdateArray.push(dataUpdateObj);
                })
            })
            const updateData = {
                tariffGroup: data.tariffGroup,
                tariffGroupData: newUpdateArray,
            }
            dispatch(editTariff(editID, updateData));
        }

    }

    const handleClose = () => {
        dispatch(resetTariffFormModal())
    }

    const handleCancelmodal = () => {
        setShowViewModal(false)
    }

    const handleOpenAddFormModal = () => {
        dispatch(prepareTariffForm())
        resetForm(tariffData)
        setFormTitle('Add Tariff Group');
        setFormButton('Submit');
        setEditFlag(false);
        setTariffData(prevState => ({
            ...prevState,
            tariffGroup: ''
        }));
        let entries = Object.entries(planResult);
        let mappingData = [];
        entries.forEach(([key, val]) => {
            const dataObj = {
                planId: val.ID,
                planName: val.PlanName,
                planScope: val.CountryName,
                planVariation: val.PlanVariant && val.PlanVariant.length > 0 && val.PlanVariant.map(variation => ({
                    eSIMGORef: variation.ESIMGORef,
                    variantName: variation.VariantName,
                    variationId: variation.ID,
                    variationPlanId: variation.PlanID,
                    isActive: variation.IsActive,
                    planPrice: "0",
                    adminBasePrice: variation.BasePrice,
                    basePrice: variation.PlanPrice,
                    // Add more properties if needed
                })),

            };
            mappingData.push(dataObj);
        });
        setShowMappingData(mappingData);
    }

    const handleOpenEditFormModal = (tariffId) => {
        dispatch(prepareTariffForm());
        dispatch(getTariffDataById(tariffId));
        seteditID(tariffId);
        setEditFlag(true);
        setFormTitle('Edit Tariff Group');
        setFormButton('Update');
        var selectedTariff = tariffResult && tariffResult.filter(tariff => tariff.ID === tariffId);
        setTariffData(prevState => ({
            ...prevState,
            tariffGroup: selectedTariff[0].TariffGroup,
        }));

    }
    const handleDelete = (ID, flag) => {
        dispatch(deleteTariff(ID, flag));
    }
    const handleCancel = () => {
        dispatch(resetTariff())
    }

    const handleChangePlanPrice = (e, planId, planVariantId) => {
        const fvalue = e.target.value;
        const updatedArray = [...showMappingData];
        const planToUpdate = updatedArray.find(plan => plan.planId === planId);
        if (planToUpdate) {
            const variationToUpdate = planToUpdate.planVariation.find(variation => variation.variationId === planVariantId);
            if (variationToUpdate) {
                // Update the planPrice for the specific variant
                variationToUpdate.planPrice = fvalue;
                // Output the updated plansArray
            }
        }
        setShowMappingData(updatedArray)
    }

    const handleChangeTariffPrice = (e, planId, planVariantId) => {
        dispatch(prepareTariffFormModal());

        // Use map to update the TariffPrice for the specified PlanID and PlanVariantID
        const updatedTariffData = tariffMapedData.map((tariff) => {
            if (tariff.PlanID === planId) {
                // Check if the PlanVariantID matches and update the TariffPrice
                const parsedVariants = JSON.parse(tariff.PlanVariant);
                const updatedVariants = parsedVariants.map((variant) => {
                    if (variant.PlanVariantID === planVariantId) {
                        return {
                            ...variant,
                            TariffPrice: e.target.value, // Assuming e.target.value is a string, convert it to a float
                        };
                    }
                    return variant;
                });

                // Update the PlanVariant array in the tariff object
                return {
                    ...tariff,
                    PlanVariant: JSON.stringify(updatedVariants),
                };
            }
            return tariff;
        });
        // Set the updated tariffMapedData state
        setTariffMapedData(updatedTariffData);
    };

    const handleShowTariffDetails = (tariffId, data) => {
        dispatch(getTariffDataById(tariffId));
        setShowViewModal(true)
        setTariffViewData(data)
    }

    const filteredItems = tariffResult && tariffResult.length > 0 && tariffResult.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const columns = [
        { displayName: '#', key: 'key' },
        { displayName: 'TARIFF GROUP', key: 'tariffGroup' },
        { displayName: 'CREATE DATE', key: 'createDate' },
        { displayName: 'STATUS', key: 'status' },
        { displayName: 'ACTIONS', key: 'action' },
    ];

    const dataSource = filteredItems && filteredItems.length > 0 && filteredItems.map((tariff, key) => {
        return {
            key: key + 1,
            tariffGroup: tariff.TariffGroup,
            createDate: moment(new Date(tariff.CreateDate)).format(`${process.env.REACT_APP_DATE_FORMAT}`),
            status: <Tag color={`${tariff.IsActive === 1 ? "badge-success" : "badge-danger"}`} title={tariff.IsActive === 1 ? 'Active' : 'Inactive'} />,
            action: (
                <>
                    <Link to="#">
                    <span data-toggle="tooltip" data-placement="top" title="Edit"><PencilFill size="1.2em" style={{ color: "#888b8e", marginRight: "3px" }} onClick={() => handleOpenEditFormModal(tariff.ID)} /></span>
                    </Link>
                    {tariff.IsActive === 1 ? (
                        <Popconfirm
                            title="Are you sure to inactive this tariff?"
                            okText="Yes"
                            cancelText="No"
                            placement="topRight"
                            onConfirm={() => handleDelete(tariff.ID, 0)}
                        >
                            <Link className="deleteBtn" to="# ">
                            <span data-toggle="tooltip" data-placement="top" title="Inactive"><DeleteBin5Fill size="1.2em" style={{ color: "#f14336", marginRight: "3px" }} /></span>
                            </Link>
                        </Popconfirm>
                    ) : (
                        <Popconfirm
                            title="Are you sure to active this tariff?"
                            okText="Yes"
                            cancelText="No"
                            placement="topRight"
                            onConfirm={() => handleDelete(tariff.ID, 1)}
                        >
                            <Link className="deleteBtn" to="# ">
                            <span data-toggle="tooltip" data-placement="top" title="Active"><CheckboxCircleFill size="1.2em" style={{ color: "#2cb549", marginRight: "3px" }} /></span>
                            </Link>
                        </Popconfirm>
                    )}
                    <Link to="#" onClick={() => handleShowTariffDetails(tariff.ID, tariff)} >
                    <span data-toggle="tooltip" data-placement="top" title="View"><EyeFill size="1.2em" style={{ color: "#0094b3" }} /></span>
                    </Link>
                </>)

        }
    });

    const addDataColumns = [
        { displayName: '#', key: 'key' },
        { displayName: 'PLAN NAME', key: 'planName' },
        { displayName: 'PLAN SCOPE', key: 'planScope' },
        { displayName: 'VARIATION-1', key: 'variation1' },
        { displayName: 'VARIATION-2', key: 'variation2' },
        { displayName: 'VARIATION-3', key: 'variation3' },
        { displayName: 'VARIATION-4', key: 'variation4' },
        { displayName: 'VARIATION-5', key: 'variation5' }
    ]

    const addTariffData = showMappingData && showMappingData.length > 0 && showMappingData.map((planData, key) => {
        return {
            key: key + 1,
            planName: planData.planName,
            planScope: <Tag color='badge-primary' title={planData.planScope} />,
            // planPrice: planData.PlanVariant[0].PlanPrice,
            variation1: planData.planVariation && planData.planVariation.length > 0 && planData.planVariation[0] ? <div className='variation-mode'>
                <p className='bundle-header m-0'>{planData.planVariation[0].eSIMGORef != '' ? planData.planVariation[0].eSIMGORef : planData.planVariation[0].variantName}</p>
                <span><input type="text" id='input-desable' value={`£${parseFloat(planData.planVariation[0].basePrice).toFixed(2)}`} disabled></input>
                    <input type="text" id='input-enable' autoComplete="off" name="planPrice" value={planData.planVariation[0].planPrice} onChange={(e) => handleChangePlanPrice(e, planData.planId, planData.planVariation[0].variationId)} /></span>
            </div> : 'N/A',
            variation2: planData.planVariation && planData.planVariation.length > 0 && planData.planVariation[1] ? <div className='variation-mode'>
                <p className='bundle-header m-0'>{planData.planVariation[1].eSIMGORef != '' ? planData.planVariation[1].eSIMGORef : planData.planVariation[1].variantName}</p>
                <div className='variation-price-change'>
                    <input type="text" id='input-desable' value={`£${parseFloat(planData.planVariation[1].basePrice).toFixed(2)}`} disabled></input>
                    <input type="text" id='input-enable' autoComplete="off" name="planPrice" value={planData.planVariation[1].planPrice} onChange={(e) => handleChangePlanPrice(e, planData.planId, planData.planVariation[1].variationId)} />
                </div>
            </div> : 'N/A',
            variation3: planData.planVariation && planData.planVariation.length > 0 && planData.planVariation[2] ? <div className='variation-mode'>
                <p className='bundle-header m-0'>{planData.planVariation[2].eSIMGORef != '' ? planData.planVariation[2].eSIMGORef : planData.planVariation[2].variantName}</p>
                <div className='variation-price-change'>
                    <input type='text' id='input-desable' value={`£${parseFloat(planData.planVariation[2].basePrice).toFixed(2)}`} disabled></input>
                    <input type="text" id='input-enable' autoComplete="off" name="planPrice" value={planData.planVariation[2].planPrice} onChange={(e) => handleChangePlanPrice(e, planData.planId, planData.planVariation[2].variationId)} />
                </div>
            </div> : 'N/A',
            variation4: planData.planVariation && planData.planVariation.length > 0 && planData.planVariation[3] ? <div className='variation-mode'>
                <p className='bundle-header m-0'>{planData.planVariation[3].eSIMGORef != '' ? planData.planVariation[3].eSIMGORef : planData.planVariation[3].variantName}</p>
                <div className='variation-price-change'>
                    <input type='text' id='input-desable' value={`£${parseFloat(planData.planVariation[3].basePrice).toFixed(2)}`} disabled></input>
                    <input type="text" id='input-enable' autoComplete="off" name="planPrice" value={planData.planVariation[3].planPrice} onChange={(e) => handleChangePlanPrice(e, planData.planId, planData.planVariation[3].variationId)} />
                </div>
            </div> : 'N/A',
            variation5: planData.planVariation && planData.planVariation.length > 0 && planData.planVariation[4] ? <div className='variation-mode'>
                <p className='bundle-header m-0'>{planData.planVariation[4].eSIMGORef != '' ? planData.planVariation[4].eSIMGORef : planData.planVariation[4].variantName}</p>
                <div className='variation-price-change'>
                    <input type='text' id='input-desable' value={`£${parseFloat(planData.planVariation[4].basePrice).toFixed(2)}`} disabled></input>
                    <input type="text" id='input-enable' autoComplete="off" name="planPrice" value={planData.planVariation[4].planPrice} onChange={(e) => handleChangePlanPrice(e, planData.planId, planData.planVariation[4].variationId)} />
                </div>
            </div> : 'N/A'
        }
    })

    const editTariffData = tariffMapedData && tariffMapedData.length > 0 && tariffMapedData.map((planData, key) => {
        const parsedTariffData = planData.PlanVariant && planData.PlanVariant !== null && JSON.parse(planData.PlanVariant);
        return {
            key: key + 1,
            planName: planData.PlanName,
            planScope: <Tag color='badge-primary' title={planData.CountryName} />,
            // planPrice: planData.PlanVariant[0].PlanPrice,
            variation1: parsedTariffData && parsedTariffData.length > 0 && parsedTariffData[0] ? <div className='variation-mode'>
                <p className='bundle-header m-0'>{parsedTariffData[0].ESIMGORef}</p>
                <div className='variation-price-change'>
                    <input type="text" className='input-desable' value={`£${parseFloat(parsedTariffData[0].BasePrice).toFixed(2)}`} disabled></input>
                    <input type="text" className='input-enable' autoComplete="off" name="planPrice" value={parsedTariffData[0].TariffPrice} onChange={(e) => handleChangeTariffPrice(e, planData.PlanID, parsedTariffData[0].PlanVariantID)} />
                </div>
            </div> : 'N/A',
            variation2: parsedTariffData && parsedTariffData.length > 0 && parsedTariffData[1] ? <div className='variation-mode'>
                <p className='bundle-header m-0'>{parsedTariffData[1].ESIMGORef}</p>
                <div className='variation-price-change'>
                    <input type="text" value={`£${parseFloat(parsedTariffData[1].BasePrice).toFixed(2)}`} disabled></input>
                    <input type="text" autoComplete="off" name="planPrice" value={parsedTariffData[1].TariffPrice} onChange={(e) => handleChangeTariffPrice(e, planData.PlanID, parsedTariffData[1].PlanVariantID)} />
                </div>
            </div> : 'N/A',
            variation3: parsedTariffData && parsedTariffData.length > 0 && parsedTariffData[2] ? <div className='variation-mode'>
                <p className='bundle-header m-0'>{parsedTariffData[2].ESIMGORef}</p>
                <div className='variation-price-change'>
                    <input type="text" value={`£${parseFloat(parsedTariffData[2].BasePrice).toFixed(2)}`} disabled></input>
                    <input type="text" autoComplete="off" name="planPrice" value={parsedTariffData[2].TariffPrice} onChange={(e) => handleChangeTariffPrice(e, planData.PlanID, parsedTariffData[2].PlanVariantID)} />
                </div>
            </div> : 'N/A',
            variation4: parsedTariffData && parsedTariffData.length > 0 && parsedTariffData[3] ? <div className='variation-mode'>
                <p className='bundle-header m-0'>{parsedTariffData[3].ESIMGORef}</p>
                <div className='variation-price-change'>
                    <input type="text" value={`£${parseFloat(parsedTariffData[3].BasePrice).toFixed(2)}`} disabled></input>
                    <input type="text" autoComplete="off" name="planPrice" value={parsedTariffData[3].TariffPrice} onChange={(e) => handleChangeTariffPrice(e, planData.PlanID, parsedTariffData[3].PlanVariantID)} />
                </div>
            </div> : 'N/A',
            variation5: parsedTariffData && parsedTariffData.length > 0 && parsedTariffData[4] ? <div className='variation-mode'>
                <p className='bundle-header m-0'>{parsedTariffData[4].ESIMGORef}</p>
                <div className='variation-price-change'>
                    <input type="text" value={`£${parseFloat(parsedTariffData[4].BasePrice).toFixed(2)}`} disabled></input>
                    <input type="text" autoComplete="off" name="planPrice" value={parsedTariffData[4].TariffPrice} onChange={(e) => handleChangeTariffPrice(e, planData.PlanID, parsedTariffData[4].PlanVariantID)} />
                </div>
            </div> : 'N/A'
        }
    })

    return (
        <>
            {showAddTariff === false ? <CardLayout title=" Tariff Groups">
                {showAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}
                <div className="row">
                    <div className='col-md-6 od-2'>
                        <SearchBar onFilter={handleFilterChange} filterText={filterText} />
                    </div>
                    <div className='col-md-6  text-right'>
                        <SideButtons title="Add Tariff Group" buttonStyle="primary" onClick={handleOpenAddFormModal} />
                    </div>
                </div>
                <Table columns={columns} data={dataSource} pagination={true} loading={loading} />
            </CardLayout> : <CardLayout title={formTitle}>
                {showAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}
                <form onSubmit={handleSubmit(editFlag ? onUpdate : onSubmit)}>
                    <div className="row align-items-center">
                        <div className='col-md-4 od-2'>
                            <label>Tariff Group</label>
                            <input type="text" placeholder="Enter Tariff Group" className="form-control"
                                name="tariffGroup" {...register("tariffGroup", {
                                    required: true,
                                })} />
                            {errors.tariffGroup?.type === "required" && (
                                <div className="invalid-feedback">Tariff Group is required !</div>

                            )}
                        </div>
                        <div className='col-md-8 text-right'>
                            <button type="button" className="btn btn-danger mr-2" onClick={handleCancel}>Back</button>
                        </div>
                    </div>
                    <Table columns={addDataColumns}
                        data={editFlag ? editTariffData : addTariffData}
                        pagination={false} loading={loading} />
                    <div className='text-right row my-2'>
                        <div className="col-md-12 pt-2">
                            <button type="button" className=" tariff-gruop-red-btn mr-2" onClick={handleCancel}>Back</button>
                            <Button title={formButton} style="minWidth:80px" buttonClass=" tariff-groupbtn" buttonType='submit' loading={buttonLoading} />
                        </div>
                    </div>
                </form>
            </CardLayout>}

            {tariffViewData ?
                <div>
                    <TariffViewModal
                        showViewModal={showViewModal}
                        title={"Tariff Data"}
                        handleCancel={handleCancelmodal}
                        responseValue={tariffViewData}
                        variantTitle={'Variant Plan'}
                        varientData={tariffMapedData}
                    >
                    </TariffViewModal>

                </div>
                : ""}
        </>
    )
}

export default TariffGroup