import React, { useState, useEffect } from 'react'
import CardLayout from '../../../components/utils/CardLayout'
import './AddFund.css'
import Table from '../../../components/utils/Table';
import SearchBar from '../../../components/utils/SearchBar';
import CheckoutForm from './CheckoutForm';
import AddtoFundAction from '../../../redux/addToFund/actions'
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import Tag from '../../../components/utils/Tag';

const { amountData, createPaymentId, getTransactionHistory } = AddtoFundAction
const columns = [
    { displayName: '#', key: 'key' },
    { displayName: 'DATE', key: 'date' },
    { displayName: 'TRANSACTION ID', key: 'transactionId' },
    { displayName: 'AMOUNT', key: 'amount' },
    { displayName: 'PREVIOUS BALANCE', key: 'previousBalance' },
    { displayName: 'ACCOUNT BALANCE', key: 'accountBalance' },
    { displayName: 'ACCOUNTING', key: 'accounting' },
];


function AddFund() {
    const dispatch = useDispatch()
    const AmountData = useSelector((state) => state.addToFund.data);
    const PaymentId = useSelector((state) => state.addToFund.PaymentId);
    const dataSuccess = useSelector((state) => state.addToFund.dataSuccess);
    const loading = useSelector((state) => state.addToFund.loadingTable);
    const trasactionResult = useSelector((state) => state.addToFund.trasactionResult);
    const [toggle, setToggle] = useState(true);
    const [amount, setAmount] = useState(0)
    const [payNow, setPayNow] = useState(false);
    const [amountToAdd, setAmountToAdd] = useState(0);
    const [errorMesssage, setErrorMessage] = useState("");
    const [filterText, setFilterText] = useState("");

    useEffect(() => {
        dispatch(amountData());
        dispatch(getTransactionHistory());
    }, [])


    const filteredItems = trasactionResult && trasactionResult.length > 0 && trasactionResult.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const dataSource = filteredItems && filteredItems.length > 0 && filteredItems.map((history, key) => {
        return {
            key: key + 1,
            date: history.CreatedDtTm !== null ? moment(new Date(history.CreatedDtTm)).format(process.env.REACT_APP_DATE_FORMAT) : 'N/A',
            transactionId: history.TxnID,
            amount: history.Amount ? `£ ${history.Amount.toFixed(2)} ` : 'N/A',
            previousBalance: history.PreviousBalance ? `£${history.PreviousBalance.toFixed(2)}` : "N/A",
            accountBalance: history.CurrentBalance ? `£${history.CurrentBalance.toFixed(2)}` : "N/A",
            accounting: <Tag color={`${history.TransactionType === "Credit" ? "badge-success" : history.TransactionType === "Debit" ? "badge-warning" : "badge-danger"}`} title={history.TransactionType} />,
        }
    });

    const openStripePaymentGateway = () => {
        const newAmount = parseFloat(amount)
        const maximumAmount = parseFloat(AmountData[0].MaximumTopUpAmount);
        const minumumAmount = parseFloat(AmountData[0].MinimumTopUpAmount);
        if (newAmount < minumumAmount) {
            setErrorMessage("Please check you minimum amount balance !")
        } else if (newAmount === 0) {
            setErrorMessage("Value should be greater than zero")
        } else if (maximumAmount !== 0 && newAmount > maximumAmount) {
            setErrorMessage("Please check you maximum amount balance !")
        } else if (newAmount) {
            const transactionFee = (newAmount / 100) * AmountData[0].PaypalTransactionFee;
            const totalAmount = (parseFloat(newAmount) + parseFloat(transactionFee));
            setAmountToAdd(totalAmount.toFixed(2));
            setToggle(false)
            setPayNow(true)
            setAmount(0)
            const obj = {
                amount: totalAmount.toFixed(2),
                transactionFeeAmount: transactionFee.toFixed(2)
            };
            dispatch(createPaymentId(obj))
        } else {
            setErrorMessage("Amount should have mandatory")
        }

    }

    const handleChange = (e) => {
        setErrorMessage("")
        e.preventDefault();
        const { name, value } = e.target;
        let obtainedAmount = Number(value);
        
        if (value == '' || isNaN(obtainedAmount)) {
            setAmount(0)
        } else {
            setAmount(value)
        }
    }

    const handleFilterChange = (value) => {
        setFilterText(value);
    };

    const styleButton = {
        color: amount === 0 && "#ffffff73",
        backgroundColor: amount === 0 && "#17aec994",
        cursor: amount === 0 ? "not-allowed" : "",
    };

    return (
        <CardLayout title="Add Top Up">
            <div className='row'>

                <div className='col-lg-4 col-md-6'>
                    <h5 className='font-weight-bold'>Top Up</h5>
                    {toggle && <div className='bg-background-add-fund mt-4'>
                        <div className='inner-add-fund'>
                            <div className='row current-div-add-funt-main justify-content-center'>
                                <div className='nun-card-add-fund'>
                                    <h4 className='h4-add-fund'>Current Amount</h4>
                                    <p className='p-add-fund'>{`£${!dataSuccess ? AmountData && AmountData[0] && AmountData[0].AccountBalance.toFixed(2) : 0.00}`}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='amount-box-add-fund'>
                                    <label className='label-acc-add-fund'>Account</label>
                                </div>
                                <div className='input-file-add-fund'> <span className='para-p'>£ </span> <span><input className='input-add-fund-1' type='text' name='amount' maxLength="4" onChange={handleChange} placeholder='0.00' /></span></div>
                            </div>

                            <div className='row'>
                                <div className='text-feild-add-fund'>

                                    <div className='ul-add-fund-1'>
                                        <li>Minimum Balance</li>
                                        <li>{`£${!dataSuccess ? AmountData && AmountData[0] && AmountData[0].MinimumTopUpAmount.toFixed(2) : 0.00}`}</li>
                                    </div>
                                    <div className='ul-add-fund-2'>
                                        <li>Maximum Balance</li>
                                        <li>{`£${!dataSuccess ? AmountData && AmountData[0] && AmountData[0].MaximumTopUpAmount.toFixed(2) : 0.00}`}</li>
                                    </div>
                                    <div className='ul-add-fund-2'>
                                        <li>PayPal Transaction fee</li>
                                        <li>{`${!dataSuccess ? AmountData && AmountData[0] && AmountData[0].PaypalTransactionFee.toFixed(2) : 0.00}%`}</li>
                                    </div>
                                    {
                                        errorMesssage && <div className="invalid-feedback">{errorMesssage}</div>
                                    }
                                </div>
                            </div>
                            <div className='row justify-content-center mt-4'>
                                <div className='col-lg-8 col-md-8 '>
                                    <button className='add-money-add-fund' style={styleButton} disabled={amount === 0 ? true : false} onClick={() => openStripePaymentGateway()} >Add Money</button>
                                </div>
                            </div>
                        </div>
                    </div>}
                    {!toggle && PaymentId &&
                        <CheckoutForm amountData={{ amountToAdd, PaymentId }} setToggle={setToggle} payNow={payNow} />
                    }
                </div>

                <div className='col-lg-8 col-md-6'>
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <h5 class="mb-0 font-weight-bold">Transaction History</h5></div>
                        <div className='rre col-lg-6 col-md-6 col-sm-6'>
                            <SearchBar onFilter={handleFilterChange} filterText={filterText} style={{ with: '100%' }} /></div>
                    </div>
                    <Table columns={columns} loading={loading} data={dataSource} pagination={true} />
                </div>

            </div>
        </CardLayout>
    )

}



export default AddFund