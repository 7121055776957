const actions = {
    GET_PLAN: "GET_PLAN",
    GET_PLAN_SUCCESS: "GET_PLAN_SUCCESS",
    GET_PLAN_FAILED: "GET_PLAN_FAILED",
    ADD_PLAN: "ADD_PLAN",
    ADD_PLAN_SUCCESS: "ADD_PLAN_SUCCESS",
    ADD_PLAN_FAILED: "ADD_PLAN_FAILED",
    GET_PLAN_DATA: "GET_PLAN_DATA",
    GET_PLAN_DATA_SUCCESS: "GET_PLAN_DATA_SUCCESS",
    GET_PLAN_DATA_FAILED: "GET_PLAN_DATA_FAILED",
    GET_PLAN_DAY: "GET_PLAN_DAY",
    GET_PLAN_DAY_SUCCESS: "GET_PLAN_DAY_SUCCESS",
    GET_PLAN_DAY_FAILED: "GET_PLAN_DAY_FAILED",
    DELETE_PLAN: "DELETE_PLAN",
    DELETE_PLAN_SUCCESS: "DELETE_PLAN_SUCCESS",
    PREPARE_PLAN_FORM: "PREPARE_PLAN_FORM",
    RESET_PLAN: "RESET_PLAN",
    RESET_PLAN_MESSAGE: "RESET_PLAN_MESSAGE",
    GET_BUNDLE_NAME_DATA: "GET_BUNDLE_NAME_DATA",
    GET_BUNDLE_NAME_DATA_SUCCESS: "GET_BUNDLE_NAME_DATA_SUCCESS",
    GET_BUNDLE_NAME_DATA_FAILED: "GET_BUNDLE_NAME_DATA_FAILED",
    CHECK_PLAN_NAME: "CHECK_PLAN_NAME",
    CHECK_PLAN_NAME_SUCCESS: "CHECK_PLAN_NAME_SUCCESS",
    CHECK_PLAN_NAME_FAILED: "CHECK_PLAN_NAME_FAILED",

    getPlan: (filterPlan) => ({
        type: actions.GET_PLAN,
        payload: { filterPlan }
    }),
    getPlanSuccess: (planResult) => ({
        type: actions.GET_PLAN_SUCCESS,
        planResult
    }),
    getPlanFailed: (planError) => ({
        type: actions.GET_PLAN_FAILED,
        planError
    }),
    addPlan: (data, filterPlan) => ({
        type: actions.ADD_PLAN,
        payload: { data, filterPlan }
    }),
    addPlanSuccess: (planMessage, planResult) => ({
        type: actions.ADD_PLAN_SUCCESS,
        planMessage,
        planResult
    }),
    addPlanFailed: (planError) => ({
        type: actions.ADD_PLAN_FAILED,
        planError
    }),
    getPlanData: (data) => ({
        type: actions.GET_PLAN_DATA,
        data
    }),
    getPlanDataSuccess: (planData) => ({
        type: actions.GET_PLAN_DATA_SUCCESS,
        planData
    }),
    getPlanDataFailed: (planError) => ({
        type: actions.GET_PLAN_DATA_FAILED,
        planError
    }),
    getPlanDay: (data) => ({
        type: actions.GET_PLAN_DAY,
        data
    }),
    getPlanDaySuccess: (planDayData) => ({
        type: actions.GET_PLAN_DAY_SUCCESS,
        planDayData
    }),
    getPlanDayFailed: (planError) => ({
        type: actions.GET_PLAN_DAY_FAILED,
        planError
    }),
    deletePlan: (planId, status, filterPlan) => ({
        type: actions.DELETE_PLAN,
        payload: { planId, status, filterPlan }
    }),
    deletePlanSuccess: (planMessage, planResult) => ({
        type: actions.DELETE_PLAN_SUCCESS,
        planMessage,
        planResult
    }),
    preparePlanForm: () => ({
        type: actions.PREPARE_PLAN_FORM,
    }),
    resetPlan: () => ({
        type: actions.RESET_PLAN,
    }),
    resetPlanMessage: () => ({
        type: actions.RESET_PLAN_MESSAGE,
    }),
    getBundleNameData: (data) => ({
        type: actions.GET_BUNDLE_NAME_DATA,
        data
    }),
    getBundleNameDataSuccess: (planBundleName) => ({
        type: actions.GET_BUNDLE_NAME_DATA_SUCCESS,
        planBundleName
    }),
    getBundleNameDataFailed: (bundleError) => ({
        type: actions.GET_BUNDLE_NAME_DATA_FAILED,
        bundleError
    }),
    checkPlanName: (planName) => ({
        type: actions.CHECK_PLAN_NAME,
        payload: { planName }
    }),
    checkPlanNameSuccess: (message) => ({
        type: actions.CHECK_PLAN_NAME_SUCCESS,
        message
    }),
    checkPlanNameFailed: (error) => ({
        type: actions.CHECK_PLAN_NAME_FAILED,
        error
    }),
}
export default actions;