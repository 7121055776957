import Modal from './Model'
import Button from './Button';
import DownloadCloud2sLineIcon from 'remixicon-react/DownloadCloud2LineIcon';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Styles from '../../pages/Reports/Audit Report/auditReport.module.css'

function TariffViewModal({ showViewModal, title, handleCancel, responseValue, varientData }) {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    var excelViewData = varientData && varientData.length > 0 && varientData.map((item, key) => {
        const parsedTariffData = item.PlanVariant && item.PlanVariant !== null && JSON.parse(item.PlanVariant);
        return {
            key: key + 1,
            PlanName: item.PlanName,
            PlanScope: item.CountryName,
            Variation1: parsedTariffData[0] ? parsedTariffData[0].ESIMGORef : 'N/A',
            BasePriceV1: parsedTariffData[0] ? parsedTariffData[0].BasePrice : 'N/A',
            TariffPriceV1: parsedTariffData[0] ? parsedTariffData[0].TariffPrice : 'N/A',
            Variation2: parsedTariffData[1] ? parsedTariffData[1].ESIMGORef : 'N/A',
            BasePriceV2: parsedTariffData[1] ? parsedTariffData[1].BasePrice : 'N/A',
            TariffPriceV2: parsedTariffData[1] ? parsedTariffData[1].TariffPrice : 'N/A',
            Variation3: parsedTariffData[2] ? parsedTariffData[2].ESIMGORef : 'N/A',
            BasePriceV3: parsedTariffData[2] ? parsedTariffData[2].BasePrice : 'N/A',
            TariffPriceV3: parsedTariffData[2] ? parsedTariffData[2].TariffPrice : 'N/A',
            Variation4: parsedTariffData[3] ? parsedTariffData[3].ESIMGORef : 'N/A',
            BasePriceV4: parsedTariffData[3] ? parsedTariffData[3].BasePrice : 'N/A',
            TariffPriceV4: parsedTariffData[3] ? parsedTariffData[3].TariffPrice : 'N/A',
            Variation5: parsedTariffData[4] ? parsedTariffData[4].ESIMGORef : 'N/A',
            BasePriceV5: parsedTariffData[4] ? parsedTariffData[4].BasePrice : 'N/A',
            TariffPriceV5: parsedTariffData[4] ? parsedTariffData[4].TariffPrice : 'N/A',
        }
    });

    const exportToCSV = async () => {
        if (excelViewData && excelViewData.length > 0) {
            const ws = XLSX.utils.json_to_sheet(excelViewData);
            const wb = { Sheets: { 'Tariff': ws }, SheetNames: ['Tariff'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            await FileSaver.saveAs(data, 'Tariff' + fileExtension);
        }
    }

    return (
        <>
            <Modal
                title={title}
                showModal={showViewModal}
                onHide={handleCancel}
                showFooter={false}
                width={1200}
            >
                <div>
                    <div>
                        {responseValue &&
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <p style={{ fontSize: '16px', color: '#2B3038', fontWeight: 'normal' }} className='mb-0'>Tariff Group</p>
                                    <p>{responseValue.TariffGroup}</p>
                                </div>
                                <div>
                                    <Button icon={<DownloadCloud2sLineIcon color='#fff' className='mr-1 excel-btn-1-aidit' size={'0.9rem'} />} title="Export" buttonClass={`btn ${Styles.exportBtn}`} style={{ marginRight: "10px" }} buttonStyle="primary" onClick={exportToCSV} />
                                </div>
                            </div>
                        }
                    </div>

                </div>
                <div className="table-responsive">
                <table className='table  table-striped table-bordered mt-3'>
                    <thead>
                        {/* <tr>
                            <th colspan="7">{responseValue.TariffGroup}</th>
                        </tr> */}
                        <tr>
                            <th scope="col">Plan Name</th>
                            <th scope="col">Plan Scope </th>
                            <th scope="col">Variation-1</th>
                            <th scope="col">Variation-2</th>
                            <th scope="col">Variation-3</th>
                            <th scope="col">Variation-4</th>
                            <th scope="col">Variation-5</th>
                        </tr>
                    </thead>
                    <tbody className='tariff-table-body'>
                        {varientData && varientData.length > 0 && varientData.map((item, key) => {
                            const parsedTariffData = item.PlanVariant && item.PlanVariant !== null && JSON.parse(item.PlanVariant);
                            return (
                                <tr>
                                    <td data-label="Account">{item.PlanName}</td>
                                    <td data-label="Due Date">{item.CountryName}</td>
                                    <td data-label="Amount">
                                        {parsedTariffData[0] ? <div>
                                            <p className='mb-1'>{parsedTariffData[0].ESIMGORef}</p>
                                            <span>
                                            <input type='text' value={`£ ${parseFloat(parsedTariffData[0].BasePrice).toFixed(2)}`} disabled style={{ width: '70px' }} className='badge-light rounded-left border-0 px-2' />
                                            <input type='text' value={parsedTariffData[0].TariffPrice.toFixed(2)} disabled style={{ width: '70px' }} className='badge-primary rounded-right border-0 px-2' /></span>
                                        </div> : 'N/A'}
                                    </td>
                                    <td data-label="Period">
                                        {parsedTariffData[1] ? <div>
                                            <p className='mb-1'>{parsedTariffData[1] ? parsedTariffData[1].ESIMGORef : 'N/A'}</p>
                                            <span><input type='text' value={`£ ${parseFloat(parsedTariffData[1].BasePrice).toFixed(2)}`} disabled style={{ width: '70px' }} className='badge-light rounded-left border-0 px-2' />
                                                <input type='text' value={parsedTariffData[1].TariffPrice.toFixed(2)} disabled style={{ width: '70px' }} className='badge-primary rounded-right border-0 px-2'  /></span>
                                        </div> : 'N/A'}
                                    </td>
                                    <td data-label="Period">
                                        {parsedTariffData[2] ? <div>
                                            <p className='mb-1'>{parsedTariffData[2] ? parsedTariffData[2].ESIMGORef : 'N/A'}</p>
                                            <span><input type='text' value={`£ ${parseFloat(parsedTariffData[2].BasePrice).toFixed(2)}`} disabled style={{ width: '70px' }} className='badge-light rounded-left border-0 px-2' />
                                                <input type='text' value={parsedTariffData[2].TariffPrice.toFixed(2)} disabled style={{ width: '70px' }} className='badge-primary rounded-right border-0 px-2' />
                                            </span>
                                        </div> : 'N/A'}
                                    </td>
                                    <td data-label="Period">
                                        {parsedTariffData[3] ? <div>
                                            <p className='mb-1'>{parsedTariffData[3] ? parsedTariffData[3].ESIMGORef : 'N/A'}</p>
                                            <span><input type='text' value={`£ ${parseFloat(parsedTariffData[3].BasePrice).toFixed(2)}`} disabled style={{ width: '70px' }} className='badge-light rounded-left border-0 px-2' />
                                                <input type='text' value={parsedTariffData[3].TariffPrice.toFixed(2)} disabled style={{ width: '70px' }} className='badge-primary rounded-right border-0 px-2' /></span>
                                        </div> : 'N/A'}
                                    </td>
                                    <td data-label="Period">
                                        {parsedTariffData[3] ? <div>
                                            <p className='mb-1'>{parsedTariffData[4] ? parsedTariffData[4].ESIMGORef : 'N/A'}</p>
                                            <span><input type='text' value={`£ ${parseFloat(parsedTariffData[4].BasePrice).toFixed(2)}`} disabled style={{ width: '70px' }} className='badge-light rounded-left border-0 px-2' />
                                                <input type='text' value={parsedTariffData[4].TariffPrice.toFixed(2)} disabled style={{ width: '70px' }} className='badge-primary rounded-right border-0 px-2' /></span>
                                        </div> : 'N/A'}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                </div>
            </Modal>
        </>
    )
}

const styles = {
    hr: {
        border: '1px solid',
        margin: '0px 0px 7px 0px',
    }
}

export default TariffViewModal