import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import RefundRequestAction from '../../redux/refundRequest/actions'



const { getRefundRequest, toRefundApproved, toRefundCancelled } = RefundRequestAction;
const Request = () => {
    const dispatch = useDispatch();
    const refundRequestState = useSelector((state) => state.refundRequest);


    useEffect(() => {
        dispatch(getRefundRequest());
        // dispatch(toRefundApproved());
        // dispatch(toRefundCancelled());
    }, []);

    let Refunddata = useMemo(() => {
        if (refundRequestState.refundRequestResult.length > 0) {
            return refundRequestState.refundRequestResult;
        }
        return [];
    }, [refundRequestState.refundRequestResult]);
    return (
        <div className="col-lg-12">
            <div className="row">
                <div className="col-md-4 pr-md-0">
                    <div className="iq-card iq-card-block iq-card-stretch iq-card-height" >
                        <div className="iq-card-body" style={{padding:"10px"}}>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="ml-2">
                                    <h6 className="mb-1 font-weight-bold">Pending<small> Refund Request</small></h6>
                                    <span className="h4 text-dark mb-0 counter d-inline-block w-100">{Refunddata && Refunddata.length > 0 && Refunddata[0].PendingRefundRequest}</span>
                                </div>
                                <div className="icon iq-icon-box bg-danger rounded-circle m-0" data-wow-delay="0.2s">
                                <i className="ri-refund-line"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 pr-md-0">
                    <div className="iq-card iq-card-block iq-card-stretch iq-card-height" >
                        <div className="iq-card-body" style={{padding:"10px"}}>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="ml-2">
                                    <h6 className="mb-1 font-weight-bold" >Complete <small>Refund Request</small></h6>
                                    <span className="h4 text-dark mb-0 counter d-inline-block w-100">{Refunddata && Refunddata.length > 0 && Refunddata[0].ApprovedRefundRequest}</span>
                                </div>
                                <div className="icon iq-icon-box bg-success rounded-circle m-0" data-wow-delay="0.2s"> 
                                   
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="currentColor"><path d="M12.0049 2C17.5277 2 22.0049 6.47715 22.0049 12C22.0049 17.5228 17.5277 22 12.0049 22C9.57847 22 7.3539 21.1358 5.62216 19.6985L5.37815 19.4892L6.27949 17.5875C7.73229 19.0759 9.76067 20 12.0049 20C16.4232 20 20.0049 16.4183 20.0049 12C20.0049 7.58172 16.4232 4 12.0049 4C7.66997 4 4.14034 7.44784 4.00869 11.7508L4.00488 12H6.50488L3.79854 17.7161C2.66796 16.096 2.00488 14.1254 2.00488 12C2.00488 6.47715 6.48204 2 12.0049 2ZM13.0049 6V8H15.5049V10H10.0049C9.72874 10 9.50488 10.2239 9.50488 10.5C9.50488 10.7455 9.68176 10.9496 9.91501 10.9919L10.0049 11H14.0049C15.3856 11 16.5049 12.1193 16.5049 13.5C16.5049 14.8807 15.3856 16 14.0049 16H13.0049V18H11.0049V16H8.50488V14H14.0049C14.281 14 14.5049 13.7761 14.5049 13.5C14.5049 13.2545 14.328 13.0504 14.0948 13.0081L14.0049 13H10.0049C8.62417 13 7.50488 11.8807 7.50488 10.5C7.50488 9.11929 8.62417 8 10.0049 8H11.0049V6H13.0049Z"></path></svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="iq-card iq-card-block iq-card-stretch iq-card-height" >
                        <div className="iq-card-body" style={{padding:"10px"}}>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="ml-2">
                                    <h6 className="mb-1 font-weight-bold">Total <small>Refund Request </small> </h6>
                                    <span className="h4 text-dark mb-0 counter d-inline-block w-100">{Refunddata && Refunddata.length > 0 && Refunddata[0].TotalRefundRequest}</span>
                                </div>
                                <div className="icon iq-icon-box bg-primary rounded-circle m-0" data-wow-delay="0.2s">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="currentColor"><path d="M5.67591 4.25667C9.60392 1.03828 15.4094 1.26236 19.076 4.92893C22.9812 8.83418 22.9812 15.1658 19.076 19.0711C15.1707 22.9763 8.83906 22.9763 4.93382 19.0711C2.40932 16.5466 1.51676 13.0081 2.25611 9.76666L2.33275 9.45394L4.26718 9.96315C3.56967 12.623 4.26329 15.5721 6.34803 17.6569C9.47222 20.781 14.5375 20.781 17.6617 17.6569C20.7859 14.5327 20.7859 9.46734 17.6617 6.34315C14.8441 3.5255 10.4475 3.24903 7.32006 5.51375L7.09886 5.67983L8.1158 6.6967L3.5196 7.75736L4.58026 3.16117L5.67591 4.25667ZM13.0049 6V8H15.5049V10H10.0049C9.72874 10 9.50488 10.2239 9.50488 10.5C9.50488 10.7455 9.68176 10.9496 9.91501 10.9919L10.0049 11H14.0049C15.3856 11 16.5049 12.1193 16.5049 13.5C16.5049 14.8807 15.3856 16 14.0049 16H13.0049V18H11.0049V16H8.50488V14H14.0049C14.281 14 14.5049 13.7761 14.5049 13.5C14.5049 13.2545 14.328 13.0504 14.0948 13.0081L14.0049 13H10.0049C8.62417 13 7.50488 11.8807 7.50488 10.5C7.50488 9.11929 8.62417 8 10.0049 8H11.0049V6H13.0049Z"></path></svg>                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default Request
