const actions = {
    GET_USER: 'GET_USER',
    GET_USER_SUCCESS: 'GET_USER_SUCCESS',
    GET_USER_FAILED: 'GET_USER_FAILED',
    ADD_USER: "ADD_USER",
    ADD_USER_SUCCESS: "ADD_USER_SUCCESS",
    ADD_USER_FAILED: "ADD_USER_FAILED",
    EDIT_USER: "EDIT_USER",
    EDIT_USER_SUCCESS: "EDIT_USER_SUCCESS",
    DELETE_USER: "DELETE_USER",
    DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
    USER_PASSWORD_RESET: "USER_PASSWORD_RESET",
    USER_PASSWORD_RESET_SUCCESS: "USER_PASSWORD_RESET_SUCCESS",
    USER_PASSWORD_RESET_FAILED:"USER_PASSWORD_RESET_FAILED",
    PREPARE_USER_FORM:"PREPARE_USER_FORM",
    PREPARE_PASSWORD_FORM:"PREPARE_PASSWORD_FORM",
    RESET_USER: "RESET_USER",
    GET_USER_ROLE:"GET_USER_ROLE",
    GET_USER_ROLE_SUCCESS:"GET_USER_ROLE_SUCCESS",
    RESET_USER_MESSAGE:"RESET_USER_MESSAGE",
    getUser: () => ({
        type: actions.GET_USER,
    }),
    getUserSuccess: (userResult) => ({
        type: actions.GET_USER_SUCCESS,
        userResult
    }),
    getUserFailed: (userError) => ({
        type: actions.GET_USER_FAILED,
        userError
    }),
    addUser: (data) => ({
        type: actions.ADD_USER,
        payload: { data }
    }),
    addUserSuccess: (userMessage, userResult) => ({
        type: actions.ADD_USER_SUCCESS,
        userResult,
        userMessage
    }),
    addUserFailed: (userError) => ({
        type: actions.ADD_USER_FAILED,
        userError
    }),
    editUser: (userId, data) => ({
        type: actions.EDIT_USER,
        payload: { userId, data }
    }),
    editUserSuccess: (userMessage, userResult) => ({
        type: actions.EDIT_USER_SUCCESS,
        userResult,
        userMessage
    }),
    deleteUser: (userId, status) => ({
        type: actions.DELETE_USER,
        payload: { userId, status }
    }),
    deleteUserSuccess: (userMessage, userResult) => ({
        type: actions.DELETE_USER_SUCCESS,
        userResult,
        userMessage
    }),
    userPasswordReset: (data) => ({
        type: actions.USER_PASSWORD_RESET,
        payload: { data }
    }),
    userPasswordResetSuccess: (userMessage, userResult) => ({
        type: actions.USER_PASSWORD_RESET_SUCCESS,
        userResult,
        userMessage
    }),
    userPasswordResetFailed: (userError) => ({
        type: actions.USER_PASSWORD_RESET_FAILED,
        userError
    }),
    prepareUserForm: () => ({
        type: actions.PREPARE_USER_FORM,
    }),
    preparePasswordForm: () => ({
        type: actions.PREPARE_PASSWORD_FORM,
    }),
    resetUser: () => ({
        type: actions.RESET_USER,
    }),
    getUserRole:(data)=>({
        type:actions.GET_USER_ROLE,
        payload: { data }
    }),
    getUserRoleSuccess:(roleResult)=>({
        type:actions.GET_USER_ROLE_SUCCESS,
        roleResult
    }),
    resetUserMessage: () => ({
        type: actions.RESET_USER_MESSAGE,
    }),
};
export default actions;
