import actions from "./actions";

const initState = {
    countryHasPlanResult: [],
    countryHasPlanLoading: false,
    countryHasPlanError: false,
    countryResult: [],
    loading: false,
    countryError: false,
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_COUNTRY_WHO_HAS_PLAN:
            return {
                ...state,
                countryHasPlanLoading: true,
            };
        case actions.GET_COUNTRY_WHO_HAS_PLAN_SUCCESS:
            return {
                ...state,
                countryHasPlanLoading: false,
                countryHasPlanError: false,
                countryHasPlanResult: action.countryResult,
            };
        case actions.GET_COUNTRY_WHO_HAS_PLAN_FAILED:
            return {
                ...state,
                countryHasPlanLoading: false,
                countryHasPlanError: action.countryError,
            };

        case actions.GET_COUNTRY:
            return {
                ...state,
                loading: true,
            };
        case actions.GET_COUNTRY_SUCCESS:
            return {
                ...state,
                loading: false,
                countryError: false,
                countryResult: action.countryResult,
            };
        case actions.GET_COUNTRY_FAILED:
            return {
                ...state,
                loading: false,
                countryError: action.countryError,
            };
        default:
            return state;
    }
}
