const actions = {
    GET_POFILE_DATA_LIST: "GET_POFILE_DATA_LIST",
    GET_POFILE_DATA_LIST_SUCCESS: "GET_POFILE_DATA_LIST_SUCCESS",
    GET_POFILE_DATA_LIST_FAILED: "GET_POFILE_DATA_LIST_FAILED",
    EDIT_PROFILE_LIST: "EDIT_PROFILE_LIST",
    EDIT_PROFILE_LIST_SUCCESS: "EDIT_PROFILE_LIST_SUCCESS",
    EDIT_PROFILE_LIST_FAILED: "EDIT_PROFILE_LIST_FAILED",
    RESET_PROFILE: "RESET_PROFILE",
    PREPARE_EDIT_FORM_MODEL:"PREPARE_EDIT_FORM_MODEL",
    RESET_PROFILE_MESSAGE:"RESET_PROFILE_MESSAGE",

    getProfileDataList: () => ({
        type: actions.GET_POFILE_DATA_LIST,
    }),
    getProfileDataListSuccess: (profileListResult) => ({
        type: actions.GET_POFILE_DATA_LIST_SUCCESS,
        profileListResult
    }),
    getProfileDataListFailed: (profileError) => ({
        type: actions.GET_POFILE_DATA_LIST_FAILED,
        profileError
    }),
    editProfileList: (Id, data) => ({
        type: actions.EDIT_PROFILE_LIST,
        payload: { Id, data }
    }),
    editProfileListSuccess: (profileMessage, profileListResult) => ({
        type: actions.EDIT_PROFILE_LIST_SUCCESS,
        profileMessage,
        profileListResult

    }),
    editProfileListFailed: (profileError) => ({
        type: actions.EDIT_PROFILE_LIST_FAILED,
        profileError,
    }),
    resetProfile: () => ({
        type: actions.RESET_PROFILE,
    }),
    prepareEditFormModel: () => ({
        type: actions.PREPARE_EDIT_FORM_MODEL,
    }),
    resetProfileMessage: () => ({
        type: actions.RESET_PROFILE_MESSAGE,
    }),
}
export default actions;