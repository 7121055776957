import moment from "moment";

export const isEmpty = (obj) => {
    for (const prop in obj) {
        if (Object.hasOwn(obj, prop)) {
            return false;
        }
    }
    return true;
}

export const twoDigitDecimal = (number) => {
    let twoDigitDecimal = parseFloat(number).toFixed(2);
    return twoDigitDecimal;
}

export const getDayDiffBetweenTwoDates = (date1, date2) => {
    const moment1 = moment(date1, "YYYY-MM-DD HH:mm:ss");
    const moment2 = moment(date2, "YYYY-MM-DD HH:mm:ss");
    const differenceInMoments = moment2.diff(moment1, 'days');
    return `${differenceInMoments} Days`;
}

export const bytesToMB = (bytes) => {
    let mb = bytes / 1000000;
    mb = mb.toFixed(2);
    return `${mb} MB`;
}

export const isAdminAgent = (tokenData) => {
    if (tokenData.length > 0) {
        if (tokenData[0].IsCompany == 1 && tokenData[0].RoleID == 29) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const isAdmin = (tokenData) => {
    if (tokenData.length > 0) {
        if (tokenData[0].IsCompany == 1 && tokenData[0].RoleID == 1) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const isDealerAgent = (tokenData) => {
    if (tokenData.length > 0) {
        if (tokenData[0].IsCompany == 0 && tokenData[0].RoleID == 29) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const isDealer = (tokenData) => {
    if (tokenData.length > 0) {
        if (tokenData[0].IsCompany == 0 && tokenData[0].RoleID == 1) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const isTariffCreationAllow = (tokenData) => {
    if (tokenData.length > 0) {
        if (tokenData[0].AllowTariffCreation == 1) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const isDealerCreationAllow = (tokenData) => {
    if (tokenData.length > 0) {
        if (tokenData[0].AllowSellerCreation == 1) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const checkStringContainRegex = (regex, str) => {
    return new RegExp(regex).test(str);
}