import actions from "./actions";

const initState = {
    getOrderByIccIdLoading: false,
    getOrderByIccIdError: null,
    getOrderByIccIdMessage: null,
    getOrderByIccIdList: [],
    getOrderByIccIdModal: false,
    orderRefundRequestLoading: false,
    orderRefundRequestError: null,
    orderRefundRequestMessage: null,
};

export default function reducer(state = initState, action) {
    switch (action.type) {

        case actions.ORDER_REFUND_REQUEST:
            return {
                ...state,
                orderRefundRequestLoading: true,
                orderRefundRequestError: null,
                orderRefundRequestMessage: null,
            };
        case actions.ORDER_REFUND_REQUEST_SUCCESS:
            return {
                ...state,
                orderRefundRequestLoading: false,
                orderRefundRequestError: null,
                orderRefundRequestMessage: action.payload.message,
            };
        case actions.ORDER_REFUND_REQUEST_FAILED:
            return {
                ...state,
                orderRefundRequestLoading: false,
                orderRefundRequestError: action.payload.error,
                orderRefundRequestMessage: null,
            };


        case actions.GET_ORDER_BY_ICCID:
            return {
                ...state,
                getOrderByIccIdLoading: true,
                getOrderByIccIdError: null,
                getOrderByIccIdMessage: null,
            };
        case actions.GET_ORDER_BY_ICCID_SUCCESS:
            return {
                ...state,
                getOrderByIccIdLoading: false,
                getOrderByIccIdError: null,
                getOrderByIccIdMessage: action.payload.message,
                getOrderByIccIdList: action.payload.data,
            };
        case actions.GET_ORDER_BY_ICCID_FAILED:
            return {
                ...state,
                getOrderByIccIdLoading: false,
                getOrderByIccIdError: action.payload.error,
                getOrderByIccIdMessage: null,
                getOrderByIccIdModal: true,
            };

        case actions.RESET_ORDER_REFUND:
            return {
                ...state,
                getOrderByIccIdError: null,
                getOrderByIccIdModal: false,
                orderRefundRequestMessage: null,

            };

        default:
            return state;
    }
}