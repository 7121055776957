import React from 'react';
import notFoundImage from '../../assets/images/404.png'
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div className="wrapper">
            <div className="container-fluid p-0">
                <div className="row no-gutters">
                    <div className="col-sm-12 text-center">
                        <div className="iq-error">
                            <img
                                src={notFoundImage}
                                className="img-fluid iq-error-img"
                                alt=""
                            />
                            <h2 className="mb-0">Oops! This Page is Not Found.</h2>
                            <p>The requested page dose not exist.</p>
                            <Link className="btn btn-primary mt-3" to="/dashboard">
                                <i className="ri-home-4-line" />
                                Back to Home
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFound