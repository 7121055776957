import React, { useEffect } from 'react'
import CardLayout from '../../components/utils/CardLayout'
import AuthActions from '../../redux/auth/actions'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import Style from './MyProfile.module.css'

const dateFormat = process.env.REACT_APP_DATE_FORMAT;
const { getUserDetails } = AuthActions;

const MyProfile = () => {
    const dispatch = useDispatch();
    const AuthState = useSelector((state) => state.auth);
    const userDetails = AuthState.userDetails && AuthState.userDetails.length > 0 ? AuthState.userDetails[0] : null;

    useEffect(() => {
        dispatch(getUserDetails());
    }, []);


    return (
        <>
<div className="content-page">
    <div className="container-fluid">
            <div className="row mb-0 justify-content-center">
                <div className="col-lg-5 profile-center profile-center-panel-1 ">
                    <div className="tab-content tab-content-profile">
                        <div
                            className="tab-pane fade active show"
                            id="profile-profile"
                            role="tabpanel"
                        >
                            <div className={Style.iq_card} id='profileuper-card'>

                                <div class='top-headerprofile'>
                                    <p className='para-my-profil'>My Profile</p>
                                </div>
                                <div className={Style.iq_card_body}>
                                    <div className="user-detail text-center">
                                        <div className="user-profile user-profil-my-pro pt-2">
                                            <div className={Style.avatar_div} id='user-cirle-my-pro'>
                                                <p className={Style.avatar_text}>{userDetails ? userDetails.Username.charAt(0).toUpperCase() : ''}</p>
                                            </div>
                                        </div>
                                        <div className="profile-detail mt-1">
                                            <h3 className="d-inline-block text-capitalize">{userDetails ? userDetails.Username : ''}</h3>
                                            <br />
                                            <p className="d-inline-block pl-0 para-user-name-1">{userDetails ? userDetails.UserRole : ''}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex justify-content-center">
                            <div className={Style.iq_card} Id='lower-part-profile'>
                            <div className="d-flex justify-content-center">
                                <div className='header-part-profile-p'>
                                    <h4 className="card-title-about">About User</h4>
                                </div>
                                </div>
                                <div className='lowe-part-profile-inner mt-3'>
                                   
                                    <div className={Style.iq_card_body}>
                                        <div className='row'>
                                            <div className='col-md-6 mb-3'>
                                                
                                                    <h6 className='about-user-th'>Name:</h6>
                                                    <p>{userDetails ? userDetails.Name : ''}</p>
                                                
                                            </div>

                                            <div className='col-md-6 mb-3'>
                                                
                                                    <h6 className='about-user-th'>Email:</h6>
                                                    <p>{userDetails ? userDetails.ContactEmailID : ''}</p>
                                                
                                            </div>
                                            <div className='col-md-6 mb-3'>
                                                
                                                    <h6 className='about-user-th'>Contact:</h6>
                                                    <p>{userDetails ? userDetails.ContactNumber : ''}</p>
                                               
                                            </div>

                                            <div className='col-md-6 mb-3'>
                                              
                                                    <h6 className='about-user-th'>Address:</h6>
                                                    <p>{userDetails && userDetails.Address != '' ? userDetails.Address : 'Not Available'}</p>
                                                
                                            </div>

                                            <div className='col-md-6 mb-3'>
                                              
                                                    <h6 className='about-user-th'>City:</h6>
                                                    <p>{userDetails && userDetails.City != '' ? userDetails.City : 'Not Available'}</p>
                                               
                                            </div>

                                            <div className='col-md-6 mb-3'>
                                               
                                                    <h6 className='about-user-th'>ZipCode:</h6>
                                                    <p>{userDetails && userDetails.ZipCode != '' ? userDetails.ZipCode : 'Not Available'}</p>
                                             
                                            </div>

                                            <div className='col-md-6 mb-3'>
                                              
                                                    <h6 className='about-user-th'>State:</h6>
                                                    <p>{userDetails && userDetails.State != '' ? userDetails.State : 'Not Available'}</p>
                                               
                                            </div>

                                            <div className='col-md-6 mb-3'>
                                                
                                                    <h6 className='about-user-th'>TariffGroup:</h6>
                                                    <p>{userDetails && userDetails.TariffGroup != '' ? userDetails.TariffGroup : 'Not Available'}</p>
                                                
                                            </div>

                                            <div className='col-md-6 mb-3'>
                                              
                                                    <h6 className='about-user-th'>Allow Seller Creation:</h6>
                                                    <p>{userDetails && userDetails.AllowSellerCreation ? 'Yes' : 'No'}</p>
                                                
                                            </div>
                                            <div className='col-md-6 mb-3'>
                                              
                                                    <h6 className='about-user-th'>Allow Tariff Creation:</h6>
                                                    <p>{userDetails && userDetails.AllowTariffCreation ? 'Yes' : 'No'}</p>
                                              
                                            </div>
                                            <div className='col-md-6 mb-3'>
                                                
                                                    <h6 className='about-user-th'>Joined:</h6>
                                                    <p>{userDetails ? moment(userDetails.CreatedDtTm).format(dateFormat) : ''}</p>
                                                
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
        </>
    )
}


export default MyProfile