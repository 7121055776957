import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData, getList } from '../../helpers/fackBackend_Helper';

// function* getOrderByIccIdResponse({ payload: { data } }) {
//     try {
//         const response = yield call(addData, '/checkSimStatusReport', data);
//         if (response.Data[0].Status) {
//             yield put(actions.getOrderByIccIdSuccess('Data get success', response.Data))
//         } else {
//             yield put(actions.getOrderByIccIdFailed(response.Data.Message));
//         }
//     } catch (error) {
//         yield put(actions.getOrderByIccIdFailed(error));
//     }
// }

function* getOrderByIccIdResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/checkSimStatusReport', data);
        if (response.Status) {
            if (response.Data && response.Data.length > 0 && response.Data[0].Status) {
                yield put(actions.getOrderByIccIdSuccess('Data get success', response.Data))
            }
            else {
                if(response.Data && response.Data.length > 0 && response.Data[0].Message){
                    yield put(actions.getOrderByIccIdFailed(response.Data[0].Message));
                }
                else{
                    yield put(actions.getOrderByIccIdFailed('No data found for this ICCID'));
                }
            }
        }
        else {
            yield put(actions.getOrderByIccIdFailed(response.error));
        }

    } catch (error) {
        yield put(actions.getOrderByIccIdFailed(error));
    }
}

function* orderRefundRequestResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/orderRefundRequest', data);
        if (response.Data[0].Status) {
            yield put(actions.orderRefundRequestSuccess('Refund request submited', response.Data))
        } else {
            yield put(actions.orderRefundRequestFailed(response.Data.Message));
        }
    } catch (error) {
        yield put(actions.orderRefundRequestFailed(error));
    }
}


export default function* rootSaga() {
    yield all([takeEvery(actions.GET_ORDER_BY_ICCID, getOrderByIccIdResponse)]);
    yield all([takeEvery(actions.ORDER_REFUND_REQUEST, orderRefundRequestResponse)]);
}