import actions from "./actions";

const initState = {
    list: [],
    openAggregatorModel: false,
    previewModel:false,
    loading: false,
    error: null,
    message: null,

};

export default function reducer(state = initState, action) {

    switch (action.type) {
        case actions.GET_AGGREGATOR:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case actions.GET_AGGREGATOR_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                list: action.data,
                modal: false,
                previewData: [],
            };

        case actions.GET_AGGREGATOR_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
                message: null,
            };

        case actions.OPEN_AGGREGATOR_MODAL:
            return {
                ...state,
                openAggregatorModel: true,
                error: null,
                message: null,
                loading: false,
            };
        case actions.ADD_AGGREGATOR:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };

        case actions.ADD_AGGREGATOR_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                message: action.message,
                openAggregatorModel: false,
            };

        case actions.ADD_AGGREGATOR_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
                message: null,
            };

        case actions.RESET_AGGREGATOR:
            return {
                ...state,
                openAggregatorModel: false,
                previewModel:false,
                loading: false,
                error: null,
                message: null,
            };
            case actions.PREVIEW_AGGREGATOR_MODAL:
            return {
                ...state,
                previewModel: true,
                error: null,
                message: null,
                loading: false,
            };
            case actions.SET_PREVIEW_DATA:
            return {
                ...state,
                previewData: action.data,
            };
            case actions.UPDATE_AGGREGATOR:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };

        case actions.UPDATE_AGGREGATOR_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                message: action.message,
                openAggregatorModel: false,
                modalEditParam: false,
            };

        case actions.UPDATE_AGGREGATOR_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
                message: null,
            };
        default:
            return state;
    }
}