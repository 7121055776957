import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isAdmin, isDealer, isTariffCreationAllow, isDealerCreationAllow, isAdminAgent, isDealerAgent } from '../../helpers/commonFunction';
import AuthActions from '../../redux/auth/actions'
import './Navbar.css'

const { getUserDataByToken } = AuthActions;

function Navbar() {
    const dispatch = useDispatch();
    const tokenData = useSelector((state) => state.auth.tokenData);

    useEffect(() => {
        dispatch(getUserDataByToken());
    }, [dispatch]);

    return (
        <div className="iq-menu-horizontal">
            <div className="ghf">
                <div className="wrapper-menu">
                    <div className="main-circle"><i class="ri-close-line"></i></div>
                    <div className="hover-circle"><i class="ri-close-line"></i></div>
                </div>
            </div>
            <nav className="iq-sidebar-menu">

                <ul id="iq-sidebar-toggle" className="iq-menu d-flex">
                    <li className='shouldActive'><NavLink to="/dashboard"><i className="ri-home-line"></i><span>Dashboard</span></NavLink></li>
                    <li className='shouldActive'>
                        <a href="#menu-design" className="iq-waves-effect collapsed" data-toggle="collapse" >
                            <i className="ri-admin-line"></i>
                            <span>Admin</span>
                            <i className="ri-arrow-right-s-line iq-arrow-right"></i>
                        </a>
                        <ul id="menu-design" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                            {
                                isAdmin(tokenData) || isAdminAgent(tokenData) ?
                                    <li className="shouldActive">
                                        <a href="#configuration" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
                                            <span><i className="ri-file-settings-line"></i>Config</span>
                                            <i className="ri-arrow-right-s-line iq-arrow-right"></i>
                                        </a>
                                        <ul id="configuration" className="iq-submenu collapse iq-submenu-data">
                                            {/* {isAdmin(tokenData) ? <li><NavLink to="/aggregator"><i className="ri-drag-move-2-line"></i>Aggregator</NavLink></li> : ''} */}
                                            {isAdmin(tokenData) || isAdminAgent(tokenData) ? <li className='shouldActive'><NavLink to="/banner"><i className="ri-image-line"></i>Banner</NavLink></li> : ''}
                                            {isAdmin(tokenData) ? <li className='shouldActive'><NavLink to="/aggregator"><i className="ri-drag-move-2-line"></i>Aggregator</NavLink></li> : ''}
                                            {isAdmin(tokenData) ? <li><NavLink to="/email-template"><i className="ri-mail-settings-line"></i>Email Template</NavLink></li> : ''}
                                        </ul>
                                    </li> : ''
                            }

                            {isAdmin(tokenData) || isAdminAgent(tokenData) ? <li className='shouldActive'><NavLink to="/verify-dealer"><i className="ri-checkbox-multiple-line"></i>Verify Dealer</NavLink></li> : ''}
                            {isAdmin(tokenData) || (isDealer(tokenData) && isDealerCreationAllow(tokenData)) || isDealerAgent(tokenData) || isAdminAgent(tokenData) ? <li className='shouldActive'><NavLink to="/dealer"><i className="ri-folder-user-line"></i>Dealer</NavLink></li> : ''}
                            {isAdmin(tokenData) || isDealer(tokenData) ? <li className='shouldActive'><NavLink to="/user"><i className="ri-user-add-line"></i>User</NavLink></li> : ''}
                            {isDealer(tokenData) || isDealerAgent(tokenData) ? <li className='shouldActive'><NavLink to="/add-top-up"><i className="ri-money-pound-circle-line"></i>Add Top Up</NavLink></li> : ''}
                            {isAdmin(tokenData) || isAdminAgent(tokenData) ? <li className='shouldActive'><NavLink to="/manual-top-up"><i className="ri-money-pound-circle-line"></i>Manual Top Up</NavLink></li> : ''}
                        </ul>
                    </li>

                    <li className="shouldActive">
                        <a href="#menu-level" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false"><i className="ri-sim-card-line"></i><span>Inventory</span><i className="ri-arrow-right-s-line iq-arrow-right"></i></a>
                        <ul id="menu-level" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                            {isAdmin(tokenData) || isAdminAgent(tokenData) ? <li className="shouldActive"><NavLink to="/sim-purchase"><i className="ri-shopping-bag-line"></i>SIM Purchase</NavLink></li> : ''}
                            {isAdmin(tokenData) || isDealer(tokenData) || isDealerAgent(tokenData) || isAdminAgent(tokenData) ? <li className="shouldActive"><NavLink to="/list-sim"><i className="ri-list-check"></i>List SIM</NavLink></li> : ''}
                        </ul>
                    </li>


                    <li className="shouldActive">
                        <a href="#ui-elements" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false"><i className="ri-add-box-line"></i><span>Package</span><i className="ri-arrow-right-s-line iq-arrow-right"></i></a>
                        <ul id="ui-elements" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                            {isAdmin(tokenData) || isAdminAgent(tokenData) ? <li className="shouldActive"><NavLink to="/plan"><i className="ri-send-plane-2-line"></i>Plan</NavLink></li> : ''}
                            {isAdmin(tokenData) || (isDealer(tokenData) && isTariffCreationAllow(tokenData) && isDealerCreationAllow(tokenData)) || isDealerAgent(tokenData) || isAdminAgent(tokenData) ? <li className="shouldActive"><NavLink to="/tariff-group"><i className="ri-stack-line"></i>Tariff Group</NavLink></li> : ''}
                            {isAdmin(tokenData) ? <li className="shouldActive"><NavLink to="/refund-request"><i className="ri-refund-line"></i>Refund Request</NavLink></li> : ''}
                            {isDealer(tokenData) || isDealerAgent(tokenData) ? <li className="shouldActive"><NavLink to="/plan-purchase"><i className="ri-refund-line"></i>Plan Purchase</NavLink></li> : ''}
                            {isDealer(tokenData) ? <li className="shouldActive"><NavLink to="/order-refund"><i className="ri-refund-line"></i>Order Refund</NavLink></li> : ''}
                            {/* {isAdmin(tokenData) ? <li className="shouldActive"><NavLink to="/coupon"><i className="ri-refund-line"></i>Coupon</NavLink></li> : ''} */}

                        </ul>
                    </li>

                    <li className="shouldActive">
                        <a href="#Pages" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false"><i className="ri-file-chart-line"></i><span>Reports</span><i className="ri-arrow-right-s-line iq-arrow-right"></i></a>
                        <ul id="Pages" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                            {isAdmin(tokenData) || isDealer(tokenData) || isDealerAgent(tokenData) || isAdminAgent(tokenData) ? <li className="shouldActive"><NavLink to="/audit-report"><i className="ri-list-ordered"></i>Audit Report</NavLink></li> : ''}
                            {isAdmin(tokenData) || isDealer(tokenData) || isDealerAgent(tokenData) || isAdminAgent(tokenData) ? <li className="shouldActive"><NavLink to="/order-report"><i className="ri-list-ordered"></i>Order Report</NavLink></li> : ''}
                            {/* <li className="shouldActive"><NavLink to="/sales-report"><i className="ri-money-dollar-circle-line"></i>Sales Report</NavLink></li> */}
                        </ul>
                    </li>

                </ul>
            </nav>
        </div>
    )
}

export default Navbar