const actions = {
  RESET_SIM_PURCHASE: 'RESET_SIM_PURCHASE',
  GET_PHYSICAL_SIM_AGGREGATOR: 'GET_PHYSICAL_SIM_AGGREGATOR',
  GET_PHYSICAL_SIM_AGGREGATOR_SUCCESS: 'GET_PHYSICAL_SIM_AGGREGATOR_SUCCESS',
  GET_PHYSICAL_SIM_AGGREGATOR_FAILED: 'GET_PHYSICAL_SIM_AGGREGATOR_FAILED',
  GET_INVOICE: 'GET_INVOICE',
  GET_INVOICE_SUCCESS: 'GET_INVOICE_SUCCESS',
  GET_INVOICE_FAILED: 'GET_INVOICE_FAILED',
  SAVE_PURCHASE_SIM: 'SAVE_PURCHASE_SIM',
  SAVE_PURCHASE_SIM_SUCCESS: 'SAVE_PURCHASE_SIM_SUCCESS',
  SAVE_PURCHASE_SIM_FAILED: 'SAVE_PURCHASE_SIM_FAILED',
  VALIDATE_SIM_DATA: 'VALIDATE_SIM_DATA',
  VALIDATE_SIM_DATA_SUCCESS: 'VALIDATE_SIM_DATA_SUCCESS',
  VALIDATE_SIM_DATA_FAILED: 'VALIDATE_SIM_DATA_FAILED',
  GET_SIM_PURCHASE: 'GET_SIM_PURCHASE',
  GET_SIM_PURCHASE_SUCCESS: 'GET_SIM_PURCHASE_SUCCESS',
  GET_SIM_PURCHASE_FAILED: 'GET_SIM_PURCHASE_FAILED',
  GET_SIM_PURCHASE_DETAILS: 'GET_SIM_PURCHASE_DETAILS',
  GET_SIM_PURCHASE_DETAILS_SUCCESS: 'GET_SIM_PURCHASE_DETAILS_SUCCESS',
  GET_SIM_PURCHASE_DETAILS_FAILED: 'GET_SIM_PURCHASE_DETAILS_FAILED',
  
  GET_SIM_TYPE:'GET_SIM_TYPE',
  GET_SIM_TYPE_SUCCESS:'GET_SIM_TYPE_SUCCESS',
  GET_SIM_TYPE_FAILED:'GET_SIM_TYPE_FAILED',

  getSimTypeData: (data) => ({
    type: actions.GET_SIM_TYPE,
    payload:data
}),
getSimTypeDataSuccess: (simTypeData) => ({
    type: actions.GET_SIM_TYPE_SUCCESS,
    simTypeData
}),
getSimTypeDataFailed: (error) => ({
    type: actions.GET_SIM_TYPE_FAILED,
    error
}),


  getSimPurchaseDetails: (purchaseId) => ({
    type: actions.GET_SIM_PURCHASE_DETAILS,
    payload: { purchaseId },
  }),
  getSimPurchaseDetailsSuccess: (message, data) => ({
    type: actions.GET_SIM_PURCHASE_DETAILS_SUCCESS,
    payload: { message, data },
  }),
  getSimPurchaseDetailsFailed: (error) => ({
    type: actions.GET_SIM_PURCHASE_DETAILS_FAILED,
    payload: { error },
  }),

  getSimPurchase: () => ({
    type: actions.GET_SIM_PURCHASE,
  }),
  getSimPurchaseSuccess: (message, data) => ({
    type: actions.GET_SIM_PURCHASE_SUCCESS,
    payload: { message, data },
  }),
  getSimPurchaseFailed: (error) => ({
    type: actions.GET_SIM_PURCHASE_FAILED,
    payload: { error },
  }),

  validateSimData: (data) => ({
    type: actions.VALIDATE_SIM_DATA,
    payload: { data }
  }),
  validateSimDataSuccess: (message, data) => ({
    type: actions.VALIDATE_SIM_DATA_SUCCESS,
    payload: { message, data },
  }),
  validateSimDataFailed: (error, data) => ({
    type: actions.VALIDATE_SIM_DATA_FAILED,
    payload: { error, data },
  }),

  savePurchaseSim: (data) => ({
    type: actions.SAVE_PURCHASE_SIM,
    payload: { data }
  }),
  savePurchaseSimSuccess: (message, data) => ({
    type: actions.SAVE_PURCHASE_SIM_SUCCESS,
    payload: { message, data },
  }),
  savePurchaseSimFailed: (error) => ({
    type: actions.SAVE_PURCHASE_SIM_FAILED,
    payload: { error },
  }),

  getInvoice: () => ({
    type: actions.GET_INVOICE,
  }),
  getInvoiceSuccess: (message, data) => ({
    type: actions.GET_INVOICE_SUCCESS,
    payload: { message, data },
  }),
  getInvoiceFailed: (error) => ({
    type: actions.GET_INVOICE_FAILED,
    payload: { error },
  }),

  getPhysicalSimAggregator: () => ({
    type: actions.GET_PHYSICAL_SIM_AGGREGATOR,
  }),
  getPhysicalSimAggregatorSuccess: (message, data) => ({
    type: actions.GET_PHYSICAL_SIM_AGGREGATOR_SUCCESS,
    payload: { message, data },
  }),
  getPhysicalSimAggregatorFailed: (error) => ({
    type: actions.GET_PHYSICAL_SIM_AGGREGATOR_FAILED,
    payload: { error },
  }),

  resetSimPurchase: () => ({
    type: actions.RESET_SIM_PURCHASE,
  }),

};

export default actions;
