import React, { useEffect, useState } from 'react'
import Styles from './LandingPage.module.css';
import EsimTravLogo from '../assets/images/e-sim-trav-logo.png';
import EsimTravTav from '../assets/images/trav.png';
import EsimTravPurchase from '../assets/images/purchase.png';
import EsimTravScan from '../assets/images/scan.png';
import EsimTravTravel from '../assets/images/travel-w.png'
import { Link } from 'react-router-dom';
const LandingPage = () => {
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    let token = localStorage.getItem("token") ? localStorage.getItem("token") : null;
    if (token != null && token != '') {
      setIsLogin(true);
    }
  }, []);

  return (

    <>
      <div className={`${Styles.nk_app_root}`}>
        <header className={`${Styles.nk_header} ${Styles.has_shape}`}>
          <div className={`${Styles.nk_shape} ${Styles.bg_shape_blur_c} ${Styles.ms_n20p} ${Styles.mt_n20p} ${Styles.start_50} ${Styles.translate_middle_x}`}></div>
          <div className={`${Styles.nk_header_main} ${Styles.nk_menu_main} ${Styles.will_shrink} ${Styles.has_border} ${Styles.ignore_mask}`}>
            <div className={`${Styles.container}`}>
              <div className={`${Styles.nk_header_wrap}`}>
                <div className={`${Styles.nk_header_logo}`}>
                  <div className={`${Styles.logo_wrap}`}>
                    <a href="" className={`${Styles.logo_link}`}>
                      <img
                        className={`${Styles.logo_img} ${Styles.logo_dark}`}
                        src={EsimTravLogo}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <nav className={`${Styles.nk_menu} ${Styles.justify_content_md_end}`}>

                  {
                    !isLogin ? <ul className={`${Styles.nk_menu_buttons}`}>
                       <li>
                      <Link to="/sign-up" className={`${Styles.btn} ${Styles.btn_secondary}`}>
                        Signup
                      </Link>
                    </li>
                    <li>
                      <Link to="/sign-in" className={`${Styles.btn} ${Styles.btn_primary}`}>
                        Signin
                      </Link>
                    </li>
                   
                  </ul> : 
                  <ul className={`${Styles.nk_menu_buttons} ${Styles.flex_lg_row_reverse}`}>
                    <li>
                      <Link to="/dashboard" className={`${Styles.btn} ${Styles.btn_primary}`}>
                        Go To Dashboard
                      </Link>
                    </li>
                  </ul>

                  }

                  
                </nav>
              </div>
            </div>
          </div>
          <div className={`${Styles.nk_hero} ${Styles.py_7}`}>
            <div className={`${Styles.container}`}>
              <div className={`${Styles.row} ${Styles.align_items_center} ${Styles.justify_content_center} ${Styles.justify_content_lg_between} ${Styles.flex_lg_row_reverse} ${Styles.g_gs}`}>
                <div className={`${Styles.col_sm_10} ${Styles.col_md_7} ${Styles.col_lg_6}`}>
                  <div className={`${Styles.nk_hero_gfx} ${Styles.px_2} ${Styles.px_sm_0} ${Styles.ps_lg_5} ${Styles.ps_xxl_0}`}>
                    <img className={`${Styles.w_100}`} src={EsimTravTav} alt="" />
                  </div>
                </div>
                <div className={`${Styles.col_md_11} ${Styles.col_lg_6} ${Styles.col_xxl_6}`}>
                  <div className={`${Styles.nk_hero_content} ${Styles.text_center} ${Styles.text_lg_start}`}>
                    <h1 className={`${Styles.display_6} ${Styles.mb_3}`}>
                      Travel eSIMs <span className={`${Styles.text_gradient_primary}`}> for resellers.</span>
                    </h1>
                    <p className={`${Styles.lead} ${Styles.mb_4} ${Styles.mb_lg_5} ${Styles.pe_xxl_6}`}>
                      Follow the latest trends, sales and styles.
                    </p>
                    <ul className={`${Styles.btn_list} ${Styles.btn_list_inline} ${Styles.gx_4} ${Styles.gy_4}`}>
                      <li>
                        <Link to="/sign-up" className={`${Styles.btn} ${Styles.btn_lg} ${Styles.rounded_pill} ${Styles.btn_secondary}`}>
                          <span>Signup with email</span>
                          <em className={`${Styles.icon} ${Styles.right_arrow}`} />
                        </Link>
                      </li>
                    </ul>
                    <p className={`${Styles.mt_4}`}>
                      Join the A-List and <span className={`${Styles.text_primary}`}>*save 15%</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </header>
        <main class={`${Styles.nk_pages}`}>
          <section className={` ${Styles.section} ${Styles.section_sm} ${Styles.section_bottom_0} ${Styles.has_shape}`}>
            <div className={`${Styles.nk_shape} ${Styles.bg_shape_blur_g} ${Styles.mt_n20p} ${Styles.start_50}`}></div>
            <div className={`${Styles.nk_shape} ${Styles.bg_shape_blur_h} ${Styles.translate_middle_y} ${Styles.top_50} ${Styles.end_50}`}></div>
            <div className={`${Styles.nk_shape} ${Styles.bg_shape_blur_i} ${Styles.mb_n30p} ${Styles.bottom_0} ${Styles.start_50}`}></div>
            <div className={`${Styles.container}`}>
              <div className={`${Styles.section_head}`}>
                <div className={`${Styles.row} ${Styles.justify_content_center} ${Styles.text_center}`}>
                  <div className={`${Styles.col_xl_11} ${Styles.col_xxl_10}`}>
                    <h2 className={`${Styles.title} ${Styles.h1}`}>How it works</h2>
                  </div>
                </div>
              </div>
              <div className={`${Styles.section_content}`}>
                <div className={`${Styles.row} ${Styles.g_gs} ${Styles.justify_content_between} ${Styles.justify_content_xl_center} ${Styles.flex_md_row_reverse} ${Styles.align_items_center}`}>
                  <div className={`${Styles.col_lg_5} ${Styles.col_md_6} ${Styles.offset_xl_1}`}>
                    <div className={`${Styles.block_gfx}${Styles.position_relative}`}>
                      <img
                        className={`${Styles.w_100} ${Styles.rounded_3}`}
                        src={EsimTravPurchase}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className={`${Styles.col_xl_4} ${Styles.col_md_6}`}>
                    <div className={`${Styles.block_text} ${Styles.rounded_3} ${Styles.p_4} ${Styles.shadow_sm}`}>
                      <h6 className={`${Styles.text_primary} ${Styles.h6}`}>Step 01</h6>
                      <h3 className={`${Styles.h3}`}>Purchase a plan</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${Styles.section_content}`}>
                <div className={`${Styles.row} ${Styles.g_gs} ${Styles.justify_content_between} ${Styles.justify_content_xl_center}  ${Styles.align_items_center}`}>
                  <div className={`${Styles.col_lg_5} ${Styles.col_md_6}`}>
                    <div className={`${Styles.block_gfx}`}>
                      <img
                        className={`${Styles.w_100} ${Styles.rounded_3}`}
                        src={EsimTravScan}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className={`${Styles.col_xl_4} ${Styles.col_md_6} ${Styles.offset_xl_1}`}>
                    <div className={`${Styles.block_text} ${Styles.rounded_3} ${Styles.p_4} ${Styles.shadow_sm}`}>
                      <h6 className={`${Styles.text_primary} ${Styles.h6}`}>Step 02</h6>
                      <h3 className={`${Styles.h3}`}>Scan QR and install eSim</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${Styles.section_content}`}>
                <div className={`${Styles.row} ${Styles.g_gs} ${Styles.justify_content_between} ${Styles.justify_content_xl_center} ${Styles.flex_md_row_reverse} ${Styles.align_items_center}`}>
                  <div className={`${Styles.col_lg_5} ${Styles.col_md_6} ${Styles.offset_xl_1} ${Styles.text_center}`}>
                    <div className={`${Styles.block_gfx}`}>
                      <img
                        className={`${Styles.img_fluid}`}
                        src={EsimTravTravel}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className={`${Styles.col_xl_4} ${Styles.col_md_6}`}>
                    <div className={`${Styles.block_text} ${Styles.rounded_3} ${Styles.p_4} ${Styles.shadow_sm}`}>
                      <h6 className={`${Styles.text_primary} ${Styles.h6}`}>Step 03</h6>
                      <h3 className={`${Styles.h3}`}>Travel and roam!</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <footer className={`${Styles.nk_footer}`}>
          <hr className={`${Styles.border_opacity_25} ${Styles.border_primary} ${Styles.m_0}`} />
          <div className={`${Styles.section} ${Styles.section_sm}  ${Styles.bg_ee}`}>
            <div className={`${Styles.container}`}>
              <div className={`${Styles.row}`}>
                <div className={`${Styles.col_xl_12} ${Styles.col_lg_12} ${Styles.col_md_9} ${Styles.me_auto} ${Styles.text_center}`}>
                  <div className={`${Styles.block_text} ${Styles.pe_xxl_5}`}>
                    <a href="" className={`${Styles.logo_link} ${Styles.mb_4}`}>
                      <div className={`${Styles.logo_wrap}`}>
                        <img
                          className={`${Styles.logo_img} ${Styles.logo_dark}`}
                          src={EsimTravLogo}
                          alt=""
                        />
                      </div>
                    </a>
                    <p>
                      Join us on our journey to make the world more accessible.
                      <br />
                      With esimtrav, your travel stories will always be just a click away.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${Styles.section} ${Styles.section_0} ${Styles.bg_ee}`}>
            <hr className={`${Styles.border_opacity_25} ${Styles.border_primary} ${Styles.m_0}`} />
            <div className={`${Styles.container}`}>
              <div className={`${Styles.py_1}`}>
                <div className={`${Styles.row}`}>
                  <div className={`${Styles.col_md} ${Styles.m_text_center}`}>
                    <p className={`${Styles.mb_2} ${Styles.mb_md_0} ${Styles.fs_14}`}>
                      Copyright © 2023. e_sim Trav
                      <a href="#" className={`${Styles.fw_bold} ${Styles.text_base}`}> E_SIM
                      </a>
                      .
                    </p>
                  </div>
                  <div className={`${Styles.col_md}`}>
                    <ul className={`${Styles.list} ${Styles.list_row} ${Styles.gx_4} ${Styles.justify_content_start} ${Styles.justify_content_md_end} ${Styles.p_0}`}>
                      <li>
                        <a href="#" className={`${Styles.link_primary}`}>
                          Terms
                        </a>
                      </li>
                      <li>
                        <a href="#" className={`${Styles.link_primary}`}>
                          Privacy Policy
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>

      </div>
    </>

  )
}

export default LandingPage