import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../assets/images/logo-full2.png'

function HeaderLogo() {
    return (
        <>

            <div className="iq-sidebar-logo">
                <div className="iq-menu-bt align-self-center">
                    <div className="wrapper-menu">
                        <div className="main-circle"><i class="ri-menu-line"></i></div>
                        <div className="hover-circle"><i class="ri-menu-line"></i></div>
                    </div>
                </div>
                <div className="top-logo">

                <Link to="/dashboard">
                        <img src={Logo} className="img-fluid" alt="" />
              </Link>
                </div>
            </div>
        </>
    )
}

export default HeaderLogo