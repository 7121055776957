import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import Calendar2LineIcon from 'remixicon-react/Calendar2LineIcon';
import EyeFill from 'remixicon-react/EyeFillIcon';
import RestartLineIcon from 'remixicon-react/RestartLineIcon';
import moment from "moment";
import momentTimezone from 'moment-timezone';
import { useState, useEffect, useRef } from 'react'
import DatePicker from "react-datepicker";
import ReactCountryFlag from 'react-country-flag';
import DownloadCloud2sLineIcon from 'remixicon-react/DownloadCloud2LineIcon';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import CardLayout from '../../../components/utils/CardLayout'
import SearchBar from '../../../components/utils/SearchBar';
import Table from '../../../components/utils/Table';
import Button from '../../../components/utils/Button'
import "react-datepicker/dist/react-datepicker.css";
import Styles from './orderReportstyle.module.css'
import countryAction from '../../../redux/country/actions'
import dealerAction from '../../../redux/dealer/actions'
import planAction from '../../../redux/plan/actions'
import OrderModalComponent from '../../../components/utils/OrderModalComponent';
import orderReportActions from '../../../redux/orderReport/actions';
import Tag from '../../../components/utils/Tag';
import Modal from '../../../components/utils/Model';
import Alert from '../../../components/utils/Alert';

const dateFormat = process.env.REACT_APP_DATE_FORMAT;
const TIMEZONE = process.env.REACT_APP_TIMEZONE;

const OrderReport = () => {
    const { getOrderReportList, openResendMailModal, closeResendMailModal, resendMail } = orderReportActions;
    const { register, handleSubmit, formState: { errors }, watch, reset } = useForm();
    const [emailResendDefaultValue, setEmailResendDefaultValue] = useState({
        customerEmail: ''
    })
    const [emailResendOrderNumber, setEmailResendOrderNumber] = useState('')
    const {
        register: registerEmailResend,
        handleSubmit: handleSubmitEmailResend,
        formState: { errors: errorsEmailResend },
        watch: watchEmailResend,
        reset: resetEmailResend
    } = useForm({
        defaultValues: emailResendDefaultValue
    });
    const [showViewModal, setShowViewModal] = useState(false)
    const [filterText, setFilterText] = useState("");
    const [toDate, setToDate] = useState(moment().startOf('day').toDate())
    const [fromDate, setFromDate] = useState(moment().subtract(10, 'days').toDate());
    const { orderReportListResult, loading, orderResendMailModal, resendMailError, resendMailMessage, resendMailLoading } = useSelector((state) => state.orderReport);
    const countryResult = useSelector((state) => state.country.countryResult);
    const dealerResult = useSelector((state) => state.dealer.dealerResult);
    const planResult = useSelector((state) => state.plan.planResult);
    const { getCountry } = countryAction;
    const { getDealer } = dealerAction;
    const { getPlan } = planAction;
    const dispatch = useDispatch()
    const datePickerRef = useRef(null);
    const datePickerRef2 = useRef(null);
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const [orderViewModalData, setOrderViewModalData] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [alertType, setAlertType] = useState('');

    useEffect(() => {

        if (resendMailError && resendMailError != null) {
            setAlertText(resendMailError);
            setAlertType("alert-danger");
            setShowAlert(true);
        }
        if (resendMailMessage && resendMailMessage != null) {
            setAlertText(resendMailMessage);
            setAlertType("alert-success");
            setShowAlert(true);
        }

    }, [resendMailError, resendMailMessage]);

    const openDatePicker = () => {
        datePickerRef.current.setOpen(true);
    };

    const openDatePicker2 = () => {
        datePickerRef2.current.setOpen(true);
    };

    const predefinedPeriods = {
        today: moment().startOf('day').toDate(),
        yesterday: moment().subtract(1, 'days').startOf('day').toDate(),
        '15days': moment().subtract(15, 'days').startOf('day').toDate(),
        month: moment().subtract(30, 'days').startOf('day').toDate(),
        quarter: moment().subtract(3, 'months').startOf('day').toDate(),
        year: moment().subtract(1, 'year').startOf('day').toDate(),
    };

    useEffect(() => {
        const newData = {
            toDate: moment(toDate).format("YYYY-MM-DD"),
            fromDate: moment(fromDate).format("YYYY-MM-DD"),
            dealerId: "",
            planId: "",
            availableForFlag: "18",
            availableForID: ""
        };
        dispatch(getCountry());
        dispatch(getDealer());
        dispatch(getPlan());
        dispatch(getOrderReportList(newData));
    }, [])

    const onSubmit = (data) => {
        const newData = {
            ...data,
            availableForFlag: "18",
            // toDate: moment(toDate).format("YYYY-MM-DD"),
            // fromDate: moment(fromDate).format("YYYY-MM-DD"),
            toDate: moment(new Date(toDate)).tz(TIMEZONE).format("YYYY-MM-DD"),
            fromDate: moment(new Date(fromDate)).tz(TIMEZONE).format("YYYY-MM-DD"),
        };
        dispatch(getOrderReportList(newData))
    };

    const changeDateHandler = (item) => {
        setFromDate(predefinedPeriods[item]);
        const formattedDate = moment(predefinedPeriods[item]).format("YYYY-MM-DD");
        const currentDate = moment().format("YYYY-MM-DD");
        const apiData = {
            fromDate: formattedDate,
            toDate: currentDate,
            dealerId: "",
            planId: "",
            availableForFlag: "18",
            availableForID: ""
        }
        dispatch(getOrderReportList(apiData))
    }

    const handleCancel = () => {
        setShowViewModal(false)
    }

    const showOrderReportDataModal = (id) => {
        let selectedOrderReport = orderReportListResult.filter((item) => item.ID === id);
        setOrderViewModalData(selectedOrderReport)
        setShowViewModal(true);
    }

    const columns = [
        { displayName: '#', key: 'key' },
        { displayName: 'ORDER NUMBER', key: 'orderNumber' },
        { displayName: 'ORDER STATUS', key: 'status' },
        { displayName: 'ORDER DATE	', key: 'orderDate' },
        { displayName: 'NET AMOUNT	', key: 'netAmount' },
        { displayName: 'COUPON DISCOUNT	', key: 'couponDiscount' },
        { displayName: 'GRAND TOTAL	', key: 'grandTotal' },
        { displayName: 'USERNAME', key: 'username' },
        { displayName: 'DEALER NAME	', key: 'dealerName' },
        { displayName: 'ACTIONS', key: 'action' },
    ];

    const filteredItems = orderReportListResult && orderReportListResult.length > 0 && orderReportListResult.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const data = filteredItems && filteredItems.length > 0 && filteredItems.map((orderItem, key) => {
        return {
            key: key + 1,
            orderNumber: orderItem.OrderNumber,
            status: <Tag color={`${orderItem.OrderStatusID === 21 ? "badge-secondary" : orderItem.OrderStatusID === 22 ? 'badge-warning' : orderItem.OrderStatusID === 23 ? 'badge-success' : "badge-danger"}`} title={orderItem.OrderStatus} />,
            orderDate: moment(new Date(orderItem.OrderDate)).format(dateFormat),
            netAmount: `£${orderItem.NetAmount ? orderItem.NetAmount.toFixed(2) : '0.00'}`,
            couponDiscount: `£${orderItem.CouponDiscount ? orderItem.CouponDiscount.toFixed(2) : '0.00'}`,
            grandTotal: `£${orderItem.GrandTotal ? orderItem.GrandTotal.toFixed(2) : '0.00'}`,
            username: orderItem.Username,
            dealerName: orderItem.DealerName,
            action: <>
                <span data-toggle="tooltip" data-placement="top" title="View"><EyeFill size="1.2em" style={{ color: "#0094b3", cursor: 'pointer' }} onClick={() => showOrderReportDataModal(orderItem.ID)} /></span>
                <span data-toggle="tooltip" data-placement="top" title="Resend mail"><RestartLineIcon size="1.2em" style={{ color: "#2ecc71", cursor: 'pointer', marginLeft: '8px' }} onClick={() => handleResendMailModal(orderItem.CustomerEmail, orderItem.OrderNumber)} /></span>
            </>
        }
    });

    const handleFilterChange = (value) => {
        setFilterText(value);
    };

    const selectPeriodList = [
        { value: 'today', title: 'Today' },
        { value: 'month', title: 'Last 30 Days' },
        { value: 'quarter', title: 'Quarter' },
        { value: 'year', title: 'One Year' }
    ]
    const exportToCSV = async () => {
        let count = 0;
        if (data && data.length > 0) {
            let xlsxData = orderReportListResult && orderReportListResult.length > 0 && orderReportListResult.map((item, ind) => {
                count = count + 1;
                let resultData = {
                    SrNo: count,
                    OrderNumber: item.OrderNumber,
                    OrderStatus: item.OrderStatus,
                    OrderDate:moment(item.OrderDate).format(dateFormat),
                    NetAmount: `£${item.NetAmount ? item.NetAmount.toFixed(2) : '0.00'}`,
                    CouponDiscount:`£${item.CouponDiscount ? item.CouponDiscount.toFixed(2) : '0.00'}`,
                    GrandTotal:`£${item.GrandTotal ? item.GrandTotal.toFixed(2) : '0.00'}`,
                    Username:item.Username,
                    DealerName: item.DealerName,
                    Note:item.Note,
                }
                return resultData

            })

            const ws = XLSX.utils.json_to_sheet(xlsxData);
            const wb = { Sheets: { 'orderReportListResult': ws }, SheetNames: ['orderReportListResult'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            await FileSaver.saveAs(data, 'Order_report' + fileExtension);
        }
    }

    // for resend mail
    const handleResendMailModal = (customerEmail, ordNum) => {
        setEmailResendOrderNumber(ordNum);
        resetEmailResend({
            customerEmail
        });
        dispatch(openResendMailModal());
    }

    const handleCloseModal = () => {
        dispatch(closeResendMailModal());
    }

    const handleResendMail = (data) => {
        data.orderNumber = emailResendOrderNumber;
        dispatch(resendMail(data));
    }

    return (
        <>
            <CardLayout title="Order Report">
                {showAlert ? <Alert type={alertType} text={alertText} closeBtn={() => setShowAlert(false)} /> : ''}

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-row">
                        <div className="col-md-3 col-lg-2 mb-2 d-flex flex-column">
                            <label>From Date</label>
                            <Calendar2LineIcon onClick={openDatePicker} size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', 'top': '36px', right: '12px', zIndex: 1 }} />
                            <DatePicker
                                ref={datePickerRef}
                                selected={fromDate}
                                className="form-control"
                                onChange={(date) => setFromDate(date)}
                                dateFormat="yyyy-MM-dd"
                                name="fromDate"
                                showMonthDropdown
                                showYearDropdown
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                                value={fromDate}
                            />
                        </div>
                        <div className="col-md-3 col-lg-2 mb-2 d-flex flex-column">
                            <label for="date">To Date</label>
                            <Calendar2LineIcon onClick={openDatePicker2} for='date' size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', 'top': '36px', right: '12px', zIndex: 1 }} />
                            <DatePicker
                                ref={datePickerRef2}
                                id='date'
                                selected={toDate}
                                className="form-control"
                                onChange={(date) => setToDate(date)}
                                dateFormat="yyyy-MM-dd"
                                name="toDate"
                                showMonthDropdown
                                showYearDropdown
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                                value={toDate}
                            />
                        </div>
                        <div className="col-md-3 col-lg-2 mb-2">
                            <label>Plan</label>
                            <select className="form-control" placeholder="Plan" name="plan"  {...register("plan", {
                            })} >
                                <option value="" key="0">Select</option>
                                {planResult.length > 0 && planResult.map(plan => (
                                    <option name="planID" value={plan.ID} key={plan.ID}>
                                        &nbsp;&nbsp; {plan.PlanName}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-3 col-lg-2 mb-2">
                            <label>Dealer</label>
                            <select className="form-control" placeholder="Dealer" name="dealer"  {...register("dealer", {
                            })} >
                                <option value="" key="0">Select</option>
                                {dealerResult.length > 0 && dealerResult.map(dealer => (
                                    <option name="dealerID" value={dealer.ID} key={dealer.ID}>
                                        &nbsp;&nbsp; {dealer.DealerName}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-3 col-lg-2  mb-2">
                            <label>Country</label>
                            <select className="form-control" placeholder="Select Country" name="availableForID"  {...register("availableForID", {
                            })} >
                                <option value="" key="0">Select</option>
                                {countryResult.length > 0 && countryResult.map(country => (
                                    <option name="availableForID" value={country.ID} key={country.ID}>
                                        <ReactCountryFlag countryCode="US" svg style={{ width: '2em', height: '2em' }} title="US" />
                                        &nbsp;&nbsp; {country.CountryName}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-2 mb-2  mt-lg-4 mt-md-4">

                            <Button title="Search"
                                buttonClass={`btn btn-primary btn-md Activity_searchBtn__GlBtC mt-1`} buttonType='submit' />

                        </div>
                    </div>
                    <div className="form-row">
                    </div>
                </form>
                <div className="col-md-6  p-0">
                    <div className="form-row ">
                        {selectPeriodList.map((item, index) =>
                            <ul className='p-1'>
                                <li className={Styles.selectUl} key={index} onClick={() => changeDateHandler(item.value, index)}
                                >{item.title}</li>
                            </ul>
                        )}
                    </div>
                </div>
                <hr />
                <div className='d-flex justify-content-between audit-report'>
                    <SearchBar onFilter={handleFilterChange} filterText={filterText} />
                    <Button icon={<DownloadCloud2sLineIcon color='#fff' className='mr-1' size={'0.9rem'} />} title="Export" buttonClass={`btn ${Styles.exportBtn}`} style={{ marginRight: "10px" }} buttonStyle="primary" onClick={exportToCSV} />
                </div>
                <Table columns={columns} loading={loading} data={data} pagination={true} />

                <OrderModalComponent
                    order={orderViewModalData}
                    handleCancel={handleCancel}
                    showViewModal={showViewModal}
                />


                <Modal
                    showModal={orderResendMailModal}
                    title={'Resend Mail #: ' + emailResendOrderNumber}
                    onHide={() => handleCloseModal()}
                    width={600}
                    showFooter={false}
                >
                    <form onSubmit={handleSubmitEmailResend(handleResendMail)}>
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <label>Customer E-Mail</label>
                                <input type="text" placeholder="Enter Customer E-Mail" className="form-control" name="bannerTitle" {...registerEmailResend("customerEmail", {
                                    required: true,
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Invalid E-Mail Address"
                                    }
                                })} />
                                {errorsEmailResend.customerEmail?.type === "required" && (<div className="invalid-feedback">Customer E-Mail is required !</div>)}
                                {errorsEmailResend.customerEmail?.type === "pattern" && (<div className="invalid-feedback">{errorsEmailResend.customerEmail.message}</div>)}
                            </div>
                        </div>

                        <div className='text-right row'>
                            <div className="col-md-12">
                                <button type="button" className="btn btn-secondary mr-2" onClick={() => handleCloseModal()}>Close</button>
                                <Button title={'Resend'} buttonClass="btn btn-primary" loading={resendMailLoading} buttonType='submit' />
                            </div>
                        </div>
                    </form>
                </Modal>

            </CardLayout>
        </>
    )
}

export default OrderReport