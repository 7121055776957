import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import dealerAction from '../../../redux/dealer/actions'
import manualAddFundAction from '../../../redux/manualAddFund/actions'
import { useDispatch, useSelector } from 'react-redux';
import Alert from '../../../components/utils/Alert';
import authAction from '../../../redux/auth/actions';
import Button from '../../../components/utils/Button';
function ManualAddFund() {
    const { getDealer } = dealerAction;
    const { getUserDataByToken } = authAction;
    const { manualAddFund, resetManualAddFund } = manualAddFundAction;
    const dispatch = useDispatch()
    const [addFormData, setAddFormData] = useState({
        dealerID: '',
        amount: '',
        remarks: ""
    });
    const { register, handleSubmit, watch, formState: { errors }, reset, clearErrors } = useForm({
        defaultValues: addFormData,
    });
    const dealerResult = useSelector((state) => state.dealer.dealerResult);
    const successMessage = useSelector((state) => state.manualAddFund.manualAddFundMessage);
    const errorMessage = useSelector((state) => state.manualAddFund.manualAddFundError);
    const updateTopUpFlag = useSelector((state) => state.manualAddFund.updateManualTopUpFlag)
    const buttonLoading = useSelector((state) => state.manualAddFund.buttonLoading);
    const dealerIDField = register("dealerID", { required: true });
    const amountField = register("amount", { required: true, min: 0.01, max: 9999 });
    const [dealerAmountToShow, setDealerAmountToShow] = useState(`0.00`)
    const [dealerName, setDealerName] = useState()
    const [dealerId, setDealerId] = useState()
    const [firstChar, setFirstChar] = useState();
    const [errorMessageForm, setErrorMessageForm] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');

    useEffect(() => {
        dispatch(getDealer());
    }, [successMessage])

    useEffect(() => {
        dispatch(getUserDataByToken());
        dispatch(getDealer());
        setDealerAmountToShow(`0.00`);
        dispatch(getDealer());
    }, [updateTopUpFlag])


    useEffect(() => {
        reset(addFormData);
    }, [addFormData]);

    useEffect(() => {
        if (errorMessage !== null && errorMessage !== undefined) {
            displayAlert(errorMessage, 'alert-danger');

        }
        if (successMessage !== null && successMessage !== undefined) {
            displayAlert(successMessage, 'alert-success');
            setAddFormData(prev => ({
                ...prev,
                dealerID: '',
                amount: '',
                remarks: ''
            }))
            setDealerAmountToShow("0.00")
        }
    }, [errorMessage, successMessage]);

    const handleChange = (e) => {
        clearErrors(['dealerID'])
        const dealerId = e.target.value;
        setDealerId(dealerId)
        if (dealerId) {
            var selectedDealer = dealerResult && dealerResult.filter(dealer => dealer.ID == dealerId);
            setDealerAmountToShow(selectedDealer && selectedDealer[0].AccountBalance)
            setDealerName(selectedDealer && selectedDealer[0].DealerName)
            const [firstName] = selectedDealer && selectedDealer[0].DealerName.split(' ');
            if (firstName) {
                const initials = firstName.charAt(0);
                setFirstChar(initials);
            }
        }
    };

    const handleNumber = (e) => {
        const { value } = e.target;
        if (!((e.shiftKey || (e.keyCode >= 48 && e.keyCode <= 57)) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 8 || e.keyCode === 190 || e.keyCode === 110)) {
            e.preventDefault();
        }
        if ((e.keyCode === 190 || e.keyCode === 110) && value.includes('.')) {
            e.preventDefault();
        }
        if (value.includes('.')) {
            const parts = value.split('.');
            if (parts[1].length >= 2 && e.keyCode !== 8) {
                e.preventDefault();
            }
        }
    };

    const handleAmountChange = (e) => {
        setErrorMessageForm("")
        clearErrors(['amount'])
    }

    // const handleSelectFundType = (fundTypeValue) => {
    //     const data = watch();
    //     if (fundTypeValue === 1 && data.amount > 0 && data.amount <= 9999 && data.remarks !== '') {
    //         const submitDealerFund = {
    //             dealerId: dealerId,
    //             amount: data.amount,
    //             fundType: 1,
    //             remarks: data.remarks
    //         };
    //         // console.log("add", submitDealerFund);
    //         dispatch(manualAddFund(submitDealerFund));
    //         setDealerName("");
    //         setFirstChar("");
    //     } else if (fundTypeValue === 2 && data.amount <= 9999 && data.remarks !== '') {
    //         if (fundTypeValue === 2 && Number(data.amount) <= Number(dealerAmountToShow)) {
    //             const submitDealerFund = {
    //                 dealerId: dealerId,
    //                 amount: data.amount,
    //                 fundType: 2,
    //                 remarks: data.remarks
    //             };
    //             // console.log("dedyc", submitDealerFund);
    //             dispatch(manualAddFund(submitDealerFund));
    //             setDealerName("");
    //             setFirstChar("");
    //         } else {
    //             setErrorMessageForm("Amount not deducted! Must be less than or equal to dealer's specified amount.")
    //         }
    //     }
    // };

    const handleSelectFundType = (fundTypeValue) => {
        const data = watch();
        if (data.amount > 0 && data.amount <= 9999 && data.remarks !== '') {
            if (fundTypeValue === 1) {
                const submitDealerFund = {
                    dealerId: dealerId,
                    amount: data.amount,
                    fundType: 1,
                    remarks: data.remarks
                };
                dispatch(manualAddFund(submitDealerFund));
                setDealerName("");
                setFirstChar("");
            } else if (fundTypeValue === 2 && Number(data.amount) <= Number(dealerAmountToShow)) {
                const submitDealerFund = {
                    dealerId: dealerId,
                    amount: data.amount,
                    fundType: 2,
                    remarks: data.remarks
                };
                dispatch(manualAddFund(submitDealerFund));
                setDealerName("");
                setFirstChar("");
            } else {
                setErrorMessageForm("Amount not deducted! Must be less than or equal to dealer's specified amount.");
            }
        }
    };

    const displayAlert = (text, type) => {
        setAlertText(text);
        setAlertType(type);
        setShowAlert(true);
        dispatch(resetManualAddFund());
    };

    const alertCancel = () => {
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
        dispatch(resetManualAddFund());
    }

    return (
        <div id="content-page" className="content-page">
            <div className="container-fluid">
                <div className="row pb-4">
                    <div className="col-sm-12">
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-md-10 bg-fade">
                                {/* {showAlert && <Alert type={alertType} text={alertText} />} */}
                                <form onSubmit={handleSubmit(handleSelectFundType)}>
                                    <div className="add-fund-card">
                                        <div className="row ">
                                            <div className="col-lg-12 col-md-12">
                                                <div className="card-head-rebbon" data-label="Current Balance">

                                                </div>
                                            </div>
                                        </div>

                                        <div className="row py-2 myt-margin">
                                            <div className="col-lg-12 col-md-12 select-bar-manul-add-fund">
                                                <div id="select-box">
                                                    <select className="form-control" name="dealerID" {...dealerIDField} onChange={handleChange} >
                                                        <option className='optt' value="" key="0">Select dealer</option>
                                                        {dealerResult.length > 0 && dealerResult.map(dealer => (
                                                            dealer.ParentID !== 0 && <option name="dealerID" value={dealer.ID} key={dealer.ID}>{dealer.DealerName}</option>
                                                        ))}
                                                    </select>
                                                    {errors.dealerID?.type === "required" && (
                                                        <div className="invalid-feedback">Please Select dealer !</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {showAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}

                                        <div className="row ppt-tp">
                                            <div className="col">
                                                <div className='cur-cls'>
                                                    <h3>Current</h3>
                                                </div>
                                                <div className="ticket-card"> <span className="dolor-ico">£</span>
                                                    <span className="dolor-nun">{dealerAmountToShow}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row pt-3 pb-2">
                                            <div className="col cirle-name-div">
                                                <div className="name-cirle">
                                                    <p>{firstChar}</p>
                                                </div>

                                                <p className='mb-0'>{dealerName}</p>

                                            </div>
                                        </div>
                                        <div className="row pb-2">
                                            <div className="col-md-12">
                                                <form className='text-center'>
                                                    <label className="amout-label-name">Amount</label>
                                                    {/* <span contenteditable="true" className=" text-center">test</span> */}
                                                    <div className='d-flex justify-content-center align-items-center'>
                                                        <span className='dol-ico'>£</span>
                                                        {/* <DollarCurrencyInput></DollarCurrencyInput> */}

                                                        <input
                                                            className="txrt-form "
                                                            type="number"
                                                            name="amount"
                                                            placeholder="00.00"
                                                            {...amountField}
                                                            onKeyDown={handleNumber}
                                                            onChange={handleAmountChange}
                                                        /></div>
                                                    {errorMessage && <div className="invalid-feedback">{errorMessageForm}</div>}
                                                    {errorMessageForm && <div className="invalid-feedback">{errorMessageForm}</div>}

                                                    {errors.amount?.type === "required" && (
                                                        <div className="invalid-feedback">Amount is required !</div>
                                                    )}
                                                    {errors.amount?.type === "min" && (
                                                        <div className="invalid-feedback">Amount must be greater than zero!</div>
                                                    )}
                                                    {errors.amount?.type === "max" && (
                                                        <div className="invalid-feedback">Amount must be less than 9999!</div>
                                                    )}
                                                    {/* {errors.amount?.type > { dealerAmountToShow } && (
                                                        <div className="invalid-feedback">Amount must be less than or equal to current amount!</div>
                                                    )} */}
                                                </form>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-4">
                                            <input type="text" className="form-control" placeholder="Remark" name="remarks"
                                                {...register("remarks", {
                                                    required: true,
                                                })} />
                                            {errors.remarks?.type === "required" && (
                                                <div className="invalid-feedback">Remark is required !</div>

                                            )}
                                        </div>
                                        <div className="row justify-content-center pt-4 pb-1  btn-manul-add-fund-row">
                                            <div className="col-lg-6 col-md-12 button-fist-p font-weight-bold mb-3 mb-lg-0">
                                                {/* <button type="submit" loading={ buttonLoading} className="add-fund-btn-do" onClick={() => handleSelectFundType(2)}>
                                                    Deduct
                                                </button> */}
                                                <Button title={'Deduct'} onClick={() => handleSelectFundType(2)} buttonClass="add-fund-btn-do" loading={buttonLoading} buttonType='submit' />
                                            </div>
                                            <div className="col-lg-6 col-md-12 button-fist-p font-weight-bold mb-3 mb-lg-0">
                                                {/* 
                                                <button type="submit" loading={ buttonLoading} className="check-btn-dolor" onClick={() => handleSelectFundType(1)}>
                                                    Add Top Up
                                                </button> */}
                                                <Button title={'Add Top Up'} onClick={() => handleSelectFundType(1)} buttonClass="check-btn-dolor" loading={buttonLoading} buttonType='submit' />
                                            </div>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    )
}

export default ManualAddFund