import { all } from 'redux-saga/effects';
import dealerSagas from './dealer/saga'
import authSagas from './auth/saga';
import countrySagas from './country/saga';
import tariffGroupSagas from './tariffGroup/saga';
import userSagas from './user/saga';
import verifyDealerSagas from './verifyDealer/saga'
import bannerSagas from './banner/saga';
import profileSagas from './profile/saga';
import manualAddFundSagas from './manualAddFund/saga';
import planSagas from './plan/saga';
import auditReportSagas from './auditReport/saga';
import simPurchaseSagas from './simPurchase/saga';
import orderReportSagas from './orderReport/saga';
import listSimSagas from './listSim/saga';
import cartSagas from './cart/saga';
import orderRefundSagas from './orderRefund/saga';
import refundRequestSagas from './refundRequest/saga';
import addToFundSagas from './addToFund/saga';
import dashboardSagas from './dashboard/saga';
import aggregatorSagas from './aggregator/saga'
import activitySagas from './activity/saga';
import emailTemplateSagas from './emailTemplate/saga';
import couponSagas from './coupon/saga'

export default function* rootSaga() {
    yield all([
        dealerSagas(),
        authSagas(),
        countrySagas(),
        tariffGroupSagas(),
        userSagas(),
        verifyDealerSagas(),
        bannerSagas(),
        profileSagas(),
        manualAddFundSagas(),
        planSagas(),
        auditReportSagas(),
        simPurchaseSagas(),
        orderReportSagas(),
        listSimSagas(),
        cartSagas(),
        orderRefundSagas(),
        refundRequestSagas(),
        aggregatorSagas(),
        addToFundSagas(),
        dashboardSagas(),
        activitySagas(),
        emailTemplateSagas(),
        couponSagas(),
    ])
}