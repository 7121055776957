const actions = {
    GET_REFUND_REQUEST: "GET_REFUND_REQUEST",
    GET_REFUND_REQUEST_SUCCESS: "GET_REFUND_REQUEST_SUCCESS",
    GET_REFUND_REQUEST_FAILED: "GET_REFUND_REQUEST_FAILED",
    RESET_REFUND_REQUEST_MESSAGE: "RESET_REFUND_REQUEST_MESSAGE",
    TO_REFUND_APPROVED: "TO_REFUND_APPROVED",
    TO_REFUND_APPROVED_SUCCESS: "TO_REFUND_APPROVED_SUCCESS",
    TO_REFUND_APPROVED_FAILED: "TO_REFUND_APPROVED_FAILED",
    TO_REFUND_CANCELLED: "TO_REFUND_CANCELLED",
    TO_REFUND_CANCELLED_SUCCESS: "TO_REFUND_CANCELLED_SUCCESS",
    TO_REFUND_CANCELLED_FAILED: "TO_REFUND_CANCELLED_FAILED",
    OPEN_REFUND_REQUEST_MODAL: "OPEN_REFUND_REQUEST_MODAL",
    CLOSE_REFUND_REQUEST_MODAL: "CLOSE_REFUND_REQUEST_MODAL",

    closeRefundRequestModal: () => ({
        type: actions.CLOSE_REFUND_REQUEST_MODAL,
    }),
    openRefundRequestModal: () => ({
        type: actions.OPEN_REFUND_REQUEST_MODAL,
    }),

    getRefundRequest: (data) => ({
        type: actions.GET_REFUND_REQUEST,
        payload: { data }
    }),
    getRefundRequestSuccess: (refundRequestResult) => ({
        type: actions.GET_REFUND_REQUEST_SUCCESS,
        refundRequestResult
    }),
    getRefundRequestFailed: (error) => ({
        type: actions.GET_REFUND_REQUEST_FAILED,
        error
    }),

    resetRefundRequestMessage: () => ({
        type: actions.RESET_REFUND_REQUEST_MESSAGE
    }),

    toRefundApproved: (refundId) => ({
        type: actions.TO_REFUND_APPROVED,
        payload: { refundId }
    }),
    toRefundApprovedSuccess: (message) => ({
        type: actions.TO_REFUND_APPROVED_SUCCESS,
        payload: { message },
    }),
    toRefundApprovedFailed: (refundError) => ({
        type: actions.TO_REFUND_APPROVED_FAILED,
        refundError
    }),

    toRefundCancelled: (data) => ({
        type: actions.TO_REFUND_CANCELLED,
        payload: { data }
    }),
    toRefundCancelledSuccess: (message) => ({
        type: actions.TO_REFUND_CANCELLED_SUCCESS,
        payload: { message },

    }),
    toRefundCancelledFailed: (refundError) => ({
        type: actions.TO_REFUND_CANCELLED_FAILED,
        refundError
    })
}

export default actions;