import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from "react-redux";


const SalesChart = () => {
    const { SalesAndDealerCountData } = useSelector((state) => state.dashboard);

    const dealerCount = SalesAndDealerCountData && SalesAndDealerCountData.length > 0 && SalesAndDealerCountData.map((item) => (
        item.DealerCount
    ))

    const salesCount = SalesAndDealerCountData && SalesAndDealerCountData.length > 0 && SalesAndDealerCountData.map((item) => (
        item.OrderCount
    ))


    const [chartData, setChartData] = useState({
        series: [
            { name: 'Orders', data: salesCount && salesCount.length > 0 && salesCount },
            { name: 'Dealers', data: dealerCount && dealerCount.length > 0 && dealerCount },
        ],
        options: {
            chart: {
                height: 350,
                type: 'area',
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'smooth',
            },
            xaxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            },
            yaxis: {

                min: 1,
                max: 250
            },

            tooltip: {
                x: {
                    format: 'dd/MM/yy HH:mm',
                },
            },
        },
    });

    useEffect(() => {
        // Render the chart when the component mounts
        const domContainer = document.querySelector('#chart');
        const chart = <ReactApexChart options={chartData.options} series={chartData && chartData.series && chartData.series} type="area" height={450} />;
        ReactDOM.render(chart, domContainer);

        // Cleanup on component unmount
        return () => {
            ReactDOM.unmountComponentAtNode(domContainer);
        };
    }, [chartData]);


    return (
        <div>
            <div id="chart"></div>
            <div id="html-dist"></div>
        </div>
    );
};

export default SalesChart;
