import React from 'react';
import CheckboxCircleLine from 'remixicon-react/CheckboxCircleLineIcon';
import AlertFillIcon from 'remixicon-react/AlertFillIcon';
import ErrorWarningFill from 'remixicon-react/ErrorWarningFillIcon';

const Alert = ({ type, text, closeBtn, width }) => {
  let closeButtonColor;
  if (type === 'alert-success') {
    closeButtonColor = '#00b894';
  } else if (type === 'alert-danger') {
    closeButtonColor = '#d63031';
  } else if (type === 'alert-warning') {
    closeButtonColor = '#fdcb6e';
  } else {
    closeButtonColor = '#2d3436';
  }


  return (
    <div className={`alert ${type} alert-dismissible`} role="alert" >
      {type === 'alert-success' ? <CheckboxCircleLine /> : ''}
      {type === 'alert-danger' ? <AlertFillIcon /> : ''}
      {type === 'alert-warning' ? <ErrorWarningFill /> : ''}
      <div className="iq-alert-text">&nbsp;{text}</div>
      <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={closeBtn}>
        <span aria-hidden="true" style={{ color: closeButtonColor, width: width }} >&times;</span>
      </button>

    </div>
  )
}

Alert.defaultProps = {
  closeBtn: false
}

export default Alert
