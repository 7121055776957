import React, { useEffect, useMemo } from 'react'
import { useState } from 'react'
import EyeFill from 'remixicon-react/EyeFillIcon';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import CloseCircleLineIcon from 'remixicon-react/CloseCircleLineIcon';
import { bytesToMB, getDayDiffBetweenTwoDates } from '../../../helpers/commonFunction';
import CardLayout from '../../../components/utils/CardLayout'
import SearchBar from '../../../components/utils/SearchBar'
import Table from '../../../components/utils/Table'
import Tag from '../../../components/utils/Tag'
import Modal from '../../../components/utils/Model'
import ListSimActions from '../../../redux/listSim/actions';
import Button from '../../../components/utils/Button';
import Styles from './ListSim.module.css'
import SimPurchaseActions from '../../../redux/simPurchase/actions'
import aggregatorActions from '../../../redux/aggregator/actions';


const {getSimTypeData } = SimPurchaseActions;
const { getAggregator } = aggregatorActions;
const { getSimList, getSimDetail, resetListSim } = ListSimActions;

const ListSim = () => {
    const dispatch = useDispatch();
    const ListSimState = useSelector((state) => state.listSim);
    const { simTypeList} = useSelector((state) => state.simPurchase);
    const { list: aggregatorList } = useSelector((state) => state.aggregator);
    const [filterText, setFilterText] = useState("");
    const [showViewModal, setShowViewModal] = useState(false);
    const [search, setSearch] = useState(false);
    const [pageNum, setPageNum] = useState(0);
    const [modalICCID, setModalICCID] = useState("");
    const [simTypeCode,setSimTypeCode]=useState('')
    const { register, handleSubmit, formState: { errors }, reset, watch } = useForm();

    const sendPlanSimType = {
        "tableName": "sim",
        "fieldName": "SIMType"
    };
    useEffect(() => {
        // dispatch(getSimList());
        dispatch(getSimTypeData(sendPlanSimType))
        dispatch(getAggregator());
    }, []);

    const columns = [
        { displayName: '#', key: 'key' },
        { displayName: 'ICCID', key: 'ICCID' },
        { displayName: 'SIM TYPE', key: 'SimType' },
        { displayName: 'MSISDN', key: 'MSISDN' },
        { displayName: 'SIM STATUS', key: 'SimStatus' },
        { displayName: 'DEALER NAME', key: 'DealerName' },
        { displayName: 'COUNTRY', key: 'Country' }
        // { displayName: 'ACTION', key: 'Action' },
    ];

    const simdata = useMemo(() => {
        return ListSimState.getSimList.length > 0 ? ListSimState.getSimList : [];
    }, [ListSimState.getSimList]);

    const filteredItems = simdata && simdata.length > 0 && simdata.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );
    const handleFilterChange = (value) => {
        setFilterText(value);
    };
    const handleCancel = () => {
        dispatch(resetListSim());
        setPageNum(0);
        setShowViewModal(false)
    }
    const handleOpenToCheckSimUsageStatus = (simId) => {
        let selectedSim = simdata.filter((item) => item.ID == simId)[0];
        let data = {
            ICCID: selectedSim.ICCID,
            AggregatorName: selectedSim.AggregatorName,
            AggregatorID: selectedSim.AggregatorID,
        }
        
        setModalICCID(selectedSim.ICCID);
        dispatch(getSimDetail(data, simId));
        setShowViewModal(true)
    }

    let dataSource = filteredItems && filteredItems.length > 0 && filteredItems.map((SimItem, key) => {
        return {
            key: key + 1,
            ICCID: SimItem.ICCID,
            SMDPAddress: SimItem.SMDPAddress,
            SimType: simTypeCode==48 ?'Physical Sim' : 'E-Sim',
            MSISDN: SimItem.MSISDN,
            DealerName: SimItem.DealerName,
            Country:SimItem.CountryName,
            SimStatus: (
                <Tag color={`${SimItem.Status === 32 ? "badge-success" : "badge-danger"}`} title={SimItem.SimStatus} />
            ),
            // Action: SimItem.loading ? <div class="spinner-border text-primary spinner-border-sm" role="status"> </div> : SimItem.Status === 35 && !SimItem.loading ? 
            // <EyeFill size="1.2rem" style={{ color: "#0094b3", cursor: 'pointer' }} onClick={() => handleOpenToCheckSimUsageStatus(SimItem.ID)} /> : '',

        }
    });

    const handleSimSearch = (data) => {
        setSearch(true);
        dispatch(getSimList(data.SIMType, data.AggID));
        setSimTypeCode(data.SIMType)
    }

    return (
        <CardLayout title="List SIM">
            <div className='row align-items-end'>
                <div className='col-lg-9 col-md-9'>
                    <form onSubmit={handleSubmit(handleSimSearch)}>
                        <div className="form-row">

                            <div className="col-md-4 col-lg-3 mb-2 ">
                                <label>Aggregator</label>
                                <select className="form-control" placeholder="Select" name="AggID" {...register("AggID", {
                                    required: true,
                                })}>
                                    <option selected disabled value='' key="0" >Select Aggregator</option>
                                    {aggregatorList && aggregatorList.length > 0 && aggregatorList.map(aggList => (
                                                <option value={aggList.ID} key={aggList.ID}>{aggList.AggregatorName}</option>
                                            ))}
                                </select>
                                {errors.AggID?.type === "required" && (<div className="invalid-feedback">Aggregator is required !</div>)}
                            </div>

                            <div className="col-md-4 col-lg-3 mb-2">
                                <label>SIM Type</label>
                                <select className="form-control" placeholder="Select" name="SIMType" {...register("SIMType", {
                                    required: true,
                                })}>
                                    <option selected disabled value='' key="0" >Select SIM Type</option>
                                    {simTypeList && simTypeList.length > 0 && simTypeList.map(simType => (
                                                <option value={simType.ID} key={simType.ID}>{simType.Value}</option>
                                            ))}
                                </select>
                                {errors.SIMType?.type === "required" && (<div className="invalid-feedback">SIM type is required !</div>)}
                            </div>



                            <div className="col-md-4 col-lg-3 mb-2 mt-4">
                                <Button title="Search" loading={ListSimState.getSimListLoading} buttonClass={`btn btn-primary  mt-1 border-none  btn-md  Activity_searchBtn__GlBtC`} buttonType='submit' />
                            </div>

                        </div>

                    </form>
                </div>
                <div className='col-lg-3 col-md-3 mb-2'>
                    <SearchBar onFilter={handleFilterChange} filterText={filterText} />
                </div>
            </div>
            {search ? <Table columns={columns} loading={ListSimState.getSimListLoading} data={dataSource} pagination={true} /> : ''}

            <Modal
                title="Check Plan Usage Status"
                showModal={ListSimState.getSimDetailModal}
                onHide={handleCancel}
                showFooter={false}
                width={400}
                bodyClasses='p-0 pb-2'
                alignment='modal-dialog-centered'
            >

                {
                    ListSimState.getSimDetailMessage ? <>
                        <div className='container-fluid p-0'>
                            <h5 className='ml-3 mt-2'>ICCID : {modalICCID}</h5>
                        </div>
                        {
                            ListSimState.getSimDetail.length > 0 ? <div className='container-fluid mt-2 p-0'>
                                <p className='ml-3 mb-2 text-dark'>{ListSimState.getSimDetail[pageNum].name}</p>
                                <div className='container-fluid d-flex justify-content-between align-items-center'>
                                    <p className='py-2 m-0'>Plan Data</p>
                                    <p className='py-2 m-0'>{bytesToMB(ListSimState.getSimDetail[pageNum].assignments[0].initialQuantity)}</p>
                                </div>
                                <div className='container-fluid d-flex justify-content-between border'>
                                    <p className='py-2 m-0'>Data Remaining</p>
                                    <p className='py-2 m-0'>{bytesToMB(ListSimState.getSimDetail[pageNum].assignments[0].remainingQuantity)}</p>
                                </div>
                                <div className='container-fluid d-flex justify-content-between'>
                                    <p className='py-2 m-0'>Plan Validity</p>
                                    <p className='py-2 m-0'>{getDayDiffBetweenTwoDates(ListSimState.getSimDetail[pageNum].assignments[0].startTime, ListSimState.getSimDetail[pageNum].assignments[0].endTime)}</p>
                                </div>
                            </div> : ''
                        }
                        <div className='text-right row mt-1'>
                            <div className="col-md-12" mb-4>
                                <Button type="button" title={'Prev'} disable={pageNum == 0} buttonClass="btn btn-sm btn-primary mr-2" onClick={() => setPageNum((prev) => prev - 1)}>Prev</Button>
                                <Button type="button" title={'Next'} disable={pageNum === ListSimState.getSimDetail.length - 1} buttonClass="btn btn-sm btn-primary mr-2" onClick={() => setPageNum((prev) => prev + 1)}>Next</Button>
                            </div>
                        </div>
                    </> : ''
                }

                {
                    ListSimState.getSimDetailError ? <>
                        <div style={Style.modalContent} className='mt-3'>
                            <CloseCircleLineIcon size="4em" color='#e74c3c' />
                            <p className='text-dark'>{ListSimState.getSimDetailError}</p>
                        </div>
                    </> : ''
                }




            </Modal>

        </CardLayout>
    )
}

const Style = {
    modalContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: 'center'
    }
}

export default ListSim
