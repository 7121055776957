import React, { useEffect } from 'react'
import Button from '../../components/utils/Button'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cartAction from '../../redux/cart/actions';
import SimPurchaseActions from '../../redux/simPurchase/actions'

const {getSimTypeData } = SimPurchaseActions;

function CartDropdown() {
    const { viewCartData } = cartAction;
    const dispatch = useDispatch();
    const { cartResult } = useSelector((state) => state.cart);
    const { simTypeList} = useSelector((state) => state.simPurchase);

    const sendPlanSimType = {
        "tableName": "sim",
        "fieldName": "SIMType"
    };

    useEffect(()=>{
        dispatch(getSimTypeData(sendPlanSimType))
    },[])

    useEffect(() => {
        dispatch(viewCartData());
    }, [])

    return (
        <>
            <a href="#" className="search-toggle iq-waves-effect">
                <i class="ri-shopping-cart-2-fill"></i>
            </a>
            {cartResult && cartResult.length > 0 && <span className="number-dots">{cartResult && cartResult.length}</span>}
            <div className="iq-sub-dropdown">
                <div className="iq-card shadow-none m-0">
                    <div className="iq-card-body p-2 ">
                        <div className="bg-light p-2 mb-2">
                            <h5 className="mb-0 text-dark">Cart<small className="badge  badge-light float-right pt-1">{cartResult && cartResult.length}</small></h5>
                        </div>

                        {cartResult && cartResult.length > 0 && cartResult.map((data, key) => {
                            return (
                                <a href="#" className="iq-sub-card" >
                                    <div className="media align-items-center">
                                        <h6>{key + 1}</h6>
                                        <div className="media-body ml-3">
                                            <h6 className="mb-1 ">{data.VariantName}</h6>
                                            <p className="mb-1 font-size-12 text-primary d-flex justify-content-between">
                                            <span>{`${data.PlanDataValue} ${data.PlanDaysValue} `}</span>
                                            {simTypeList && simTypeList.length > 0 && simTypeList.filter(item=>item.ID==data.PlanSIMType).map(simType => (
                                                <span key={simType.ID}><i>{simType.Value}</i></span>
                                            ))}
                                            </p>
                                            <small className=" font-size-14 font-weight-bold text-dark">{`(QTY-${data.Quantity})`}</small>
                                            <small className="float-right font-size-14 font-weight-bold text-dark">£{parseFloat(data.PlanPrice).toFixed(2)}</small>
                                        </div>
                                    </div>
                                </a>
                            )
                        })}

                        <div className='text-right pt-3 pb-2'>
                            {cartResult && cartResult.length > 0 ? <Link to="/cart"><button className='btn btn-primary' >VIEW CART</button></Link> :
                                <Link to="/plan-purchase"><button className='btn btn-primary'  >ADD BUNDLE</button></Link>}
                        </div></div>
                </div>
            </div>
        </>
    )
}

export default CartDropdown