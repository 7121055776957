import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import auditActions  from '../../redux/auditReport/actions'


const {getAuditReport}=auditActions;
const dateFormat = process.env.REACT_APP_DATE_FORMAT;
const Transaction = () => {
    const dispatch = useDispatch();
    const { auditResult } = useSelector((state) => state.auditReport);
    useEffect(() => {
        dispatch(getAuditReport())
     }, []);
    return (
    
            <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                <div class="iq-card-header d-flex justify-content-between">
                    <div className="iq-header-title">
                    <h4 class="card-title font-weight-bold" style={{ fontSize: "18px" }}>Transactions History</h4>
                    </div>
                    
                    {
                        auditResult && auditResult.length !== 0 && <div className="iq-card-header-toolbar d-flex align-items-center">
                            <div className="dropdown">
                                <Link to="/audit-report"><span className="dropdown-toggle text-primary" >View All</span></Link>
                            </div>
                        </div>
                    }
                </div>
                <div className="iq-card-body pb-3" style={{ padding: "0px" }}>
                    <ul className="iq-timeline" style={{ border: "none", boxShadow: "none" }}>
                        {auditResult && auditResult.length === 0 && <p>No data found</p>}
                        {auditResult && auditResult.length > 0 && auditResult.filter((item, index) =>  index <= 4).map((item, index) => (
                            <li key={index} className="ml-0">
                                <div className="d-flex align-items-left justify-content-between" style={{marginBottom:"20px"}}>
                                    <div className="media align-items-center">
                                        <div className={`${item.Amount > 0 ? "iq-bg-success rounded-sm icon-date" : "iq-bg-danger rounded-sm icon-date"}`} data-wow-delay="0.2s" >
                                            <div className="date-meta">
                                                <h3 className="date" style={{ fontSize: "15px", color: "black" }}><b>{moment(item.Date).format("D")}</b></h3>
                                                <p className="mb-0" style={{ fontSize: "13px" }}>{moment(item.Date).format("MMM")}</p>
                                                <div className={`${item.Amount > 0 ? 'icon-dot bg-success' : 'icon-dot bg-danger'}`}></div>
                                            </div>
                                        </div>
                                        <div className="media-body ml-2">
                                            <h5 className="mb-0" style={{ fontSize: "16px" }} >{item.PaymentType}</h5>
                                            <small> #{item.TxnID}</small>
                                        </div>
                                    </div>
                                    <div className="media-body ml-2" style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                        <h6 className={`text-${item.Amount > 0 ? 'success' : 'danger'}`}>
                                            {item.Amount > 0 ? `+${item.Amount}` : item.Amount}<i className={`ri-arrow-${item.Amount > 0 ? 'up-fill' : 'down-fill'} pl-2`}></i>
                                        </h6>
                                    </div>

                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        
    )
}

export default Transaction
