import React, { Children } from 'react';

const Modal = ({ title, showModal, onHide, onSaveChanges, width, showHeader = true, showFooter = true, buttons = [], children, submitButtonTitle, alignment, bodyClasses = '' }) => {
    const modalWidth = `${width}px`;

    return (
        <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden={!showModal}>
            <div className={`modal-dialog ${alignment}`} style={{ maxWidth: modalWidth ? modalWidth : '500px' }} role="document">
                <div className="modal-content">
                    {showHeader && <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">{String(title).toUpperCase()}</h5>
                        <button type="button" className="close" onClick={onHide} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>}
                    <div className={`modal-body ${bodyClasses}`}>
                        {children}
                    </div>
                    {showFooter && (
                        <div className="modal-footer">
                            {buttons.length > 0 ? (
                                buttons.map((button, index) => (
                                    <button key={index} type="button" className={`btn ${button.className}`} onClick={button.onClick}>
                                        {button.label}
                                    </button>
                                ))
                            ) : (
                                <>
                                    <button type="button" className="btn btn-secondary" onClick={onHide}>
                                        Close
                                    </button>
                                    <button type="button" className="btn btn-primary" onClick={onSaveChanges}>
                                        {submitButtonTitle}
                                    </button>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Modal;

Modal.defaultProps = {
    alignment: ''
}

// How to use

// const [showModal, setShowModal] = useState(false);

// const openModal = () => {
//     setShowModal(true);
// };

// const closeModal = () => {
//     setShowModal(false);
// };

// const handleSaveChanges = () => {
//     // Handle save changes logic here
//     closeModal(); // Close the modal after saving changes
// };

// const customButtons = [
//     {
//         label: 'Custom Button 1',
//         className: 'btn btn-info',
//         onClick: () => {
//             // Handle custom button 1 click
//             console.log('Custom Button 1 clicked');
//         },
//     },
//     {
//         label: 'Custom Button 2',
//         className: 'btn btn-warning',
//         onClick: () => {
//             // Handle custom button 2 click
//             console.log('Custom Button 2 clicked');
//         },
//     },
// ];




// <div>
//     <button onClick={openModal}>Open Modal</button>

//     <ModalComponent
//         showModal={showModal}
//         title="Add Dealer"
//         onHide={closeModal}
//         onSaveChanges={handleSaveChanges}
//         width={600}
//         showFooter={true}
//         // buttons={customButtons}
//         submitButtonTitle="Submit"
//     >
//         <div>
//             hello
//         </div>
//     </ModalComponent>
// </div>
