import actions from "./actions";

const initState = {
    verifyDealerResult: [],
    loading: false,
    buttonLoading: false,
    verifyDealerError: null,
    verifyDealerMessage: null,
    verifyDealerFormModal: false
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_NON_VERIFY_DEALER:
            return {
                ...state,
                loading: true,
                verifyDealerError: null,
                buttonLoading: false,
                verifyDealerMessage: null,
                verifyDealerFormModal: false
            };
        case actions.GET_NON_VERIFY_DEALER_SUCCESS:
            return {
                ...state,
                loading: false,
                verifyDealerError: null,
                buttonLoading: false,
                verifyDealerMessage: null,
                verifyDealerResult: action.verifyDealerResult,
            };
        case actions.GET_NON_VERIFY_DEALER_FAILED:
            return {
                ...state,
                loading: false,
                verifyDealerError: action.verifyDealerError,
            };
        case actions.TO_VERIFY_DEALER:
            return {
                ...state,
                loading: true,
                verifyDealerError: null,
                buttonLoading: true,
                verifyDealerMessage: null,
            };
        case actions.TO_VERIFY_DEALER_SUCCESS:
            return {
                ...state,
                loading: false,
                buttonLoading: false,
                verifyDealerError: null,
                verifyDealerMessage: action.verifyDealerMessage,
                verifyDealerResult: action.verifyDealerResult,
                verifyDealerFormModal: false
            };
        case actions.TO_VERIFY_DEALER_FAILED:
            return {
                ...state,
                loading: false,
                buttonLoading: false,
                verifyDealerError: action.verifyDealerError,
                verifyDealerFormModal: true
            };
        case actions.PREPARE_VERIFY_DEALER_FORM:
            return {
                ...state,
                loading: false,
                verifyDealerError: null,
                verifyDealerMessage: null,
                verifyDealerFormModal: true
            };
        case actions.RESET_VERIFY_DEALER:
            return {
                ...state,
                loading: false,
                verifyDealerError: null,
                verifyDealerMessage: null,
                verifyDealerFormModal: false,
            };
        case actions.RESET_VERIFY_Dealer_Message:
            return {
                ...state,
                loading: false,
                verifyDealerError: null,
                verifyDealerMessage: null,
            };

        default:
            return state;
    }
}
