import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AddtoFundAction from '../../../redux/addToFund/actions'
import CloseFill from 'remixicon-react/CloseFillIcon';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const { sendPaymentData } = AddtoFundAction
const CheckoutForm = ({ amountData, setToggle, payNow }) => {
    const dispatch = useDispatch()
    const [clientSecret, setClientSecret] = useState('');


    useEffect(() => {
        // Create a Checkout Session as soon as the page loads
        if (payNow) {
            fetch(`${process.env.REACT_APP_BASEURL}/create-checkout-session`, {
                method: "POST",
                body: JSON.stringify(amountData),
                headers: {
                    "Content-Type": "application/json", // Specify the content type as JSON
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setClientSecret(data.clientSecret);
                    setToggle(false)
                });
        }
    }, [payNow]);

    const handleCancel = () => {
        const obj = {
            paymentId: amountData.PaymentId,
            stripeResponse: {
                status: "Payment Cancelled",
                message: "Payment cancelled by user"
            },
            transcation_id: ""
        }
        dispatch(sendPaymentData(obj));
        setToggle(true)
    }

    return (
        <div id="checkout">
            {clientSecret ? (
                <div>
                    {/* <button className="px-3 fs-6 bg-dark rounded-1" onClick={() => { setToggle(true) }}>Cancel</button> */}
                    <p className='text-end mb-0' style={{ textAlign: "end", cursor: "pointer" }} onClick={handleCancel}><CloseFill size="1.8em" /></p>
                    <EmbeddedCheckoutProvider
                        stripe={stripePromise}
                        options={{ clientSecret }}
                    >
                        <EmbeddedCheckout />
                    </EmbeddedCheckoutProvider>

                </div>
            )
                :
                <>
                    <p className='text-center'>Checkout form will open here</p>
                    <div class="spinnerInner"></div>
                </>
            }
        </div>
    )
}

export default CheckoutForm
