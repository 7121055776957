import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import { getList, addData } from '../../helpers/fackBackend_Helper';


function* getSimListResponse({ payload: { simType, Aggregator } }) {
  try {
    const response = yield call(getList, `/inventory/${simType}/${Aggregator}`);
    if (response.Status) {
      yield put(actions.getSimListSuccess(response.Message, response.Data));
    } else {
      yield put(actions.getSimListFailed(response.Message));
    }
  } catch (error) {
    yield put(actions.getSimListFailed(error));
  }
}
export function* watchGetSimListResponse() {
  yield takeEvery(actions.GET_SIM_LIST, getSimListResponse)
}

function* getSimDetailResponse({ payload: { data, simId } }) {
  try {
    const response = yield call(addData, `/getSimPlanDetails`, data);
    if (response.Status) {
      yield put(actions.getSimDetailSuccess(response.Message, response.Data.bundles, simId));
    } else {
      yield put(actions.getSimDetailFailed(response.Message, simId));
    }
  } catch (error) {
    yield put(actions.getSimDetailFailed(error, simId));
  }
}
export function* watchGetSimDetailResponse() {
  yield takeEvery(actions.GET_SIM_DETAIL, getSimDetailResponse)
}


export default function* rootSaga() {
  yield all([
    fork(watchGetSimListResponse),
    fork(watchGetSimDetailResponse),
  ]);
}
