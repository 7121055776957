import actions from './actions';

const initState = {
  physicalSimAggregatorLoading: false,
  physicalSimAggregatorError: null,
  physicalSimAggregatorMessage: null,
  physicalSimAggregator: [],
  getInvoiceLoading: false,
  getInvoiceError: null,
  getInvoiceMessage: null,
  getInvoice: [],
  savePurchaseLoading: false,
  savePurchaseError: null,
  savePurchaseMessage: null,
  savePurchaseSuccessModal: false,
  validateSimDataLoading: false,
  validateSimDataError: null,
  validateSimDataMessage: null,
  validateSimData: null,
  getSimPurchaseLoading: false,
  getSimPurchaseError: null,
  getSimPurchaseMessage: null,
  getSimPurchase: [],
  getSimPurchaseDetailsLoading: false,
  getSimPurchaseDetailsError: null,
  getSimPurchaseDetailsMessage: null,
  getSimPurchaseDetails: [],
  simTypeList: [],
  simTypeMessage: null,
  simTypeError: null,
  simTypeLoading: false,
};


export default function authReducer(state = initState, action) {
  switch (action.type) {


    case actions.GET_SIM_TYPE:
      return {
        ...state,
        simTypeMessage: null,
        simTypeError: null,
        simTypeLoading: false,
      };
    case actions.GET_SIM_TYPE_SUCCESS:
      return {
        ...state,
        simTypeMessage: null,
        simTypeError: null,
        simTypeLoading: false,
        simTypeList: action.simTypeData,
      };
    case actions.GET_SIM_TYPE_FAILED:
      return {
        ...state,
        simTypeMessage: null,
        simTypeError: action.error,
        simTypeLoading: false,
      }

    case actions.GET_SIM_PURCHASE_DETAILS:
      return {
        ...state,
        getSimPurchaseDetailsLoading: true,
        getSimPurchaseDetailsError: null,
        getSimPurchaseDetailsMessage: null,
      };
    case actions.GET_SIM_PURCHASE_DETAILS_SUCCESS:
      return {
        ...state,
        getSimPurchaseDetailsLoading: false,
        getSimPurchaseDetailsError: null,
        getSimPurchaseDetailsMessage: action.payload.message,
        getSimPurchaseDetails: action.payload.data,
      };
    case actions.GET_SIM_PURCHASE_DETAILS_FAILED:
      return {
        ...state,
        getSimPurchaseDetailsLoading: false,
        getSimPurchaseDetailsError: action.payload.error,
        getSimPurchaseDetailsMessage: null,
      };



    case actions.GET_SIM_PURCHASE:
      return {
        ...state,
        getSimPurchaseLoading: true,
        getSimPurchaseError: null,
        getSimPurchaseMessage: null,
      };
    case actions.GET_SIM_PURCHASE_SUCCESS:
      return {
        ...state,
        getSimPurchaseLoading: false,
        getSimPurchaseError: null,
        getSimPurchaseMessage: action.payload.message,
        getSimPurchase: action.payload.data,
      };
    case actions.GET_SIM_PURCHASE_FAILED:
      return {
        ...state,
        getSimPurchaseLoading: false,
        getSimPurchaseError: action.payload.error,
        getSimPurchaseMessage: null,
      };


    case actions.VALIDATE_SIM_DATA:
      return {
        ...state,
        validateSimDataLoading: true,
        validateSimDataError: null,
        validateSimDataMessage: null,
        validateSimData: null,
      };
    case actions.VALIDATE_SIM_DATA_SUCCESS:
      return {
        ...state,
        validateSimDataLoading: false,
        validateSimDataError: null,
        validateSimDataMessage: action.payload.message,
      };
    case actions.VALIDATE_SIM_DATA_FAILED:
      return {
        ...state,
        validateSimDataLoading: false,
        validateSimDataError: action.payload.error,
        validateSimDataMessage: null,
        validateSimData: action.payload.data,
      };

    case actions.SAVE_PURCHASE_SIM:
      return {
        ...state,
        savePurchaseLoading: true,
        savePurchaseError: null,
        savePurchaseMessage: null,
      };
    case actions.SAVE_PURCHASE_SIM_SUCCESS:
      return {
        ...state,
        savePurchaseLoading: false,
        savePurchaseError: null,
        savePurchaseMessage: action.payload.message,
        savePurchaseSuccessModal: true
      };
    case actions.SAVE_PURCHASE_SIM_FAILED:
      return {
        ...state,
        savePurchaseLoading: false,
        savePurchaseError: action.payload.error,
        savePurchaseMessage: null,
      };


    case actions.GET_INVOICE:
      return {
        ...state,
        getInvoiceLoading: true,
        getInvoiceError: null,
        getInvoiceMessage: null,
      };
    case actions.GET_INVOICE_SUCCESS:
      return {
        ...state,
        getInvoiceLoading: false,
        getInvoiceError: null,
        getInvoiceMessage: action.payload.message,
        getInvoice: action.payload.data,
      };
    case actions.GET_INVOICE_FAILED:
      return {
        ...state,
        getInvoiceLoading: false,
        getInvoiceError: action.payload.error,
        getInvoiceMessage: null,
      };

    case actions.GET_PHYSICAL_SIM_AGGREGATOR:
      return {
        ...state,
        physicalSimAggregatorLoading: true,
        physicalSimAggregatorError: null,
        physicalSimAggregatorMessage: null,
      };
    case actions.GET_PHYSICAL_SIM_AGGREGATOR_SUCCESS:
      return {
        ...state,
        physicalSimAggregatorLoading: false,
        physicalSimAggregatorError: null,
        physicalSimAggregatorMessage: action.payload.message,
        physicalSimAggregator: action.payload.data,
      };
    case actions.GET_PHYSICAL_SIM_AGGREGATOR_FAILED:
      return {
        ...state,
        physicalSimAggregatorLoading: false,
        physicalSimAggregatorError: action.payload.error,
        physicalSimAggregatorMessage: null,
      };

    case actions.RESET_SIM_PURCHASE:
      return {
        ...state,
        savePurchaseSuccessModal: false,
      };

    default:
      return state;
  }

}
