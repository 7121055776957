import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Button from '../../../components/utils/Button';
import Table from '../../../components/utils/Table';
import CountryActions from '../../../redux/country/actions'
import { useDispatch, useSelector } from 'react-redux';
import SearchBar from '../../../components/utils/SearchBar';
import Tag from '../../../components/utils/Tag';
import Modal from '../../../components/utils/Model';
import planAction from '../../../redux/plan/actions';
import cartAction from '../../../redux/cart/actions';
import authAction from '../../../redux/auth/actions';
import { useForm } from "react-hook-form";
import ProgressBar from '../../../components/utils/ProgressBar';
import NoImageAvailable from '../../../assets/images/no image available.jpg'
import SimPurchaseActions from '../../../redux/simPurchase/actions'
import { useNavigate } from "react-router-dom";


const { getSimTypeData } = SimPurchaseActions;


function PlanPurchase() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { getCountry, getCountryWhoHasPlan } = CountryActions
    const { getPlan } = planAction;
    const { getUserDataByToken } = authAction;
    const { checkCartData, addPlanToCart, viewCartData, prepareViewPlanModal, resetViewPlanModal, resetWholePage } = cartAction;
    const [filterText, setFilterText] = useState("");
    const [selectedCountryOptions, setSelectedCountryOptions] = useState();
    const [showPlanNameOnModal, setshowPlanNameOnModal] = useState('')
    const [showCountryNameOnModal, setshowCountryNameOnModal] = useState('')
    const [selectedEachPlanData, setSelectedEachPlanData] = useState(null);
    const [selectedImageURL, setSelectedImageURL] = useState(null)
    const [filterPlanByCountry, setfilterPlanByCountry] = useState({
        countryID: []
    })
    const countryResult = useSelector((state) => state.country.countryResult);
    const countryHasPlanResult = useSelector((state) => state.country.countryHasPlanResult);
    const { planResult, loading } = useSelector((state) => state.plan);
    const { checkCartFlag, cartResult, showModal, cartError, cartMessage, showModalLoading ,buttonLoading} = useSelector((state) => state.cart);
    const tokenData = useSelector((state) => state.auth.tokenData);
    const { simTypeList } = useSelector((state) => state.simPurchase);
    const [showViewCart, setshowViewCart] = useState(false);



    const [addToCart, setAddToCart] = useState({
        planPrice: '',
        planID: '',
        quantity: '1',
        calculationPrice: 0,
        planVariantID: '',
    })
    const [accountBalance, setAccountBalance] = useState('0.00');
    const [planBundleLength, setPlanBundleLength] = useState(0);
    const [planBundleQuantity, setPlanBundleQuantity] = useState(0);
    const [planBundleAmount, setPlanBundleAmount] = useState(0.00);


    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }, reset: resetForm
    } = useForm({ defaultValues: addToCart });

    // useEffect(() => {
    //     resetForm(addToCart);
    // }, [addToCart]);

    var totalPrice = 0;
    var priceCalculation = 0;
    var bundleCalculatedQuantity = 0;
    var bundleCalculatedAmount = 0.0;

    const sendPlanSimType = {
        "tableName": "sim",
        "fieldName": "SIMType"
    };

    useEffect(() => {
        // dispatch(viewCartData());
        // dispatch(getCountry());
        dispatch(getCountryWhoHasPlan());
        dispatch(getUserDataByToken());
        dispatch(getSimTypeData(sendPlanSimType))
    }, [])


    useEffect(() => {
        dispatch(viewCartData());
    }, []) // dispatch cartMessage, cartError, cartResult


    useEffect(() => {
        dispatch(getPlan(filterPlanByCountry))
    }, [filterPlanByCountry])

    useEffect(() => {
        dispatch(resetWholePage());
    }, [])

    useEffect(() => {
        if (tokenData && tokenData !== undefined && tokenData.length > 0) {
            const balance = tokenData[0].AccountBalance !== null ? tokenData[0].AccountBalance : 0.00;
            const newBalance = balance; // .toFixed(2)
            setAccountBalance(newBalance.toFixed(2));
        }
    }, [tokenData]);

    useEffect(() => {
        if (cartResult && cartResult !== undefined && cartResult.length > 0) {
            setPlanBundleLength(cartResult.length);
            cartResult.map((data) => {
                bundleCalculatedQuantity = bundleCalculatedQuantity + data.Quantity;
                bundleCalculatedAmount = bundleCalculatedAmount + (Number(data.PlanPrice) * Number(data.Quantity));
                priceCalculation = data.PlanPrice * Number(data.Quantity);
                totalPrice = totalPrice + priceCalculation;
                setPlanBundleQuantity(bundleCalculatedQuantity);
                setPlanBundleAmount(parseFloat(bundleCalculatedAmount).toFixed(2));
            })
        } else {
            setPlanBundleLength(0);
            setPlanBundleQuantity(0);
            setPlanBundleAmount(0.00);
        }
    }, [cartResult]);

    useEffect(() => {
        if (showViewCart && cartMessage ) {
            navigate("/cart");
        }
    }, [cartMessage]);



    const handleFilterChange = (value) => {
        setFilterText(value);
    };
    const handleRefreshData = () => {
        setfilterPlanByCountry(prevState => ({
            ...prevState,
            countryID: []
        }));
        setFilterText("");
        setSelectedCountryOptions(null);
    }

    const filterCountryData = countryHasPlanResult && countryHasPlanResult.length > 0 && countryHasPlanResult.filter(
        item =>
            JSON.stringify(item.CountryName)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const handleSelectionCountry = (e, countryId) => {
        if (e.target.checked) {
            setSelectedCountryOptions(countryId);
            setfilterPlanByCountry(prevState => ({
                ...prevState,
                countryID: countryId
            }));
        } else {
            setSelectedCountryOptions(null);
        }

    }

    const handleChange = (e) => {
        const fname = e.target.name;
        const fvalue = e.target.value;
        const str = addToCart.planPrice
        const noSpecialChars = str.replace(/\£/g, '');
        const calculatednewprice = +noSpecialChars;


        if (fvalue === '0') {
            setAddToCart(prevState => ({
                ...prevState,
                quantity: 1,
                calculationPrice: 1 * calculatednewprice
            }));
        } else {
            setAddToCart(prevState => ({
                ...prevState,
                quantity: fvalue,
                calculationPrice: Number(fvalue) * calculatednewprice
            }));
        }
    }

    const handleIncrement = (e) => {
        e.preventDefault();
        const str = addToCart.planPrice
        const noSpecialChars = str.replace(/\£/g, '');
        const calculatednewprice = +noSpecialChars;
        setAddToCart((prevState) => ({
            ...prevState,
            quantity: prevState.quantity + 1,
            calculationPrice: (prevState.quantity + 1) * calculatednewprice,
        }));
    };

    const handleDecrement = (e) => {
        e.preventDefault();
        const str = addToCart.planPrice
        const noSpecialChars = str.replace(/\£/g, '');
        const calculatednewprice = +noSpecialChars;
        if (addToCart.quantity > 1) {
            setAddToCart((prevState) => ({
                ...prevState,
                quantity: prevState.quantity - 1,
                calculationPrice: (prevState.quantity - 1) * calculatednewprice,
            }));
        }
    };
    const handleRowClick = (rowData) => {
        dispatch(prepareViewPlanModal())
        const selectedPlanData = planResult.filter(plan => plan.ID === rowData.planID);
        setshowPlanNameOnModal(selectedPlanData[0].PlanName);
        setshowCountryNameOnModal(selectedPlanData[0].CountryName)
        setSelectedImageURL(selectedPlanData[0].ImageUrl)
        const selectedPlanVariantData = selectedPlanData && selectedPlanData[0].PlanVariant && selectedPlanData[0].PlanVariant.filter(variant => variant.ID === rowData.planVariantID)
        setSelectedEachPlanData(selectedPlanVariantData)
        const str = rowData.price
        const noSpecialChars = str.replace(/\£/g, '');
        const calCulatedNewPrice = +noSpecialChars;
        setAddToCart(prevState => ({
            ...prevState,
            planID: rowData.planID,
            planVariantID: rowData.planVariantID,
            quantity: 1,
            planPrice: rowData.price,
            calculationPrice: calCulatedNewPrice
        }));
        dispatch(checkCartData(rowData.planVariantID))
    }

    const handleAddToCartSubmit = (item) => {
        if (item == 1) {
            dispatch(addPlanToCart(addToCart))
            setshowViewCart(true)
        }
        else {
            dispatch(addPlanToCart(addToCart))
            setshowViewCart(false)
        }
    }

    const closeModal = () => {
        dispatch(resetViewPlanModal())
    };

    const columns = [
        { displayName: 'PLAN NAME', key: 'PlanName' },
        { displayName: 'SIM TYPE', key: 'planSIMType' },
        { displayName: 'VARIATION NAME', key: 'variationName' },
        { displayName: 'COUNTRY', key: 'country' },
        { displayName: 'DATA', key: 'PlanData' },
        { displayName: 'DURATION', key: 'duration' },
        { displayName: 'PRICE', key: 'price' },
    ];

    const data = planResult && planResult.length > 0 && planResult.map((plan, key) => {
        return plan.PlanVariant && plan.PlanVariant.length > 0 && plan.PlanVariant.map((variant, key1) => {

            let planSimTypeData = simTypeList && simTypeList.length > 0 && simTypeList.filter(sim => sim.ID == variant.PlanSIMType)

            return {
                PlanName: plan.PlanName,
                planVariantID: variant.ID,
                planID: variant.PlanID,
                country: <Tag color='badge-primary' title={plan.CountryName}></Tag>,
                bundleName: variant.ESIMGORef,
                variationName: `${variant.VariantName}`,
                PlanData: variant.PlanDataValue,
                duration: variant.PlanDaysValue,
                price: `£${variant.PlanPrice.toFixed(2)}`,
                planSIMType: planSimTypeData && planSimTypeData.length > 0 ? planSimTypeData[0].Value : ''
            }
        });
    }).flat();



    return (
        <>
            <div id="content-page" class="content-page">
                <div class="container-fluid">
                    <ProgressBar activeStep={0} activeStatus=" active"></ProgressBar>
                    <div className='row'>
                        <div className='col-md-3'>
                            <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                                <div className="parent-filter">
                                    <div className="col filter-resign">
                                        <h5 >Filter By Country</h5>
                                        <span><i className='ion-android-refresh fab-icon-code' style={{ 'fontSize': "19px", 'cursor': 'pointer' }} onClick={handleRefreshData}></i></span>
                                    </div>
                                    <SearchBar onFilter={handleFilterChange} filterText={filterText} />
                                </div>

                                <div className="carding">
                                    <div className="accordion accordion-flush" id="accordionFlushExample1">
                                        {filterCountryData && filterCountryData.length > 0 && filterCountryData.map(res => (
                                            <div className="accordion-body">
                                                <div className="cat col-md-12 action position-relative text-center">

                                                    <label>
                                                        <input type="checkbox" name='countryId' value={res.ID} checked={res.ID === selectedCountryOptions ? true : false} onChange={(e) => handleSelectionCountry(e, res.ID)} /><span >{res.CountryName}</span>
                                                    </label>

                                                    {/* <div id="posoti"> {res.ISOCode !== null && <CountryFlag countryCode={res.ISOCode} svg style={{ width: '20px', height: '20px' }} />}</div> */}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="col-md-9" >
                            <div class="iq-card iq-card-block iq-card-stretch iq-card-height p-3">
                                <div className="row align-items-center">
                                    <div className="col-md-3 col-sm-12 card-view-1 mb-1">
                                        <p className="bundl-text mb-0">Bundles Selected</p>
                                        <p className="bqyt-text">{`${planBundleLength} Bundle (Qty:${planBundleQuantity})`}</p>
                                    </div>
                                    <div className="col-md-3 col-sm-12 card-view-1 mb-1 ">
                                        <p className="accblane-text mb-0">Account Balance</p>
                                        <p className="zero-text">{accountBalance < 0 ? `-£${Math.abs(accountBalance)}` : `£${accountBalance}`}</p>
                                    </div>
                                    <div className="col-md-3 col-sm-12 card-view-1 mb-1">
                                        <p className="accblane-text mb-0">Total</p>
                                        <p className="sixthy-text">£{planBundleAmount}</p>
                                    </div>
                                    <div className="col-md-3 text-right">
                                        <Link to="/cart"><Button title="View Cart" style="minWidth:80px" buttonClass="btn  view-card-bplan" buttonType='submit' /></Link>
                                    </div>
                                </div>

                                {/* mobile code            */}
                                <div >
                                    {data && data.length > 0 && data.map((item, index) => (

                                        <div class="card cardmobilecard mb-3" onClick={() => handleRowClick(item)}>

                                            <div class="card-image-container-fluid ">
                                                <div className='row mb-2 card-row-bg'>
                                                    <div className='col-6 text-start'>

                                                        <div >
                                                            <h5 className='mobile-plan-card'>PLAN NAME</h5>
                                                            <p className='mobile-para'>{item.PlanName}</p>
                                                        </div>
                                                    </div>
                                                    <div className='col-6 text-end'>
                                                        <div >
                                                            <h5 className='mobile-plan-card'>VARIATION NAME</h5>
                                                            <p className='mobile-para'>{item.variationName}</p>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className='row mb-2 card-row-bg'>
                                                    <div className='col-6 text-start'>
                                                        <div >
                                                            <h5 className='mobile-plan-card'>COUNTRY</h5>
                                                            <p className='mobile-para'>{item.country}</p>
                                                        </div>
                                                    </div>
                                                    <div className='col-6 text-end'>
                                                        <div >
                                                            <h5 className='mobile-plan-card'>DATA</h5>
                                                            <p className='mobile-para'>{item.PlanData}</p>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='row  card-row-bg'>
                                                    <div className='col-6 text-start'>
                                                        <div >
                                                            <h5 className='mobile-plan-card'>DURATION</h5>
                                                            <p className='mobile-para'>{item.duration}</p>
                                                        </div>
                                                    </div>
                                                    <div className='col-6 text-end'>
                                                        <div >
                                                            <h5 className='mobile-plan-card'>PRICE</h5>
                                                            <p className='mobile-para'>{item.price}</p>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    ))}
                                </div>


                                {/* mobile code  */}
                                <div className='table-hide-plan-purches'>
                                    <Table paginationClass="pagination-plan-purches " columns={columns} data={data} pagination={true} loading={loading || showModalLoading} onRowClick={handleRowClick} />
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {showModalLoading ? '' : <Modal
                showModal={showModal}
                modalWidth='850px'
                onHide={closeModal}
                width={870}
                bodyClasses="model-plan-purchase"
                showFooter={false}
                showHeader={false}
                alignment='modal-dialog-centered'
            >
                {selectedEachPlanData && selectedEachPlanData.length > 0 && selectedEachPlanData.map((data, key) => {
                    return (
                        <div className='row p-0 m-0'>
                            <div className='col-lg-6 col-md-6 col-sm-12 p-0'>
                                <div className=' plan-purchase-image-container'>
                                    <img src={selectedImageURL ? `${process.env.REACT_APP_BASEURL}/planImages/${selectedImageURL}` : NoImageAvailable} className='set-box '></img>

                                    <div className='image-overlay'>
                                        <div className='model-cart-details-purshase'>
                                            {/* <p style={{ fontFamily: 'Muli', fontWeight: '900' }}>{showPlanNameOnModal}</p> */}
                                            <p className='text-center mb-0' style={{ fontFamily: 'Muli', fontSize: '17px', fontWeight: '900', letterSpacing: '1px' }}>{showPlanNameOnModal}</p>
                                            {/* <p className='text-center' style={{ fontFamily: 'Muli', fontWeight: '900', letterSpacing: '1px' }}>{data.VariantName}</p> */}
                                            {/* <p className='text-center' style={{ fontFamily: 'Muli', fontWeight: '900', letterSpacing: '1px' }}>{`${data.PlanDataValue}, ${data.PlanDaysValue}, ${showCountryNameOnModal}, `}
                                                {simTypeList && simTypeList.length > 0 && simTypeList.filter(item => item.ID == data.PlanSIMType).map(simType => (
                                                    <span key={simType.ID}>{simType.Value}</span>
                                                ))}
                                            </p> */}

                                            <div className='row text-center'>
                                                <div className='col-sm-6'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className='mt-3 mb-2' width="40" height="40" fill="currentColor"><path d="M11.9498 7.94975L10.5356 9.36396L8.00079 6.828L8.00004 20H6.00004L6.00079 6.828L3.46451 9.36396L2.05029 7.94975L7.00004 3L11.9498 7.94975ZM21.9498 16.0503L17 21L12.0503 16.0503L13.4645 14.636L16.0008 17.172L16 4H18L18.0008 17.172L20.5356 14.636L21.9498 16.0503Z"></path></svg>

                                                    <p style={{ fontSize: '14px' }}>{data.PlanDataValue}</p>
                                                </div>
                                                <div className='col-sm-6'>
                                                    <i class="ri-calendar-2-fill" style={{ fontSize: '40px' }}></i>
                                                    <p style={{ fontSize: '14px' }}>{data.PlanDaysValue}</p>
                                                </div>
                                               

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-12  '>
                                <div className='div-set-purchase-pup'>
                                    <div className='row'>
                                        <div className='col d-flex align-item-center justify-content-between'>
                                            <span className='budle-head-purshase pt-1'><h4 style={{ fontWeight: 'bold' }}>Bundle Details </h4></span>
                                            <span style={{ display: 'flex', justifyContent: 'end', fontSize: '25px', fontWeight: '900', color: 'black' }}><i class="ri-close-line" style={{ cursor: 'pointer' }} onClick={closeModal}></i></span>
                                        </div>
                                    </div>


                                    <div>
                                        <h6 style={{ marginBottom: '6px', fontSize: '17px', fontWeight: 'bold' }}>{showPlanNameOnModal}</h6>
                                        <p style={{ fontSize: '13px', fontWeight: '550', marginBottom: '6px' }}>{`${data.VariantName}`}</p>

                                        {/* <p style={{ fontFamily: 'Muli', fontWeight: '500', color: '#737070', marginBottom: '0px' }}>{`${data.VariantName}`}</p> */}
                                        <div className="form-row">
                                            {/* <span className="col-lg-3">{data.PlanData}</span>
                                                <span className="col-lg-3">{data.PlanDays}</span> */}
                                            <p className="col-lg-3" style={{ fontSize: '12px', fontWeight: '500' }} >{showCountryNameOnModal}</p>
                                            {simTypeList && simTypeList.length > 0 && simTypeList.filter(item => item.ID == data.PlanSIMType).map(simType => (
                                                <span key={simType.ID} className="col-lg-3" style={{ fontSize: '12px', fontWeight: '500' }}><i>{simType.Value}</i></span>
                                            ))}
                                        </div>

                                        {/* <p>{`${data.PlanDataValue}, ${data.PlanDaysValue}, ${showCountryNameOnModal},`}
                                            {simTypeList && simTypeList.length > 0 && simTypeList.filter(item => item.ID == data.PlanSIMType).map(simType => (
                                                <span key={simType.ID} style={{ marginLeft: '3px' }}>{simType.Value}</span>
                                            ))}</p> */}

                                        <h5 style={{ fontWeight: 'bold' }}>Base Country</h5>
                                        <input type='text' className='purchese-plan-input-r' value={showCountryNameOnModal} disabled style={{ width: '100%', borderRadius: '20px', padding: '2px 14px', border: '1px solid #e8d9d9', marginTop: '10px' }} />
                                    </div>
                                    <form class='form-popup-purchase' onSubmit={handleSubmit(handleAddToCartSubmit)}>
                                        <div className='row '>
                                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                                <div className='row-purchase-footer'>
                                                    <div className='row my-2'>
                                                        <div className='col-lg-3 col-md-6 col-6 text-start-1'><div className='qty-pupopt' >QTY</div></div>
                                                        <div className='col-lg-5 col-md-6 col-6 text-center'><div className='incr-btn-box' style={{ borderRadius: '5px' }}>
                                                            <button onClick={(e) => handleDecrement(e)} className='increment-button'><i class="ri-subtract-line"></i></button>
                                                            <input type="number" name="quantity" {...register("quantity", {
                                                                required: true,
                                                            })} value={addToCart.quantity} style={{ width: '40px', height: '30px', textAlign: 'center', border: 'transparent' }} onChange={handleChange} onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} ></input>
                                                            <button onClick={(e) => handleIncrement(e)} className='increment-button ' ><i class="ri-add-line"></i></button>
                                                        </div></div>
                                                        <div className='col-lg-4 col-md-6 col-6 text-start-1'> <div className='total-cart-am'><p style={{ fontSize: '14px', fontWeight: 'bold', color: 'black', }} >Total - £{isNaN(addToCart.calculationPrice) ? '0.00' :`${parseFloat( addToCart.calculationPrice).toFixed(2)}`}</p></div> </div>
                                                    </div>
                                                    <div className='row my-2'>
                                                        <div className='col-lg-12 col-md-6 col-6 text-end-2 '>
                                                            <div className='button-cart-act'>
                                                                <div className='button-purshase-btn'>
                                                                    {checkCartFlag !== undefined && checkCartFlag === 1 ? <Link to="/cart" className="col-lg-12 d-flex justify-content-end pr-0" ><Button title='VIEW CART' style="minWidth:80px" buttonClass="btn btn-primary purchase-cart-b botton-height-purshasedashoard" buttonType='button' onClick={closeModal} /></Link> :
                                                                        <div className="d-flex justify-content-between" style={{ gap: '4px' }}>
                                                                            <Button title='BUY NOW' loading={buttonLoading} style="minWidth:80px" buttonClass="btn btn-primary botton-height-purshasedashoard" buttonType='button' onClick={() => handleAddToCartSubmit(1)} />
                                                                            <Button title='ADD BUNDLE' style="minWidth:80px" buttonClass="btn btn-primary botton-height-purshasedashoard" buttonType='button' onClick={() => handleAddToCartSubmit(2)} />
                                                                        </div>
                                                                    }
                                                                    {/* <div className="d-flex" style={{ gap: '3px',}}>
                                                                    <Button title='BUY NOW' style="minWidth:80px" buttonClass="btn btn-primary purchase-cart-b botton-height-purshase" buttonType='button' onClick={()=>handleAddToCartSubmit(1)}/>
                                                                    <Button title='ADD BUNDLE' style="minWidth:80px" buttonClass="btn btn-primary purchase-cart-b botton-height-purshase" buttonType='button' onClick={()=>handleAddToCartSubmit(2)}/>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                        {errors.quantity?.type === "required" && (
                                            <div className="invalid-feedback">quantity should not be blank !</div>

                                        )}
                                    </form>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </Modal>}
        </>

    )
}

export default PlanPurchase