import React, { useState, useEffect } from 'react'
import CardLayout from '../../../components/utils/CardLayout'
import SearchBar from '../../../components/utils/SearchBar'
import SideButtons from '../../../components/utils/SideButtons'
import Table from '../../../components/utils/Table'
import userAction from '../../../redux/user/actions'
import { useDispatch, useSelector } from 'react-redux';
import Tag from '../../../components/utils/Tag'
import moment from "moment";
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';
import PencilFill from 'remixicon-react/PencilFillIcon';
import Modal from '../../../components/utils/Model'
import Button from '../../../components/utils/Button'
import EyeLineIcon from 'remixicon-react/EyeLineIcon';
import EyeOffLineIcon from 'remixicon-react/EyeOffLineIcon';
import Lock2Fill from 'remixicon-react/Lock2FillIcon';
import DeleteBin5Fill from 'remixicon-react/DeleteBin5FillIcon';
import CheckboxCircleFill from 'remixicon-react/CheckboxCircleFillIcon';
import Popconfirm from '../../../components/utils/Popover';
import Alert from '../../../components/utils/Alert';
import CheckboxCircleLine from 'remixicon-react/CheckboxCircleLineIcon';
import CloseCircleLineIcon from 'remixicon-react/CloseCircleLineIcon';
import { PASSWORD_VALIDATE } from '../../../helpers/constant'

const User = () => {
  const { getUser, addUser, editUser, deleteUser, userPasswordReset, resetUser, prepareUserForm, preparePasswordForm, getUserRole, resetUserMessage } = userAction;
  const dispatch = useDispatch();
  const token = localStorage.getItem('token');
  const userFormModal = useSelector((state) => state.user.userFormModal);
  const userPasswordModal = useSelector((state) => state.user.userPasswordModal);
  const userResult = useSelector((state) => state.user.userResult);
  const successMessage = useSelector((state) => state.user.userMessage);
  const errorMessage = useSelector((state) => state.user.userError);
  const loading = useSelector((state) => state.user.loading);
  const buttonLoading = useSelector((state) => state.user.buttonLoading);
  const [filterText, setFilterText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [formTitle, setFormTitle] = useState('');
  const [formButton, setFormButton] = useState('');
  const [editFlag, setEditFlag] = useState(false);
  const [editID, seteditID] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertText, setAlertText] = useState('');
  const [userData, setuserData] = useState({
    name: '',
    username: '',
    password: '',
    roleId: null
  })

  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const [passwordData, setPasswordData] = useState({
    newPassword: "",
    userId: null
  })
  const dateFormat = process.env.REACT_APP_DATE_FORMAT;


  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }, reset
  } = useForm({ defaultValues: userData });

  const {
    register: registerReset,
    handleSubmit: handleSubmitReset,
    watch: watchReset,
    formState: { errors: errorsReset }, reset: resetReset
  } = useForm({ defaultValues: passwordData });

  const password = watch('password', '');
  const newPassword = watchReset('newPassword', '');
  const confirmPassword = watch('confirmPassword', '');

  const columns = [{ displayName: '#', key: 'key' },
  { displayName: 'NAME', key: 'name' },
  { displayName: 'USERNAME', key: 'username' },
  { displayName: 'USER ROLE', key: 'userRole' },
  { displayName: 'CREATE DATE', key: 'createDate' },
  { displayName: 'STATUS', key: 'status' },
  { displayName: 'ACTION', key: 'action' },]

  useEffect(() => {
    if (errorMessage !== null && errorMessage !== undefined) {
      displayAlert(errorMessage, 'alert-danger');
    }
    if (successMessage !== null && successMessage !== undefined) {
      displayAlert(successMessage, 'alert-success');
    }
  }, [errorMessage, successMessage]);

  useEffect(() => {
    dispatch(getUser());
  }, [])

  useEffect(() => {
    reset(userData);
    resetReset(passwordData);
  }, [userData, passwordData]);

  const handleCancel = () => {
    dispatch(resetUser());
    setPasswordShow(false)
    setConfirmPasswordShow(false)
    setEditFlag(false)
    reset();
    resetReset();
    setuserData(prevState => ({
      ...prevState,
      name: "",
      username: "",
      roleId: ""
    }));

    setPasswordData(prevState => ({
      ...prevState,
      newPassword: "",
      confirmNewPassword: "",
      userId: null
    }));
  };
  const onSubmit = (data) => {
    const newData = {
      ...data,
      roleId: 29
    }
    dispatch(addUser(newData));

  };

  const handleOpenAddFormModal = () => {
    reset();
    setConfirmPasswordShow(false);
    setPasswordShow(false);
    dispatch(prepareUserForm());
    setFormTitle('Add User');
    setFormButton('Submit');
    setEditFlag(false);
  }

  const filteredItems = userResult && userResult.length > 0 && userResult.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );


  const displayAlert = (text, type) => {
    setAlertText(text);
    setAlertType(type);
    setShowAlert(true);
    dispatch(resetUserMessage());
  };

  const alertCancel = () => {
    setShowAlert(false);
    setAlertText('');
    setAlertType('');
    dispatch(resetUserMessage());
  }

  var dataSource = filteredItems && filteredItems.length > 0 && filteredItems.map((user, key) => {
    return {
      key: key + 1,
      name: user.Name,
      username: user.Username,
      userRole: user.UserRole,
      createDate: moment(new Date(user.CreatedDtTm)).format(dateFormat),
      status: <Tag color={`${user.IsActive === 1 ? "badge-success" : "badge-danger"}`} title={user.IsActive === 1 ? 'Active' : 'Inactive'} />,
      action: ((token !== user.Token) &&
        <>
          <Link to="#">
          <span data-toggle="tooltip" data-placement="top" title="Edit"><PencilFill size="1.2em" style={{ color: "#888b8e", marginRight: "3px" }} onClick={() => handleOpenEditFormModal(user.ID)} /></span>
          </Link>
          {user.IsActive === 1 ? (
            <Popconfirm
              title="Are you sure to inactive this user?"
              okText="Yes"
              cancelText="No"
              placement="topRight"
              onConfirm={() => handleDelete(user.ID, 0)}
            >
              <Link className="deleteBtn" to="# ">
              <span data-toggle="tooltip" data-placement="top" title="Inactive"><DeleteBin5Fill size="1.2em" style={{ color: "#f14336", marginRight: "3px" }} /></span>
              </Link>
            </Popconfirm>
          ) : (
            <Popconfirm
              title="Are you sure to active this user?"
              okText="Yes"
              cancelText="No"
              placement="topRight"
              onConfirm={() => handleDelete(user.ID, 1)}
            >
              <Link className="deleteBtn" to="# ">
              <span data-toggle="tooltip" data-placement="top" title="Active"><CheckboxCircleFill size="1.2em" style={{ color: "#2cb549", marginRight: "3px" }} /></span>
              </Link>
            </Popconfirm>
          )}

          <Link to="#" onClick={() => handleOpenPasswordFormModal(user.ID)} >
          <span data-toggle="tooltip" data-placement="top" title="Reset password"><Lock2Fill size="1.2em" style={{ color: "#0094b3" }} /></span>
          </Link>
        </>)
    }
  });

  const handleDelete = (ID, flag) => {
    dispatch(deleteUser(ID, flag));
  }

  const handleUpdate = (data) => {
    const newdata = {
      name: data.name
    }
    dispatch(editUser(editID, newdata))
  }
  const handleOpenPasswordFormModal = (userId) => {
    setFormButton("Reset Password")
    dispatch(preparePasswordForm());
    setConfirmPasswordShow(false);
    setPasswordShow(false);
    setPasswordData(prevState => ({
      ...prevState,
      newPassword: "",
      confirmNewPassword: "",
      userId: userId
    }));
  }

  const handleOpenEditFormModal = (userId) => {
    const data = {
      tableName: "user",
      fieldName: "RoleID"
    }
    dispatch(prepareUserForm());
    setFormTitle('Edit User');
    setFormButton('Update');
    setEditFlag(true);
    seteditID(userId);
    var selectedUser = userResult && userResult.filter(user => user.ID === userId);
    setuserData(prevState => ({
      ...prevState,
      name: selectedUser[0].Name,
      username: selectedUser[0].Username,
      roleId: selectedUser[0].RoleID
    }));
  }

  const handleFilterChange = (value) => {
    setFilterText(value);
  };

  const handleResetPassword = (data) => {
    dispatch(userPasswordReset(data))
  };


  return (
    <CardLayout title="Users">
      {showAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}
      <div className="row">
        <div className='col-lg-6 col-md-6 od-2'>
          <SearchBar onFilter={handleFilterChange} filterText={filterText} /></div>
        <div className='col-lg-6 col-md-6 text-right'>
          <SideButtons title="Add User" buttonStyle="primary" onClick={handleOpenAddFormModal} /></div></div>


      <Table columns={columns} loading={loading} data={dataSource} pagination={true} />
      <Modal
        showModal={userFormModal}
        title={formTitle}
        onHide={handleCancel}
        width={500}
        showFooter={false}
      >
        <form onSubmit={handleSubmit(editFlag ? handleUpdate : onSubmit)}>
          <div className="form-row">
            <div className="col-md-12 mb-3">
              <label>Name</label>
              <input type="text" placeholder="Enter name" class="form-control" name="name" {...register("name", {
                required: true,
              })} />
              {errors.name?.type === "required" && (
                <div class="invalid-feedback">Name is required !</div>

              )}
            </div>
            <div className="col-md-12 mb-3">
              <label>Username</label>
              <input disabled={editFlag} type="text" class="form-control" placeholder="Enter username" name="username" {...register("username", {
                required: true,
              })} />
              {errors.username?.type === "required" && (
                <div class="invalid-feedback">Username is required !</div>
              )}
            </div>

            {!editFlag && <div className="col-lg-12">
              <div className="form-group mb-3" style={{ position: 'relative' }}>
                <label htmlFor="password">Password</label>
                <input type={passwordShow ? 'text' : 'password'} className="form-control" name='password' {...register('password', {
                  required: true,
                  // minLength: 4,
                  validate: (value) => {
                    return PASSWORD_VALIDATE.test(value)
                  }
                })} placeholder="Password" />

                <div style={Style.iconContainer}>
                  {
                    passwordShow ? <EyeLineIcon size="1.1em" style={{ cursor: 'pointer' }} onClick={() => setPasswordShow((pre) => !pre)} /> : <EyeOffLineIcon size="1.1em" style={{ cursor: 'pointer' }} onClick={() => setPasswordShow((pre) => !pre)} />
                  }
                  {errors.password ? <CloseCircleLineIcon size="1.1em" color='#e74c3c' /> : ''}
                  {password !== '' && !errors.password ? <CheckboxCircleLine size="1.1em" color='#00b894' /> : ''}
                </div>

                {errors.password && errors.password.type === "required" != '' ? <div className="invalid-feedback">Password is required !</div> : ''}
                {/* {errors.password && errors.password.type === "minLength" != '' ? <div className="invalid-feedback">Password should contain at least 4 characters !</div> : ''} */}
                {errors.password && errors.password.type === "validate" != '' ? <div className="invalid-feedback">Invalid password format !</div> : ''}
              </div>
            </div>}

            {!editFlag && <div className="col-lg-12">
              <div className="form-group mb-4" style={{ position: 'relative' }}>
                <label htmlFor="confirmPassword">Confirm Password</label>
                <input type={confirmPasswordShow ? 'text' : 'password'} className="form-control" name='confirmPassword' {...register('confirmPassword', {
                  required: true,
                  minLength: 4,
                  validate: (value) => {
                    return value === password;
                  }
                })} placeholder="Confirm password" />
                <div style={Style.iconContainer}>
                  {
                    confirmPasswordShow ? <EyeLineIcon size="1.1em" style={{ cursor: 'pointer' }} onClick={() => setConfirmPasswordShow((pre) => !pre)} /> : <EyeOffLineIcon size="1.1em" style={{ cursor: 'pointer' }} onClick={() => setConfirmPasswordShow((pre) => !pre)} />
                  }
                  {errors.confirmPassword ? <CloseCircleLineIcon size="1.1em" color='#e74c3c' /> : ''}
                  {confirmPassword !== '' && !errors.confirmPassword ? <CheckboxCircleLine size="1.1em" color='#00b894' /> : ''}
                </div>

                {errors.confirmPassword && errors.confirmPassword.type === "required" != '' ? <div className="invalid-feedback">Confirm password is required !</div> : ''}
                {errors.confirmPassword && errors.confirmPassword.type === "minLength" != '' ? <div className="invalid-feedback">Confirm password should contain at least 4 characters !</div> : ''}
                {errors.confirmPassword && errors.confirmPassword.type === "validate" != '' ? <div className="invalid-feedback">Password and confirm password does not match !</div> : ''}
              </div>
            </div>}

          </div>
          <div className='text-right row'>
            <div className="col-md-12">
              <button type="button" class="btn btn-secondary mr-2" onClick={handleCancel}>Close</button>
              <Button title={formButton} style="minWidth:80px" loading={buttonLoading} buttonClass="btn btn-primary" buttonType='submit' />
            </div>
          </div>
        </form>
      </Modal>

      <Modal
        showModal={userPasswordModal}
        title="Reset Password"
        onHide={handleCancel}
        width={500}

        showFooter={false}
      >
        <form onSubmit={handleSubmitReset(handleResetPassword)}>
          <div className="form-row">
            <div className="col-lg-12">
              <div className="form-group mb-3" style={{ position: 'relative' }}>
                <label htmlFor=" new password">New Password</label>
                <input type={passwordShow ? 'text' : 'password'} className="form-control" name='newPassword' {...registerReset('newPassword', {
                  required: true,
                  minLength: 4
                })} placeholder="New password" />
                {
                  passwordShow ? <EyeLineIcon size="1.1em" style={Style.eyeIcon} onClick={() => setPasswordShow((pre) => !pre)} /> : <EyeOffLineIcon size="1.1em" style={Style.eyeIcon} onClick={() => setPasswordShow((pre) => !pre)} />
                }
                {errorsReset.newPassword && errorsReset.newPassword.type === "required" != '' ? <div className="invalid-feedback">Please input new password !</div> : ''}
                {errorsReset.newPassword && errorsReset.newPassword.type === "minLength" != '' ? <div className="invalid-feedback">New password should contain at least 4 characters !</div> : ''}
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-group mb-4" style={{ position: 'relative' }}>
                <label htmlFor="confirmnewPassword">Confirm New Password</label>
                <input type={confirmPasswordShow ? 'text' : 'password'} className="form-control" name='confirmNewPassword' {...registerReset('confirmNewPassword', {
                  required: true,
                  minLength: 4,
                  validate: (value) => {
                    return value === newPassword;
                  }
                })} placeholder="Confirm new password" />
                {
                  confirmPasswordShow ? <EyeLineIcon size="1.1em" style={Style.eyeIcon} onClick={() => setConfirmPasswordShow((pre) => !pre)} /> : <EyeOffLineIcon size="1.1em" style={Style.eyeIcon} onClick={() => setConfirmPasswordShow((pre) => !pre)} />
                }
                {errorsReset.confirmNewPassword && errorsReset.confirmNewPassword.type === "required" != '' ? <div className="invalid-feedback"> Please input confirm new password !</div> : ''}
                {errorsReset.confirmNewPassword && errorsReset.confirmNewPassword.type === "minLength" != '' ? <div className="invalid-feedback">Confirm new password should contain at least 4 characters !</div> : ''}
                {errorsReset.confirmNewPassword && errorsReset.confirmNewPassword.type === "validate" != '' ? <div className="invalid-feedback"> New password and confirm new password does not match !</div> : ''}
              </div>
            </div>
          </div>

          <div className='text-right row'>
            <div className="col-md-12">
              <button type="button" className="btn btn-secondary mr-2" onClick={handleCancel}>Close</button>
              <Button title={formButton} style="minWidth:80px" loading={buttonLoading} buttonClass="btn btn-primary" buttonType='submit' />
            </div>
          </div>
        </form>
      </Modal>
    </CardLayout>
  )
}
const Style = {
  eyeIcon: {
    position: 'absolute',
    right: '10px',
    top: '40px',
    cursor: 'pointer'
  },
  iconContainer: {
    position: 'absolute',
    top: '35px',
    right: '10px'
  }
}
export default User
