import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import CheckboxCircleLine from 'remixicon-react/CheckboxCircleLineIcon';
import OwlCarousel from 'react-owl-carousel';
import { useForm, Controller } from 'react-hook-form';
import EyeLineIcon from 'remixicon-react/EyeLineIcon';
import EyeOffLineIcon from 'remixicon-react/EyeOffLineIcon';
import PhoneInput from 'react-phone-number-input';
import CloseCircleLineIcon from 'remixicon-react/CloseCircleLineIcon';
import AlertLineIcon from 'remixicon-react/AlertLineIcon';
import 'react-phone-number-input/style.css'
import sideImage from '../assets/images/slide-1.png';
import sideImagetwo from '../assets/images/slide-2.png';
import sideImagethree from '../assets/images/slide-3.png';
import AuthActions from '../redux/auth/actions';
import Alert from '../components/utils/Alert';
import Button from '../components/utils/Button';
import Modal from '../components/utils/Model';
import { PASSWORD_VALIDATE } from '../helpers/constant';
import { checkStringContainRegex } from '../helpers/commonFunction';
const { signUp, authReset, closeSignUpModal } = AuthActions;

export const Signup = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const Auth = useSelector((state) => state.auth);
    const { register, handleSubmit, watch, formState: { errors }, control } = useForm();

    const password = watch('password', '');
    const confirmPassword = watch('confirmPassword', '');
    const [passwordShow, setPasswordShow] = useState(false);
    const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);

    if (Auth.signUpMessage) {
        setTimeout(() => {
            dispatch(authReset());
        }, 1500);
    }

    const handleCancel = () => {
        dispatch(closeSignUpModal())
        navigate('/sign-in');
    }

    const handleSignUp = (data) => {
        // console.log(data);
        dispatch(signUp(data));
    }

    if (Auth.signUpError) {
        setTimeout(() => {
            dispatch(authReset());
        }, 1500);
    }

    return (
        <>
            <Modal
                showModal={Auth.signUpModal}
                title="Success"
                onHide={handleCancel}
                onSaveChanges={handleCancel}
                showFooter={false}
                alignment='modal-dialog-centered'
            >
                <div style={Style.modalContent}>
                    <CheckboxCircleLine size="4em" color='#00b894' />
                    <p className='text-dark'>Register success please wait until admin activate your account</p>
                </div>

            </Modal>

            <section className="sign-in-page mt-lg-5 mt-md-5 ">
                <div className="container bg-white  p-0">
                    <div className="row no-gutters">
                        <div className="col-md-6 align-self-center">
                            <div className="sign-in-from my-4">
                                <a className="sign-in-logo mb-2 text-center d-lg-none d-md-none d-sm-block" href="#"><img src="./assets/images/logo-full2.png" className="img-fluid" alt="logo" /></a>
                                <h1 className="mb-0">Sign Up</h1>
                                <p>Enter your details for register as dealer with us.</p>

                                {Auth.signUpError ? <Alert type="alert-danger" text={Auth.signUpError} /> : ''}

                                <form className="mt-4" onSubmit={handleSubmit(handleSignUp)}>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group mb-2">
                                                <label htmlFor="fullName">Full name</label>
                                                <input type="text" className="form-control" name='dealerName' {...register('dealerName', {
                                                    required: true
                                                })} placeholder="Full name" />
                                                {errors.dealerName && errors.dealerName.type === "required" != '' ? <div className="invalid-feedback">Full name is required !</div> : ''}
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group mb-2">
                                                <label htmlFor="email">Email</label>
                                                <input type="text" className="form-control" name='contactEmailID' {...register('contactEmailID', {
                                                    required: true,
                                                    pattern: /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/
                                                })} placeholder="Email" />
                                                {errors.contactEmailID && errors.contactEmailID.type === "required" != '' ? <div className="invalid-feedback">Email is required !</div> : ''}
                                                {errors.contactEmailID && errors.contactEmailID.type === "pattern" != '' ? <div className="invalid-feedback">Email is not valid !</div> : ''}
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group mb-2">
                                                <label htmlFor="companyName">Company name</label>
                                                <input type="text" className="form-control" name='contactPerson' {...register('contactPerson', {
                                                    required: true
                                                })} placeholder="Company name" />
                                                {errors.contactPerson && errors.contactPerson.type === "required" != '' ? <div className="invalid-feedback">Company name is required</div> : ''}
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group mb-2">
                                                <label htmlFor="contactNumber">Contact number</label>
                                                <Controller
                                                    name="contactNumber"
                                                    control={control}
                                                    rules={{
                                                        required: true,
                                                        minLength: 10,
                                                    }}
                                                    render={({ field: { onChange, value } }) => (
                                                        <PhoneInput
                                                            value={value}
                                                            onChange={onChange}
                                                            defaultCountry="US"
                                                            international
                                                            className="form-control"
                                                            countryCallingCodeEditable={false}
                                                            id="contactNumber"
                                                            placeholder="Contact number"
                                                        />
                                                    )}
                                                />
                                                {errors.contactNumber?.type === "required" && (
                                                    <div className="invalid-feedback">Contact number is required !</div>
                                                )}
                                                {errors.contactNumber?.type === "minLength" && (
                                                    <div className="invalid-feedback">Contact number is invalid !</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group mb-2">
                                                <label htmlFor="username">Username</label>
                                                <input type="text" className="form-control" name='username' {...register('username', {
                                                    required: true
                                                })} placeholder="Username" />
                                                {errors.username && errors.username.type === "required" != '' ? <div className="invalid-feedback">Username is required !</div> : ''}
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group mb-3" style={{ position: 'relative' }}>
                                                <label htmlFor="password">Password</label>
                                                <input type={passwordShow ? 'text' : 'password'} className="form-control" name='password' {...register('password', {
                                                    required: true,
                                                    // minLength: 4,
                                                    validate: (value) => {
                                                        return PASSWORD_VALIDATE.test(value)
                                                    }
                                                })} placeholder="Password" />
                                                <div style={Style.iconContainer}>
                                                    {
                                                        passwordShow ? <EyeLineIcon size="1.1em" style={{ cursor: 'pointer' }} onClick={() => setPasswordShow((pre) => !pre)} /> : <EyeOffLineIcon size="1.1em" style={{ cursor: 'pointer' }} onClick={() => setPasswordShow((pre) => !pre)} />
                                                    }
                                                    {errors.password ? <CloseCircleLineIcon size="1.1em" color='#e74c3c' /> : ''}
                                                    {password !== '' && !errors.password ? <CheckboxCircleLine size="1.1em" color='#00b894' /> : ''}
                                                </div>
                                                {errors.password && errors.password.type === "required" != '' ? <div className="invalid-feedback">Password is required !</div> : ''}
                                                {/* {errors.password && errors.password.type === "minLength" != '' ? <div className="invalid-feedback">Password should contain at least 4 characters !</div> : ''} */}
                                                {errors.password && errors.password.type === "validate" != '' ? <div className="invalid-feedback">Invalid password format !</div> : ''}

                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group mb-3" style={{ position: 'relative' }}>
                                                <label htmlFor="confirmPassword">Confirm Password</label>
                                                <input type={confirmPasswordShow ? 'text' : 'password'} className="form-control" name='confirmPassword' {...register('confirmPassword', {
                                                    required: true,
                                                    minLength: 4,
                                                    validate: (value) => {
                                                        return value === password;
                                                    }
                                                })} placeholder="Confirm Password" />

                                                <div style={Style.iconContainer}>
                                                    {
                                                        confirmPasswordShow ? <EyeLineIcon size="1.1em" style={{ cursor: 'pointer' }} onClick={() => setConfirmPasswordShow((pre) => !pre)} /> : <EyeOffLineIcon size="1.1em" style={{ cursor: 'pointer' }} onClick={() => setConfirmPasswordShow((pre) => !pre)} />
                                                    }
                                                    {errors.confirmPassword ? <CloseCircleLineIcon size="1.1em" color='#e74c3c' /> : ''}
                                                    {confirmPassword !== '' && !errors.confirmPassword ? <CheckboxCircleLine size="1.1em" color='#00b894' /> : ''}
                                                </div>

                                                {errors.confirmPassword && errors.confirmPassword.type === "required" != '' ? <div className="invalid-feedback">Confirm password is required !</div> : ''}
                                                {errors.confirmPassword && errors.confirmPassword.type === "minLength" != '' ? <div className="invalid-feedback">Confirm password should contain at least 4 characters !</div> : ''}
                                                {errors.confirmPassword && errors.confirmPassword.type === "validate" != '' ? <div className="invalid-feedback">Confirm password not match !</div> : ''}
                                            </div>
                                        </div>

                                        {
                                            password != '' ?
                                                <>
                                                    <div className='col-lg-6'>
                                                        <ul className="list-unstyled">
                                                            <li className="">
                                                                <span className="low-upper-case">
                                                                    {checkStringContainRegex('^(?=.*[a-z])(?=.*[A-Z]).+$', password) ? <CheckboxCircleLine color='#00b894' size={16} /> : <AlertLineIcon size={16} />}
                                                                    &nbsp;Lowercase &amp; Uppercase
                                                                </span>
                                                            </li>
                                                            <li className="">
                                                                <span className="one-number">
                                                                    {checkStringContainRegex('.*[0-9].*', password) ? <CheckboxCircleLine color='#00b894' size={16} /> : <AlertLineIcon size={16} />}
                                                                    &nbsp;Number (0-9)
                                                                </span>
                                                            </li>
                                                            <li className="text-nowrap">
                                                                <span className="one-special-char">
                                                                    {checkStringContainRegex('.*[^a-zA-Z0-9\s].*', password) ? <CheckboxCircleLine color='#00b894' size={16} /> : <AlertLineIcon size={16} />}
                                                                    &nbsp;Special Character (!@#$%^&*)
                                                                </span>
                                                            </li>
                                                            <li className="">
                                                                <span className="eight-character">
                                                                    {checkStringContainRegex('^.{8,}$', password) ? <CheckboxCircleLine color='#00b894' size={16} /> : <AlertLineIcon size={16} />}
                                                                    &nbsp;Atleast 8 Character
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </> : ''
                                        }



                                    </div>

                                    <div className="d-inline-block w-100">
                                        <Button title='Sign up' buttonClass="btn btn-primary w-100" loading={Auth.signUpLoading} buttonType='submit' />
                                    </div>

                                    <div className="sign-info ">
                                        <span className="dark-color d-inline-block line-height-2">Already have an account ? <Link to="/sign-in">Sign in</Link></span>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-6 text-center d-none d-lg-block d-md-block d-sm-none">
                            <div className="sign-in-detail text-white">
                                <a className="sign-in-logo mb-5" href="#"><img src="./assets/images/logo-white.png" className="img-fluid" alt="logo" /></a>

                                <OwlCarousel items={1}>
                                    <div className="item">
                                        <img src={sideImage} className="img-fluid mb-4" alt="logo" />

                                    </div>
                                    <div className="item">
                                        <img src={sideImagetwo} className="img-fluid mb-4" alt="logo" />

                                    </div>
                                    <div className="item">
                                        <img src={sideImagethree} className="img-fluid mb-4" alt="logo" />

                                    </div>
                                </OwlCarousel>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Sign in END --> */}
        </>
    )
}

const Style = {
    eyeIcon: {
        position: 'absolute',
        right: '10px',
        top: '40px',
        cursor: 'pointer'
    },
    modalContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: 'center'
    },
    iconContainer: {
        position: 'absolute',
        top: '35px',
        right: '10px'
    }
}

export default Signup;