const actions = {
    CHECK_CART_DATA: "CHECK_CART_DATA",
    CHECK_CART_DATA_SUCCESSFUL: "CHECK_CART_DATA_SUCCESSFUL",
    CHECK_CART_DATA_FAILED: "CHECK_CART_DATA_FAILED",
    ADD_PLAN_TO_CART: "ADD_PLAN_TO_CART",
    ADD_PLAN_TO_CART_SUCCESSFUL: "ADD_PLAN_TO_CART_SUCCESSFUL",
    ADD_PLAN_TO_CART_FAILED: "ADD_PLAN_TO_CART_FAILED",
    VIEW_CART_DATA: 'VIEW_CART_DATA',
    VIEW_CART_DATA_SUCCESSFUL: 'VIEW_CART_DATA_SUCCESSFUL',
    VIEW_CART_DATA_FAILED: 'VIEW_CART_DATA_FAILED',
    DELETE_CART: "DELETE_CART",
    DELETE_CART_SUCCESSFUL: "DELETE_CART_SUCCESSFUL",
    DELETE_CART_FAILED: "DELETE_CART_FAILED",
    PREPARE_VIEW_CART_VIEW: "PREPARE_VIEW_CART_VIEW",
    PREPARE_VIEW_PLAN_PURCHASE: "PREPARE_VIEW_PLAN_PURCHASE",
    PREPARE_VIEW_PLAN_MODAL: "PREPARE_VIEW_PLAN_MODAL",
    RESET_VIEW_PLAN_MODAL: "RESET_VIEW_PLAN_MODAL",
    RESET_WHOLE_PAGE: "RESET_WHOLE_PAGE",
    PREPARE_VIEW_PURCHASE_INVOICE: "PREPARE_VIEW_PURCHASE_INVOICE",
    RESET_VIEW_PURCHASE_INVOICE: "RESET_VIEW_PURCHASE_INVOICE",
    UPDATE_CART_QUANTITY: "UPDATE_CART_QUANTITY",
    UPDATE_CART_QUANTITY_SUCCESSFUL: "UPDATE_CART_QUANTITY_SUCCESSFUL",
    PURCHASE_PLAN_PAYMENT: "PURCHASE_PLAN_PAYMENT",
    PURCHASE_PLAN_PAYMENT_SUCCESS: "PURCHASE_PLAN_PAYMENT_SUCCESS",
    PURCHASE_PLAN_PAYMENT_FAILED: "PURCHASE_PLAN_PAYMENT_FAILED",

    checkCartData: (planId) => ({
        type: actions.CHECK_CART_DATA,
        payload: { planId }
    }),
    checkCartDataSuccessful: (checkCartFlag) => ({
        type: actions.CHECK_CART_DATA_SUCCESSFUL,
        checkCartFlag
    }),
    checkCartDataFailed: (cartError) => ({
        type: actions.CHECK_CART_DATA_FAILED,
        cartError
    }),
    addPlanToCart: (data) => ({
        type: actions.ADD_PLAN_TO_CART,
        payload: { data }
    }),
    addPlanToCartSuccessful: (cartMessage) => ({
        type: actions.ADD_PLAN_TO_CART_SUCCESSFUL,
        cartMessage
    }),
    addPlanToCartFailed: (cartError) => ({
        type: actions.ADD_PLAN_TO_CART_FAILED,
        cartError
    }),
    viewCartData: () => ({
        type: actions.VIEW_CART_DATA,
    }),
    viewCartDataSuccessful: (cartResult) => ({
        type: actions.VIEW_CART_DATA_SUCCESSFUL,
        cartResult
    }),
    viewCartDataFailed: (cartError) => ({
        type: actions.VIEW_CART_DATA_FAILED,
        cartError
    }),
    deleteCart: (cartId) => ({
        type: actions.DELETE_CART,
        payload: { cartId }
    }),
    deleteCartSuccessful: (cartMessage, cartResult) => ({
        type: actions.DELETE_CART_SUCCESSFUL,
        cartMessage, cartResult
    }),
    deleteCartFailed: (cartError) => ({
        type: actions.DELETE_CART_FAILED,
        cartError
    }),
    prepareViewPlanModal: () => ({
        type: actions.PREPARE_VIEW_PLAN_MODAL
    }),
    resetViewPlanModal: () => ({
        type: actions.RESET_VIEW_PLAN_MODAL
    }),
    resetWholePage: () => ({
        type: actions.RESET_WHOLE_PAGE
    }),
    prepareViewPurchaseInvoice: () => ({
        type: actions.PREPARE_VIEW_PURCHASE_INVOICE
    }),
    resetViewPurchaseInvoice: () => ({
        type: actions.RESET_VIEW_PURCHASE_INVOICE
    }),
    updateCartQuantity: (data) => ({
        type: actions.UPDATE_CART_QUANTITY,
        payload: { data }
    }),
    updateCartQuantitySuccessful: (message, cartResult) => ({
        type: actions.UPDATE_CART_QUANTITY_SUCCESSFUL,
        message, cartResult
    }),
    purchasePlanPayment: (provideCustomerEmail) => ({
        type: actions.PURCHASE_PLAN_PAYMENT,
        payload: { provideCustomerEmail }
    }),
    purchasePlanPaymentSuccess: (paymentSuccessMessage) => ({
        type: actions.PURCHASE_PLAN_PAYMENT_SUCCESS,
        paymentSuccessMessage
    }),
    purchasePlanPaymentFailed: (paymentErrorMessage) => ({
        type: actions.PURCHASE_PLAN_PAYMENT_FAILED,
        paymentErrorMessage
    }),
}

export default actions;