const actions = {
    PREPARE_COUPON_FORM: "PREPARE_COUPON_FORM",
    RESET_COUPON_FORM: "RESET_COUPON_FORM",
    ADD_COUPON: "ADD_COUPON",
    ADD_COUPON_SUCCESS: "ADD_COUPON_SUCCESS",
    ADD_COUPON_FAILED: "ADD_COUPON_FAILED",
    GET_COUPON: "GET_COUPON",
    GET_COUPON_SUCCESS: "GET_COUPON_SUCCESS",
    GET_COUPON_FAILED: "GET_COUPON_FAILED",
    RESET_COUPON: "RESET_COUPON",
    DELETE_COUPON: "DELETE_COUPON",
    DELETE_COUPON_SUCCESS: "DELETE_COUPON_SUCCESS",
    DELETE_COUPON_FAILED:"DELETE_COUPON_FAILED",
    EDIT_COUPON: "EDIT_COUPON",
    EDIT_COUPON_SUCCESS: "EDIT_COUPON_SUCCESS",
    EDIT_COUPON_FAILED:"EDIT_COUPON_FAILED",
    

    prepareCouponForm: () => ({
        type: actions.PREPARE_COUPON_FORM
    }),
    resetCouponForm: () => ({
        type: actions.RESET_COUPON_FORM
    }),
    addCoupon: (data) => ({
        type: actions.ADD_COUPON,
        payload: { data }
    }),
    addCouponSuccess: (regionMessage, regionResult) => ({
        type: actions.ADD_COUPON_SUCCESS,
        regionMessage, regionResult
    }),
    addCouponFailed: (error) => ({
        type: actions.ADD_COUPON_FAILED,
        error
    }),
    getCoupon: () => ({
        type: actions.GET_COUPON,
    }),
    getCouponSuccess: (data) => ({
        type: actions.GET_COUPON_SUCCESS,
        data
    }),
    getCouponFailed: (error) => ({
        type: actions.GET_COUPON_FAILED,
        error
    }),
    resetCoupon: () => ({
        type: actions.RESET_COUPON
    }),
    deleteCoupon: (couponId, status) => ({
        type: actions.DELETE_COUPON,
        payload: { couponId, status }
    }),
    deleteCouponSuccess: (regionMessage) => ({
        type: actions.DELETE_COUPON_SUCCESS,
        payload:{regionMessage}
    }),

    deleteCouponFailed: (error) => ({
        type: actions.DELETE_COUPON_FAILED,
        payload:{error}
    }),

    editCoupon: (data) => ({
        type: actions.EDIT_COUPON,
        payload: { data }
    }),
    editCouponSuccess: (regionMessage) => ({
        type: actions.EDIT_COUPON_SUCCESS,
        regionMessage
    }),
    editCouponFailed: (error) => ({
        type: actions.EDIT_COUPON_FAILED,
        error
    }),

}
export default actions;