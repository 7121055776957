import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { getList } from '../../helpers/fackBackend_Helper';

function* getFeaturedPlanResponse() {
    try {
        const response = yield call(getList, '/getFeaturedPlan');
        if (response.response.Status) {
            yield put(actions.getFeaturedPlanSuccess(response.response.featuredPlan));
        } else {
            yield put(actions.getFeaturedPlanFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getFeaturedPlanFailed(error));
    }
}

function* getTopSellerPlanResponse() {
    try {
        const response = yield call(getList, '/getTopSellerPlan');
        if (response.response.Status) {
            yield put(actions.getTopSellerPlanSuccess(response.response.topSellerPlan));
        } else {
            yield put(actions.getTopSellerPlanFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getTopSellerPlanFailed(error));
    }
}

function* getDashboardCountResponse() {
    try {
        const response = yield call(getList, '/getDashboardCount');
        if (response.Status) {
            yield put(actions.getDashboardCountSuccess(response));
        } else {
            yield put(actions.getDashboardCountFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.getDashboardCountFailed(error));
    }
}

function* getSalesAndDelaerCountResponse() {
    try {
        const response = yield call(getList, '/salesAndDealerReport');
        if (response.Status) {
            yield put(actions.getSalesAndDealerCountSuccess(response.Data));
        } else {
            yield put(actions.getSalesAndDealerCountFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.getSalesAndDealerCountFailed(error));
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.GET_FEATURED_PLAN, getFeaturedPlanResponse),
        takeEvery(actions.GET_TOP_SELLER_PLAN, getTopSellerPlanResponse),
        takeEvery(actions.GET_DASHBOARD_COUNT, getDashboardCountResponse),
        takeEvery(actions.GET_SALES_AND_DEALER_COUNT, getSalesAndDelaerCountResponse),
    ]);

}
