import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from './Navbar';
// import Notification from '../utils/Notification';
// import SearchBar from '../utils/SearchBar';
import Profile from '../utils/Profile';
import HeaderLogo from '../utils/HeaderLogo';
import AuthActions from '../../redux/auth/actions'
import CartDropdown from '../../pages/Cart/CartDropdown';
import { isDealer } from '../../helpers/commonFunction';
import { useNavigate } from 'react-router-dom';

const { getUserDetails } = AuthActions;
function Header(props) {
    const dispatch = useDispatch();
    const tokenData = useSelector((state) => state.auth.tokenData);
    const getUserDetailsError = useSelector((state) => state.auth.getUserDetailsError);
    const { dealerError } = useSelector((state) => state.dealer);
    const { tariffError } = useSelector((state) => state.tariffGroup);
    const { userError } = useSelector((state) => state.user);
    const { verifyDealerError } = useSelector((state) => state.verifyDealer);
    const { profileError } = useSelector((state) => state.profile);
    const { orderReportListError } = useSelector((state) => state.orderReport);
    const { cartError } = useSelector((state) => state.cart);
    const { getOrderByIccIdError } = useSelector((state) => state.orderRefund);
    const { error: refundRequestError } = useSelector((state) => state.refundRequest);
    const { Error: addToFundError } = useSelector((state) => state.addToFund);
    const { dashboardError } = useSelector((state) => state.dashboard);
    const { error: activityError } = useSelector((state) => state.activity);
    const { error: auditReportError } = useSelector((state) => state.auditReport);

    const navigate = useNavigate();
    const ErrorStates = [
        getUserDetailsError,
        dealerError,
        tariffError,
        userError,
        verifyDealerError,
        profileError,
        orderReportListError,
        cartError,
        refundRequestError,
        addToFundError,
        dashboardError,
        activityError,
        // getOrderByIccIdError,
        auditReportError,
    ];

    // const redirectToDashboard = () => {
    //     navigate('#');
    // };
    useEffect(() => {
        dispatch(getUserDetails());
    }, []);

    useEffect(() => {
        if (ErrorStates.some(item => item)) {
            localStorage.removeItem("token");
            window.location.href = '/sign-in';
        }
    }, ErrorStates);

    return (
        <>
            {/* // <!-- loader Start --> */}
            {/* <div id="loading">
                <div id="loading-center">
                </div>
            </div> */}
            {/* //   <!-- loader END --> */}

            {/* <!-- TOP Nav Bar --> */}
            <div className="iq-top-navbar">
                <div className="iq-navbar-custom d-flex align-items-center justify-content-between">
                    
                        <HeaderLogo />
                    
                    <Navbar />
                    <nav className="navbar navbar-expand-lg navbar-light p-0">

                      

                        {
                            isDealer(tokenData) ?

                                <ul className="navbar-nav ml-auto navbar-list">

                                    <li className="nav-item dropdown">
                                        <CartDropdown />
                                    </li>
                                </ul>
                                : ''
                        }
                        {/* <Notification /> */}

                        <Profile />
                    </nav>
                </div>
            </div>
            {/* <!-- TOP Nav Bar END --> */}
        </>
    )
}

export default Header;