
import React, { useEffect, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import PhoneInput from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from "react-hook-form";
import CardLayout from '../../../components/utils/CardLayout'
import SearchBar from '../../../components/utils/SearchBar';
import Table from '../../../components/utils/Table';
import verifyDealerAction from '../../../redux/verifyDealer/actions'
import Alert from '../../../components/utils/Alert';
import Tag from '../../../components/utils/Tag';
import Button from '../../../components/utils/Button'
import Modal from '../../../components/utils/Model';
import countryAction from '../../../redux/country/actions'
import tariffGroupAction from '../../../redux/tariffGroup/actions'

function VerifyDealer() {
    const dispatch = useDispatch();
    const { getCountry } = countryAction;
    const { getTariff } = tariffGroupAction;
    const { getNonVerifyDealer, toVerifyDealer, prepareVerifyDealerForm, resetVerifyDealer, resetVerifyDealerMessage } = verifyDealerAction;
    const verifyDealerList = useSelector((state) => state.verifyDealer.verifyDealerResult);
    const verifyFormModal = useSelector((state) => state.verifyDealer.verifyDealerFormModal);
    const buttonLoading = useSelector((state) => state.verifyDealer.buttonLoading);
    const tableLoading = useSelector((state) => state.verifyDealer.loading);
    const successMessage = useSelector((state) => state.verifyDealer.verifyDealerMessage);
    const errorMessage = useSelector((state) => state.verifyDealer.verifyDealerError);
    const countryResult = useSelector((state) => state.country.countryResult);
    const tariffResult = useSelector((state) => state.tariffGroup.tariffResult);
    const [formButton, setFormButton] = useState('');
    const [filterText, setFilterText] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');
    const [dealerId, setDealerId] = useState("");
    const [dealerData, setDealerData] = useState({
        minimumTopUpAmount: 0,
        maximumTopUpAmount: 0,
        allowSellerCreation: 0,
        allowTariffCreation: 0,
        paypalTransactionFee: 0
    })
    const { register, handleSubmit, formState: { errors }, watch, reset, control } = useForm({
        defaultValues: dealerData,
    });
    const minimumTopUpAmount = watch('minimumTopUpAmount');
    // console.log(verifyDealerList)


    useEffect(() => {
        dispatch(getNonVerifyDealer());
        dispatch(getCountry());
        dispatch(getTariff());
    }, [])

    useEffect(() => {
        if (errorMessage !== null && errorMessage !== undefined) {
            displayAlert(errorMessage, 'alert-danger');
        }
        if (successMessage !== null && successMessage !== undefined) {
            displayAlert(successMessage, 'alert-success');
        }
    }, [errorMessage, successMessage]);

    useEffect(() => {
        reset(dealerData);
    }, [dealerData]);

    const handleOpenAddFormModal = (dealerId) => {
        dispatch(prepareVerifyDealerForm());
        setFormButton('Submit');
        setDealerId(dealerId);
        dispatch(getCountry());
        dispatch(getTariff());
        var selectedDealer = verifyDealerList.filter(dealer => dealer.ID === dealerId);
        setDealerData(prevState => ({
            ...prevState,
            ID: selectedDealer[0].ID,
            dealerName: selectedDealer[0].DealerName,
            Username: selectedDealer[0].Username,
            contactPerson: selectedDealer[0].ContactPerson,
            contactNumber: selectedDealer[0].ContactNumber,
            contactEmailID: selectedDealer[0].ContactEmailID,
            address: selectedDealer[0].Address,
            city: selectedDealer[0].City,
            state: selectedDealer[0].State,
            zipCode: selectedDealer[0].ZipCode,
            countryID: selectedDealer[0].CountryID,
            tariffGroupID: selectedDealer[0].TariffGroupID,
        }));
    }

    const columns = [
        { displayName: '#', key: 'key' },
        { displayName: 'DEALER NAME', key: 'dealerName' },
        { displayName: 'USERNAME', key: 'username' },
        { displayName: 'EMAIL', key: 'contactEmailId' },
        { displayName: 'CONTACT NUMBER', key: 'contactNumber' },
        { displayName: 'STATUS', key: 'status' },
        { displayName: 'ACTIONS', key: 'action' },
    ];

    const filteredItems = verifyDealerList && verifyDealerList.length > 0 && verifyDealerList.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    var data = filteredItems && filteredItems.length > 0 && filteredItems.map((dealer, key) => {
        return {
            key: key + 1,
            dealerName: dealer.DealerName,
            username: dealer.Username,
            contactEmailId: dealer.ContactEmailID,
            contactNumber: dealer.ContactNumber,
            contactPerson: dealer.ContactPerson,
            status: <Tag color={`${dealer.IsActive === 1 ? "badge-success" : "badge-danger"}`}
                title={dealer.IsActive === 1 ? 'Active' : 'Inactive'} />,
            action: <Button title='Verify' buttonClass='btn btn-primary' buttonType='button'
                onClick={() => handleOpenAddFormModal(dealer.ID)} />,
        }
    });

    const handleCancel = () => {
        setDealerData(prevState => ({
            ...prevState,
            dealerName: "",
            contactPerson: "",
            contactNumber: "",
            contactEmailID: "",
            address: "",
            countryID: "",
            zipCode: "",
            state: "",
            city: "",
            tariffGroupID: "",
            minimumTopUpAmount: 0,
            maximumTopUpAmount: 0,
            paypalTransactionFee: 0,
            allowSellerCreation: 0,
            allowTariffCreation: 0,
        }));
        dispatch(resetVerifyDealer());
    }

    const onSubmit = (data) => {
        data.address = data.address ? data.address : '';
        data.city = data.city ? data.city : '';
        data.state = data.state ? data.state : '';
        data.zipCode = data.zipCode ? data.zipCode : '';
        const newData = {
            ...data,
            allowSellerCreation: data.allowSellerCreation ? 1 : 0,
            allowTariffCreation: data.allowTariffCreation ? 1 : 0,
        };
        // console.log(newData);
        dispatch(toVerifyDealer(dealerId, newData));
    };

    const displayAlert = (text, type) => {
        setAlertText(text);
        setAlertType(type);
        setShowAlert(true);
        dispatch(resetVerifyDealerMessage());
    };

    const alertCancel = () => {
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
        dispatch(resetVerifyDealerMessage());
    }

    const handleFilterChange = (value) => {
        setFilterText(value);
    };

    return (
        <CardLayout title="Verify Dealers">
            {showAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}
            <div className='row'>
            <div className="col-lg-6 col-md-6 col-sm-6">
            <SearchBar onFilter={handleFilterChange} filterText={filterText} /></div></div>
            <Table columns={columns} data={data} pagination={true} />
            <Modal
                title="Verify Dealer"
                showModal={verifyFormModal}
                onHide={handleCancel}
                showFooter={false}
                width={700}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <label>Dealer name</label>
                            <input type="text" placeholder="Enter dealer name" className="form-control" name="dealerName" {...register("dealerName", {
                                required: true,
                            })} />
                            {errors.dealerName?.type === "required" && (
                                <div className="invalid-feedback">Dealer name is required !</div>

                            )}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Contact Person</label>
                            <input type="text" className="form-control" placeholder="Enter contact person" name="contactPerson" {...register("contactPerson", {
                                required: true,
                            })} />
                            {errors.contactPerson?.type === "required" && (
                                <div className="invalid-feedback">Contact person is required !</div>
                            )}
                        </div>

                        <div className="col-md-6 mb-3">
                            <label>Phone</label>
                            <Controller
                                name="contactNumber"
                                control={control}
                                rules={{
                                    required: true,
                                    minLength: 10,
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <PhoneInput
                                        value={value}
                                        onChange={onChange}
                                        defaultCountry="US"
                                        international
                                        className="form-control"
                                        countryCallingCodeEditable={false}
                                        id="contactNumber"
                                        placeholder="Enter phone number"
                                    />
                                )}
                            />
                            {errors.contactNumber?.type === "required" && (
                                <div className="invalid-feedback">Phone number is required !</div>
                            )}
                            {(errors.contactNumber?.type === "minLength" || errors.contactNumber?.type === "maxLength") && (
                                <div className="invalid-feedback">Phone number should be at least 10-12 digit !</div>
                            )}
                        </div>

                        <div className="col-md-6 mb-3">
                            <label>Email ID</label>
                            <input type="text" className="form-control" placeholder="Enter your email" name="contactEmailID" {...register("contactEmailID", {
                                required: true,
                                pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/
                            })} />
                            {errors.contactEmailID?.type === "required" && (
                                <div className="invalid-feedback">Email is required !</div>
                            )}
                            {errors.contactEmailID?.type === "pattern" && (
                                <div className="invalid-feedback">Email is not valid !</div>
                            )}
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-8 mb-3">
                            <label>Address</label>
                            <input type="text" className="form-control" placeholder="Enter your address" name="address" {...register("address", {
                                required: false,
                            })} />
                            {errors.address?.type === "required" && (
                                <div className="invalid-feedback">Address is required !</div>

                            )}
                        </div>
                        <div className="col-md-4 mb-3">
                            <label>City</label>
                            <input type="text" className="form-control" placeholder="Enter your city" name="city" {...register("city", {
                                required: false,
                            })} />
                            {errors.city?.type === "required" && (
                                <div className="invalid-feedback">City is required !</div>
                            )}
                        </div>
                        <div className="col-md-4 mb-3">
                            <label>State</label>
                            <input type="text" className="form-control" placeholder="Enter your state" name="state" {...register("state", {
                                required: false,
                            })} />
                            {errors.state?.type === "required" && (
                                <div className="invalid-feedback">State is required !</div>
                            )}
                        </div>
                        <div className="col-md-4 mb-3">
                            <label>Country</label>
                            <select className="form-control" placeholder="Select Country" name="countryID"  {...register("countryID", {
                                required: true,
                            })} >
                                <option value="" key="0">Select</option>
                                {countryResult.length > 0 && countryResult.map(country => (
                                    <option name="countryID" value={country.ID} key={country.ID}>
                                        <ReactCountryFlag countryCode="US" svg style={{ width: '2em', height: '2em' }} title="US" />
                                        &nbsp;&nbsp; {country.CountryName}</option>

                                ))}
                            </select>
                            {errors.countryID?.type === "required" && (
                                <div className="invalid-feedback">Country is required !</div>
                            )}
                        </div>
                        <div className="col-md-4 mb-3">
                            <label>Postal Code</label>
                            <input type="number" className="form-control" placeholder="Enter your postal code" onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} name="zipCode" {...register("zipCode", {
                                required: false,
                                minLength: 4,
                            })} />
                            {errors.zipCode?.type === "required" && (
                                <div className="invalid-feedback">Postal code is required !</div>
                            )}
                            {(errors.zipCode?.type === "minLength") && (
                                <div className="invalid-feedback">Postal code should be at least 5 digit !</div>
                            )}
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <label>Minimum Top Up Amount</label>
                            <input type="number" className="form-control" placeholder="Enter minimum top up amount" onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} name="minimumTopUpAmount" {...register("minimumTopUpAmount", {
                                required: true,
                            })} />
                            {errors.minimumTopUpAmount?.type === "required" && (
                                <div className="invalid-feedback">Minimum top up amount is required !</div>

                            )}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Maximum Top Up Amount</label>
                            <input type="number" className="form-control" onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} placeholder="Enter maximum top up amount" name="maximumTopUpAmount"
                                {...register('maximumTopUpAmount', {
                                    required: true,
                                    validate: {
                                        greaterThanMin: (value) =>
                                            parseFloat(value) >= parseFloat(minimumTopUpAmount) ||
                                            'Maximum Top-Up Amount should be greater than Minimum Top-Up Amount',
                                    },
                                })}
                            />
                            {errors.maximumTopUpAmount && (
                                <div className="invalid-feedback">{errors.maximumTopUpAmount.message}</div>
                            )}
                            {errors.maximumTopUpAmount?.type === "required" && (
                                <div className="invalid-feedback">Maximum top up amount is required !</div>
                            )}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Tariff Group</label>
                            <select className="form-control" placeholder="Select tariff group" name="tariffGroupID"  {...register("tariffGroupID", {
                                required: true,
                            })} >
                                <option value="" key="0" >Select</option>
                                {tariffResult.length > 0 && tariffResult.map(tariff => (
                                    <option value={tariff.ID} key={tariff.ID}>{tariff.TariffGroup}</option>
                                ))}
                            </select>

                            {errors.tariffGroupID?.type === "required" && (
                                <div className="invalid-feedback">Tariff group is required !</div>
                            )}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Transaction Fee in (%)</label>
                            <input type="number" className="form-control" placeholder='Enter paypal Transaction fee' onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} name="paypalTransactionFee" {...register("paypalTransactionFee", {
                                required: true,
                            })} />
                            {errors.paypalTransactionFee?.type === "required" && (
                                <div className="invalid-feedback">Paypal transaction is required !</div>
                            )}
                        </div>
                        {/* <div className="col-md-6 mb-3">
                            <div className="custom-control custom-checkbox custom-control-inline">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="customCheck-t"
                                    {...register("allowSellerCreation", { value: "1", setValueAs: (val) => (val[0] === "1" ? "1" : "0") })}
                                />
                                <label className="custom-control-label" for="customCheck-t">Allow Dealer Creation</label>
                            </div>
                        </div> */}
                        {/* <div className="col-md-6 mb-3">
                            <div className="custom-control custom-checkbox custom-control-inline">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="customCheck-f"
                                    {...register("allowTariffCreation", { value: "1", setValueAs: (val) => (val[0] === "1" ? "1" : "0") })}
                                />
                                <label className="custom-control-label" for="customCheck-f">Allow Tariff Creation</label>
                            </div>
                        </div> */}

                    </div>
                    <div className='text-right row'>
                        <div className="col-md-12">
                            <button type="button" className="btn btn-secondary mr-2" onClick={handleCancel}>Close</button>
                            <Button title={formButton} style="minWidth:80px" buttonClass="btn btn-primary" loading={buttonLoading} buttonType='submit' />
                        </div>
                    </div>
                </form>
            </Modal>



        </CardLayout>

    )
}

export default VerifyDealer