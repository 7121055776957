import React from 'react'
import Modal from './Model';


const PlanModel = ({ showViewModal, title, handleCancel, responseValue, variantTitle, variantHeading, varientData, images }) => {






    return (
        <>
            <Modal
                title={title}
                showModal={showViewModal}
                onHide={handleCancel}
                showFooter={false}
                width={800}
            >
                <div className="row">
                    <div className="col-md-8">
                        <div className='pland' style={{ display: 'grid', gridTemplateColumns: `${images ? 'auto auto auto' : 'auto auto auto auto'}`, width: `${images && images.length > 0 ? '100%' : '100%'}`, marginBottom: '7px', gap: '15px' }}>
                            {responseValue && responseValue.length > 0 && responseValue.map((item, index) => (
                                <div key={index}>
                                    <h5 style={{ fontSize: '16px', color: '#2B3038', fontWeight: 'normal' }}>{item.name}</h5>
                                    <p>{item.value}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-md-4">
                        {images &&
                            <img src={`${process.env.REACT_APP_BASEURL}/planImages/${images}`} />
                        }
                    </div>
                </div>
                <hr style={styles.hr} />
                <h5 style={{ marginBottom: '9px' }}>{variantTitle}</h5>
                <hr style={styles.hr} />


                <table className='table-striped table-bordered table mt-3'>
                    <tr>
                        {variantHeading && variantHeading.length > 0 && variantHeading.map((item, index) => (
                            <th key={index} style={{ width: `${index < 2 ? '33%' : '12%'}`, marginBottom: '-3px' }}>
                                <span style={{ fontWeight: '700' }}>{item.name}</span>
                            </th>
                        ))}
                    </tr>
                    <>
                        {varientData && varientData.length > 0 && varientData.map((item, indx) => (
                            <tr>
                                {item.vairiantValue.map((data, i) => (
                                    <td style={{ width: `${i < 2 ? '33%' : '12%'}`, marginBottom: '11px' }}>
                                        <span>{data.value}</span>
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </>

                </table>

            </Modal>
        </>
    )
}
const styles = {
    hr: {
        border: '1px solid',
        margin: '10px 0px 7px 0px',
    }
}

export default PlanModel
