const actions = {
    RESET_ORDER_REFUND: "RESET_ORDER_REFUND",
    GET_ORDER_BY_ICCID: "GET_ORDER_BY_ICCID",
    GET_ORDER_BY_ICCID_SUCCESS: "GET_ORDER_BY_ICCID_SUCCESS",
    GET_ORDER_BY_ICCID_FAILED: "GET_ORDER_BY_ICCID_FAILED",
    ORDER_REFUND_REQUEST: "ORDER_REFUND_REQUEST",
    ORDER_REFUND_REQUEST_SUCCESS: "ORDER_REFUND_REQUEST_SUCCESS",
    ORDER_REFUND_REQUEST_FAILED: "ORDER_REFUND_REQUEST_FAILED",

    orderRefundRequest: (data) => ({
        type: actions.ORDER_REFUND_REQUEST,
        payload: { data }
    }),
    orderRefundRequestSuccess: (message, data) => ({
        type: actions.ORDER_REFUND_REQUEST_SUCCESS,
        payload: { message, data }
    }),
    orderRefundRequestFailed: (error) => ({
        type: actions.ORDER_REFUND_REQUEST_FAILED,
        payload: { error }
    }),

    getOrderByIccId: (data) => ({
        type: actions.GET_ORDER_BY_ICCID,
        payload: { data }
    }),
    getOrderByIccIdSuccess: (message, data) => ({
        type: actions.GET_ORDER_BY_ICCID_SUCCESS,
        payload: { message, data }
    }),
    getOrderByIccIdFailed: (error) => ({
        type: actions.GET_ORDER_BY_ICCID_FAILED,
        payload: { error }
    }),

    resetOrderRefund: () => ({
        type: actions.RESET_ORDER_REFUND,
    }),
}

export default actions;