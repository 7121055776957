import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { editData, getList } from '../../helpers/fackBackend_Helper';

function* getNonVerifyDealerResponse() {
    try {
        const response = yield call(getList, '/verifyDealer');
        if (response.response.Status) {
            yield put(
                actions.getNonVerifyDealerSuccess(
                    response.response.Data
                )
            );
        } else {
            yield put(actions.getNonVerifyDealerFailed());
        }
    } catch (error) {
        yield put(actions.getNonVerifyDealerFailed());
    }
}


function* toVerifyDealerResponse({ payload: { dealerId, data } }) {
    try {
        const response = yield call(editData, '/verifyDealer/' + dealerId, data);
        if (response.response.Status) {
            const responseList = yield call(getList, '/verifyDealer');
            if (responseList.response.Status) {
                yield put(
                    actions.toVerifyDealerSuccess(
                        responseList.response.Data, response.response.Message,
                    ));
            } else {
                yield put(actions.toVerifyDealerFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.toVerifyDealerFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.toVerifyDealerFailed(error));
    }
}


export default function* rootSaga() {
    yield all([takeEvery(actions.GET_NON_VERIFY_DEALER, getNonVerifyDealerResponse)]);
    yield all([takeEvery(actions.TO_VERIFY_DEALER, toVerifyDealerResponse)]);
}
