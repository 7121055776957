import React, { useState } from 'react';
import FileForbidFill from 'remixicon-react/FileForbidFillIcon';

function Table({ columns, data, pagination, loading, onRowClick, tableClass, tableFixed, paginationClass }) {
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10); // Default items per page

    const sortedData = () => {
        if (!Array.isArray(data)) {
            return []; // Return an empty array if data is not an array
        }
        const sortableData = [...data];
        if (sortConfig.key !== null) {
            sortableData.sort((a, b) => {
                let comparison = 0;
                const keyA = a[sortConfig.key];
                const keyB = b[sortConfig.key];

                if (keyA > keyB) {
                    comparison = 1;
                } else if (keyA < keyB) {
                    comparison = -1;
                }

                return sortConfig.direction === 'descending' ? comparison * -1 : comparison;
            });
        }
        return sortableData;
    };

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const paginatedData = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return sortedData().slice(startIndex, endIndex);
    };

    const totalPages = Math.ceil(sortedData().length / itemsPerPage);
    const visiblePages = 3;

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleItemsPerPageChange = (value) => {
        setItemsPerPage(value);
        setCurrentPage(1); // Reset to first page when changing items per page
    };

    const renderPageNumbers = () => {
        const pages = [];
        let startPage = 1;

        if (totalPages > visiblePages) {
            if (currentPage <= Math.ceil(visiblePages / 2)) {
                startPage = 1;
            } else if (currentPage > totalPages - Math.floor(visiblePages / 2)) {
                startPage = totalPages - visiblePages + 1;
            } else {
                startPage = currentPage - Math.floor(visiblePages / 2);
            }
        }

        const endPage = Math.min(startPage + visiblePages - 1, totalPages);

        if (startPage > 1) {
            pages.push(
                <li className="page-item" key={1}>
                    <a className="page-link" href="#" onClick={() => handlePageChange(1)}>1</a>
                </li>
            );
            pages.push(
                <li className="page-item disabled" key="ellipsis-start">
                    <span className="page-link">...</span>
                </li>
            );
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <li className={`page-item ${currentPage === i ? 'active' : ''}`} key={i}>
                    <a className="page-link" href="#" onClick={() => handlePageChange(i)}>{i}</a>
                </li>
            );
        }

        if (endPage < totalPages) {
            pages.push(
                <li className="page-item disabled" key="ellipsis-end">
                    <span className="page-link">...</span>
                </li>
            );
            pages.push(
                <li className="page-item" key={totalPages}>
                    <a className="page-link" href="#" onClick={() => handlePageChange(totalPages)}>{totalPages}</a>
                </li>
            );
        }

        return pages;
    };


    return (
        <div className={pagination ? `table-pagination-class` : ''}>
            <div className={data && data.length > 0 && pagination && `table-pagination-class ${tableClass}`} style={{ overflow: "auto" }}>
                {/* Table */}
                <table id="user-list-table" className={`table ${tableFixed} table-striped table-bordered mt-3`} role="grid" aria-describedby="user-list-page-info">
                    {/* Table Header */}
                    <thead>
                        <tr>
                            {columns.map((column, index) => (
                                <th key={index} onClick={() => requestSort(column.key)}>
                                    {column.displayName}
                                    {sortConfig.key === column.key && (
                                        sortConfig.direction === 'ascending' ? ' ⮟' : ' ⮝'
                                    )}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    {/* Table Body */}
                    <tbody>
                        {!loading && paginatedData().map((row, rowIndex) => (
                            <tr key={rowIndex} style={{ cursor: 'pointer' }} onClick={() => onRowClick && onRowClick(row)}>
                                {columns.map((column, colIndex) => (
                                    <td key={colIndex}>{row[column.key]}</td>
                                ))}
                            </tr>
                        ))}

                    </tbody>
                </table>
                {loading && <div class="spinnerInner"></div>}
                {
                    data.length > 0 ? "" : <>{!loading &&
                        <>
                            <div className='text-center mt-1 pt-4 pb-5'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 1024 1024"><path fill="#e3e3e3" d="M854.6 288.7L639.4 73.4c-6-6-14.2-9.4-22.7-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.6-9.4-22.6M790.2 326H602V137.8zm1.8 562H232V136h302v216a42 42 0 0 0 42 42h216zM402 549c0 5.4 4.4 9.5 9.8 9.5h32.4c5.4 0 9.8-4.2 9.8-9.4c0-28.2 25.8-51.6 58-51.6s58 23.4 58 51.5c0 25.3-21 47.2-49.3 50.9c-19.3 2.8-34.5 20.3-34.7 40.1v32c0 5.5 4.5 10 10 10h32c5.5 0 10-4.5 10-10v-12.2c0-6 4-11.5 9.7-13.3c44.6-14.4 75-54 74.3-98.9c-.8-55.5-49.2-100.8-108.5-101.6c-61.4-.7-111.5 45.6-111.5 103m78 195a32 32 0 1 0 64 0a32 32 0 1 0-64 0" /></svg>
                                <br /> No Data

                            </div>
                            <hr className='hr-tag-line' />
                        </>
                    }
                    </>
                }
                {loading && <hr className='hr-tag-line' />}
            </div>
            {/* Pagination */}
            {data.length > 0 && pagination && (loading == false) && (
                <div className={`row justify-content-between mt-md-3 mt-lg-0 align-items-center ${paginationClass}`}>
                    <div id="user-list-page-info" className="col-md-6">
                        <span>{`Showing ${(currentPage - 1) * itemsPerPage + 1} to ${Math.min(currentPage * itemsPerPage, data.length)} of ${data.length} entries`}</span>
                    </div>
                    <div className="col-md-6">
                        <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-end mb-0">
                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                    <a className="page-link" href="#" onClick={() => handlePageChange(currentPage - 1)} tabIndex="-1" aria-disabled={currentPage === 1}> <span aria-hidden="true">&laquo;</span></a>
                                </li>
                                {renderPageNumbers()}
                                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                    <a className="page-link" style={{ borderRadius: "0 0.25rem 0.25rem 0" }} href="#" onClick={() => handlePageChange(currentPage + 1)}><span aria-hidden="true">&raquo;</span></a>
                                </li>
                                <li className="page-item ml-3">
                                    <select class="form-control page-link" style={{ borderRadius: "0.25rem" }} onChange={(e) => handleItemsPerPageChange(Number(e.target.value))} value={itemsPerPage}>
                                        <option value={10}>10 / page</option>
                                        <option value={20}>20 / page</option>
                                        <option value={50}>50 / page</option>
                                        <option value={100}>100 / page</option>
                                    </select>
                                </li>
                            </ul>

                        </nav>
                    </div>
                </div>
            )}

        </div>
    );
}

Table.defaultProps = {
    pagination: true
}

export default Table;
