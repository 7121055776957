import React from 'react'

const Card = ({ image, header, body, footer, imageClick }) => {
    return (
        <>
            <div className="card iq-mb-3">
                {image ? <img src={image} className="card-img-top img-fluid cursor-pointer" style={{ height: '170px', width: '100%' }} onClick={() => imageClick(image)} /> : ''}
                {header ? <div className="card-header"> {header} </div> : ''}
                {body ? <div className="card-body"> {body} </div> : ''}
                {footer ? <div className="card-footer text-muted d-flex justify-content-between"> {footer} </div> : ''}
            </div>
        </>
    )
}

export default Card