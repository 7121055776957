import React from 'react'
import CardLayout from '../../../components/utils/CardLayout'
import SearchBar from '../../../components/utils/SearchBar';
import Table from '../../../components/utils/Table';
import Button from '../../../components/utils/Button'
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import moment from "moment";
import momentTimezone from 'moment-timezone';
import { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import Styles from './auditReport.module.css'
// import EyeFill from 'remixicon-react/EyeFillIcon';
import auditActions from '../../../redux/auditReport/actions'
import DownloadCloud2sLineIcon from 'remixicon-react/DownloadCloud2LineIcon';
import Calendar2LineIcon from 'remixicon-react/Calendar2LineIcon';
import Tag from '../../../components/utils/Tag'
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const TIMEZONE = process.env.REACT_APP_TIMEZONE;

const AuditReport = () => {
  const { getAuditReport, resetAuditReportMessage } = auditActions;
  const dispatch = useDispatch();
  // const [auditPreview, setAuditPreview] = useState([]);
  const { auditResult, loading } = useSelector((state) => state.auditReport);
  const { register, handleSubmit, formState: { errors }, watch, reset } = useForm();
  const [toDate, setToDate] = useState(moment().startOf('day').toDate())
  const [fromDate, setFromDate] = useState(moment().subtract(7, 'days').toDate());
  const datePickerRef = useRef(null);
  const datePickerRef2 = useRef(null);
  const dateFormat = process.env.REACT_APP_DATE_FORMAT;
  const [filterText, setFilterText] = useState("");
  const [selectedDate, setSelectedDate] = useState()
  // const [showViewModal, setShowViewModal] = useState(false)
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const [searchData, setSearchData] = useState({
    fromDate: fromDate,
    toDate: toDate,
    transactionType: ""
  })

  useEffect(() => {
    const newData = {
      toDate: moment(toDate).format("YYYY-MM-DD"),
      fromDate: moment(fromDate).format("YYYY-MM-DD"),
      transactionType: ""
    };
    dispatch(getAuditReport(newData));
  }, [])

  const predefinedPeriods = {
    today: moment().startOf('day').toDate(),
    yesterday: moment().subtract(1, 'days').startOf('day').toDate(),
    '15days': moment().subtract(15, 'days').startOf('day').toDate(),
    month: moment().subtract(30, 'days').startOf('day').toDate(),
    quarter: moment().subtract(3, 'months').startOf('day').toDate(),
    year: moment().subtract(1, 'year').startOf('day').toDate(),
  };
  const selectPeriodList = [
    { value: 'today', title: 'Today' },
    { value: 'month', title: 'Last 30 Days' },
    { value: 'quarter', title: 'Quarter' },
    { value: 'year', title: 'Year' }
  ]
  const openDatePicker = () => {
    datePickerRef.current.setOpen(true);
  };
  const openDatePicker2 = () => {
    datePickerRef2.current.setOpen(true);
  };

  const changeDateHandler = (item) => {
    setFromDate(predefinedPeriods[item]);
    const formattedDate = moment(predefinedPeriods[item]).format("YYYY-MM-DD");
    const currentDate = moment().format("YYYY-MM-DD");
    const apiData = {
      fromDate: formattedDate,
      toDate: currentDate,
      transactionType: ""
    }
    dispatch(getAuditReport(apiData))
  }


  const onSubmit = (data) => {
    const newData = {
      ...data,
      // toDate: moment(toDate).format("YYYY-MM-DD"),
      // fromDate: moment(fromDate).format("YYYY-MM-DD"),
      toDate: moment(new Date(toDate)).tz(TIMEZONE).format("YYYY-MM-DD"),
      fromDate: moment(new Date(fromDate)).tz(TIMEZONE).format("YYYY-MM-DD"),
    };
    setSelectedDate(newData)
    dispatch(getAuditReport(newData))
  };

  const columns = [
    { displayName: '#', key: 'key' },
    { displayName: 'DATE', key: 'Date' },
    { displayName: 'TRANSACTION ID', key: 'TransactionId' },
    { displayName: 'PAYMENT TYPE', key: 'PaymentType' },
    { displayName: 'AMOUNT', key: 'Amount' },
    { displayName: 'PREVIOUS BALANCE', key: 'PreviousBalance' },
    { displayName: 'CURRENT BALANCE', key: 'CurrentBalance' },
    { displayName: 'DEALER NAME	', key: 'DealerName' },
    { displayName: 'ACCOUNTING', key: 'Accounting' },
    { displayName: 'REMARKS', key: 'Remarks' },
  ];

  const filteredItems = auditResult && auditResult.length > 0 && auditResult.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );
  const handleFilterChange = (value) => {
    setFilterText(value);
  };


  var data = filteredItems && filteredItems.length > 0 && filteredItems.map((auditItem, key) => {
    return {
      key: key + 1,
      TransactionId: auditItem.TxnID,
      PaymentType: auditItem.PaymentType,
      Amount: `£${auditItem.Amount ? auditItem.Amount.toFixed(2) : '0.00'}`,
      PreviousBalance: `£${auditItem.PreviousBalance ? auditItem.PreviousBalance.toFixed(2) : '0.00'}`,
      CurrentBalance: `£${auditItem.CurrentBalance ? auditItem.CurrentBalance.toFixed(2) : '0.00'}`,
      DealerName: auditItem.DealerName,
      Date: moment(auditItem.CreatedDtTm).format(dateFormat),
      Accounting: <Tag color={auditItem.TransactionType === 'Credit' ? "badge-success" : auditItem.TransactionType === 'Debit' ? "badge-warning" : "badge-danger"} title={auditItem.TransactionType}></Tag>,
      Remarks:auditItem.Remarks,
    }
  });

  const exportToCSV = async () => {
    let count=0;
    if (data && data.length > 0) {
      let xlsxData=auditResult && auditResult.length > 0 && auditResult.map((item,ind)=>{
         count= count+1;
        let resultData={
          SrNo: count,
          CreatedDtTm:moment(item.CreatedDtTm).format(dateFormat),
          TxnID:item.TxnID,
          PaymentType:item.PaymentType,
          Amount:`£${item.Amount ? item.Amount.toFixed(2) : '0.00'}`,
          PreviousBalance:`£${item.PreviousBalance ? item.PreviousBalance.toFixed(2) : '0.00'}`,
          CurrentBalance:`£${item.CurrentBalance ? item.CurrentBalance.toFixed(2) : '0.00'}`,
          DealerName:item.DealerName,
          TransactionType:item.TransactionType,
          Remarks:item.Remarks,
         }
         return resultData
         
        })

      const ws = XLSX.utils.json_to_sheet(xlsxData);
      const wb = { Sheets: { 'auditResult': ws }, SheetNames: ['auditResult'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      await FileSaver.saveAs(data, 'Audit_report' + fileExtension);
    }
  }
  return (
    <CardLayout title="Audit Report">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row">

          <div className="col-md-3 col-lg-2 mb-2 d-flex flex-column">
            <label>From Date</label>
            <Calendar2LineIcon onClick={openDatePicker} size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', 'top': '36px', right: '12px', zIndex: 1 }} />
            <DatePicker
              ref={datePickerRef}
              selected={fromDate}
              className="form-control"
              onChange={(date) => setFromDate(date)}
              dateFormat="yyyy-MM-dd"
              name="fromDate"
              showMonthDropdown
              showYearDropdown
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              value={fromDate}
            />
          </div>

          <div className="col-md-3 col-lg-2 mb-2 d-flex flex-column">
            <label>To Date</label>
            <Calendar2LineIcon onClick={openDatePicker2} size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', 'top': '36px', right: '12px', zIndex: 1 }} />
            <DatePicker
              ref={datePickerRef2}
              selected={toDate}
              className="form-control center"
              onChange={(date) => setToDate(date)}
              dateFormat="yyyy-MM-dd"
              name="todate"
              showMonthDropdown
              showYearDropdown
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              value={toDate}
            />
            {errors.dealerName?.type === "required" && (
              <div className="invalid-feedback">Date is required !</div>
            )}
          </div>
          <div className="col-md-3 col-lg-2 mb-2">
            <label>Select Transaction Type</label>
            <select className="form-control" placeholder="Select" name="transactionType"  {...register("transactionType", {
            })} >
              <option value="" key="0" >Select</option>
              {/* <option value="1">Order </option> */}
              <option value="2">Add Fund</option>
            </select>
          </div>
          <div className="col-md-3 mb-2  mt-lg-4 mt-md-4">

            <Button title="Search" style="minWidth:80px" buttonClass={` btn btn-primary mt-1 btn-md Activity_searchBtn__GlBtC`} buttonType='submit' />

          </div>
          <div className="col-md-6 ">
            <div className="form-row">
              {selectPeriodList.map((item, index) =>
                <ul className='p-1'>
                  <li className={Styles.selectUl} key={index} onClick={() => changeDateHandler(item.value, index)}
                  >{item.title}</li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </form>
      <hr />
      <div className='d-flex justify-content-between audit-report'>
        <SearchBar onFilter={handleFilterChange} filterText={filterText} />
        <Button icon={<DownloadCloud2sLineIcon color='#fff' className='mr-1 excel-btn-1-aidit ' size={'0.9rem'} />} title="Export" buttonClass={`btn ${Styles.exportBtn}`} style={{ marginRight: "10px" }} buttonStyle="primary" onClick={exportToCSV} />
      </div>
      <Table columns={columns} loading={loading} data={data} pagination={true} />

    </CardLayout>
  )
};


export default AuditReport
