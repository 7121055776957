import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import CardLayout from '../../../../components/utils/CardLayout';
import SearchBar from '../../../../components/utils/SearchBar';
import SideButtons from '../../../../components/utils/SideButtons';
import Button from '../../../../components/utils/Button';
import Table from '../../../../components/utils/Table';
import Tag from '../../../../components/utils/Tag';
import PencilFill from 'remixicon-react/PencilFillIcon';
import EyeFill from 'remixicon-react/EyeFillIcon';
import DeleteBin5Fill from 'remixicon-react/DeleteBin5FillIcon';
import { Link } from 'react-router-dom';
import Modal from '../../../../components/utils/Model'
import Alert from '../../../../components/utils/Alert';
import emailTemplateActions from '../../../../redux/emailTemplate/actions'
import CheckboxCircleFill from 'remixicon-react/CheckboxCircleFillIcon';
import Popconfirm from '../../../../components/utils/Popover'
import { useDispatch, useSelector } from 'react-redux';
const { openAddCategoryModal, closeAddCategoryModal, getTemplateCategory, addCategory, updateTemplateCategory, updateCategoryStatus, resetMessages, openAddTemplateModal, closeAddTemplateModal, addEmailTemplate, getTemplate, updateTemplate, updateTemplateStatus } = emailTemplateActions;

const EmailTemplate = () => {
  const dispatch = useDispatch();
  const { templateCategory, template, addTemplateModal, addCategoryModal, templateCategoryLoading, statusMessage, categoryStatusChangeMessage, categoryStatusChangeError, addCategoryloading, addTemplateloading } = useSelector((state) => state.emailTemplate);
  const [filterText, setFilterText] = useState("");
  const [activeTab, setActiveTab] = useState('Category');
  const [formTitle, setFormTitle] = useState('')
  const [editFlag, setEditFlag] = useState(false);
  const [formButton, setFormButton] = useState('');
  const [categoryId, setCategoryId] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertText, setAlertText] = useState('');
  const [templateFrom, setTemplateFrom] = useState();
  const [templateId, setTemplateId] = useState(0);
  const [htmlData, setHtmlData] = useState()
  const [openViewModal, setOpenViewModal] = useState(false)
  const [categoryFrom, setCategoryFrom] = useState({
    category: "",
  })
  const {
    register,
    handleSubmit,
    formState: { errors }, reset
  } = useForm({ defaultValues: categoryFrom });

  const { register: registerTemplate,
    handleSubmit: handleSubmitTemplate,
    reset: resetReset,
    formState: { errors: errorsReset },
  } = useForm({ defaultValues: templateFrom });

  useEffect(() => {
    dispatch(getTemplateCategory());
  }, []);

  useEffect(() => {
    reset(categoryFrom);
  }, [categoryFrom]);

  useEffect(() => {
    dispatch(getTemplate())
  }, [])

  useEffect(() => {
    resetReset(templateFrom);
  }, [templateFrom]);

  useEffect(() => {
    if (categoryStatusChangeError !== null && categoryStatusChangeError !== undefined) {
      displayAlert(categoryStatusChangeError, 'alert-danger');
    }
    if (statusMessage !== null && statusMessage !== undefined) {
      displayAlert(statusMessage, 'alert-success');
    }
  }, [categoryStatusChangeError, statusMessage]);

  const column = [
    { displayName: '#', key: 'key' },
    { displayName: 'CATEGORY', key: 'category' },
    { displayName: 'STATUS', key: 'status' },
    { displayName: 'ACTION', key: 'action' },
  ];
  const columns2 = [
    { displayName: '#', key: 'key' },
    { displayName: 'SUBJECT', key: 'subject' },
    { displayName: 'CATEGORY', key: 'category' },
    { displayName: 'STATUS', key: 'status' },
    { displayName: 'ACTION', key: 'action' },
  ];

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleFilterChange = (value) => {
    setFilterText(value);
  };

  const handleAddCategory = () => {
    setCategoryFrom(prevState => ({
      ...prevState,
      category: "",
    }));
    dispatch(openAddCategoryModal());
    setFormTitle('Add Category');
    setFormButton('Submit');
    setEditFlag(false);
    reset();
  };

  const handleCancel = () => {
    dispatch(closeAddCategoryModal());
    setOpenViewModal(false)
    dispatch(closeAddTemplateModal());
    setEditFlag(false)
  };


  const onSubmit = (data) => {
    dispatch(addCategory(data));
  };


  const handleUpdate = (data) => {
    const updatedData = {
      id: categoryId,
      category: data.category
    }
    dispatch(updateTemplateCategory(categoryId, updatedData));
  }

  const handleEditCategorymodal = (id) => {
    setFormTitle('Edit Aggregator');
    setFormButton('Update');
    dispatch(openAddCategoryModal());
    setCategoryId(id)
    const selectedCategory = templateCategory.filter(item => item.ID == id);
    setCategoryFrom(prev => ({
      ...prev,
      category: selectedCategory[0].Category,
    }))
    setEditFlag(true)
  }

  const displayAlert = (text, type) => {
    setAlertText(text);
    setAlertType(type);
    setShowAlert(true);
    dispatch(resetMessages());
  };

  const alertCancel = () => {
    setShowAlert(false);
    setAlertText('');
    setAlertType('');
    dispatch(resetMessages());
  }
  const handleCategoryDelete = (ID, flag) => {
    dispatch(updateCategoryStatus(ID, flag));
  }

  const addTemplateSubmitHandler = (data) => {
    dispatch(addEmailTemplate(data))
    resetReset()
  }

  const handleAddTemplate = () => {
    setTemplateFrom(prev => ({
      ...prev,
      Subject: '',
      CategoryID: '',
      Content: '',
      Cc: '',
      Bcc: ''
    }))
    dispatch(openAddTemplateModal());
    setEditFlag(false);
    setFormTitle('Add Template')
    setFormButton('Submit');
    resetReset();
  }

  const editTemplate = (item) => {
    setEditFlag(true);
    dispatch(openAddTemplateModal());
    setFormTitle('Update Template')
    setFormButton('Update');
    setTemplateId(item.ID)
    const selectedTemplate = template.filter(newItem => newItem.ID === item.ID);
    setTemplateFrom(prev => ({
      ...prev,
      Subject: selectedTemplate[0].Subject,
      CategoryID: selectedTemplate[0].CategoryID,
      Content: selectedTemplate[0].Content,
      Cc: selectedTemplate[0].Cc,
      Bcc: selectedTemplate[0].Bcc
    }))
    setEditFlag(true)
  }

  const editTemplateSubmit = (data) => {
    dispatch(updateTemplate(templateId, data))
  }

  const viewTemplateHandler = (data) => {
    setOpenViewModal(true)
    setHtmlData(data.Content)
  }

  const handleDelete = (ID, flag) => {
    dispatch(updateTemplateStatus(ID, flag));
  }

  const filteredItemsCategory = templateCategory && templateCategory.length > 0 && templateCategory.filter(
    (item) =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );


  var dataSourceCategory = filteredItemsCategory && filteredItemsCategory.length > 0 && filteredItemsCategory.map((item, key) => {
    return {
      key: key + 1,
      category: item.Category,
      status: (<Tag color={`${item.IsActive === 1 ? 'badge-success' : 'badge-danger'}`} title={item.IsActive === 1 ? 'Active' : 'Inactive'} />),
      action: (
        <div>
          <Link to="#" onClick={() => handleEditCategorymodal(item.ID)}>
            <span data-toggle="tooltip" data-placement="top" title="Edit"><PencilFill size="1.2em" style={{ color: '#888b8e', marginRight: '3px' }} /></span>
          </Link>
          {item.IsActive === 1 ? <Popconfirm
            title="Are you sure to inactive this category?"
            okText="Yes"
            cancelText="No"
            placement="bottomRight"
            onConfirm={() => handleCategoryDelete(item.ID, 0)}
          >
            <Link className="deleteBtn" to="# ">
              <span data-toggle="tooltip" data-placement="top" title="Inactive"><DeleteBin5Fill size="1.2em" style={{ color: "#f14336", marginRight: "3px" }} /></span>
            </Link>
          </Popconfirm> : <Popconfirm
            title="Are you sure to active this category?"
            okText="Yes"
            cancelText="No"
            placement="bottomRight"
            onConfirm={() => handleCategoryDelete(item.ID, 1)}
          >
            <Link className="deleteBtn" to="# ">
              <span data-toggle="tooltip" data-placement="top" title="Active"><CheckboxCircleFill size="1.2em" style={{ color: "#2cb549", marginRight: "3px" }} /></span>
            </Link>
          </Popconfirm>}

        </div>
      ),
    };
  });

  const filteredItemsTemplate = template && template.length > 0 && template.filter(
    (item) =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  var dataSourceTemplate = filteredItemsTemplate && filteredItemsTemplate.length > 0 && filteredItemsTemplate.map((item, key) => {
    return {
      key: key + 1,
      subject: item.Subject,
      category: item.Category,
      status: (<Tag color={`${item.IsActive === 1 ? 'badge-success' : 'badge-danger'}`} title={item.IsActive === 1 ? 'Active' : 'Inactive'} />),
      action: (
        <div>
          <Link to="#" onClick={() => editTemplate(item)}>
            <span data-toggle="tooltip" data-placement="top" title="Edit"><PencilFill size="1.2em" style={{ color: "#888b8e", marginRight: "3px" }} /></span>
          </Link>

          {item.IsActive === 1 ? (
            <Popconfirm
              title="Are you sure to inactive this user?"
              okText="Yes"
              cancelText="No"
              placement="topRight"
              onConfirm={() => handleDelete(item.ID, 0)}
            >
              <Link className="deleteBtn" to="# ">
                <span data-toggle="tooltip" data-placement="top" title="Inactive"><DeleteBin5Fill size="1.2em" style={{ color: "#f14336", marginRight: "3px" }} /></span>
              </Link>
            </Popconfirm>
          ) : (
            <Popconfirm
              title="Are you sure to active this user?"
              okText="Yes"
              cancelText="No"
              placement="topRight"
              onConfirm={() => handleDelete(item.ID, 1)}
            >
              <Link className="deleteBtn" to="# ">
                <span data-toggle="tooltip" data-placement="top" title="Active"><CheckboxCircleFill size="1.2em" style={{ color: "#2cb549", marginRight: "3px" }} /></span>
              </Link>
            </Popconfirm>
          )}

          <Link to="#">
            <span data-toggle="tooltip" data-placement="top" title="View"><EyeFill size="1.2em" style={{ color: "#0094b3", marginRight: "3px" }} onClick={() => viewTemplateHandler(item)} /></span>
          </Link>

        </div>
      ),
    };
  });
  return (<>

    <CardLayout title={activeTab === 'Category' ? 'Category' : 'E-Mail Template'}>
      {showAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${activeTab === 'Category' ? 'active' : ''}`}
            onClick={() => handleTabClick('Category')}
          >
            Category
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${activeTab === 'Template' ? 'active' : ''}`}
            onClick={() => handleTabClick('Template')}
          >
            Template
          </button>
        </li>
      </ul>
      <div className="tab-content" id="myTabContent">
        <div
          className={`tab-pane fade show ${activeTab === 'Category' ? 'active' : ''}`}
          id="home"
          role="tabpanel"
        >
          <div className="row">
            <div className="col-md-6 od-2">
              <SearchBar onFilter={handleFilterChange} filterText={filterText} />
            </div>
            <div className="col-md-6 text-right">
              <SideButtons title={`Add ${activeTab}`} buttonStyle="primary" onClick={handleAddCategory} /></div>
          </div>

          <Table data={dataSourceCategory} loading={templateCategoryLoading} columns={column} pagination={true} />
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'Template' ? 'show active' : ''}`}
          id="profile"
          role="tabpanel"
        >
          <div className="row">
            <div className="col-md-6 od-2">
              <SearchBar onFilter={handleFilterChange} filterText={filterText} /></div>
            <div className="col-md-6 text-right">
              <SideButtons title={`Add ${activeTab}`} buttonStyle="primary" onClick={handleAddTemplate} /></div>
          </div>
          <Table data={dataSourceTemplate} columns={columns2} pagination={true} />
        </div>
      </div>
      <Modal
        showModal={addCategoryModal}
        title={formTitle}
        onHide={handleCancel}
        width={600}
        showFooter={false}
      >
        <form onSubmit={handleSubmit(editFlag ? handleUpdate : onSubmit)}>
          <div className="form-row">
            <div className="col-md-12 mb-3">
              <label>Category Name</label>
              <input type="text" placeholder="Category Name" class="form-control" name="category" {...register("category", {
                required: true,
              })} />
              {errors.category?.type === "required" && (
                <div class="invalid-feedback">Please input category name !</div>

              )}
            </div>
          </div>
          <div className='text-right row'>
            <div className="col-md-12">
              <button type="button" class="btn btn-secondary mr-2" onClick={handleCancel}>Close</button>
              <Button title={formButton} style="minWidth:80px" loading={addCategoryloading} buttonClass="btn btn-primary" buttonType='submit' />
            </div>
          </div>
        </form>
      </Modal>
      <Modal
        showModal={addTemplateModal}
        title={formTitle}
        onHide={handleCancel}
        width={600}
        showFooter={false}
      >
        <form onSubmit={handleSubmitTemplate(editFlag ? editTemplateSubmit : addTemplateSubmitHandler)}>
          <div className="form-row  mb-3">
            <div className="col-md-12">
              <label>Template Content</label>
              <textarea className="form-control" style={{ width: '100%', height: '100px' }} name='Content' {...registerTemplate("Content", { required: true, })} />
              {errorsReset.Content?.type === "required" && (
                <div class="invalid-feedback">Please input content!</div>
              )}
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-6 mb-3">
              <label>Subject</label>
              <input placeholder='Subject' className="form-control" type='text' name='Subject' {...registerTemplate("Subject", { required: true, })} />
              {errorsReset.Subject?.type === "required" && (
                <div class="invalid-feedback">Please input category!</div>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label>Template Category</label>
              <select className="form-control" name='CategoryID' {...registerTemplate("CategoryID", { required: true, })} >
                <option value="" key="0" disabled>Select</option>
                {templateCategory && templateCategory.length > 0 && templateCategory.map((item, index) => (
                  <option value={item.ID} key={item.ID}>{item.Category}</option>
                ))}
              </select>
              {errorsReset.CategoryID?.type === "required" && (
                <div class="invalid-feedback">Please select template category!</div>
              )}
            </div>
          </div>
          <div className="form-row  mb-3">
            <div className="col-md-12">
              <label>Template Content</label>
              <textarea placeholder='Template Content ....' className="form-control" style={{ width: '100%', height: '150px' }} name='Content' {...registerTemplate("Content", { required: true, })} />
              {errorsReset.Content?.type === "required" && (
                <div class="invalid-feedback">Please input content!</div>
              )}
            </div>
          </div>
          <div className="form-row  mb-3">
            <div className="col-md-12">
              <label className='d-block mb-0'>Cc</label>
              <small>Enter email addresses separated by commas</small>
              <input className="form-control" type='text' placeholder='Cc' name='Cc' {...registerTemplate("Cc")} />
            </div>
          </div>
          <div className="form-row  mb-3">
            <div className="col-md-12">
              <label className='d-block mb-0'>Bcc</label>
              <small className="pl-0">Enter email addresses separated by commas</small>
              <input className="form-control" type='email' placeholder='Bcc' name='Bcc' {...registerTemplate("Bcc")} />

            </div>
          </div>

          <div className='text-right row'>
            <div className="col-md-12">
              <button type="button" className="btn btn-secondary mr-2" onClick={handleCancel}>Close</button>
              <Button title={formButton} style="minWidth:80px" loading={addTemplateloading} buttonClass="btn btn-primary" buttonType='submit' />
            </div>
          </div>
        </form>
      </Modal>
      <Modal
        showModal={openViewModal}
        title={'Template Preview'}
        onHide={handleCancel}
        width={700}
        showFooter={false}
      >
        <div className='row'>
          <div className="col-md-12 email-table">
            <div dangerouslySetInnerHTML={{ __html: htmlData }} />
          </div>
        </div>
      </Modal>
    </CardLayout >

  </>
  );
};

export default EmailTemplate;
