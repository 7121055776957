import moment from 'moment';
import Modal from './Model';
import Tag from './Tag';
import noImageFound from '../../assets/images/noImage.png';
import { twoDigitDecimal } from '../../helpers/commonFunction';
import SimPurchaseActions from '../../redux/simPurchase/actions'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from './Table';
import RestartLineIcon from 'remixicon-react/RestartLineIcon';
import orderReportActions from '../../redux/orderReport/actions';
import Alert from './Alert';



const { getSimTypeData } = SimPurchaseActions;
const { getOrderReportDetails, openQRImage, closeQRImage, resendMailByICCID } = orderReportActions;

const dateFormat = process.env.REACT_APP_DATE_FORMAT;

const OrderModalComponent = ({ showViewModal, handleCancel, order }) => {
    const dispatch = useDispatch()
    const { simTypeList } = useSelector((state) => state.simPurchase);
    const { orderReportDetailsResult, loading, viewQRImage, resendMailBYICCIDMessage, resendMailBYICCIDError, resendMailByICCIDLoading, } = useSelector((state) => state.orderReport);
    const [qrImageIccid, setQrImageIccid] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [alertType, setAlertType] = useState('');
    const [loaderID, setloaderID] = useState('');
    const [dealerID, setDealerID] = useState('');


    const sendPlanSimType = {
        "tableName": "sim",
        "fieldName": "SIMType"
    };

    useEffect(() => {

        if (resendMailBYICCIDError && resendMailBYICCIDError != null) {
            setAlertText(resendMailBYICCIDError);
            setAlertType("alert-danger");
            setShowAlert(true);
        }
        if (resendMailBYICCIDMessage && resendMailBYICCIDMessage != null) {
            setAlertText(resendMailBYICCIDMessage);
            setAlertType("alert-success");
            setShowAlert(true);
        }

    }, [resendMailBYICCIDError, resendMailBYICCIDMessage]);

    useEffect(() => {
        if (order && order.length > 0) {
            dispatch(getOrderReportDetails(order[0].ID))
        }
    }, [order])

    const columns = [
        { displayName: '#', key: 'key' },
        { displayName: 'SIM NUMBER', key: 'simNumber' },
        { displayName: 'PLAN', key: 'plan' },
        { displayName: 'QR CODE', key: 'qrCode' },
        { displayName: 'ACTIONS', key: 'action' },
    ];


    const data = orderReportDetailsResult && orderReportDetailsResult.length > 0 && orderReportDetailsResult.map((orderItem, key) => {
        const handleError = (e) => {
            e.target.src = noImageFound;
        };

        return {
            key: key + 1,
            simNumber: orderItem.ICCID,
            plan: orderItem.PlanName,
            qrCode: <span data-toggle="tooltip" data-placement="top" title="View">
                <img onClick={() => handleViewQRImgae(orderItem.ICCID, orderItem.DealerID)} src={orderItem.ICCID ? `${orderItem.DealerID == 26 ? 'https://webhook.esimtrav.com' : process.env.REACT_APP_BASEURL}/qrcode/${orderItem.ICCID}.png` : noImageFound}
                    onError={handleError} style={{ width: '60px' }} /></span>,
            action: <>
                {resendMailByICCIDLoading && loaderID == orderItem.ID ? <div class="spinner-border spinner-border-sm" style={{ margin: '5px 0 0 8px' }} role="status">
                    <span class="sr-only">Loading...</span>
                </div> : <span data-toggle="tooltip" data-placement="top" title="Resend mail"><RestartLineIcon size="1.2em" style={{ color: "#2ecc71", cursor: 'pointer', marginLeft: '8px' }} onClick={() => handleResendMailByICCID(orderItem)} /></span>}
            </>
        }
    });


    const handleViewQRImgae = (iccid, dealerId) => {
        setQrImageIccid(iccid)
        setDealerID(dealerId)
        dispatch(openQRImage())
    }
    const handleCloseQRImgae = () => {
        dispatch(closeQRImage())
        setQrImageIccid('')

    }

    const handleResendMailByICCID = (data) => {
        setloaderID(data.ID)
        const newData = {
            ID: data.ID,
            OrderNumber: data.OrderNumber,
            ActivationCode: data.ActivationCode,
            CustomerEmail: data.CustomerEmail,
            DealerID: data.DealerID,
            DealerName: data.DealerName,
            ICCID: data.ICCID,
            PlanName: data.PlanName,
            SMDPAddress: data.SMDPAddress,
            VariantName: data.VariantName,
            PreferedAggregator: data.PreferedAggregator,
        }
        dispatch(resendMailByICCID(newData))
    }

    useEffect(() => {
        dispatch(getSimTypeData(sendPlanSimType))
    }, [])

    const handleImageError = (e) => {
        e.target.src = noImageFound;
    };

    const orderData = order.length > 0 ? order[0] : new Object();
    const planDetails = orderData.OrderDetails ? JSON.parse(orderData.OrderDetails) : [];
    return (
        <>
            <Modal
                title={`Order #: ${orderData.OrderNumber}`}
                showModal={showViewModal}
                onHide={handleCancel}
                showFooter={false}
                width={700}
            >
                <div className="form-row">
                    <div className='form-row col-md-12'>
                        <div className="col-md-2 mb-3">
                            <div>
                                <a style={Styles.comment}>Order Date</a>
                                <p>{moment(orderData.OrderDate).format(dateFormat)}</p>
                            </div>
                        </div>
                        <div className="col-md-2 mb-3">
                            <div>
                                <a style={Styles.comment} className='text-nowrap'>Grand Total</a>
                                <p>{`£${twoDigitDecimal(orderData.GrandTotal)}`}</p>
                            </div>
                        </div>
                        <div className="col-md-3 mb-3">
                            <div>
                                <a style={Styles.comment} className='text-nowrap'>Coupon Discount</a>
                                <p>{`£${twoDigitDecimal(orderData.CouponDiscount)}`}</p>
                            </div>
                        </div>
                        <div className="col-md-3 mb-3">
                            <div>
                                <a style={Styles.comment}>Net Amount</a>
                                <p>{`£${twoDigitDecimal(orderData.NetAmount)}`}</p>
                            </div>
                        </div>
                        <div className="col-md-2 mb-3">

                            <a style={Styles.comment}>Status</a>
                            <p><Tag title={orderData.OrderStatus} color={`${orderData.OrderStatusID === 21 ? "badge-secondary" : orderData.OrderStatusID === 22 ? 'badge-warning' : orderData.OrderStatusID === 23 ? 'badge-success' : "badge-danger"}`} /></p>

                        </div>
                    </div>
                </div>

                <div className="form-column">
                    <hr style={Styles.hr} />
                    <div className="col-md-12 mb-2 mt-2">
                        <p style={{ fontSize: '18px', color: '#2B3038', fontWeight: 'normal', margin: '-6px -12px -6px -12px' }} className='py-2'>Plan Details</p>
                    </div>
                    <hr style={Styles.hr} />
                </div>


                {
                    planDetails && planDetails.length > 0 && planDetails.map((item, index) => {
                        return <>
                            <div className="form-column" style={{ marginLeft: '-15px' }}>
                                <div className="col-md-12 mt-2">
                                    <p style={Styles.planComment}>Plan Name</p>
                                    <p style={Styles.planName}>{item.PlanName}</p>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-lg-6 pt-3'>
                                    <div className="form-row">
                                        <div className="col-md-4 mb-3">
                                            <div>
                                                <a style={Styles.planComment}>Data</a>
                                                <p style={Styles.planData}>{item.PlanData}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <div >
                                                <a style={Styles.planComment}>Validity</a>
                                                <p style={Styles.planData}>{item.PlanDays}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <div >
                                                <a style={Styles.planComment}>Country</a>
                                                <p style={Styles.planData}>{item.CountryName}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <div >
                                                <a style={Styles.planComment}>Plan Price</a>
                                                <p style={Styles.planData}>{`£${twoDigitDecimal(item.TotalAmount)}`}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <div >
                                                <a style={Styles.planComment}>Aggregator</a>
                                                <p style={Styles.planData}>{item.AggregatorName}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <div >
                                                <a style={Styles.planComment}>Quantity</a>
                                                <p style={Styles.planData}>{item.Quantity}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <div >
                                                <a style={Styles.planComment}>SIM Type</a>
                                                {simTypeList && simTypeList.length > 0 && simTypeList.filter(data => data.ID == item.PlanSIMType).map(simType => (
                                                    <p style={Styles.planData} key={simType.ID}>{simType.Value}</p>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <div >
                                                <a style={Styles.planComment}>Variant</a>
                                                <p style={Styles.planData}>{item.VariantName}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <img src={item.ImageUrl ? `${process.env.REACT_APP_BASEURL}/planImages/${item.ImageUrl}` : noImageFound} style={Styles.planImage} className='img-fluid' />
                                </div>
                            </div>

                            {index + 1 != planDetails.length ? <hr /> : ''}

                        </>
                    })
                }

                {showAlert ? <Alert type={alertType} text={alertText} closeBtn={() => setShowAlert(false)} /> : ''}
                <Table columns={columns} loading={loading} data={data} pagination={true} />
            </Modal>

            <Modal
                showModal={viewQRImage}
                title={'SIM NUMBER #: ' + qrImageIccid}
                onHide={() => handleCloseQRImgae()}
                width={600}
                showFooter={false}
            >
                <div className='d-flex justify-content-center'>
                    <img src={qrImageIccid ? `${dealerID == 26 ? 'https://webhook.esimtrav.com' : process.env.REACT_APP_BASEURL}/qrcode/${qrImageIccid}.png` : noImageFound} style={{ width: '280px' }}
                        onError={handleImageError}
                    />
                </div>
            </Modal>
        </>
    )
}

const Styles = {
    hr: {
        border: '1px solid',
        margin: 0
    },
    comment: {
        fontSize: '16px',
        color: '#2B3038',
        fontWeight: 'normal'
    },
    planComment: {
        fontSize: '15px',
        color: '#2B3038'
    },
    planName: {
        marginTop: '-18px'
    },
    planData: {
        marginTop: '-6px'
    },
    planImage: {
        width: '250px',
        margin: 'auto',
        display: 'block'
    }
}
export default OrderModalComponent