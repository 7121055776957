import actions from "./actions";

const initState = {
    profileListResult: [],
    loading: false,
    profileError: null,
    profileMessage: null,
    prepareEditFormModel: false,
    buttonLoading: false,
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_POFILE_DATA_LIST:
            return {
                ...state,
                loading: true,
                profileError: null,
                profileMessage: null,
                buttonLoading: false,
            };
        case actions.GET_POFILE_DATA_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                profileError: null,
                profileMessage: null,
                profileListResult: action.profileListResult,
                buttonLoading: false,
            };
        case actions.GET_POFILE_DATA_LIST_FAILED:
            return {
                ...state,
                loading: false,
                profileError: action.profileError,
                profileMessage: null,
                buttonLoading: false,

            };
        case actions.EDIT_PROFILE_LIST:
            return {
                ...state,
                loading: true,
                profileError: null,
                profileMessage: null,
                buttonLoading: true,

            };
        case actions.EDIT_PROFILE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                profileError: null,
                profileListResult: action.profileListResult,
                prepareEditFormModel: false,
                profileMessage: action.profileMessage,
                buttonLoading: false,
            };
        case actions.EDIT_PROFILE_LIST_FAILED:
            return {
                ...state,
                loading: false,
                profileMessage: null,
                profileError: action.profileError,
                buttonLoading: false,
            };
        case actions.RESET_PROFILE:
            return {
                ...state,
                loading: false,
                profileMessage: null,
                profileError: null,
                buttonLoading: false,
                prepareEditFormModel: false,
            };
        case actions.PREPARE_EDIT_FORM_MODEL:
            return {
                ...state,
                prepareEditFormModel: true,
            };
        case actions.RESET_PROFILE_MESSAGE:
            return {
                ...state,
                loading: false,
                profileMessage: null,
                profileError: null,
                buttonLoading: false,
            };
        default:
            return state;
    }
}
