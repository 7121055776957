import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import OwlCarousel from 'react-owl-carousel';
import EyeLineIcon from 'remixicon-react/EyeLineIcon';
import EyeOffLineIcon from 'remixicon-react/EyeOffLineIcon';
import sideImage from '../assets/images/slide-1.png';
import sideImagetwo from '../assets/images/slide-2.png';
import sideImagethree from '../assets/images/slide-3.png';
import { useDispatch, useSelector } from "react-redux";
import AuthActions from '../redux/auth/actions';
import Alert from "../components/utils/Alert";
import Button from "../components/utils/Button";
const { login } = AuthActions;




function Signin() {
    const dispatch = useDispatch();
    const Auth = useSelector((state) => state.auth);
    const { register, handleSubmit, formState: { errors }, } = useForm();
    const [passwordShow, setPasswordShow] = useState(false);



    const handleLogin = (data) => {
        dispatch(login(data));
        localStorage.setItem('user', 'Address Fill');
    }

    if (Auth.loginMessage) {
        setTimeout(() => {
            localStorage.setItem('token', Auth.loginToken);
            window.location.href = '/dashboard';

        }, 500);
    }
    return (
        <>
            <section className="sign-in-page  mt-lg-5 mt-md-5 pt-4">
                <div className="container bg-white  p-0">
                    <div className="row no-gutters">
                        <div className="col-md-6 align-self-center">
                            <div className="sign-in-from">
                                <a className="sign-in-logo mb-2 text-center d-lg-none d-md-none d-sm-block" href="#"><img src="./assets/images/logo-full2.png" className="img-fluid" alt="logo" /></a>
                                <h1 className="mb-0">Sign in</h1>
                                <p>Enter your email address and password to access admin panel.</p>
                                {Auth.loginMessage ? <Alert type="alert-success" text={Auth.loginMessage} /> : ''}
                                {Auth.loginError ? <Alert type="alert-danger" text={Auth.loginError} /> : ''}

                                <form className="mt-4" onSubmit={handleSubmit(handleLogin)}>

                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Username</label>
                                        <input type="text" className="form-control" name='username' {...register('username', {
                                            required: true
                                        })} placeholder="Enter username" />
                                        {errors.username && errors.username.type === "required" != '' ? <div className="invalid-feedback">Username is required</div> : ''}
                                    </div>

                                    <div className="form-group" style={{ position: 'relative' }}>
                                        <label htmlFor="exampleInputPassword1">Password</label>
                                        <input type={passwordShow ? 'text' : 'password'} className="form-control" name='password' {...register('password', {
                                            required: true
                                        })} placeholder="Enter password" />
                                        {
                                            passwordShow ? <EyeLineIcon size="1.1em" style={Style.eyeIcon} onClick={() => setPasswordShow((pre) => !pre)} /> : <EyeOffLineIcon size="1.1em" style={Style.eyeIcon} onClick={() => setPasswordShow((pre) => !pre)} />
                                        }
                                        {errors.password && errors.password.type === "required" != '' ? <div className="invalid-feedback">Password is required</div> : ''}
                                    </div>
                                    {/* <Link to="/forget-password" className="float-right">Forgot password?</Link> */}
                                    <div className="d-inline-block w-100 mt-1">
                                        {/* <div className="custom-control custom-checkbox d-inline-block mt-2" style={{padding : 'unset'}} >
                                            <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                            <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
                                        <Link to="/forget-password" className="float-right">Forgot password?</Link>
                                        </div> */}
                                        <Button title='Sign in' buttonClass="btn btn-primary float-right w-25" loading={Auth.loginLoading} buttonType='submit' />
                                    </div>
                                    <div className="sign-info">
                                        <span className="dark-color d-inline-block line-height-2">Don't have an account? <Link to="/sign-up">Sign up</Link></span>
                                        <Link to="/forget-password" className="float-right">Forgot password?</Link>
                                    </div>
                                </form>
                            </div>
                        </div>


                        <div className="col-md-6 text-center d-none d-lg-block d-md-block d-sm-none">
                            <div className="sign-in-detail text-white">
                                <a className="sign-in-logo mb-5" href="#"><img src="./assets/images/logo-white.png" className="img-fluid" alt="logo" /></a>

                                <OwlCarousel items={1}>
                                    <div className="item">
                                        <img src={sideImage} className="img-fluid mb-4" alt="logo" />

                                    </div>
                                    <div className="item">
                                        <img src={sideImagetwo} className="img-fluid mb-4" alt="logo" />

                                    </div>
                                    <div className="item">
                                        <img src={sideImagethree} className="img-fluid mb-4" alt="logo" />

                                    </div>
                                </OwlCarousel>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Sign in END --> */}
        </>
    )
}

const Style = {
    eyeIcon: {
        position: 'absolute',
        right: '15px',
        top: '40px',
        cursor: 'pointer'
    },
}


export default Signin;