import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { getList, addData, editData, deleteData } from '../../helpers/fackBackend_Helper';

function* getCouponResponse() {
    try {
        const response = yield call(getList, '/getCoupon');
        if (response.Status) {
            yield put(actions.getCouponSuccess(response.Coupons));
        } else {
            yield put(actions.getCouponFailed(
                response.response.Message
            ));
        }
    } catch (error) {
        yield put(actions.getCouponFailed(error));
    }
}

function* addCouponResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/addCoupon', data);
        if (response.Status) {
            yield put(actions.addCouponSuccess(response.Message, response.Message));
             yield put(actions.getCoupon());
        } else {
            yield put(actions.addCouponFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.addCouponFailed(error));
    }
}

function* editCouponResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/updateCoupon', data);
        if (response.Status) {
                yield put( actions.editCouponSuccess( response.Message) );
                yield put(actions.getCoupon());
        } else {
            yield put(actions.editCouponFailed( response.Message ));
        }
    } catch (error) {
        yield put(actions.editCouponFailed(error));
    }
}

function* deleteCouponResponse({ payload: { couponId, status } }) {
    try {
        const response = yield call(deleteData, '/updateCouponStatus/' + status + '/' + couponId);
        if (response.Status) {
            yield put(actions.deleteCouponSuccess(response.Message))
                yield put(actions.getCoupon());
        } else {
            yield put(actions.deleteCouponFailed(
                response.response.Message
            ));
        }
    } catch (error) {
        yield put(actions.deleteCouponFailed(error));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.ADD_COUPON, addCouponResponse)]);
    yield all([takeEvery(actions.GET_COUPON, getCouponResponse)]);
    yield all([takeEvery(actions.DELETE_COUPON, deleteCouponResponse)]);
    yield all([takeEvery(actions.EDIT_COUPON, editCouponResponse)]);
}