import React from 'react'
import Button from './Button';
import { Link } from 'react-router-dom';
function Message({ image, message, amount, content1, content2, link, buttonTitle, buttonClass, onClick }) {
    return (
        <div className='text-center py-2'>
            <img src={image} style={{ width: '90px', marginTop: '10px' }} />
            <h2 style={{ fontWeight: '600', fontSize: '35px' }}>{message}</h2>
            <h2 style={{ fontWeight: '900' }}>{amount}</h2>
            <h4 style={{ marginTop: '5px' }}>{content1}</h4>
            <h5>{content2}</h5>
            <Link to={link ? link : `/dashboard`}><Button onClick={onClick} title={buttonTitle} style={{ minWidth: "100px" }} buttonClass={buttonClass ? buttonClass : "btn btn-danger my-4"} /></Link>
        </div>

    )
}

export default Message