import React, { useEffect, useRef, useState } from 'react';
import CardLayout from '../../../components/utils/CardLayout';
import SearchBar from '../../../components/utils/SearchBar';
import SideButtons from '../../../components/utils/SideButtons';
import Table from '../../../components/utils/Table';
import couponActions from '../../../redux/coupon/actions'
import { useDispatch, useSelector } from 'react-redux';
import Tag from '../../../components/utils/Tag';
import { Link } from 'react-router-dom';
import CheckboxCircleFill from 'remixicon-react/CheckboxCircleFillIcon';
import PencilFill from 'remixicon-react/PencilFillIcon';
import Popconfirm from '../../../components/utils/Popover';
import DeleteBin5Fill from 'remixicon-react/DeleteBin5FillIcon';
import moment from "moment";
import Modal from '../../../components/utils/Model';
import Button from '../../../components/utils/Button';
import { useForm } from 'react-hook-form';
import Calendar2LineIcon from 'remixicon-react/Calendar2LineIcon';
import DatePicker from "react-datepicker";
import SimPurchaseActions from '../../../redux/simPurchase/actions'
import Alert from '../../../components/utils/Alert';


const { getSimTypeData } = SimPurchaseActions;
const { prepareCouponForm, resetCouponForm, addCoupon, getCoupon, deleteCoupon, editCoupon, resetCoupon } = couponActions;

function Coupons() {
    const dispatch = useDispatch()
    const { couponData, couponFormModal, couponLoading, couponMessage, couponError, addCouponMessage, addCouponError, editCouponError, editCouponMessage, deleteMessage, deleteError, showErrorAlert } = useSelector((state) => state.coupon);
    const couponTypeList = useSelector((state) => state.simPurchase.simTypeList);
    const [filterText, setFilterText] = useState("");
    const [formTitle, setFormTitle] = useState('');
    const [formButton, setFormButton] = useState('');
    const [editFlag, setEditFlag] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');
    const [couponLimitFlag, setCouponLimitFlag] = useState(false);
    const [editCheck, setEditCheck] = useState(false);
    const datePickerRef = useRef(null);
    const datePickerRef2 = useRef(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [couponTypeError, setCouponTypeError] = useState(false);

    const [couponFormData, setCouponFormData] = useState({
        couponId: "",
        couponCode: "",
        couponType: "",
        startDate: '',
        endDate: '',
        couponFigure: "",
        couponLimit: null,
        couponLimitFlag: '',
    });

    const { register, handleSubmit, formState: { errors }, watch, reset, control, setValue } = useForm({
        defaultValues: couponFormData,
    });
    let watchCouponType = watch('couponType')

    useEffect(() => {
        if (watchCouponType) {
            setCouponFormData(prevState => ({
                ...prevState,
                couponType: watchCouponType,
            }));
        }
    }, [watchCouponType])

    const sendPlanSimType = {
        "tableName": "coupon",
        "fieldName": "CouponType"
    };

    useEffect(() => {
        dispatch(getCoupon());
        dispatch(getSimTypeData(sendPlanSimType))
    }, []);

    useEffect(() => {
        reset(couponFormData);
    }, [editFlag]);

    useEffect(() => {
        if (addCouponError !== null && addCouponError !== undefined) {
            displayAlert(addCouponError, 'alert-danger');
        }
        if (addCouponMessage !== null && addCouponMessage !== undefined) {
            displayAlert(addCouponMessage, 'alert-success');
            formFieldReset()
        }
        if (editCouponError !== null && editCouponError !== undefined) {
            displayAlert(editCouponError, 'alert-danger');
        }
        if (editCouponMessage !== null && editCouponMessage !== undefined) {
            displayAlert(editCouponMessage, 'alert-success');
            formFieldReset()
        }
        if (deleteError !== null && deleteError !== undefined) {
            displayAlert(deleteError, 'alert-danger');
        }
        if (deleteMessage !== null && deleteMessage !== undefined) {
            displayAlert(deleteMessage, 'alert-success');
            // formFieldReset()
        }
    }, [addCouponError, addCouponMessage, editCouponMessage, editCouponError, deleteMessage, deleteError]);

    const columns = [
        { displayName: '#', key: 'key' },
        { displayName: 'COUPON CODE', key: 'couponCode' },
        { displayName: 'COUPON TYPE', key: 'couponType' },
        { displayName: 'COUPON FIGURE', key: 'couponFigure' },
        { displayName: 'USAGES LIMIT', key: 'usagesLimit' },
        { displayName: 'LIMIT ALLOWED', key: 'limitAllowed' },
        { displayName: 'START DATE', key: 'startDate' },
        { displayName: 'END DATE', key: 'endDate' },
        { displayName: 'STATUS', key: 'status' },
        { displayName: 'ACTIONS', key: 'action' },
    ];
    const filteredItems = couponData && couponData.length > 0 && couponData.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    var data = filteredItems && filteredItems.length > 0 && filteredItems.map((coupon, key) => {
        var actionContent = (
            <>
                <Link to="#">
                    <span data-toggle="tooltip" data-placement="top" title="Edit"><PencilFill size="1.2em" style={{ color: "#888b8e", marginRight: "3px" }} onClick={() => handleOpenEditFormModal(coupon, coupon.ID)} /></span>
                </Link>
                {coupon.Status === 1 ? (
                    <Popconfirm
                        title="Are you sure to inactive this plan?"
                        okText="Yes"
                        cancelText="No"
                        placement="topRight"
                        onConfirm={() => handleDelete(coupon.ID, 0)}
                    >
                        <Link className="deleteBtn" to="# ">
                            <span data-toggle="tooltip" data-placement="top" title="Inactive"><DeleteBin5Fill size="1.2em" style={{ color: "#f14336", marginRight: "3px" }} /></span>
                        </Link>
                    </Popconfirm>
                ) : (
                    <Popconfirm
                        title="Are you sure to active this plan?"
                        okText="Yes"
                        cancelText="No"
                        placement="topRight"
                        onConfirm={() => handleDelete(coupon.ID, 1)}
                    >
                        <Link className="deleteBtn" to="# ">
                            <span data-toggle="tooltip" data-placement="top" title="Active"><CheckboxCircleFill size="1.2em" style={{ color: "#2cb549", marginRight: "3px" }} /></span>
                        </Link>
                    </Popconfirm>
                )}

            </>
        );

        return {
            key: key + 1,
            couponCode: coupon.CouponCode,
            couponType: coupon.CouponType,
            couponFigure: coupon.CouponType === 'Fixed' ? `£${coupon.CouponFigure.toFixed(2)}` : `${Math.abs(coupon.CouponFigure)}%`,
            usagesLimit: <Tag color={`${coupon.CouponLimitFlag === 1 ? "badge-success" : "badge-danger"}`} title={coupon.CouponLimitFlag === 1 ? 'Yes' : 'No'} />,
            limitAllowed: (coupon.CouponLimitFlag === 0) ? 'Unlimited' : coupon.CouponLimit,
            startDate: coupon.StartDtTm !== null ? moment(new Date(coupon.StartDtTm)).format(`${process.env.REACT_APP_DATE_FORMAT}`) : '',
            endDate: coupon.EndDtTm !== null ? moment(new Date(coupon.EndDtTm)).format(`${process.env.REACT_APP_DATE_FORMAT}`) : '',
            status: <Tag color={`${coupon.Status === 1 ? "badge-success" : "badge-danger"}`} title={coupon.Status === 1 ? 'Active' : 'Inactive'} />,
            action: actionContent,
        }
    });

    const handleFilterChange = (value) => {
        setFilterText(value);
    };

    const handleOpenAddFormModal = () => {
        setCouponFormData({
            couponId: "",
            couponCode: "",
            couponType: "",
            startDate: "",
            endDate: "",
            couponFigure: "",
            couponLimit: null
        });
        setFormTitle('Add Coupon');
        setFormButton('Submit');
        dispatch(prepareCouponForm())
    }

    const handleOpenEditFormModal = (data, id) => {
        setFormTitle('Edit Coupon');
        setFormButton('Update');
        setEditFlag(true)
        dispatch(prepareCouponForm())

        if (data.CouponLimitFlag) {
            setCouponLimitFlag(true);
            setEditCheck(true);
        }
        setCouponFormData(prevState => ({
            ...prevState,
            couponId: id,
            couponCode: data.CouponCode,
            couponType: data.CouponTypeId,
            startDate: moment(new Date(data.StartDtTm)).format(`${process.env.REACT_APP_DATE_FORMAT}`),
            endDate: moment(new Date(data.EndDtTm)).format(`${process.env.REACT_APP_DATE_FORMAT}`),
            couponFigure: data.CouponFigure,
            couponLimit: data.CouponLimit,
            couponLimitFlag: data.CouponLimitFlag,
        }));

        const customStartDate = moment(data.StartDtTm, moment.ISO_8601).toDate();
        const customEndDate = moment(data.EndDtTm, moment.ISO_8601).toDate();
        setStartDate(customStartDate);
        setEndDate(customEndDate);
    }

    const handleDelete = (id, status) => {
        dispatch(deleteCoupon(id, status))
    }

    const onSubmit = (data) => {
        data.startDate = couponFormData.startDate
        data.endDate = couponFormData.endDate
        data.couponLimitFlag = couponFormData.couponLimitFlag ? couponFormData.couponLimitFlag : 0;
        data.couponLimit = data.couponLimit ? data.couponLimit : 0;
        delete data.couponId;
        if (data.couponType == 57 && data.couponFigure > 100) {
            setCouponTypeError(true)
        }
        else {
            dispatch(addCoupon(data))
        }
    }

    const handleUpdate = (data) => {
        data.startDate = moment(couponFormData.startDate).format('YYYY-MM-DD')
        data.endDate = moment(couponFormData.endDate).format('YYYY-MM-DD')
        data.couponLimitFlag = couponFormData.couponLimitFlag ? couponFormData.couponLimitFlag : 0;
        data.couponLimit = data.couponLimit ? data.couponLimit : 0;
        if (data.couponType == 57 && data.couponFigure > 100) {
            setCouponTypeError(true)
        }
        else {
            dispatch(editCoupon(data))
        }

    }

    const handleCancel = () => {
        dispatch(resetCouponForm())
        reset(couponFormData)
        setStartDate(null)
        setEndDate(null)
        setCouponLimitFlag(false);
        setEditCheck(false);
        setCouponFormData({
            couponId: "",
            couponCode: "",
            couponType: "",
            startDate: "",
            endDate: "",
            couponFigure: "",
            couponLimit: null
        });
        setCouponFormData(prevState => ({
            ...prevState,
            couponType: ''
        }));
        setEditFlag(false)
        setCouponTypeError(false)
    }

    const formFieldReset = () => {
        reset(couponFormData)
        setStartDate(null)
        setEndDate(null)
        setCouponLimitFlag(false);
        setEditCheck(false);
        setCouponFormData({
            couponId: "",
            couponCode: "",
            couponType: "",
            startDate: "",
            endDate: "",
            couponFigure: "",
            couponLimit: null
        });
        setCouponFormData(prevState => ({
            ...prevState,
            couponType: ''
        }));
        setEditFlag(false)
        setCouponTypeError(false)
    }

    const openDatePicker = () => {
        datePickerRef.current.setOpen(true);
    };
    const openDatePicker2 = () => {
        datePickerRef2.current.setOpen(true);
    };
    const handleStartDateChange = (date) => {
        setStartDate(date);
        setCouponFormData(prevState => ({
            ...prevState,
            startDate: moment(date).format('YYYY-MM-DD')
        }));
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
        setCouponFormData(prevState => ({
            ...prevState,
            endDate: moment(date).format('YYYY-MM-DD')
        }));
    };

    const handleCouponLimit = (e) => {
        if (e.target.checked) {
            setCouponFormData(prevState => ({
                ...prevState,
                ['couponLimitFlag']: 1
            }));
        } else {
            setCouponFormData(prevState => ({
                ...prevState,
                ['couponLimitFlag']: 0,
                ['couponLimit']: null
            }));
        }
        setCouponLimitFlag(e.target.checked);
        setEditCheck(e.target.checked);
    }

    const displayAlert = (text, type) => {
        setAlertText(text);
        setAlertType(type);
        setShowAlert(true);
        dispatch(resetCoupon());
    };

    const alertCancel = () => {
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
        dispatch(resetCoupon());
    }

    const handlePatternChange = (e) => {
        let inputValue = e.target.value;
        const regex = /^(0|[1-9]\d*)(\.\d{0,2})?$/
        if (!regex.test(inputValue)) {
            setValue('couponFigure', parseFloat(inputValue).toFixed(2))
        }
    }

    return (
        <CardLayout title="Coupon">
            {showAlert && !showErrorAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}
            <div className="row">
                <div className='col-lg-6 col-md-6  od-2'>
                    <SearchBar onFilter={handleFilterChange} filterText={filterText} />
                </div>
                <div className='col-lg-6 col-md-6  text-right'>
                    <SideButtons title="Add Coupon" buttonStyle="primary" onClick={handleOpenAddFormModal} />
                </div>

            </div>
            <Table columns={columns} loading={couponLoading} data={data} pagination={true} />
            <Modal
                showModal={couponFormModal}
                title={formTitle}
                onHide={handleCancel}
                width={550}
                showFooter={false}
            >
                {showAlert && showErrorAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}
                <form onSubmit={handleSubmit(editFlag ? handleUpdate : onSubmit)}>
                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <label>Coupon Code</label>
                            <input type="text" placeholder="Enter Coupon Code" className="form-control"
                                name="couponCode" {...register("couponCode", {
                                    required: true,
                                })} />
                            {errors.couponCode?.type === "required" && (
                                <div className="invalid-feedback">Coupon code is required !</div>

                            )}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Select Coupon Type</label>
                            <select className="form-control" placeholder="Select Coupon" name="couponType"  {...register("couponType", {
                                required: true,
                            })}
                            >
                                <option value="" key="0">Select</option>
                                {couponTypeList && couponTypeList.length > 0 && couponTypeList.map((item, ind) => (
                                    <option name="couponType" value={item.ID} key={item.ID}>{item.Value}</option>
                                ))}
                            </select>
                            {errors.couponType?.type === "required" && (
                                <div className="invalid-feedback">Coupon type is required !</div>
                            )}
                        </div>

                        <div className="col-md-5 mb-3">
                            <label>Coupon Figure</label>
                            <input type="tel"
                                placeholder={couponFormData.couponType == 56 ? 'Enter Fixed Amount' : couponFormData.couponType == 57 ? 'Enter Percentage For Coupon' : 'Enter Coupon Figure'}
                                className="form-control"
                                name="couponFigure"
                                {...register("couponFigure", {
                                    required: true,
                                })}
                                onChange={handlePatternChange}
                            />
                            {errors.couponFigure?.type === "required" && (<div className="invalid-feedback">Coupon figure is required !</div>)}
                            {couponTypeError ? <div className="invalid-feedback">Coupon figure can not be more then 100%!</div> : ''}
                        </div>

                        <div className="col-md-3 mb-3 d-flex align-items-start flex-column">
                        <label className=''>Coupon Limit</label>
                            <div className='coupon-check-parent d-flex align-items-start'>
                            <input type="checkbox" className="form-control coupon-check-box ml-0" style={{cursor: 'pointer'}} checked={editCheck} placeholder="Enter Coupon Figure" name="couponLimitFlag" {...register("couponLimitFlag", {
                            })} onChange={handleCouponLimit} />
                            
                            </div>
                        </div>

                        {couponLimitFlag ? <div className="col-md-4 mb-3">
                            <label>Coupon Allowed</label>
                            <input type="tel" className="form-control" placeholder="Enter Coupon Allowed" name="couponLimit" {...register("couponLimit", {
                                required: true,
                                pattern: /^(0|[1-9]\d*)?$/,
                            })} />
                            {errors.couponLimit?.type === "required" && (<div className="invalid-feedback">Coupon allowed is required !</div>)}
                            {errors.couponLimit?.type === "pattern" && (<div className="invalid-feedback">Please enter integer value for coupon allowed!</div>)}                        </div> : ''}

                        <div className="col-md-6 mb-2 d-flex flex-column">
                            <label>Start Date</label>
                            <Calendar2LineIcon onClick={openDatePicker} size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', 'top': '36px', right: '8px', zIndex: 1 }} />
                            <DatePicker
                                ref={datePickerRef}
                                selected={startDate}
                                onChange={handleStartDateChange}
                                selectsStart
                                className="form-control"
                                startDate={startDate}
                                endDate={endDate}
                                showMonthDropdown
                                showYearDropdown
                            // required
                            />
                        </div>

                        <div className="col-md-6 mb-2 d-flex flex-column">
                            <label>End Date</label>
                            <Calendar2LineIcon disabled={editFlag} onClick={openDatePicker2} size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', 'top': '36px', right: '8px', zIndex: 1 }} />
                            <DatePicker
                                ref={datePickerRef2}
                                selected={endDate}
                                className="form-control"
                                onChange={handleEndDateChange}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                showMonthDropdown
                                showYearDropdown
                            // required
                            />
                        </div>

                    </div>

                    <hr />
                    <div className='text-right row'>
                        <div className="col-md-12">
                            <button type="button" className="btn btn-secondary mr-2" onClick={handleCancel}>Close</button>
                            <Button title={formButton} style="minWidth:80px" buttonClass="btn btn-primary" loading={couponLoading} buttonType='submit' />
                        </div>
                    </div>
                </form>
            </Modal>

        </CardLayout>
    )
}

export default Coupons;

