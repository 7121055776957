import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import AddtoFundAction from '../../../redux/addToFund/actions'
import { useDispatch, useSelector } from 'react-redux';
import paymentSuccessImg from '../../../assets/images/Hand-successful.png'
import paymentErrorImg from '../../../assets/images/payment-error-m.png'
import Message from '../../../components/utils/Message';
import Box from '../../../components/utils/Box';
import ErrorImage from '../../../assets/images/close.png';
import AuthActions from '../../../redux/auth/actions';


const { getUserDataByToken } = AuthActions;
const { sendPaymentData, resetAddToFundMessage } = AddtoFundAction;

const Return = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const afterPaymentSuccessMessage = useSelector((state) => state.addToFund.afterPaymentSuccessMessage);
    const orderError = useSelector((state) => state.addToFund.orderError);
    const [status, setStatus] = useState(null);

    useEffect(() => {
        dispatch(getUserDataByToken());
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get('session_id');
        const paymentId = urlParams.get('payment_id');

        fetch(`${process.env.REACT_APP_BASEURL}/session-status?session_id=${sessionId}`)
            .then((res) => res.json())
            .then((data) => {
                setStatus(data.status);
                const obj = {
                    paymentId: paymentId,
                    stripeResponse: data.payment_intent,
                    transcation_id: data.transcation_id
                }
                dispatch(sendPaymentData(obj));
            });
    }, []);

    const handleBack = () => {
        window.history.replaceState(null, '', '/add-fund');
        // Replace the previous entry in the history stack (the /return route)
        window.history.replaceState(null, '', '/');
        dispatch(resetAddToFundMessage());
    }



    if (status === 'open') {
        return (
            navigate('/add-fund')
        )
    }

    if (afterPaymentSuccessMessage == 'Payment success') {
        return (
            <div id="content-page" class={`content-page `}>
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-sm-6">
                            <div class="card-style-layout card-style-success-p">
                                <div className='confirm-order-container confirm-order-inner-succes '>
                                    <div className='img-parent-success'><img src={paymentSuccessImg} alt="Logo" /></div>
                                    <div className='payment-content-text mt-2 text-center pb-3'>
                                        <h4 className='text-center text-bolder'>Payment Successfull</h4>
                                        <p className='text-center para-success-text m-0'>Amount added successfully in your wallet.</p>
                                        <p className='text-center para-success-text p-0 m-0'>Thanks for being there with us.</p>
                                        <Link to="/dashboard"><button className='payment-successfulbtn mt-2' onClick={handleBack}>OK</button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else if (orderError == 'Payment failed') {
        return <div id="content-page" class={`content-page `}>
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-sm-6">
                        <div class="card-style-layout card-style-success-p-feaild">
                            <div className='confirm-order-container confirm-order-inner-succes-feaild '>
                                <div className='img-parent-success pt-2'><img src={paymentErrorImg} alt="Logo" /></div>
                                <div className='payment-content-text-feaild mt-2 text-center pb-3'>
                                    <h4 className='text-center text-bolder'>Payment failed</h4>
                                    <p className='text-center para-success-text m-0'>Hey,seems like there was some trouble.</p>
                                    <p className='text-center para-success-text p-0 m-0'>please contact to admin !</p>
                                    <Link to="/add-fund"><button onClick={handleBack} className='payment-successfulbtn-feaild mt-2 '>OK</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    return <div id="content-page" class={`content-page `}>
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <div class="card-style-layout">
                        <div class="spinnerInner"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Return
