import React from 'react'
import { Navigate } from 'react-router-dom';
import { isDealer, isDealerAgent, isTariffCreationAllow } from '../../helpers/commonFunction'
import { useSelector } from 'react-redux';
import Loader from './Loader';


const Dealer = ({ children }) => {
    const tokenData = useSelector((state) => state.auth.tokenData);
    const getUserDetailsLoading = useSelector((state) => state.auth.getUserDetailsLoading);
    if (getUserDetailsLoading) {
        return <Loader />
    } else {
        if (tokenData.length > 0) {
            if (isDealer(tokenData) || isDealerAgent(tokenData)) {
                return <>{children}</>
            } else {
                return <Navigate to='/404' />
            }
        } else {
            return <Loader />
        }
    }
}

const DealerTariffCreationAllow = ({ children }) => {
    const tokenData = useSelector((state) => state.auth.tokenData);
    const getUserDetailsLoading = useSelector((state) => state.auth.getUserDetailsLoading);
    if (getUserDetailsLoading) {
        return <Loader />
    } else {
        if (tokenData.length > 0) {
            if (isTariffCreationAllow(tokenData) || isDealerAgent(tokenData)) {
                return <>{children}</>
            } else {
                return <Navigate to='/404' />
            }
        } else {
            return <Loader />
        }
    }
}

export { DealerTariffCreationAllow }
export default Dealer