import { BrowserRouter, Routes, Route, Outlet, Navigate } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import { jwtDecode } from "jwt-decode";
import './assets/css/style.css'
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import Admin from "./components/utils/Admin";
import DealerComponent, { DealerTariffCreationAllow } from "./components/utils/Dealer";
import NotFound from "./components/utils/NotFound";
import Dashboard from "./pages/Dashboard";
import Dealer from "./pages/Admin/Dealer/Dealer";
import VerifyDealer from "./pages/Admin/VerifyDealer/VerifyDealer";
import User from "./pages/Admin/User/User";
import ForgetPassword from "./pages/ForgetPassword";
import ResetPassword from "./pages/ResetPassword";
import OrderReport from "./pages/Reports/Order Report/OrderReport";
import AuditReport from "./pages/Reports/Audit Report/AuditReport";
import SalesReport from "./pages/Reports/Sales Report/SalesReport";
import Aggregator from "./pages/Admin/Config/Aggregator/Aggregator";
// import EmailTemplate from "./pages/Admin/Config/EmailTemplate/EmailTemplate";
import Banner from "./pages/Admin/Config/Banner/Banner";
import MyProfile from "./pages/Profile/MyProfile";
import AddFund from "./pages/Admin/AddFund/AddFund";
import TariffGroup from "./pages/Package/Tariff Group/TariffGroup";
import PlanPurchase from "./pages/Package/PlanPurchase/PlanPurchase";
import Plan from "./pages/Package/Plan/Plan";
import OrderRefund from "./pages/Package/Order Refund/OrderRefund";
import RefundRequest from "./pages/Package/Refund Request/RefundRequest";
import Cart from "./pages/Cart/Cart";
import ListSim from "./pages/Inventory/ListSim/ListSim";
import SimPurchase from "./pages/Inventory/SimPurchase/SimPurchase";
import ManualAddFund from "./pages/Admin/ManualAddFund/ManualAddFund";
import Return from "./pages/Admin/AddFund/Return";
import Activity from "./pages/Other/Activity/Activity";
import EmailTemplate from "./pages/Admin/Config/EmailTemplate/EmailTemplate";
import Coupon from "./pages/Package/Coupon/Coupons";

function App() {
  // const tokenData = useSelector((state) => state.auth.tokenData);

  const Layout = () => {
    return (
      <>
        <Header />
        <Outlet />
        <Footer />
      </>
    )
  }

  const isAuthenticated = () => {
    let token = localStorage.getItem("token");
    if (token) {
      let decodedToken = jwtDecode(token);
      let currentDate = new Date();
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        localStorage.removeItem("token");
        return false;
      } else {
        return true;
      }
    } else {
      localStorage.removeItem("token");
      return false;
    }
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="/sign-in" element={isAuthenticated() ? <Navigate to="/dashboard" /> : <Signin />} />
          <Route path="/sign-up" element={isAuthenticated() ? <Navigate to="/dashboard" /> : <Signup />} />
          <Route path="/forget-password" element={isAuthenticated() ? <Navigate to="/dashboard" /> : <ForgetPassword />} />
          <Route path="/reset-password/*" element={isAuthenticated() ? <Navigate to="/dashboard" /> : <ResetPassword />} />
          <Route path="/" element={!isAuthenticated() ? <Navigate to="/sign-in" /> : <Layout />}>

            {/* Common route */}
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/order-report" element={<OrderReport />} />
            <Route path="/audit-report" element={<AuditReport />} />
            <Route path='/dealer' element={<Dealer />}></Route>
            <Route path='/user' element={<User />}></Route>
            <Route path='/my-profile' element={<MyProfile />}></Route>
            <Route path='/tariff-group' element={<DealerTariffCreationAllow><TariffGroup /></DealerTariffCreationAllow>}></Route>
            <Route path='/list-sim' element={<ListSim />}></Route>
            <Route path='/activity-log' element={<Activity />}></Route>
            {/* End Common route */}


            {/* Admin route */}
            <Route path='/aggregator' element={<Admin><Aggregator /></Admin>}></Route>
            <Route path='/email-template' element={<Admin><EmailTemplate /></Admin>}></Route>
            <Route path='/verify-dealer' element={<Admin><VerifyDealer /></Admin>}></Route>
            <Route path='/manual-top-up' element={<Admin><ManualAddFund /></Admin>}></Route>
            <Route path='/banner' element={<Admin><Banner /></Admin>}></Route>
            <Route path='/plan' element={<Admin><Plan /></Admin>}></Route>
            <Route path='/refund-request' element={<Admin><RefundRequest /></Admin>}></Route>
            <Route path='/sim-purchase' element={<Admin><SimPurchase /></Admin>}></Route>
            <Route path='/coupon' element={<Admin><Coupon /></Admin>}></Route>

            {/* End Admin route */}


            {/* Dealer route */}
            <Route path='/add-top-up' element={<DealerComponent><AddFund /></DealerComponent>}></Route>
            <Route path='/return' element={<DealerComponent><Return /></DealerComponent>}></Route>
            <Route path='/plan-purchase' element={<DealerComponent><PlanPurchase /></DealerComponent>}></Route>
            <Route path='/order-refund' element={<DealerComponent><OrderRefund /></DealerComponent>}></Route>
            <Route path='/cart' element={<DealerComponent><Cart /></DealerComponent>}></Route>
            {/* End Dealer route */}


            {/* <Route path="/sales-report" element={<SalesReport />} /> */}
            <Route path='/add-to-cart' element={<Cart />}></Route>
          </Route>

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
