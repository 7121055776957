import actions from "./actions";

const initState = {
    refundRequestResult: [],
    loading: false,
    error: null,
    buttonLoading: false,
    refundMessage: null,
    refundError: null,
    refundModal: false,
};

export default function reducer(state = initState, action) {
    switch (action.type) {

        case actions.CLOSE_REFUND_REQUEST_MODAL:
            return {
                ...state,
                refundModal: false
            };

        case actions.OPEN_REFUND_REQUEST_MODAL:
            return {
                ...state,
                refundModal: true
            };

        case actions.GET_REFUND_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                buttonLoading: true
            };
        case actions.GET_REFUND_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                buttonLoading: false,
                error: null,
                refundRequestResult: action.refundRequestResult,
            };
        case actions.GET_REFUND_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case actions.RESET_REFUND_REQUEST_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                buttonLoading: false,
                refundMessage: null,
                refundError: null
            };

        case actions.TO_REFUND_APPROVED:
            return {
                ...state,
                loading: false,
                buttonLoading: true,
            };
        case actions.TO_REFUND_APPROVED_SUCCESS:
            return {
                ...state,
                loading: false,
                refundMessage: action.payload.message,
                buttonLoading: false,
                refundModal: false,
            }
        case actions.TO_REFUND_APPROVED_FAILED:
            return {
                ...state,
                loading: false,
                refundError: action.refundError
            }

        case actions.TO_REFUND_CANCELLED:
            return {
                ...state,
                loading: false,
                buttonLoading: true
            };
        case actions.TO_REFUND_CANCELLED_SUCCESS:
            return {
                ...state,
                loading: false,
                buttonLoading: false,
                refundMessage: action.payload.message,
                refundModal: false,
            }
        case actions.TO_REFUND_CANCELLED_FAILED:
            return {
                ...state,
                loading: false,
                refundError: action.refundError

            }
        default:
            return state;
    }
}