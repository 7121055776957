import actions from "./actions";

const initState = {
    loading: false,
    tariffResult: [],
    tariffError: null,
    tariffMessage: null,
    buttonLoading: false,
    tariffFormModal: false,
    tariffMapedData: [],
    showAddTariff: false,
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_TARIFF:
            return {
                ...state,
                loading: true,
                tariffMessage: null,
                tariffError: null,
                buttonLoading: false,
                showAddTariff: false,
            };
        case actions.GET_TARIFF_SUCCESS:
            return {
                ...state,
                loading: false,
                tariffResult: action.tariffResult,
                tariffMessage: null,
                tariffError: null,
                buttonLoading: false,
                showAddTariff: false,
            };
        case actions.GET_TARIFF_FAILED:
            return {
                ...state,
                loading: false,
                tariffError: action.tariffError,
                tariffMessage: null,
                buttonLoading: false,
                showAddTariff: false,
            };
        case actions.ADD_TARIFF:
            return {
                ...state,
                loading: true,
                tariffMessage: null,
                tariffError: null,
                buttonLoading: true,
            };
        case actions.ADD_TARIFF_SUCCESS:
            return {
                ...state,
                loading: false,
                tariffError: null,
                tariffMessage: action.tariffMessage,
                tariffResult: action.tariffResult,
                buttonLoading: false,
                showAddTariff: false,
            };
        case actions.ADD_TARIFF_FAILED:
            return {
                ...state,
                loading: false,
                tariffMessage: null,
                tariffError: action.tariffError,
                buttonLoading: false,
                showAddTariff: true,
            };
        case actions.EDIT_TARIFF:
            return {
                ...state,
                loading: true,
                tariffMessage: null,
                tariffError: null,
                buttonLoading: true,
            };
        case actions.EDIT_TARIFF_SUCCESS:
            return {
                ...state,
                loading: false,
                tariffError: null,
                tariffMessage: action.tariffMessage,
                tariffResult: action.tariffResult,
                buttonLoading: false,
                showAddTariff: false,
            };

        case actions.GET_TARIFF_DATA_BY_ID:
            return {
                ...state,
                loading: true,
                tariffMessage: null,
                tariffError: null,
            };
        case actions.GET_TARIFF_DATA_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                tariffMessage: null,
                tariffError: null,
                tariffMapedData: action.tariffMapedData
            };
        case actions.DELETE_TARIFF:
            return {
                ...state,
                loading: true,
                tariffMessage: null,
                tariffError: null,
                buttonLoading: false,
                tariffFormModal: false,
                showAddTariff: false
            };
        case actions.DELETE_TARIFF_SUCCESS:
            return {
                ...state,
                loading: false,
                tariffError: null,
                tariffMessage: action.tariffMessage,
                tariffResult: action.tariffResult,
                buttonLoading: false,
                tariffFormModal: false,
                showAddTariff: false
            };

        case actions.DELETE_TARIFF_FAILED:
            return {
                ...state,
                loading: false,
                tariffMessage: null,
                tariffError: action.tariffError,
                buttonLoading: false,
                showAddTariff: false,
            }

        case actions.PREPARE_TARIFF_FORM:
            return {
                ...state,
                loading: false,
                tariffError: null,
                tariffMessage: null,
                buttonLoading: false,
                showAddTariff: true,
            };
        case actions.RESET_TARIFF:
            return {
                ...state,
                loading: false,
                tariffError: null,
                tariffMessage: null,
                buttonLoading: false,
                showAddTariff: false,
            };
        case actions.RESER_TARIFF_MESSAGE:
            return {
                ...state,
                loading: false,
                tariffError: null,
                tariffMessage: null,
                buttonLoading: false,
            };
        case actions.PREPARE_TARIFF_FORM_MODAL:
            return {
                ...state,
                loading: false,
                tariffError: null,
                tariffMessage: null,
                buttonLoading: false,
                showAddTariff: true
            }
        case actions.RESET_TARIFF_FORM_MODAL:
            return {
                ...state,
                loading: false,
                tariffError: null,
                tariffMessage: null,
                buttonLoading: false,
                showAddTariff: true
            }
        default:
            return state;
    }
}
