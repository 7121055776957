import { useState, useEffect, useRef, useMemo } from 'react'
import CardLayout from '../../../components/utils/CardLayout'
import Table from '../../../components/utils/Table'
import SearchBar from '../../../components/utils/SearchBar'
import SideButtons from '../../../components/utils/SideButtons';
import Modal from '../../../components/utils/Model';
import { useForm, useFieldArray } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import countryAction from '../../../redux/country/actions'
import planAction from '../../../redux/plan/actions'
import Alert from '../../../components/utils/Alert';
import PencilFill from 'remixicon-react/PencilFillIcon';
import DeleteBin5Fill from 'remixicon-react/DeleteBin5FillIcon';
import CheckboxCircleFill from 'remixicon-react/CheckboxCircleFillIcon';
import EyeLineIcon from 'remixicon-react/EyeLineIcon';
import EyeOffLineIcon from 'remixicon-react/EyeOffLineIcon';
import { Link } from 'react-router-dom';
import Popconfirm from '../../../components/utils/Popover';
import moment from "moment";
import Tag from '../../../components/utils/Tag';
import Compressor from 'compressorjs';
import Calendar2LineIcon from 'remixicon-react/Calendar2LineIcon';
import DatePicker from "react-datepicker";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CloseCircleFill from 'remixicon-react/CloseCircleFillIcon';
import Button from '../../../components/utils/Button';
import AddFillIcon from 'remixicon-react/AddFillIcon';
import EyeFill from 'remixicon-react/EyeFillIcon';
import PlanModel from '../../../components/utils/PlanModel';
import aggregatorActions from '../../../redux/aggregator/actions';
import { isEmpty } from '../../../helpers/commonFunction';
import SimPurchaseActions from '../../../redux/simPurchase/actions'

const {getSimTypeData } = SimPurchaseActions;


function Plan() {
    const dispatch = useDispatch();
    const { addPlan, preparePlanForm, resetPlan, getBundleNameData, getPlan, resetPlanMessage, deletePlan, getPlanData, getPlanDay, checkPlanName } = planAction;
    const { getCountry } = countryAction;
    const { getAggregator } = aggregatorActions;
    const countryResult = useSelector((state) => state.country.countryResult);
    const { list: aggregatorList } = useSelector((state) => state.aggregator);
    const { simTypeList} = useSelector((state) => state.simPurchase);
    const { planFormModal, planBundleName, planResult, planMessage, bundleError, planError, planNameMessage, loading, planDay, planData, buttonLoading } = useSelector((state) => state.plan);
    const [filterText, setFilterText] = useState("");
    const [showViewModal, setShowViewModal] = useState(false)
    const [planViewData, setplanViewData] = useState()
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');
    const [formTitle, setFormTitle] = useState('');
    const [editFlag, setEditFlag] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileImage, setSelectedFileImage] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date(new Date().setFullYear(new Date().getFullYear() + 1)));
    const [planFromData, setPlanFromData] = useState({
        name: "",
        dateEarliestAvailable: startDate.getTime(),
        dateLatestAvailable: endDate.getTime(),
        availableForFlag: 18,
        preferedAggregator: '',
        availableForID: "",
        planDesc: "",
        featuredPlan: 0,
        bestSeller: 0,
        checkBox: "empty",
        imageUrl: "",
        PlanVariant: [
            {
                variantName: "",
                planData: "",
                planPrice: "",
                planDays: "",
                eSIMGORef: "",
                isActive: 1,
                planSIMType:"",
            }
        ]
    })
    const emptyData = {
        name: "",
        dateEarliestAvailable: startDate.getTime(),
        dateLatestAvailable: endDate.getTime(),
        availableForFlag: 18,
        preferedAggregator: '',
        availableForID: "",
        planDesc: "",
        featuredPlan: 0,
        bestSeller: 0,
        checkBox: "empty",
        imageUrl: "",
        PlanVariant: [
            {
                variantName: "",
                planData: "",
                planPrice: "",
                planDays: "",
                eSIMGORef: "",
                isActive: 1,
                planSIMType:""
            }
        ]
    }
    const [errorImage, setErrorImage] = useState("")
    const [errorPlanDesc, setErrorPlanDesc] = useState("")
    const [nameMessage, setNameMessage] = useState("")
    const [imagePreview, setImagePreview] = useState(false)
    const [desContent, setDesContent] = useState("")
    const datePickerRef = useRef(null);
    const datePickerRef2 = useRef(null);
    const [skuReq, setSkuReq] = useState(true);
    


    const { register, handleSubmit, formState: { errors }, watch, reset: resetForm, control, setValue, } = useForm({
        defaultValues: planFromData,
    });

    const columns = [
        { displayName: '#', key: 'key' },
        { displayName: 'PLAN NAME', key: 'planName' },
        { displayName: 'PLAN TYPE', key: 'planType' },
        { displayName: 'COUNTRY', key: 'country' },
        { displayName: 'FEATURED', key: 'featuredPlan' },
        { displayName: 'BEST SELLER', key: 'bestSeller' },
        { displayName: 'STATUS', key: 'status' },
        { displayName: 'ACTIONS', key: 'action' },
    ];

    const { fields = [], append, remove } = useFieldArray({
        name: "PlanVariant",
        control
    })
    const preferedAggregator = register("preferedAggregator", {
        required: editFlag ? false : true
    });
    const countryField = register("availableForID", { required: true });
    const nameField = register("name", { required: true });
    const sendPlanData = {
        "tableName": "plan",
        "fieldName": "PlanData"
    };
    const sendPlanDay = {
        "tableName": "plan",
        "fieldName": "PlanDays"
    };
    const sendPlanSimType = {
        "tableName": "sim",
        "fieldName": "SIMType"
    };

    useEffect(() => {
        dispatch(getCountry());
        dispatch(getAggregator());
        dispatch(getPlanData(sendPlanData));
        dispatch(getPlanDay(sendPlanDay));
        dispatch(getPlan({
            "countryID": "[]"
        }))
        dispatch(getSimTypeData(sendPlanSimType))
    }, [])

    useEffect(() => {
        if (planError !== null && planError !== undefined) {
            displayAlert(planError, 'alert-danger');
        }
        if (planMessage !== null && planMessage !== undefined) {
            displayAlert(planMessage, 'alert-success');
        }
        if (planNameMessage !== null && planNameMessage !== undefined) {
            if (planNameMessage === 0) {
                setValue('name', '')
                setNameMessage('Plan name already exist !')
                dispatch(resetPlanMessage());
            } else {
                setNameMessage('')
                dispatch(resetPlanMessage());
            }
        }
    }, [planError, planMessage, planNameMessage]);

    useEffect(() => {
        if (editFlag === true) {
            resetForm(planFromData);
        }
    }, [planFromData, planBundleName, editFlag]);

    useEffect(() => {
        if (editFlag === false) {
            resetForm(planFromData);
        }
    }, [planFromData])

    const handleShowDealerDetails = (ID, data) => {
        setShowViewModal(true)
        setplanViewData(data)
    }

    const responseData = useMemo(() => {
        const responseValue = planViewData ? [
            {
                name: 'Plan Name',
                value: planViewData.PlanName
            },
            {
                name: 'Country Name',
                value: planViewData.CountryName,
            },
            {
                name: 'Aggregator Name',
                value: planViewData.AggregatorName,
            },
            {
                name: 'Available Between',
                value: moment(planViewData.DateEarliestAvailable).format(`${process.env.REACT_APP_DATE_FORMAT}`) + ' To ' + moment(planViewData.DateLatestAvailable).format(`${process.env.REACT_APP_DATE_FORMAT}`),
            },
            {
                name: 'Isactive',
                value: <Tag color={`${planViewData.IsActive === 1 ? "badge-success" : "badge-danger"}`} title={planViewData.IsActive === 1 ? 'Active' : 'Inactive'} />,

            },
            {
                name: 'Best Seller',
                value: <Tag color={`${planViewData.BestSeller === 1 ? "badge-success" : "badge-danger"}`} title={planViewData.BestSeller === 1 ? 'Yes' : 'No'} />,

            },

            {
                name: 'Featured Plan',
                value: <Tag color={`${planViewData.FeaturedPlan === 1 ? "badge-success" : "badge-danger"}`} title={planViewData.FeaturedPlan === 1 ? 'Yes' : 'No'} />,

            },

        ] : [];

        const images = planViewData && planViewData.ImageUrl;

        const variantHeading = [{
            name: "Variant Name",
        },
        {
            name: "ESIMGORef",
        },
        {
            name: "Data",
        },
        {
            name: "Validity",
        },
        {
            name: "Price",
        },
        {
            name: "SIM Type",
        },
        ]
        const vairiantData = planViewData ? planViewData.PlanVariant.map((item, key) => {
            let PlanSimTypeData=simTypeList && simTypeList.length>0 && simTypeList.filter(simType=>simType.ID==item.PlanSIMType)

            const vairiantValue = [
                {
                    key: key,
                    name: "Variant Name",
                    value: item.VariantName
                },
                {
                    name: "ESIMGORef",
                    value: item.ESIMGORef
                },
                {
                    name: "Data",
                    value: item.PlanDataValue
                },
                {
                    name: "Validity",
                    value: item.PlanDaysValue
                },
                {
                    name: "Price",
                    value: `£${item.PlanPrice ? item.PlanPrice.toFixed(2) : '0.00'}`
                },
                {
                    name: "SIM Type",
                    value: PlanSimTypeData && PlanSimTypeData.length>0 ? PlanSimTypeData[0].Value : ''
                },

            ]
            return { vairiantValue };
        }) : [];

        return { responseValue, images, variantHeading, vairiantData }
    }, [planViewData])



    const handleCancelmodal = () => {
        setShowViewModal(false)
    }

    const handleOpenEditFormModal = (ID) => {
        dispatch(preparePlanForm());
        setFormTitle("Edit Plan");
        setEditFlag(true);
        var selectedPlan = planResult.filter(plan => plan.ID === ID);
        const bundleData = {
            "countryCode": selectedPlan[0].AvailableForIDValue
        }
        dispatch(getBundleNameData(bundleData));
        const modifiedPlanVariant = selectedPlan && selectedPlan[0] && selectedPlan[0].PlanVariant.filter(plan => plan.IsActive === 1).map(variant => ({
            ID: variant.ID,
            planID: variant.PlanID,
            planData: variant.PlanData,
            planDays: variant.PlanDays,
            eSIMGORef: variant.ESIMGORef,
            planPrice: variant.PlanPrice,
            planDataValue: variant.PlanDataValue,
            planDaysValue: variant.PlanDaysValue,
            isActive: variant.IsActive,
            variantName: variant.VariantName,
            planSIMType:variant.PlanSIMType,
        }));

        setPlanFromData(prevState => ({
            ...prevState,
            planID: selectedPlan[0].ID,
            name: selectedPlan[0].PlanName,
            dateEarliestAvailable: startDate.getTime(),
            dateLatestAvailable: endDate.getTime(),
            availableForFlag: 18,
            preferedAggregator: selectedPlan[0].PreferedAggregator,
            availableForID: selectedPlan[0].AvailableForID,
            planDesc: selectedPlan[0].PlanDesc,
            featuredPlan: selectedPlan[0].FeaturedPlan,
            bestSeller: selectedPlan[0].BestSeller,
            checkBox: selectedPlan[0].BestSeller === 1 ? "bestSeller" : selectedPlan[0].FeaturedPlan === 1 ? "featuredPlan" : "empty",
            imageUrl: selectedPlan[0].ImageUrl,
            PlanVariant: modifiedPlanVariant
        }));
        setSelectedFileImage(selectedPlan[0].ImageUrl);
        setImagePreview(true);
    }

    const handleDelete = (ID, flag) => {
        const filterPlan = {
            "countryID": "[]"
        }
        dispatch(deletePlan(ID, flag, filterPlan));
    }

    const filteredItems = planResult && planResult.length > 0 && planResult.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const dataSource = filteredItems && filteredItems.length > 0 && filteredItems.map((plan, key) => {

        var actionContent = (
            <>
                <Link to="#">
                <span data-toggle="tooltip" data-placement="top" title="Edit"><PencilFill size="1.2em" style={{ color: "#888b8e", marginRight: "3px" }} onClick={() => handleOpenEditFormModal(plan.ID)} /></span>
                </Link>
                {plan.IsActive === 1 ? (
                    <Popconfirm
                        title="Are you sure to inactive this plan?"
                        okText="Yes"
                        cancelText="No"
                        placement="topRight"
                        onConfirm={() => handleDelete(plan.ID, 0)}
                    >
                        <Link className="deleteBtn" to="# ">
                        <span data-toggle="tooltip" data-placement="top" title="Inactive"><DeleteBin5Fill size="1.2em" style={{ color: "#f14336", marginRight: "3px" }} /></span>
                        </Link>
                    </Popconfirm>
                ) : (
                    <Popconfirm
                        title="Are you sure to active this plan?"
                        okText="Yes"
                        cancelText="No"
                        placement="topRight"
                        onConfirm={() => handleDelete(plan.ID, 1)}
                    >
                        <Link className="deleteBtn" to="# ">
                        <span data-toggle="tooltip" data-placement="top" title="Active"><CheckboxCircleFill size="1.2em" style={{ color: "#2cb549", marginRight: "3px" }} /></span>
                        </Link>
                    </Popconfirm>
                )}
                <Link to="#" onClick={() => handleShowDealerDetails(plan.ID, plan)} >
                <span data-toggle="tooltip" data-placement="top" title="View"><EyeFill size="1.2em" style={{ color: "#0094b3" }} /></span>
                </Link>
            </>
        );

        // { displayName: 'USAGES LIMIT', key: 'usagesLimit'},
        // { displayName: 'LIMIT ALLOWED', key: 'limitAllowed' },
        // usagesLimit:<Tag color={`${coupon.CouponLimitFlag === 1 ? "badge-success" : "badge-danger"}`} title={coupon.CouponLimitFlag === 1 ? 'Yes' : 'No'} />,
        //     limitAllowed: (coupon.CouponLimitFlag === 0) ? 'Unlimited' : coupon.CouponLimit,


        return {
            key: key + 1,
            planName: plan.PlanName,
            country: <Tag color='badge-primary' title={plan.CountryName} />,
            planType: plan.AvailableForFlagValue,
            featuredPlan: <Tag color={`${plan.FeaturedPlan === 1 ? "badge-success" : "badge-danger"}`} title={plan.FeaturedPlan === 1 ? 'Yes' : 'No'} />,
            bestSeller: <Tag color={`${plan.BestSeller === 1 ? "badge-success" : "badge-danger"}`} title={plan.BestSeller === 1 ? 'Yes' : 'No'} />,
            createDate: plan.CreatedDtTm !== null ? moment(new Date(plan.CreatedDtTm)).format(`${process.env.REACT_APP_DATE_FORMAT}`) : 'N/A',
            status: <Tag color={`${plan.IsActive === 1 ? "badge-success" : "badge-danger"}`} title={plan.IsActive === 1 ? 'Active' : 'Inactive'} />,
            action: actionContent,
        }
    });

    const handleFilterChange = (value) => {
        setFilterText(value);
    };

    const handleOpenAddFormModal = () => {
        dispatch(preparePlanForm());
        setFormTitle("Add Plan");
        resetForm({
            PlanVariant: [
                {
                    variantName: "",
                    planData: "",
                    planPrice: "",
                    planDays: "",
                    eSIMGORef: "",
                    isActive: 1,
                    planSIMType:"",
                }
            ]
        });
        setEditFlag(false)
        setPlanFromData(emptyData);
        setSelectedFile(null);
        setSelectedFileImage(null)
        setDesContent("")
    }


    const displayAlert = (text, type) => {
        setAlertText(text);
        setAlertType(type);
        setShowAlert(true);
    };

    const alertCancel = () => {
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
        dispatch(resetPlanMessage());
    }

    const openDatePicker = () => {
        datePickerRef.current.setOpen(true);
    };
    const openDatePicker2 = () => {
        datePickerRef2.current.setOpen(true);
    };

    const handleCancel = () => {
        setPlanFromData(emptyData);
        setSelectedFile(null);
        setSelectedFileImage(null)
        dispatch(resetPlan());
    }

    const onSubmit = (data) => {
        if (data.imageUrl === "") {
            setErrorImage("Thumbnail is Required !")
        } else if (data.planDesc === "") {
            setErrorPlanDesc("Description is Required !")
        } else {
            const newData = {
                ...data,
                featuredPlan: data.checkBox === "featuredPlan" ? 1 : 0,
                bestSeller: data.checkBox === 'bestSeller' ? 1 : 0

            }
            dispatch(addPlan(newData));
        }
    }

    const handleStartDateChange = (date) => {
        const milliseconds = date.getTime();
        setStartDate(date);
        setValue('dateEarliestAvailable', milliseconds);
    };

    const handleEndDateChange = (date) => {
        const milliseconds = date.getTime();
        setEndDate(date);
        setValue('dateLatestAvailable', milliseconds);
    };

    const handleCompressedUpload = (image, callback) => {
        new Compressor(image, {
            quality: 0.2,
            success: (compressedResult) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64String = reader.result;
                    callback(compressedResult, base64String);
                };
                reader.readAsDataURL(compressedResult);
            },
        });
    };

    const handleImageChange = (e) => {
        setErrorImage("")
        const uploadImage = e.target.files[0];
        setSelectedFile(uploadImage);
        setImagePreview(false);

        handleCompressedUpload(uploadImage, (compressedResult, base64String) => {
            if (compressedResult) {
                const imageTypeRegex = /^image\/(jpeg|jpg|png)$/;
                const maxSizeInBytes = 1024 * 1024; // 1024 KB in bytes
                const expectedWidth = 3048;
                const expectedHeight = 3048;
                const image = new Image();
                image.onload = () => {
                    if (
                        uploadImage.type.match(imageTypeRegex) &&
                        uploadImage.size <= maxSizeInBytes &&
                        image.width <= expectedWidth &&
                        image.height <= expectedHeight &&
                        image.width > image.height
                    ) {
                        setValue('imageUrl', base64String);
                        setSelectedFileImage(base64String)
                    } else {

                    }
                };
                image.src = URL.createObjectURL(uploadImage);
            }
        });
    };

    const handlePlanDesc = (content) => {
        setErrorPlanDesc("");
        setDesContent(content)
        setValue('planDesc', content);
    }
    const onUpdate = (data) => {
        if (data.imageUrl === "") {
            setErrorImage("Thumbnail is Required !");
        } else if (data.planDesc === "") {
            setErrorPlanDesc("Description is Required !");
        } else {
            const newData = {
                ...data,
                featuredPlan: data.checkBox === "featuredPlan" ? 1 : 0,
                bestSeller: data.checkBox === 'bestSeller' ? 1 : 0
            };
            dispatch(addPlan(newData));
        }
    };

    const handleCountry = (e) => {
        const countryId = e.target.value;
        if (countryId) {
            const selectedCountry = countryResult && countryResult.filter(country => country.ID == countryId);
            const data = {
                "countryCode": selectedCountry[0].ISO2
            }
            dispatch(getBundleNameData(data));
        }
    }

    const handleBlur = (e) => {
        const fvalue = e.target.value
        if (fvalue) {
            dispatch(checkPlanName(fvalue))
        }
    }

    const handleNumber = (e) => {
        const keyCode = e.keyCode;
        if (!((e.shiftKey || (keyCode < 48 || keyCode > 57)) && (keyCode < 96 || keyCode > 105) && keyCode !== 8 && keyCode === 190)) {
            return;
        }
        e.preventDefault();
    };

    const handleChangeAggregator = (e) => {
        if (e.target.value > 0) {
            let selectedAggegator = aggregatorList.filter((item) => item.ID == e.target.value);
            if (selectedAggegator[0].SKURequired) {
                setSkuReq(false);
            } else {
                setSkuReq(true);
            }
        }
    }

    return (
        <CardLayout title="Plans">
            {showAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}
            <div className='row align-items-center'>
                <div className="col-md-6 od-2">
                    <SearchBar onFilter={handleFilterChange} filterText={filterText} /></div>
                <div className="col-md-6  text-right">
                    <SideButtons title="Add Plan" buttonStyle="primary" onClick={handleOpenAddFormModal} /></div></div>
            <Table columns={columns} data={dataSource} pagination={true} loading={loading} />
            <Modal
                showModal={planFormModal}
                title={formTitle}
                onHide={handleCancel}
                width={1200}
                bodyClasses="modelPlanPage"
                showFooter={false}
            >
                <form onSubmit={handleSubmit(editFlag ? onUpdate : onSubmit)}>
                    <div className="form-row">

                        <div className="col-md-2 mb-2">
                            <label>Aggregator</label>
                            <select className="form-control" disabled={editFlag} name="preferedAggregator"  {...preferedAggregator} /* onChange={(e) => handleChangeAggregator(e)} */
                                onChange={(e) => {
                                    preferedAggregator.onChange(e);
                                    handleChangeAggregator(e);
                                }} >
                                <option value="" key="0" disabled>Select</option>
                                {aggregatorList.length > 0 && aggregatorList.map(item => (
                                    <option value={item.ID} key={item.ID}> {item.AggregatorName}</option>
                                ))}
                            </select>
                            {errors.preferedAggregator?.type === "required" && (<div className="invalid-feedback">Aggregator is required !</div>)}
                        </div>

                        <div className="col-md-3 mb-2">
                            <label>Plan Name</label>
                            <input type="text" disabled={editFlag} placeholder="Enter plan name" className="form-control" name="name" {...nameField} onBlur={handleBlur} onChange={(e) => {
                                nameField.onChange(e);
                                setNameMessage("");
                            }} />
                            {errors.name?.type === "required" && (
                                <div className="invalid-feedback">Plan name is required !</div>

                            )}
                            {
                                nameMessage && <div className="invalid-feedback">{nameMessage}</div>
                            }
                        </div>

                        <div className="col-md-2 mb-2 d-flex flex-column">
                            <label>Start Date</label>
                            <Calendar2LineIcon onClick={openDatePicker} size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', 'top': '36px', right: '8px', zIndex: 1 }} />
                            <DatePicker
                                ref={datePickerRef}
                                selected={startDate}
                                onChange={handleStartDateChange}
                                selectsStart
                                className="form-control"
                                startDate={startDate}
                                endDate={endDate}
                                showMonthDropdown
                                showYearDropdown
                            />
                        </div>
                        <div className="col-md-2 mb-2 d-flex flex-column">
                            <label>End Date</label>
                            <Calendar2LineIcon disabled={editFlag} onClick={openDatePicker2} size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', 'top': '36px', right: '8px', zIndex: 1 }} />
                            <DatePicker
                                ref={datePickerRef2}
                                selected={endDate}
                                className="form-control"
                                onChange={handleEndDateChange}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                showMonthDropdown
                                showYearDropdown
                            />
                        </div>
                        <div className="col-md-3 mb-2">
                            <label>Country</label>
                            <select className="form-control" disabled={editFlag} placeholder="Select Country" name="availableForID"  {...countryField}
                                onChange={(e) => {
                                    countryField.onChange(e);
                                    handleCountry(e);
                                }} >
                                <option value="" key="0">Select</option>
                                {countryResult.length > 0 && countryResult.map(country => (
                                    <option name="availableForID" value={country.ID} key={country.ID}>
                                        {country.CountryName}</option>

                                ))}
                            </select>
                            {bundleError && <div className="invalid-feedback">Esim go bundle not available !</div>}
                            {errors.availableForID?.type === "required" && (
                                <div className="invalid-feedback">Country is required !</div>
                            )}
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12 mb-2 ">
                            <hr style={{ marginBottom: "8px" }} />
                            <h4 className="card-title font-weight-bold mb-0">Variations</h4>
                            <hr style={{ marginTop: "8px" }} />
                        </div>
                    </div>

                    {fields && fields.length > 0 && fields.filter(data => data.isActive === 1).map((fieldData, index) => {
                        return (
                            <>
                                <div className="form-row" style={{ position: "relative" }} key={fieldData.id}>
                                    <div className="col-md-2  mb-2">
                                        <label>Plan Data</label>
                                        <select className="form-control" {...register(`PlanVariant.${index}.planData`, {
                                            required: 'Plan data is required !',
                                        })} >
                                            <option value="" key="0">Select</option>
                                            {planData && planData.length > 0 && planData.map(data => (
                                                <option value={data.ID} key={data.ID}>
                                                    {data.Value}</option>

                                            ))}
                                        </select>
                                        {errors && errors.PlanVariant && errors.PlanVariant[index] && errors.PlanVariant[index].planData && (
                                            <div className="invalid-feedback">{errors.PlanVariant[index].planData.message}</div>
                                        )}
                                    </div>
                                    <div className="col-md-2  mb-2">
                                        <label>Validity</label>
                                        <select className="form-control" {...register(`PlanVariant.${index}.planDays`, {
                                            required: "Validity is required !",
                                        })} >
                                            <option value="" key="0">Select</option>
                                            {planDay && planDay.length > 0 && planDay.map(data => (
                                                <option value={data.ID} key={data.ID}>
                                                    {data.Value}</option>

                                            ))}
                                        </select>
                                        {errors && errors.PlanVariant && errors.PlanVariant[index] && errors.PlanVariant[index].planDays && (
                                            <div className="invalid-feedback">{errors.PlanVariant[index].planDays.message}</div>
                                        )}
                                    </div>
                                    <div className="col  mb-2">
                                        <label>Price</label>
                                        <input
                                            type="number"
                                            placeholder="Enter plan price"
                                            className="form-control"
                                            // onKeyDown={handleNumber}
                                            step="0.01"
                                            onWheel={(e) => e.target.blur()}
                                            onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()}
                                            {...register(`PlanVariant.${index}.planPrice`, {
                                                valueAsNumber: true,
                                                required: 'Plan price is required !',
                                            })}
                                        />
                                        {errors && errors.PlanVariant && errors.PlanVariant[index] && errors.PlanVariant[index].planPrice && (
                                            <div className="invalid-feedback">{errors.PlanVariant[index].planPrice.message}</div>
                                        )}
                                    </div>
                                    <div className="col-md-2 mb-2">
                                        <label>Variation Name</label>
                                        <input
                                            type="text"
                                            placeholder="Enter Variant name"
                                            className="form-control"
                                            {...register(`PlanVariant.${index}.variantName`, {
                                                required: 'Variant name is required !',
                                            })}
                                        />
                                        {errors && errors.PlanVariant && errors.PlanVariant[index] && errors.PlanVariant[index].variantName && (
                                            <div className="invalid-feedback">{errors.PlanVariant[index].variantName.message}</div>
                                        )}
                                    </div>
                                    <div className="col-md-2 mb-2">
                                        <label>SIM Type</label>
                                        <select className="form-control" placeholder="Select" name="planSIMType" {...register(`PlanVariant.${index}.planSIMType`, {
                                            required: 'SIM Type is required !',

                                        })}>
                                            <option value='' selected disabled key={0}>Select SIM</option>
                                            {simTypeList && simTypeList.length > 0 && simTypeList.map(simType => (
                                                <option value={simType.ID} key={simType.ID}>{simType.Value}</option>
                                            ))}
                                        </select>
                                        {errors && errors.PlanVariant && errors.PlanVariant[index] && errors.PlanVariant[index].planSIMType && (
                                            <div className="invalid-feedback">{errors.PlanVariant[index].planSIMType.message}</div>
                                        )}
                                    </div>
                                    <div className="col-md-2  mb-2">
                                        <label>Esim Go Ref</label>
                                        <select disabled={skuReq} className="form-control" placeholder="Select esim go plan"  {...register(`PlanVariant.${index}.eSIMGORef`, {
                                            required: skuReq ? false : "Esim go ref is required !",
                                        })} >
                                            <option value="" key="0">Select</option>
                                            {planBundleName && planBundleName.length > 0 && planBundleName.map(bundleName => (
                                                <option name="availableForID" value={bundleName.esimGoBundle} key={bundleName.esimGoBundle}>
                                                    {bundleName.esimGoBundle}</option>

                                            ))}
                                        </select>
                                        {errors && errors.PlanVariant && errors.PlanVariant[index] && errors.PlanVariant[index].eSIMGORef && (
                                            <div className="invalid-feedback">{errors.PlanVariant[index].eSIMGORef.message}</div>
                                        )}
                                    </div>

                                    {index > 0 && <div className='mt-md-4 m-w-100' style={{}} onClick={() => remove(index)}>
                                        <CloseCircleFill style={{ color: "#f14336", marginTop: "13px", cursor: "pointer" }} />
                                    </div>}
                                </div>
                                <hr className='mt-2 mb-3' />
                            </>
                        )
                    })}


                    <div className="form-row align-items-center">
                        <div className="col-md-4 mb-3 od-2">
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="customRadio6" value="featuredPlan" name="customRadio-1" class="custom-control-input" {...register('checkBox')} />
                                <label class="custom-control-label" for="customRadio6"> Featured Plan </label>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3 od-2">
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="customRadio7" value="bestSeller" name="customRadio-1" class="custom-control-input" {...register('checkBox')} />
                                <label class="custom-control-label" for="customRadio7"> Best Seller </label>
                            </div>
                        </div>
                        <div className="col-md-4 text-right">
                            <button type="button" className='btn btn-primary font-weight-bold' onClick={() => append({
                                variantName: "",
                                planData: "",
                                planPrice: "",
                                planDays: "",
                                eSIMGORef: "",
                                isActive: 1
                            })}><AddFillIcon size={16} style={{ fontWeight: "bold" }} />Add More</button>
                        </div>
                    </div>


                    <div className="form-row">
                        <div className="col-md-12 mb-3">
                            <label>Description </label>
                            {editFlag && <div dangerouslySetInnerHTML={{ __html: planFromData.planDesc }}></div>}
                            <ReactQuill theme="snow" value={desContent} onChange={handlePlanDesc} style={{ height: '200px', marginBottom: '30px' }} />
                            <div className="invalid-feedback">{errorPlanDesc}</div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12">
                            <label>Thumbnail</label>
                            <div class="form-group mb-0">
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input" accept=".jpg, .jpeg, .png" name='imageurl' onChange={e => handleImageChange(e)} />
                                    <label class="custom-file-label">{selectedFile ? selectedFile.name : 'Choose file'}</label>
                                </div>
                                <div className="invalid-feedback">{errorImage}</div>
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            {
                                selectedFileImage && editFlag && imagePreview && <div >
                                    <img src={`${process.env.REACT_APP_BASEURL}/planImages/${selectedFileImage}`} height={250} width={'100%'} />
                                </div>
                            }
                            {
                                selectedFileImage && !imagePreview && <div >
                                    <img src={selectedFileImage} height={250} width={'100%'} />
                                </div>
                            }
                        </div>
                    </div>

                    <div className='text-right row'>
                        <div className="col-md-12">
                            <button type="button" className="btn btn-secondary mr-2" onClick={handleCancel}>Close</button>
                            <Button title={editFlag ? "Update" : "Submit"} disable={!isEmpty(errors)} style="minWidth:80px" loading={buttonLoading} buttonClass="btn btn-primary" buttonType='submit' />
                        </div>
                    </div>
                </form>
            </Modal>

            {planViewData ?
                <div>
                    <PlanModel
                        showViewModal={showViewModal}
                        title={"Plan Data"}
                        handleCancel={handleCancelmodal}
                        responseValue={responseData.responseValue}
                        images={responseData.images}
                        variantTitle={'Variant Plan'}
                        variantHeading={responseData.variantHeading}
                        varientData={responseData.vairiantData}
                    >
                    </PlanModel>

                </div>
                : ""}

        </CardLayout>

    )
}

export default Plan