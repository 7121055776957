import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData, getList } from '../../helpers/fackBackend_Helper';

function* getRefundRequestResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/orderRefundList', data);
        if (response.Status) {
            yield put(actions.getRefundRequestSuccess(response.Data))
        } else {
            yield put(actions.getRefundRequestFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.getRefundRequestFailed(error));
    }
}

function* toRefundDataApprovedResponse({ payload: { refundId } }) {
    try {
        const response = yield call(getList, '/orderRefundApproved/' + refundId);
        if (response.Status) {
            yield put(actions.toRefundApprovedSuccess(response.Message));
        } else {
            yield put(actions.toRefundApprovedFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.toRefundApprovedFailed(error));
    }
}

function* toRefundDataCanceledResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/orderRefundCancelled', data);
        if (response.Status) {
            yield put(actions.toRefundCancelledSuccess(response.Message));
        } else {
            yield put(actions.toRefundCancelledFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.toRefundCancelledFailed(error));
    }
}




export default function* rootSaga() {
    yield all([takeEvery(actions.GET_REFUND_REQUEST, getRefundRequestResponse)]);
    yield all([takeEvery(actions.TO_REFUND_APPROVED, toRefundDataApprovedResponse)]);
    yield all([takeEvery(actions.TO_REFUND_CANCELLED, toRefundDataCanceledResponse)]);

}