import actions from "./actions";

const initState = {
    addCategoryModal: false,
    addCategoryloading: false,
    addCategoryError: null,
    statusMessage:null,
    // template category list
    templateCategory: [],
    templateCategoryLoading: false,
    templateCategoryError: null,
    templateCategoryMessage: null,
    // add email template
    addTemplateModal: false,
    addTemplateloading: false,
    addTemplateError: null,
    // template list
    template: [],
    templateLoading: false,
    templateError: null,
    templateMessage: null,
    // template status change
    templateStatusChangeLoading: false,
    templateStatusChangeError: null,
    // category status change
    categoryStatusChangeLoading: false,
    categoryStatusChangeError: null,
};

export default function reducer(state = initState, action) {
    switch (action.type) {

        case actions.OPEN_ADD_CATEGORY_MODAL:
            return {
                ...state,
                addCategoryModal: true,
            };
        case actions.CLOSE_ADD_CATEGORY_MODAL:
            return {
                ...state,
                addCategoryModal: false,
            };

        case actions.ADD_CATEGORY:
            return {
                ...state,
                addCategoryloading: true,
                addCategoryError: null,
                statusMessage: null,
            };
        case actions.ADD_CATEGORY_SUCCESS:
            return {
                ...state,
                addCategoryloading: false,
                addCategoryError: null,
                statusMessage: action.payload.message,
                addCategoryModal: false,
            };
        case actions.ADD_CATEGORY_FAILED:
            return {
                ...state,
                addCategoryloading: false,
                categoryStatusChangeError: action.payload.error,
                statusMessage: null,
                addCategoryModal: false,
            };

        case actions.GET_TEMPLATE_CATEGORY:
            return {
                ...state,
                templateCategoryLoading: true,
                templateCategoryError: null,
                templateCategoryMessage: null,
            };


        case actions.GET_TEMPLATE_CATEGORY_SUCCESS:
            return {
                ...state,
                templateCategoryLoading: false,
                templateCategoryError: null,
                statusMessage: action.payload.message,
                templateCategory: action.payload.data,
            };
        case actions.GET_TEMPLATE_CATEGORY_FAILED:
            return {
                ...state,
                templateCategoryLoading: false,
                templateCategoryError: action.payload.error,
                statusMessage: null,
            };
        case actions.UPDATE_TEMPLATE_CATEGORY:
            return {
                ...state,
                addCategoryloading: true,
                addCategoryError: null,
                statusMessage: null,
            };
        case actions.UPDATE_TEMPLATE_CATEGORY_SUCCESS:
            return {
                ...state,
                addCategoryloading: false,
                addCategoryError: null,
                statusMessage: action.payload.message,
                addCategoryModal: false,
            };
        case actions.UPDATE_TEMPLATE_CATEGORY_FAILED:
            return {
                ...state,
                addCategoryloading: false,
                categoryStatusChangeError: action.payload.error,
                statusMessage: null,
            };
        case actions.UPDATE_CATEGORY_STATUS:
            return {
                ...state,
                categoryStatusChangeLoading: true,
                categoryStatusChangeError: null,
                statusMessage: null,
            };
        case actions.UPDATE_CATEGORY_STATUS_SUCCESS:
            return {
                ...state,
                categoryStatusChangeLoading: false,
                categoryStatusChangeError: null,
                statusMessage: action.payload.message,
            };
        case actions.UPDATE_CATEGORY_STATUS_FAILED:
            return {
                ...state,
                categoryStatusChangeLoading: false,
                categoryStatusChangeError: action.payload.error,
                statusMessage: null,
            };
        case actions.RESET_MESSAGES:
            return {
                ...state,
                addCategoryError: null,
                addTemplateError: null,
                categoryStatusChangeError: null,
                statusMessage: null,
                templateStatusChangeError: null,
               
            };
        case actions.OPEN_ADD_TEMPLATE_MODAL:
            return {
                ...state,
                addTemplateModal: true,
            };
        case actions.CLOSE_ADD_TEMPLATE_MODAL:
            return {
                ...state,
                addTemplateModal: false,
            };

        case actions.ADD_EMAIL_TEMPLATE:
            return {
                ...state,
                addTemplateloading: true,
                addTemplateError: null,
                statusMessage: null,
            };
        case actions.ADD_EMAIL_TEMPLATE_SUCCESS:
            return {
                ...state,
                addTemplateloading: false,
                addTemplateError: null,
                statusMessage: action.payload.message,
                addTemplateModal: false,
            };
        case actions.ADD_EMAIL_TEMPLATE_FAILED:
            return {
                ...state,
                addTemplateloading: false,
                categoryStatusChangeError: action.payload.error,
                statusMessage: null,
                addTemplateModal: false,
            };

        case actions.GET_TEMPLATE:
            return {
                ...state,
                templateLoading: true,
                templateError: null,
                templateMessage: null,
            };
        case actions.GET_TEMPLATE_SUCCESS:
            return {
                ...state,
                templateLoading: false,
                templateError: null,
                statusMessage: action.payload.message,
                template: action.payload.data,
            };
        case actions.GET_TEMPLATE_FAILED:
            return {
                ...state,
                templateLoading: false,
                categoryStatusChangeError: action.payload.error,
                statusMessage: null,
            };

        case actions.UPDATE_TEMPLATE:
            return {
                ...state,
                addTemplateloading: true,
                addTemplateError: null,
                statusMessage: null,
            };
        case actions.UPDATE_TEMPLATE_SUCCESS:
            return {
                ...state,
                addTemplateloading: false,
                addTemplateError: null,
                statusMessage: action.payload.message,
                addTemplateModal: false,
            };
        case actions.UPDATE_TEMPLATE_FAILED:
            return {
                ...state,
                addTemplateloading: false,
                categoryStatusChangeError: action.payload.error,
                statusMessage: null,
            };
        case actions.UPDATE_TEMPLATE_STATUS:
            return {
                ...state,
                templateStatusChangeLoading: true,
                templateStatusChangeError: null,
                statusMessage: null,
            };
        case actions.UPDATE_TEMPLATE_STATUS_SUCCESS:
            return {
                ...state,
                templateStatusChangeLoading: false,
                templateStatusChangeError: null,
                statusMessage: action.payload.message,
            };
        case actions.UPDATE_TEMPLATE_STATUS_FAILED:
            return {
                ...state,
                templateStatusChangeLoading: false,
                categoryStatusChangeError: action.payload.error,
                statusMessage: null,
            };

        default:
            return state;
    }
}