import { combineReducers } from "redux";
import dealer from './dealer/reducer';
import auth from './auth/reducer'
import country from './country/reducer'
import tariffGroup from './tariffGroup/reducer'
import user from './user/reducer'
import verifyDealer from './verifyDealer/reducer'
import banner from './banner/reducer'
import profile from './profile/reducer'
import manualAddFund from './manualAddFund/reducer'
import plan from './plan/reducer'
import auditReport from './auditReport/reducer'
import simPurchase from './simPurchase/reducer'
import orderReport from './orderReport/reducer'
import listSim from './listSim/reducer'
import cart from './cart/reducer'
import orderRefund from './orderRefund/reducer'
import refundRequest from './refundRequest/reducer'
import addToFund from './addToFund/reducer'
import aggregator from './aggregator/reducer'
import dashboard from './dashboard/reducer'
import activity from './activity/reducer'
import emailTemplate from'./emailTemplate/reducer'
import coupon from "./coupon/reducer";

const rootReducer = combineReducers({
    dealer,
    auth,
    country,
    tariffGroup,
    user,
    verifyDealer,
    banner,
    profile,
    manualAddFund,
    plan,
    auditReport,
    simPurchase,
    orderReport,
    listSim,
    cart,
    orderRefund,
    refundRequest,
    addToFund,
    aggregator,
    dashboard,
    activity,
    emailTemplate,
    coupon,
});
export default rootReducer;