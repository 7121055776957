import actions from "./actions";

const initState = {
    buttonLoading: false,
    couponFormModal: false,
    couponData: [],
    couponLoading: false,
    couponError: null,
    couponMessage: null,
    addCouponMessage: null,
    addCouponError: null,
    editCouponMessage: null,
    editCouponError: null,
    deleteMessage: null,
    deleteError: null,
    showErrorAlert:false,
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.PREPARE_COUPON_FORM:
            return {
                ...state,
                couponFormModal: true,
                couponError: null,
                couponMessage: null,
            };

        case actions.RESET_COUPON_FORM:
            return {
                ...state,
                couponFormModal: false
            };

        case actions.ADD_COUPON:
            return {
                ...state,
                couponLoading: true,
                couponError: null,
                couponMessage: null,
                addCouponMessage: null,
                addCouponError: null,
                showErrorAlert:false,
            };

        case actions.ADD_COUPON_SUCCESS:
            return {
                ...state,
                couponLoading: false,
                couponError: null,
                addCouponMessage: action.regionMessage,
                couponFormModal: false,
                addCouponError: null,
                showErrorAlert:false,
            };

        case actions.ADD_COUPON_FAILED:
            return {
                ...state,
                couponLoading: false,
                addCouponError: action.error,
                addCouponMessage: null,
                couponMessage: null,
                showErrorAlert:true,
            };

        case actions.GET_COUPON:
            return {
                ...state,
                couponLoading: true,
                couponError: null,
                couponMessage: null,
                couponFormModal: false
            };
        case actions.GET_COUPON_SUCCESS:
            return {
                ...state,
                couponLoading: false,
                couponError: null,
                couponMessage: null,
                couponFormModal: false,
                couponData: action.data
            };
        case actions.GET_COUPON_FAILED:
            return {
                ...state,
                couponLoading: false,
                couponError: action,
                couponMessage: null,
                couponFormModal: false
            };
        case actions.RESET_COUPON:
            return {
                ...state,
                couponError: null,
                couponMessage: null,
                addCouponMessage: null,
                addCouponError: null,
                editCouponMessage: null,
                editCouponError: null,
                deleteMessage: null,
                deleteError: null,
            };
        case actions.DELETE_COUPON:
            return {
                ...state,
                deleteMessage: null,
                deleteError: null,
                buttonLoading: false,
                showErrorAlert:false,
            };
        case actions.DELETE_COUPON_SUCCESS:
            return {
                ...state,
                deleteMessage: action.payload.regionMessage,
                deleteError: null,
                buttonLoading: false,
                showErrorAlert:false,
            };
        case actions.DELETE_COUPON_FAILED:
            return {
                ...state,
                deleteMessage: null,
                deleteError: action.error,
                buttonLoading: false,
                showErrorAlert:false,
            };
        case actions.EDIT_COUPON:
            return {
                ...state,
                couponLoading: true,
                couponError: null,
                couponMessage: null,
                editCouponMessage: null,
                editCouponError: null,
                showErrorAlert:false,
            };
        case actions.EDIT_COUPON_SUCCESS:
            return {
                ...state,
                couponLoading: false,
                couponError: null,
                couponMessage: null,
                couponFormModal: false,
                editCouponMessage: action.regionMessage,
                editCouponError: null,
                  showErrorAlert:false,
            };
        case actions.EDIT_COUPON_FAILED:
            return {
                ...state,
                couponLoading: false,
                couponFormModal: true,
                editCouponMessage: null,
                editCouponError: action.error,
                showErrorAlert:true,
            };
        default:
            return state;
    }
}