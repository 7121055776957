import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import Calendar2LineIcon from 'remixicon-react/Calendar2LineIcon';
import moment from "moment";
import { useState, useEffect, useRef } from 'react'
import DatePicker from "react-datepicker";
import CardLayout from '../../../components/utils/CardLayout'
import SearchBar from '../../../components/utils/SearchBar';
import Table from '../../../components/utils/Table';
import Button from '../../../components/utils/Button'
import "react-datepicker/dist/react-datepicker.css";
import Styles from './Activity.module.css'
import ActivityAction from '../../../redux/activity/actions'

const dateFormat = process.env.REACT_APP_DATE_FORMAT;
const longTimeFormat = process.env.REACT_APP_LONG_TIME_FORMAT;

const selectMenu = [
    {
        value: 'Banner',
        title: 'Banner'
    },
    {
        value: 'Dealer',
        title: 'Dealer'
    },
    {
        value: 'Login',
        title: 'Login'
    },
    {
        value: 'User',
        title: 'User'
    },
    {
        value: 'Verify Dealer',
        title: 'Verify Dealer'
    },
    {
        value: 'Plan',
        title: 'Plan'
    },
    {
        value: 'Tariff Group',
        title: 'Tariff Group'
    },
    {
        value: 'Manual Add Fund',
        title: 'Manual Add Fund'
    },
    {
        value: 'Sim Purchase',
        title: 'Sim Purchase'
    },
]

const Activity = () => {
    const { getActivity } = ActivityAction;
    const { activityList, loading } = useSelector((state) => state.activity);
    const { register, handleSubmit, formState: { errors }, watch, reset } = useForm();
    const [filterText, setFilterText] = useState("");
    const [toDate, setToDate] = useState(moment().startOf('day').toDate())
    const [fromDate, setFromDate] = useState(moment().subtract(2, 'days').toDate());
    const { orderReportListResult } = useSelector((state) => state.orderReport);
    const dispatch = useDispatch()
    const datePickerRef = useRef(null);
    const datePickerRef2 = useRef(null);
    console.log(activityList )

    const openDatePicker = () => {
        datePickerRef.current.setOpen(true);
    };
    const openDatePicker2 = () => {
        datePickerRef2.current.setOpen(true);
    };

    useEffect(() => {
        selectMenu.sort((a, b) => a.title.localeCompare(b.title));
        dispatch(getActivity({
            toDate: moment(toDate).format("YYYY-MM-DD"),
            fromDate: moment(fromDate).format("YYYY-MM-DD"),
            menu: 'all'
        }));
    }, [])

    const onSubmit = (data) => {
        const newData = {
            toDate: moment(toDate).format("YYYY-MM-DD"),
            fromDate: moment(fromDate).format("YYYY-MM-DD"),
            menu: data.menu,
        };
        dispatch(getActivity(newData))
    };

    const columns = [
        { displayName: '#', key: 'key' },
        { displayName: 'MENU', key: 'menu' },
        { displayName: 'TYPE', key: 'type' },
        { displayName: 'DESCRIPTION', key: 'description' },
        { displayName: 'DATE', key: 'date' },
        { displayName: 'TIMESTAMP', key: 'timestamp' },
    ];

    const filteredItems = orderReportListResult && activityList.length > 0 && activityList.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    var data = filteredItems && filteredItems.length > 0 && filteredItems.map((orderItem, key) => {
        return {
            key: key + 1,
            menu: orderItem.MenuName,
            type: orderItem.ActionType,
            description: orderItem.ActionDescription,
            date: moment(new Date(orderItem.ActionDtTm)).format(dateFormat),
            timestamp: moment(new Date(orderItem.ActionDtTm)).format(longTimeFormat),
        }
    });

    const handleFilterChange = (value) => {
        setFilterText(value);
    };

    const predefinedPeriods = {
        today: moment().startOf('day').toDate(),
        yesterday: moment().subtract(1, 'days').startOf('day').toDate(),
        '15days': moment().subtract(15, 'days').startOf('day').toDate(),
        month: moment().subtract(30, 'days').startOf('day').toDate(),
        quarter: moment().subtract(3, 'months').startOf('day').toDate(),
        year: moment().subtract(1, 'year').startOf('day').toDate(),
    };

    const selectPeriodList = [
        { value: 'today', title: 'Today' },
        { value: 'month', title: 'Last 30 Days' },
        { value: 'quarter', title: 'Quarter' },
        { value: 'year', title: 'One Year' }
    ];

    const changeDateHandler = (item) => {
        setFromDate(predefinedPeriods[item]);
        const formattedDate = moment(predefinedPeriods[item]).format("YYYY-MM-DD");
        const currentDate = moment().format("YYYY-MM-DD");
        const apiData = {
            toDate: currentDate,
            fromDate: formattedDate,
            menu: 'all',
        };
        console.log(apiData);
        dispatch(getActivity(apiData))
    }

    return (
        <>
            <CardLayout title="Activity Log">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-row">
                        <div className="col-md-3 col-lg-2 mb-2 d-flex flex-column">
                            <label>From Date</label>
                            <Calendar2LineIcon onClick={openDatePicker} size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', 'top': '36px', right: '12px', zIndex: 1 }} />
                            <DatePicker
                                ref={datePickerRef}
                                selected={fromDate}
                                className="form-control"
                                onChange={(date) => setFromDate(date)}
                                dateFormat="yyyy-MM-dd"
                                name="fromDate"
                                showMonthDropdown
                                showYearDropdown
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                                value={fromDate}
                            />
                        </div>
                        <div className="col-md-3 col-lg-2 mb-2 d-flex flex-column">
                            <label for="date">To Date</label>
                            <Calendar2LineIcon onClick={openDatePicker2} for='date' size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', 'top': '36px', right: '12px', zIndex: 1 }} />
                            <DatePicker
                                ref={datePickerRef2}
                                id='date'
                                selected={toDate}
                                className="form-control"
                                onChange={(date) => setToDate(date)}
                                dateFormat="yyyy-MM-dd"
                                name="toDate"
                                showMonthDropdown
                                showYearDropdown
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                                value={toDate}
                            />
                        </div>

                        <div className="col-md-3 col-lg-2 mb-2">
                            <label>Menu</label>
                            <select className="form-control" placeholder="Menu" name="menu"  {...register("menu", {
                            })} >
                                <option value="all" key="0" selected>All</option>
                                {
                                    selectMenu.map((item, index) => {
                                        return <option value={item.value} key={`${index}0`}>{item.title}</option>
                                    })
                                }
                            </select>
                        </div>

                        <div className="col-md-2 mb-2  mt-md-4">
                            
                                    <Button title="Search"
                                        buttonClass={`btn btn-primary btn-md mt-1 Activity_searchBtn__GlBtC`} buttonType='submit' />
                                
                        </div>
                    </div>
                    <div className="form-row">
                    <div className="col-md-6 ">
                    <div className="form-row">
                        {selectPeriodList.map((item, index) =>
                            <ul style={{ display: 'flex', alignItems: 'start', gap: '5px' }} className='p-1'>
                                <li className={Styles.selectUl} key={index} onClick={() => changeDateHandler(item.value, index)}>{item.title}</li>
                            </ul>
                        )}
                    </div>
                </div>
                    </div>
                </form>

                

                <hr />
                <div className='d-flex justify-content-between'>
                    <SearchBar onFilter={handleFilterChange} filterText={filterText} />
                </div>
                <Table columns={columns} loading={loading} data={data} pagination={true} />

            </CardLayout>

        </>
    )
}

export default Activity