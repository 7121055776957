import React from 'react'

function Box({ style, children }) {
    return (
        <div className='iq-card box-style-layout confirm-order-card-style-layout' style={style}>
            {children}
        </div>
    )
}

export default Box