import React from 'react'

function ProgressBar({ activeStep, activeStatus }) {
    return (
        <div class="row">
            <div class="col-sm-12">
                <ul id="progressbar" className="text-center">
                    <li className={`step${activeStep === 0 ? activeStatus : ''}`} id="step1" >
                        Select Plans
                    </li>
                    <li className={`step${activeStep === 1 ? activeStatus : ''}`} id="step2" >
                        Confirm Order
                    </li>
                    <li className={`step${activeStep === 2 ? activeStatus : ''}`} id="step3">
                        Payment Status
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default ProgressBar