import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isAdmin, isDealer, isAdminAgent, isDealerAgent } from "../helpers/commonFunction";
import OwlCarousel from 'react-owl-carousel';
import moment from "moment";
import bannerActions from '../../src/redux/banner/actions';
import AuthActions from '../../src/redux/auth/actions';
import dashboardActions from '../redux/dashboard/actions';
import cartAction from '../redux/cart/actions';
import SimCardLineIcon from 'remixicon-react/SimCardLineIcon';
import UserLineIcon from 'remixicon-react/UserLineIcon';
import ShoppingCartLineIcon from 'remixicon-react/ShoppingCartLineIcon';
import Wallet2LineIcon from 'remixicon-react/Wallet2LineIcon';
import Loader from "../components/utils/Loader";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import PlanCarousel from "./PlanCarousel";
import Modal from '../components/utils/Model';
import modalImage from '../assets/images/modal.jpg';
import { useForm } from "react-hook-form";
import Button from '../components/utils/Button';
import NoImageAvailable from '../assets/images/no image available.jpg'
import SalesChart from "./Dashboard Components/SalesChart";
import ActivityAction from '../redux/activity/actions'
import Transaction from "./Dashboard Components/Transaction";
import Request from "./Dashboard Components/Request";
import NonVerifyDealerModel from "../components/utils/NonVerifyDealerModel";
import SimPurchaseActions from '../redux/simPurchase/actions'
import { useNavigate } from "react-router-dom";






const { getSimTypeData } = SimPurchaseActions;
const baseURL = process.env.REACT_APP_BASEURL;
const { getActivity } = ActivityAction;
const { getBanner } = bannerActions;
const { getUserDataByToken } = AuthActions;
const { getFeaturedPlan, getTopSellerPlan, getDashboardCount, getSalesAndDealerCount } = dashboardActions;
const { checkCartData, addPlanToCart, prepareViewPlanModal, resetViewPlanModal, resetWholePage, viewCartData } = cartAction;

const dateFormat = process.env.REACT_APP_DATE_FORMAT;
const timeFormat = process.env.REACT_APP_TIME_FORMAT;

function Dashboard() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const AuthState = useSelector((state) => state.auth);
    const tokenData = useSelector((state) => state.auth.tokenData);
    const { checkCartFlag, showModal, cartMessage, cartError, buttonLoading, showModalLoading } = useSelector((state) => state.cart);
    const { activityList } = useSelector((state) => state.activity);
    const userDetails = AuthState.userDetails && AuthState.userDetails.length > 0 ? AuthState.userDetails[0] : null;
    const bannerList = useSelector((state) => state.banner.bannerResult);
    const loading = useSelector((state) => state.banner.loading);
    const { featuredPlanResult, topSellerPlanResult, dashboardCountData, SalesAndDealerCountData } = useSelector((state) => state.dashboard);
    const { simTypeList } = useSelector((state) => state.simPurchase);
    const [selectedEachPlanData, setSelectedEachPlanData] = useState([]);
    const [showViewCart, setshowViewCart] = useState(false);

    const [addToCart, setAddToCart] = useState({
        planPrice: '',
        planID: '',
        quantity: 1,
        calculationPrice: '',
        planVariantID: '',
    })
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }, reset: resetForm
    } = useForm({ defaultValues: addToCart });

    const sendPlanSimType = {
        "tableName": "sim",
        "fieldName": "SIMType"
    };

    useEffect(() => {
        dispatch(getUserDataByToken());
        dispatch(getBanner());
        dispatch(getFeaturedPlan());
        dispatch(getTopSellerPlan());
        dispatch(getSalesAndDealerCount());
        dispatch(getDashboardCount());
        // dispatch(getActivityForDashboard());
        dispatch(getActivity({
            fromDate: '',
            toDate: ''
        }));
        dispatch(getSimTypeData(sendPlanSimType))
    }, []);

    useEffect(() => {
        dispatch(viewCartData());
    }, [cartMessage, cartError]);

    useEffect(() => {
        if (showViewCart) {
            navigate("/cart");
        }
    }, [cartMessage]);

    const handleShowPlanDetails = (data) => {
        dispatch(prepareViewPlanModal())
        const arr = [];
        arr.push(data);
        const str = data.PlanPrice
        // const noSpecialChars = str.replace(/\$/g, '');
        const calCulatedNewPrice = +str;
        setAddToCart(prevState => ({
            ...prevState,
            planID: data.PlanID,
            planVariantID: data.PlanVariantID,
            quantity: 1,
            planPrice: data.PlanPrice,
            calculationPrice: calCulatedNewPrice
        }));
        setSelectedEachPlanData(arr)
        dispatch(checkCartData(data.PlanVariantID))
    }

    const closeModal = () => {
        dispatch(resetViewPlanModal())
    }

    const handleChange = (e) => {
        const fname = e.target.name;
        const fvalue = e.target.value;
        const str = addToCart.planPrice
        // const noSpecialChars = str.replace(/\$/g, '');
        const calculatednewprice = +str;

        if (fvalue === '0') {
            setAddToCart(prevState => ({
                ...prevState,
                quantity: 1,
                calculationPrice: 1 * calculatednewprice
            }));
        } else {
            setAddToCart(prevState => ({
                ...prevState,
                quantity: fvalue,
                calculationPrice: Number(fvalue) * calculatednewprice
            }));
        }
    }


    const handleIncrement = (e) => {
        e.preventDefault();
        const str = addToCart.planPrice
        // const noSpecialChars = str.replace(/\$/g, '');
        const calculatednewprice = +str;
        setAddToCart((prevState) => ({
            ...prevState,
            quantity: prevState.quantity + 1,
            calculationPrice: (prevState.quantity + 1) * calculatednewprice,
        }));
    };

    const handleDecrement = (e) => {
        e.preventDefault();
        const str = addToCart.planPrice
        // const noSpecialChars = str.replace(/\$/g, '');
        const calculatednewprice = +str;
        if (addToCart.quantity > 1) {
            setAddToCart((prevState) => ({
                ...prevState,
                quantity: prevState.quantity - 1,
                calculationPrice: (prevState.quantity - 1) * calculatednewprice,
            }));
        }
    };

    const handleAddToCartSubmit = (item) => {
        if (item == 1) {
            dispatch(addPlanToCart(addToCart))
            setshowViewCart(true)
        }
        else {
            dispatch(addPlanToCart(addToCart))
            setshowViewCart(false)
        }
    }


    return (
        <>

            {
                loading ? <Loader /> : ''
            }
            
            {
                isAdmin(tokenData) || isAdminAgent(tokenData) ? <div id="content-page" className="content-page">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-3 od-2 od-md-2">
                                <div className="iq-card iq-card iq-card-block iq-card-stretch iq-card-height">
                                    <div className="iq-card-body text-center">
                                        <img src="./assets/images/page-img/41.png" className="img-fluid iq-image-full" alt="page-img" />
                                    </div>
                                    <div className="iq-card-header d-flex justify-content-between">
                                        <div className="iq-header-title">
                                            <h4 className="card-title font-weight-bold" style={{ fontSize: "18px" }}>Activity timeline </h4>
                                        </div>
                                        {
                                            activityList && activityList.length !== 0 && <div className="iq-card-header-toolbar d-flex align-items-center">
                                                <div className="dropdown">
                                                    <Link to="/activity-log"><span className="dropdown-toggle text-primary">View All</span></Link>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="iq-card-body" style={{ height: '100%' }}>
                                        <ul className="iq-timeline" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: "100%" }}>
                                            {
                                                activityList && activityList.length === 0 && <p>No data found</p>
                                            }
                                            {
                                                activityList && activityList.length > 0 && activityList.map((item, index) => {
                                                    return <>
                                                        <li key={index} style={{ display: 'flex', justifyContent: 'space-between', }}>
                                                            <div className="timeline-dots"></div>
                                                            <div>
                                                                <h6 className="float-left mb-1">{item.ActionType}</h6>
                                                                <div className="d-inline-block w-100">
                                                                    <p >{item.ActionDescription}</p>
                                                                </div>
                                                            </div>
                                                            <p style={{ display: 'flex', flexDirection: 'column' }}>
                                                                <small className="float-right mt-1">{moment(item.ActionDtTm).format(dateFormat)}</small>
                                                                <small className="float-right mt-1">{moment(item.ActionDtTm).format(timeFormat)}</small>
                                                            </p>
                                                        </li>
                                                    </>
                                                })
                                            }

                                        </ul>
                                    </div>


                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="row">
                                    <div className="col-xl-9 od-2">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                                                    <div className="iq-card-body">
                                                        <div className="row">
                                                            <div className="col-md-6 col-lg-3">
                                                                <div className="d-flex align-items-center mb-3 mb-lg-0">
                                                                    <div className="rounded-circle iq-card-icon iq-bg-primary mr-3">
                                                                        <SimCardLineIcon />
                                                                    </div>
                                                                    <div className="text-center">
                                                                        <h4 className="font-weight-bold">{dashboardCountData.SimCount ?? '0'}</h4>
                                                                        <p className="mb-0">Sims</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 col-lg-3">
                                                                <div className="d-flex align-items-center mb-3 mb-lg-0">
                                                                    <div className="rounded-circle iq-card-icon iq-bg-info mr-3">
                                                                        <UserLineIcon />
                                                                    </div>
                                                                    <div className="text-center">
                                                                        <h4 className="font-weight-bold">{dashboardCountData.DealerCount ?? '0'}</h4>
                                                                        <p className="mb-0">Dealers</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 col-lg-3">
                                                                <div className="d-flex align-items-center mb-3 mb-md-0">
                                                                    <div className="rounded-circle iq-card-icon iq-bg-danger mr-3">
                                                                        <ShoppingCartLineIcon />
                                                                    </div>
                                                                    <div className="text-center">
                                                                        <h4 className="font-weight-bold">{dashboardCountData.OrderCount ?? '0'}</h4>
                                                                        <p className="mb-0">Orders</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 col-lg-3">
                                                                <div className="d-flex align-items-center mb-3 mb-md-0">
                                                                    <div className="rounded-circle iq-card-icon iq-bg-warning mr-3">
                                                                        <Wallet2LineIcon />
                                                                    </div>
                                                                    <div className="text-center">
                                                                        <h4 className="font-weight-bold">{dashboardCountData.SalesAmount ?? '0'}</h4>
                                                                        <p className="mb-0">Amount</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <Request />




                                        </div>
                                    </div>
                                    <div className="col-xl-3" >

                                        <div className="iq-card iq-card-block iq-card-stretch iq-card-height ">
                                            <div className="iq-card-body text-center mt-lg-3">
                                                <h2 className="mb-0 font-weight-bold" style={{ color: userDetails && userDetails.AccountBalance < 0 ? "#f14336" : "#0aa06e" }}><span>£</span><span className="counter">{userDetails && userDetails.AccountBalance ? userDetails.AccountBalance < 0 ? `(${userDetails.AccountBalance.toFixed(2)})` : userDetails.AccountBalance.toFixed(2) : 0.00}</span></h2>
                                                <p className="mb-0">Your Current Balance</p>
                                                <Link to="/manual-top-up" className="btn btn-danger d-block mt-3 mb-1">Add Top Up</Link>
                                            </div>
                                        </div>


                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-md-8">

                                        <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                                            <div className="iq-card-body" >
                                                {SalesAndDealerCountData && SalesAndDealerCountData.length > 0 && <SalesChart />}
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-4">
                                        <Transaction />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : ''
            }

            {
                isDealer(tokenData) || isDealerAgent(tokenData) ? <div id="content-page" className="content-page">
                    <div className="container-fluid" >
                        <div className="row">
                            <div className="col-lg-3 od-2 od-md-2">
                                <div className="iq-card iq-card iq-card-block iq-card-stretch iq-card-height">
                                    <div className="iq-card-body text-center">
                                        <img src="./assets/images/page-img/41.png" className="img-fluid iq-image-full" alt="page-img" />
                                    </div>
                                    <div className="iq-card-header d-flex justify-content-between" >
                                        <div className="iq-header-title" >
                                            <h4 className="card-title font-weight-bold" style={{ fontSize: "18px" }}>Activity timeline</h4>
                                        </div>
                                        {
                                            activityList && activityList.length !== 0 && <div className="iq-card-header-toolbar d-flex align-items-center">
                                                <div className="dropdown">
                                                    <Link to="/activity-log"><span className="dropdown-toggle text-primary">View All</span></Link>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="iq-card-body" style={{ height: '100%' }}>
                                        <ul className="iq-timeline" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: "100%" }}>
                                            {
                                                activityList && activityList.length === 0 && <p>No data found</p>
                                            }
                                            {
                                                activityList && activityList.length > 0 && activityList.map((item, index) => {
                                                    return <>
                                                        <li key={index} style={{ display: 'flex', justifyContent: 'space-between', }}>
                                                            <div className="timeline-dots"></div>
                                                            <div>
                                                                <h6 className="float-left mb-1">{item.ActionType}</h6>
                                                                <div className="d-inline-block w-100">
                                                                    <p >{item.ActionDescription}</p>
                                                                </div>
                                                            </div>
                                                            <p style={{ display: 'flex', flexDirection: 'column' }}>
                                                                <small className="float-right mt-1">{moment(item.ActionDtTm).format(dateFormat)}</small>
                                                                <small className="float-right mt-1">{moment(item.ActionDtTm).format(timeFormat)}</small>
                                                            </p>
                                                        </li>
                                                    </>
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="row">
                                    <div className="col-xl-9 od-2">

                                        {bannerList && bannerList.length > 0 &&
                                            <div className="iq-card iq-card-block iq-card-stretch">
                                                <div className="iq-card-body">
                                                    {
                                                        bannerList && bannerList.length > 0 && <OwlCarousel className='owl-theme' items={1} nav={false} autoplay={true} loop>
                                                            {
                                                                bannerList.map((item) => {
                                                                    return <div className='item' key={item.ID}>
                                                                        <img src={`${baseURL}/bannerImages/${item.BannerImageUrl}`} className="img-fluid" alt="logo" />
                                                                    </div>
                                                                })
                                                            }
                                                        </OwlCarousel>
                                                    }
                                                </div>
                                            </div>
                                        }



                                    </div>

                                    <div className="col-xl-3">
                                        <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                                            <div className="iq-card-body text-center mt-lg-4">
                                                <h2 className="mb-0 font-weight-bold" style={{ color: userDetails && userDetails.AccountBalance < 0 ? "#f14336" : "#0aa06e" }}><span>£</span><span className="counter">{userDetails && userDetails.AccountBalance ? userDetails.AccountBalance < 0 ? `(${userDetails.AccountBalance.toFixed(2)})` : userDetails.AccountBalance.toFixed(2) : 0.00}</span></h2>
                                                <p className="mb-0">Your Current Balance</p>
                                                <Link to="/add-top-up" className="btn btn-danger d-block mt-3 mb-1">Add Top Up</Link>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="iq-card iq-card-block iq-card-stretch ">
                                            <div className="iq-card-body">
                                                <div className="row">
                                                    <div className="col-md-6 col-lg-3">
                                                        <div className="d-flex align-items-center mb-3 mb-lg-0">
                                                            <div className="rounded-circle iq-card-icon iq-bg-primary mr-3">
                                                                <SimCardLineIcon />
                                                            </div>
                                                            <div className="text-center">
                                                                <h4 className="font-weight-bold">{dashboardCountData.SimCount ?? '0'}</h4>
                                                                <p className="mb-0">Sims</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-3">
                                                        <div className="d-flex align-items-center mb-3 mb-lg-0">
                                                            <div className="rounded-circle iq-card-icon iq-bg-info mr-3">
                                                                <UserLineIcon />
                                                            </div>
                                                            <div className="text-center">
                                                                <h4 className="font-weight-bold">{dashboardCountData.DealerCount ?? '0'}</h4>
                                                                <p className="mb-0">Dealers</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-3">
                                                        <div className="d-flex align-items-center mb-3 mb-md-0">
                                                            <div className="rounded-circle iq-card-icon iq-bg-danger mr-3">
                                                                <ShoppingCartLineIcon />
                                                            </div>
                                                            <div className="text-center">
                                                                <h4 className="font-weight-bold">{dashboardCountData.OrderCount ?? '0'}</h4>
                                                                <p className="mb-0">Orders</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-3">
                                                        <div className="d-flex align-items-center mb-3 mb-md-0">
                                                            <div className="rounded-circle iq-card-icon iq-bg-warning mr-3">
                                                                <Wallet2LineIcon />
                                                            </div>
                                                            <div className="text-center">
                                                                <h4 className="font-weight-bold">{dashboardCountData.SalesAmount ?? '0'}</h4>
                                                                <p className="mb-0">Amount</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">

                                            {topSellerPlanResult && topSellerPlanResult.length > 0 && <div className="col-md-6">
                                                <PlanCarousel planTitle="Top Best Seller" planDetailData={topSellerPlanResult} handleShowPlanDetails={handleShowPlanDetails} />
                                            </div>}



                                            {featuredPlanResult && featuredPlanResult.length > 0 && <div className="col-md-6" >
                                                <PlanCarousel planTitle="Featured Plan" planDetailData={featuredPlanResult} handleShowPlanDetails={handleShowPlanDetails} />
                                            </div>}


                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <Transaction />
                                    </div>
                                </div>



                            </div>
                        </div>

                    </div>
                </div>
                    : ''
            }

            {showModalLoading ? '' : <Modal
                showModal={showModal}
                modalWidth='800px'
                onHide={closeModal}
                width={850}
                bodyClasses="model-plan-purchase"
                showFooter={false}
                showHeader={false}
                alignment='modal-dialog-centered'
            >
                {selectedEachPlanData && selectedEachPlanData.length > 0 && selectedEachPlanData.map((data, key) => {
                    return (
                        <div className='row m-0'>
                            <div className='col-lg-6 col-md-6 col-sm-6 p-0'>
                                <div className="plan-purchase-image-container">
                                    <img src={data.ImageUrl ? `${process.env.REACT_APP_BASEURL}/planImages/${data.ImageUrl}` : NoImageAvailable} className='set-box'></img>
                                    <div className='image-overlay'>
                                        <div className='model-cart-details-purshase'>
                                            <p className='text-center mb-0' style={{ fontFamily: 'Muli', fontSize: '17px', fontWeight: '900', letterSpacing: '1px' }}>{data.PlanName}</p>
                                            {/* <p className='text-center' style={{ fontFamily: 'Muli', fontWeight: '900', letterSpacing: '1px', marginBottom: '0px' }}>{data.VariantName}</p>
                                            <p className='text-center' style={{ fontFamily: 'Muli', fontWeight: '900', letterSpacing: '1px', marginBottom: '0px' }}>{`${data.PlanData}, ${data.PlanDays},`}</p>
                                            <p className='text-center' style={{ fontFamily: 'Muli', fontWeight: '900', letterSpacing: '1px', marginBottom: '0px' }}>{data.CountryName}</p> */}

                                            {/* <p className='text-center' style={{ fontFamily: 'Muli', fontWeight: '900', letterSpacing: '1px' }}>{simTypeList && simTypeList.length > 0 && simTypeList.filter(item => item.ID == data.PlanSIMType).map(simType => (
                                                <span key={simType.ID} style={{ marginLeft: '3px' }}>{simType.Value}</span>
                                            ))}</p> */}
                                            <div className='row text-center'>
                                                <div className='col-sm-6'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="40" height="40" fill="currentColor"><path d="M11.9498 7.94975L10.5356 9.36396L8.00079 6.828L8.00004 20H6.00004L6.00079 6.828L3.46451 9.36396L2.05029 7.94975L7.00004 3L11.9498 7.94975ZM21.9498 16.0503L17 21L12.0503 16.0503L13.4645 14.636L16.0008 17.172L16 4H18L18.0008 17.172L20.5356 14.636L21.9498 16.0503Z"></path></svg>
                                                    <i className="ri-arrow-up-down-fill" style={{ fontSize: '40px' }}></i>
                                                    <p style={{ fontSize: '14px' }}>{data.PlanData}</p>
                                                </div>
                                                <div className='col-sm-6'>
                                                    <i class="ri-calendar-2-fill" style={{ fontSize: '40px' }}></i>
                                                    <p style={{ fontSize: '14px' }}>{data.PlanDays}</p>
                                                </div>
                                                {/* <div className='col-sm-4'>
                                                <i className="ri-database-2-fill" style={{ fontSize: '40px' }}></i>
                                                <p className='text-center' style={{ fontSize: '14px' }}>{showCountryNameOnModal}</p>
                                            </div> */}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-6 col-sm-6  '>
                                <div className="div-set-purchase-pup-dashboard">
                                    <div className='row py-0 mt-1 mb-0'>
                                        <div className='col d-flex align-item-center justify-content-between'>
                                            <span className='budle-head-purshase'><h4 style={{ fontWeight: 'bold' }}>Bundle Details</h4></span>
                                            <span style={{ display: 'flex', justifyContent: 'end', fontSize: '25px', fontWeight: '900', color: 'black' }}><i className="ri-close-line" style={{ cursor: 'pointer' }} onClick={closeModal}></i></span>
                                        </div>
                                    </div>
                                    <div style={{marginTop:'-6px'}}>
                                        <h6 style={{ marginBottom: '6px', fontSize: '17px', fontWeight: 'bold' }}>{data.PlanName}</h6>
                                        <div style={{ fontFamily: 'Muli', color: '#737070', marginBottom: '9px' }}>
                                            <p style={{ fontSize: '13px', fontWeight: '550', marginBottom: '6px' }}>{`${data.VariantName}`}</p>
                                            <div className="form-row">
                                                {/* <span className="col-lg-3">{data.PlanData}</span>
                                                <span className="col-lg-3">{data.PlanDays}</span> */}
                                                <p className="col-lg-3" style={{ fontSize: '12px', fontWeight: '500' }} >{data.CountryName}</p>
                                                {simTypeList && simTypeList.length > 0 && simTypeList.filter(item => item.ID == data.PlanSIMType).map(simType => (
                                                    <span key={simType.ID} className="col-lg-3" style={{ fontSize: '12px', fontWeight: '500' }}><i>{simType.Value}</i></span>
                                                ))}
                                            </div>



                                        </div>
                                        <h5 style={{ fontWeight: 'bold' }}>Base Country</h5>
                                        <input type='text' className='purchese-plan-input-r' value={data.CountryName} disabled style={{ width: '100%', borderRadius: '20px', padding: '2px 14px', border: '1px solid #e8d9d9', marginTop: '10px' }} />
                                    </div>
                                    <form className='form-popup-purchase'>
                                        <div className='row '>
                                            <div className=" row-purchase-footer-dashboard ">
                                                <div className='footer-purchase-pup-dashboard'>
                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-6 col-sm-6 col-6 mb-3"><div className="qtydashbord" >QTY</div></div>
                                                        <div className="col-lg-5 col-md-6 col-sm-6 col-6 mb-3">
                                                            <div className='incrdashbord text-center' >
                                                                <button onClick={(e) => handleDecrement(e)} className='increment-button'><i className="ri-subtract-line"></i></button>
                                                                <input type="number" name="quantity" {...register("quantity", {
                                                                    required: true,
                                                                })} value={addToCart.quantity} style={{ width: '40px', height: '30px', textAlign: 'center', border: 'transparent' }} onChange={handleChange} onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} ></input>
                                                                <button onClick={(e) => handleIncrement(e)} className='increment-button ' ><i className="ri-add-line"></i></button>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                                                            <div className="totaldashboard"><p style={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }} >Total - £{parseFloat(addToCart.calculationPrice).toFixed(2)}</p>
                                                            </div>
                                                        </div>

                                                        <div className='col-lg-12 col-md-6 col-sm-6 col-6'>
                                                            <div className="buttondashboard">
                                                                <div className="button-purshase-btn">
                                                                    {checkCartFlag !== undefined && checkCartFlag === 1 ? <Link to="/cart" className="col-lg-12 d-flex justify-content-end pr-0">
                                                                        <Button title='VIEW CART' style="minWidth:80px" buttonClass="btn btn-primary purchase-cart-b botton-height-purshasedashoard" buttonType='button' onClick={closeModal} /></Link> :
                                                                        <div className="d-flex justify-content-between" style={{ gap: '4px' }}>
                                                                            <Button title='BUY NOW' loading={buttonLoading} style="minWidth:80px" buttonClass="btn btn-primary botton-height-purshasedashoard" buttonType='button' onClick={() => handleAddToCartSubmit(1)} />
                                                                            <Button title='ADD BUNDLE' style="minWidth:80px" buttonClass="btn btn-primary botton-height-purshasedashoard" buttonType='button' onClick={() => handleAddToCartSubmit(2)} />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>


                                            </div>
                                        </div>

                                        {errors.quantity?.type === "required" && (
                                            <div className="invalid-feedback">quantity should not be blank !</div>

                                        )}
                                    </form>

                                </div>
                            </div>
                        </div>
                    )
                })}
            </Modal>}
            <NonVerifyDealerModel />


        </>
    )
}

export default Dashboard;