const actions = {
    GET_NON_VERIFY_DEALER: 'GET_NON_VERIFY_DEALER',
    GET_NON_VERIFY_DEALER_SUCCESS: 'GET_NON_VERIFY_DEALER_SUCCESS',
    GET_NON_VERIFY_DEALER_FAILED: 'GET_NON_VERIFY_DEALER_FAILED',
    TO_VERIFY_DEALER: "TO_VERIFY_DEALER",
    TO_VERIFY_DEALER_SUCCESS: "TO_VERIFY_DEALER_SUCCESS",
    TO_VERIFY_DEALER_FAILED: "TO_VERIFY_DEALER_FAILED",
    PREPARE_VERIFY_DEALER_FORM: "PREPARE_VERIFY_DEALER_FORM",
    RESET_VERIFY_DEALER: "RESET_VERIFY_DEALER",
    RESET_VERIFY_Dealer_Message:"RESET_VERIFY_Dealer_Message",
    
    getNonVerifyDealer: () => ({
        type: actions.GET_NON_VERIFY_DEALER,
    }),
    getNonVerifyDealerSuccess: (verifyDealerResult) => ({
        type: actions.GET_NON_VERIFY_DEALER_SUCCESS,
        verifyDealerResult
    }),
    getNonVerifyDealerFailed: (verifyDealerError) => ({
        type: actions.GET_NON_VERIFY_DEALER_FAILED,
        verifyDealerError
    }),

    toVerifyDealer: (dealerId, data) => ({
        type: actions.TO_VERIFY_DEALER,
        payload: { dealerId, data }
    }),
    toVerifyDealerSuccess: (verifyDealerResult, verifyDealerMessage) => ({
        type: actions.TO_VERIFY_DEALER_SUCCESS,
        verifyDealerResult,
        verifyDealerMessage
    }),
    toVerifyDealerFailed: (verifyDealerError) => ({
        type: actions.TO_VERIFY_DEALER_FAILED,
        verifyDealerError
    }),
    prepareVerifyDealerForm: () => ({
        type: actions.PREPARE_VERIFY_DEALER_FORM,
    }),
    resetVerifyDealer: () => ({
        type: actions.RESET_VERIFY_DEALER,
    }),
    resetVerifyDealerMessage:()=>({
        type:actions.RESET_VERIFY_Dealer_Message
    })
};
export default actions;
