import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData, getList } from '../../helpers/fackBackend_Helper';

function* amountDataResponse() {
    try {
        const response = yield call(getList, '/prepareAddFund');
        if (response.response.Status) {
            yield put(
                actions.amountDataSuccess(
                    response.response.Data
                ));
        } else {
            yield put(actions.amountDataFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.amountDataFailed(error));
    }
}

function* getTransactionHistoryResponse() {
    try {
        const response = yield call(getList, '/addFundHistory');
        if (response.response.Status) {
            yield put(
                actions.getTransactionHistorySuccess(
                    response.response.Data
                ));
        } else {
            yield put(actions.getTransactionHistoryFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getTransactionHistoryFailed(error));
    }
}

function* createPaymentIdResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/addFund', data);
        if (response.response.Status) {
            yield put(
                actions.createPaymentIdSuccess(
                    response.response.PaymentId
                ));
        } else {
            yield put(actions.createPaymentIdFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.createPaymentIdFailed(error));
    }
}

function* sendPaymentDataResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/updateAddFund', data);
        if (response.response.Status) {
            const responseList = yield call(getList, '/prepareAddFund');
            if (responseList.response.Status) {
                const responseHistory = yield call(getList, '/addFundHistory');
                yield put(
                    actions.sendPaymentDataSuccess(
                        response.response.Message, responseList.response.Data, responseHistory.response.Data
                    ));
            } else {
                yield put(actions.sendPaymentDataFailed(
                    response.response.Message
                ));
            }
        } else {
            const responseHistory = yield call(getList, '/addFundHistory');
            yield put(
                actions.sendPaymentDataFailed(
                    response.response.Message, responseHistory.response.Data
                ));
        }
    } catch (error) {
        yield put(actions.sendPaymentDataFailed(error));
    }
}

function* getPaymentGatewayDealerResponse() {
    try {
        const response = yield call(getList, '/getPaymentGatewayForCheckout');
        if (response.response.Status) {
            yield put(actions.getPaymentGatewayDealerSuccess(response.response.PaymentGateway));
        } else {
            yield put(actions.getPaymentGatewayDealerFailed(response.response));
        }
    } catch (error) {
        yield put(actions.getPaymentGatewayDealerFailed(error));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.AMOUNT_DATA, amountDataResponse)]);
    yield all([takeEvery(actions.CREATE_PAYMENT_ID, createPaymentIdResponse)]);
    yield all([takeEvery(actions.SEND_PAYMENT_DATA, sendPaymentDataResponse)]);
    yield all([takeEvery(actions.GET_TRANSACTION_HISTORY, getTransactionHistoryResponse)]);
    yield all([takeEvery(actions.GET_PAYMENT_GATEWAY_DEALER, getPaymentGatewayDealerResponse)]);
}