import actions from "./actions";

const initState = {
    featuredPlanResult: [],
    topSellerPlanResult: [],
    dashboardError: null,
    dashboardMessage: null,
    showModal: false,
    dashboardLoading: false,
    dashboardMessage: null,
    dashboardError: null,
    dashboardCountData: [],
    dashboardActivityLoading: false,
    dashboardActivityMessage: null,
    dashboardActivityError: null,
    dashboardActivityData: [],
    SalesAndDealerCountData: []
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_FEATURED_PLAN:
            return {
                ...state,
                dashboardError: null,
                loading: true,
                dashboardMessage: null,
            };
        case actions.GET_FEATURED_PLAN_SUCCESS:
            return {
                ...state,
                dashboardError: null,
                loading: false,
                featuredPlanResult: action.featuredPlanResult,
            };
        case actions.GET_FEATURED_PLAN_FAILED:
            return {
                ...state,
                dashboardError: action.dashboardError,
                dashboardMessage: null,
                loading: false,
            };
        case actions.GET_TOP_SELLER_PLAN:
            return {
                ...state,
                dashboardError: null,
                loading: true,
                dashboardMessage: null,
            };
        case actions.GET_TOP_SELLER_PLAN_SUCCESS:
            return {
                ...state,
                dashboardError: null,
                loading: false,
                topSellerPlanResult: action.topSellerPlanResult,
            };
        case actions.GET_TOP_SELLER_PLAN_FAILED:
            return {
                ...state,
                dashboardError: action.dashboardError,
                dashboardMessage: null,
                loading: false,
            };
        case actions.PREPARE_DASHBOARD_MODAL:
            return {
                ...state,
                dashboardError: null,
                dashboardMessage: null,
                loading: false,
                showModal: true
            };
        case actions.RESET_DASHBOARD_MODAL:
            return {
                ...state,
                dashboardError: null,
                dashboardMessage: null,
                loading: false,
                showModal: false
            };

        case actions.GET_DASHBOARD_COUNT:
            return {
                ...state,
                dashboardLoading: true,
                dashboardMessage: null,
                dashboardError: null,
            };
        case actions.GET_DASHBOARD_COUNT_SUCCESS:
            return {
                ...state,
                dashboardLoading: false,
                dashboardMessage: null,
                dashboardError: null,
                dashboardCountData: action.payload.data,
            };
        case actions.GET_DASHBOARD_COUNT_FAILED:
            return {
                ...state,
                dashboardLoading: false,
                dashboardMessage: null,
                dashboardError: action.payload.error,
            };

        // case actions.GET_ACTIVITY_FOR_DASHBOARD:
        //     return {
        //         ...state,
        //         dashboardActivityLoading: true,
        //         dashboardActivityMessage: null,
        //         dashboardActivityError: null,
        //     };
        // case actions.GET_ACTIVITY_FOR_DASHBOARD_SUCCESS:
        //     return {
        //         ...state,
        //         dashboardActivityLoading: false,
        //         dashboardActivityMessage: null,
        //         dashboardActivityError: null,
        //         dashboardActivityData: action.payload.data,
        //     };
        // case actions.GET_ACTIVITY_FOR_DASHBOARD_FAILED:
        //     return {
        //         ...state,
        //         dashboardActivityLoading: false,
        //         dashboardActivityMessage: null,
        //         dashboardActivityError: action.payload.error,
        //     };

        case actions.GET_SALES_AND_DEALER_COUNT:
            return {
                ...state,
                dashboardLoading: true,
                dashboardMessage: null,
                dashboardError: null,
            };
        case actions.GET_SALES_AND_DEALER_COUNT_SUCCESS:
            return {
                ...state,
                dashboardLoading: false,
                dashboardMessage: null,
                dashboardError: null,
                SalesAndDealerCountData: action.payload.data,
            };
        case actions.GET_SALES_AND_DEALER_COUNT_FAILED:
            return {
                ...state,
                dashboardLoading: false,
                dashboardMessage: null,
                dashboardError: action.payload.error,
            };



        default:
            return state;
    }
}