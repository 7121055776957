import React from 'react';

// const titleClasses = {
//   Active: 'iq-bg-success',
//   InActive: 'iq-bg-danger',
//   pending: 'iq-bg-secondary-dark',
//   default: 'iq-bg-primary',
//   processing: 'iq-bg-warning'
// };

function Tag({ title,color }) {
  // const badgeClass = titleClasses[title] || titleClasses.default;

  return (
    <span className={`badge ${color}`}>{title}</span>
  );
}

export default Tag;
