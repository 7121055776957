import React, { useState } from 'react';

const PopConfirm = ({ title, okText, cancelText, onConfirm, children }) => {
    const [showPopover, setShowPopover] = useState(false);

    const handleCancel = () => {
        setShowPopover(false);
    };

    const handleConfirm = () => {
        setShowPopover(false);
        if (onConfirm) {
            onConfirm();
        }
    };

    const handleClick = () => {
        setShowPopover((prevState) => !prevState); // Toggle the popover state
    };

    return (
        <div style={{ position: 'relative', display: 'inline-block' }}>
            <div onClick={handleClick}>{children}</div>
            {showPopover && (
                <div className='border-propver-width'
                    style={{
                        position: 'absolute',
                        top: "-26px",
                        right: "30px",
                        zIndex: '9999',
                        backgroundColor: 'white',
                        border: '1px solid #ccc',
                        padding: '10px',
                        width: '300px',
                    }}
                >
                    <p style={{ margin: '0', padding: '0' }}>{title}</p>
                    <button style={{ marginRight: '5px' }} className="btn btn-primary" onClick={handleConfirm}>
                        {okText}
                    </button>
                    <button className="btn btn-secondary" onClick={handleCancel}>
                        {cancelText}
                    </button>
                </div>
            )}
        </div>
    );
};

export default PopConfirm;
