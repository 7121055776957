import React, { useState, useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import NoImageAvailable from '../assets/images/no image available.jpg'
import ArrowUpDownFill from 'remixicon-react/ArrowUpDownFillIcon';
import CalendarTodoFill from 'remixicon-react/CalendarTodoFillIcon'
import SimPurchaseActions from '../redux/simPurchase/actions'
import { useDispatch, useSelector } from 'react-redux';


const {getSimTypeData } = SimPurchaseActions;



function PlanCarousel({ planTitle, planDetailData, handleShowPlanDetails, itemsData }) {
    const dispatch = useDispatch();
    const { simTypeList} = useSelector((state) => state.simPurchase);

    const sendPlanSimType = {
        "tableName": "sim",
        "fieldName": "SIMType"
    };

    useEffect(()=>{
        dispatch(getSimTypeData(sendPlanSimType))
    },[])

    // const planVariantsWithAdditionalKeys = planResult && planResult.map(plan => {
    //     const { PlanVariant, PlanName, PlanDesc, AvailableForFlag, AvailableForFlagValue, AvailableForID, AvailableForIDValue, AvailableForIDValueISO3, ImageUrl, ...rest } = plan;

    //     // Adding additional keys to each PlanVariant object
    //     const planVariantsWithKeys = PlanVariant.map(variant => ({
    //         ...variant,
    //         PlanName,
    //         PlanDesc,
    //         AvailableForFlag,
    //         AvailableForFlagValue,
    //         AvailableForID,
    //         AvailableForIDValue,
    //         AvailableForIDValueISO3,
    //         ImageUrl,
    //         ...rest,
    //     }));

    //     return planVariantsWithKeys;
    // });

    // const flattenedPlanVariants = planVariantsWithAdditionalKeys.flat();

    //console.log("planDetailData", planDetailData)
    return (
        <>
            <div className="iq-card iq-card-block iq-card-stretch iq-card-height" >
                <div className="iq-card-body">
                    <h4 className='mb-3 font-weight-bold' style={{ fontSize: "18px" }}>{planTitle}</h4>
                    <OwlCarousel className='owl-theme' margin={10} items={itemsData ? itemsData : 1} nav>
                        {planDetailData && planDetailData && planDetailData.length > 0 && planDetailData.map((data, index) => {
                            return (
                                <div className='item' key={index} >
                                    <div className="iq-card mb-0" onClick={() => handleShowPlanDetails(data)}>
                                        <img
                                            src={data.ImageUrl ? `${process.env.REACT_APP_BASEURL}/planImages/${data.ImageUrl}` : NoImageAvailable}
                                            className="img-fluid rounded-top carousel-image"
                                            alt={`Image ${index + 1}`}
                                            style={{ width: '100%', height: '140px', objectFit: 'cover', marginBottom: '10px' }}
                                            onClick={() => handleShowPlanDetails(data)}
                                        />
                                        <div className='px-3 pb-2'>
                                            {/* <h5 className="card-title" style={{ marginTop: '10px' }}>{data.AvailableForIDValue} - {data.VariantName}</h5> */}
                                            <h6 className="card-title plan-h">{data.PlanName}</h6>
                                            <p className="card-title plan-h" style={{fontSize:'13px',fontWeight:'normal'}}>{data.VariantName}, 
                                            {simTypeList && simTypeList.length > 0 && simTypeList.filter(item=>item.ID==data.PlanSIMType).map(simType => (
                                                <span key={simType.ID} style={{marginLeft:'3px'}}>{simType.Value}</span>
                                            ))}
                                            </p>
                                            <div className="card-text plan-v form-row">
                                                <p className='col-md-6 d-flex align-item-center' ><ArrowUpDownFill size="1.3em" color='#00b894' style={{paddingTop:'3px'}}/><span>{data.PlanData}</span></p>
                                                <p className='col-md-6 d-flex align-item-center justify-content-end'><CalendarTodoFill size="1.3em" color='#00b894' style={{paddingTop:'3px'}}/><span>{data.PlanDays}</span></p>
                                            </div>
                                            {/* <p className="card-text"style={{marginTop:'0px',marginBottom:'0.2rem'}}> {data.PlanDaysValue}</p> */}

                                            <p className="card-text plan-price">
                                                £{data.PlanPrice ? data.PlanPrice.toFixed(2) : '0.00'}
                                            </p>

                                        </div>

                                    </div>

                                </div>
                            )
                        })}
                    </OwlCarousel>
                </div>
            </div>


        </>
    );
}

export default PlanCarousel;
