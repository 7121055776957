import React, { useEffect, useMemo, useState } from 'react';
import PencilFill from 'remixicon-react/PencilFillIcon';
import DeleteBin5Fill from 'remixicon-react/DeleteBin5FillIcon';
import CheckboxCircleFill from 'remixicon-react/CheckboxCircleFillIcon';
import EyeLineIcon from 'remixicon-react/EyeLineIcon';
import EyeOffLineIcon from 'remixicon-react/EyeOffLineIcon';
import IndeterminateCircleFillIcon from 'remixicon-react/IndeterminateCircleFillIcon';
import EyeFill from 'remixicon-react/EyeFillIcon';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from "react-hook-form";
import Tag from '../../../components/utils/Tag';
import { Link } from 'react-router-dom';
import moment from "moment";
import ReactCountryFlag from 'react-country-flag';
import PhoneInput from 'react-phone-number-input';
import CardLayout from '../../../components/utils/CardLayout';
import Table from '../../../components/utils/Table';
import SideButtons from '../../../components/utils/SideButtons';
import SearchBar from '../../../components/utils/SearchBar';
import dealerAction from '../../../redux/dealer/actions'
import countryAction from '../../../redux/country/actions'
import tariffGroupAction from '../../../redux/tariffGroup/actions'
import Modal from '../../../components/utils/Model';
import Popconfirm from '../../../components/utils/Popover';
import Alert from '../../../components/utils/Alert';
import Button from '../../../components/utils/Button';
import DealerViewModel from '../../../components/utils/DealerViewModel';
import { isAdminAgent, isDealerAgent } from '../../../helpers/commonFunction';
import { PASSWORD_VALIDATE } from '../../../helpers/constant';


function Dealer() {
    const { getDealer, resetDealer, addDealer, getTariffAllowCreation, getDealerAllowCreation, editDealer, deleteDealer, prepareDealerForm, getDealerLevel, resetDealerMessage } = dealerAction;
    const { getCountry } = countryAction;
    const { getTariff } = tariffGroupAction;
    const dispatch = useDispatch()
    const loading = useSelector((state) => state.dealer.loading);
    // const profileDataResult = useSelector((state) => state.profileList.profileListResult); // Implemmented when user profile completed
    const dealerResult = useSelector((state) => state.dealer.dealerResult);
    const dealerLevelResult = useSelector((state) => state.dealer.dealerLevelResult);
    const dealerCreationResult = useSelector((state) => state.dealer.dealerCreationResult);
    const dealerTariffResult = useSelector((state) => state.dealer.dealerTariffResult);
    const successMessage = useSelector((state) => state.dealer.dealerMessage);
    const errorMessage = useSelector((state) => state.dealer.dealerError);
    const buttonLoading = useSelector((state) => state.dealer.buttonLoading);
    const dealerFormModal = useSelector((state) => state.dealer.dealerFormModal);
    const countryResult = useSelector((state) => state.country.countryResult);
    const tariffResult = useSelector((state) => state.tariffGroup.tariffResult);
    const { userDetails, tokenData } = useSelector((state) => state.auth);
    const [filterText, setFilterText] = useState("");
    const [formTitle, setFormTitle] = useState('');
    const [formButton, setFormButton] = useState('');
    const [editFlag, setEditFlag] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');
    const [editID, seteditID] = useState(null);
    const [selectedDealerDetails, setselectedDealerDetails] = useState([])
    const [dealerData, setDealerData] = useState({
        minimumTopUpAmount: 0,
        maximumTopUpAmount: 0,
        allowSellerCreation: 0,
        allowTariffCreation: 0,
        paypalTransactionFee: 0
    })
    const [dealerCreatetionFlag, setDealerCreatetionFlag] = useState();
    const [tariffCreatetionFlag, setTariffCreatetionFlag] = useState();
    const { register, handleSubmit, formState: { errors }, watch, reset, control } = useForm({
        defaultValues: dealerData,
    });
    const minimumTopUpAmount = watch('minimumTopUpAmount');
    const [passwordShow, setPasswordShow] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false)
    const [responseData, setResponseData] = useState([])


    useEffect(() => {
        dispatch(getDealer());
        dispatch(getCountry());
        dispatch(getTariff());
        dispatch(getDealerLevel());
    }, [])

    useEffect(() => {
        if (errorMessage !== null && errorMessage !== undefined) {
            displayAlert(errorMessage, 'alert-danger');
        }
        if (successMessage !== null && successMessage !== undefined) {
            displayAlert(successMessage, 'alert-success');
        }
    }, [errorMessage, successMessage]);

    useEffect(() => {
        reset(dealerData);
    }, [dealerData]);

    useEffect(() => {
        if (dealerCreationResult === 1) {
            setDealerCreatetionFlag(false)
        } else if (dealerCreationResult === 0) {
            setDealerCreatetionFlag(true)
        }

        if (dealerTariffResult === 1) {
            setTariffCreatetionFlag(false);
        } else if (dealerTariffResult === 0) {
            setTariffCreatetionFlag(true)
        }
    }, [dealerCreationResult, dealerTariffResult]);

    const handleShowDealerDetails = (item) => {
        setResponseData(item)
        setShowViewModal(true);

    }

    // console.log("AuthState", userDetails[0].DealerID)

    const responseItems = responseData ? () => {
        const dealerData = [
            [
                { name: "Dealer Name", value: responseData.DealerName },
                { name: "Allow Dealer Creation", value: responseData.AllowSellerCreation === 1 ? 'Yes' : 'No' },
                { name: "Email ID", value: responseData.ContactEmailID },
                { name: "Creation Date", value: moment(responseData.CreatedDtTm).format(`${process.env.REACT_APP_DATE_FORMAT}`) }
            ],
            [
                { name: 'Other Details' }
            ],
            [
                { name: "Dealer Name", value: responseData.DealerName },
                { name: "Username", value: responseData.Username },
                { name: " Email ID", value: responseData.ContactEmailID },
                { name: "Number", value: responseData.ContactNumber },
                { name: "Contact Person", value: responseData.ContactPerson },
                { name: "Account Balance", value: `£ ${responseData.AccountBalance ? responseData.AccountBalance.toFixed(2) : '0.00'}` },
                { name: "Creation Date", value: moment(responseData.CreatedDtTm).format(`${process.env.REACT_APP_DATE_FORMAT}`) },
                { name: "Verified Date", value: moment(responseData.verifiedDate).format(`${process.env.REACT_APP_DATE_FORMAT}`) },
                { name: "Tariff Group", value: responseData.TariffGroup },
                { name: "Minimum Amount", value: `£ ${responseData.MinimumTopUpAmount ? responseData.MinimumTopUpAmount.toFixed(2) : '0.00'}` },
                { name: "Maximum Amount", value: `£ ${responseData.MaximumTopUpAmount ? responseData.MaximumTopUpAmount.toFixed(2) : '0.00'}` },
                { name: "Transaction Fee", value: `£ ${responseData.PaypalTransactionFee ? responseData.PaypalTransactionFee.toFixed(2) : '0.00'}` },
                { name: "Address", value: responseData.Address ? responseData.Address : "N/A" },
                { name: "State", value: responseData.State ? responseData.State : "N/A" },
                { name: "Country", value: responseData.CountryName ? responseData.CountryName : "N/A" },
                { name: "Postal Code", value: responseData.ZipCode ? responseData.ZipCode : "N/A" },
                { name: "Dealer Creation", value: <Tag color={`${responseData.AllowSellerCreation === 1 ? "badge-success" : "badge-danger"}`} title={responseData.AllowSellerCreation === 1 ? "Yes" : "No"} /> },
                { name: "Tariff Creation", value: <Tag color={`${responseData.AllowTariffCreation === 1 ? "badge-success" : "badge-danger"}`} title={responseData.AllowTariffCreation === 1 ? "Yes" : "No"} /> },
                { name: "Status", value: <Tag color={`${responseData.IsActive === 1 ? "badge-success" : "badge-danger"}`} title={responseData.IsActive === 1 ? "Active" : "Inactive"} /> },
                { name: "Verified", value: <Tag color={`${responseData.IsVerified === 1 ? "badge-success" : "badge-danger"}`} title={responseData.IsVerified === 1 ? "Yes" : "No"} /> },

            ],

        ]
        return dealerData;
    } : [];
    //console.log(responseData)

    const dealerItem = responseItems()

    const handleCancelmodal = () => {
        setShowViewModal(false)
    }


    const columns = [
        { displayName: '#', key: 'key' },
        { displayName: 'DEALER NAME', key: 'dealerName' },
        { displayName: 'USERNAME', key: 'username' },
        { displayName: 'EMAIL', key: 'contactEmailId' },
        { displayName: 'COUNTRY', key: 'country' },
        { displayName: 'TARIFF GROUP', key: 'tariffGroup' },
        { displayName: 'ACCOUNT BALANCE', key: 'accountBalance' },
        { displayName: 'CREATE DATE', key: 'createDate' },
        { displayName: 'VERIFY DATE', key: 'verifiedDate' },
        { displayName: 'STATUS', key: 'status' },
        { displayName: 'ACTIONS', key: 'action' },
    ];

    const filteredItems = dealerResult && dealerResult.length > 0 && dealerResult.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    var data = filteredItems && filteredItems.length > 0 && filteredItems.map((dealer, key) => {
        var address = '';
        if (dealer.Address != null && dealer.Address != '')
            address += dealer.Address + ', ';
        if (dealer.State != null && dealer.State != '')
            address += dealer.State + ', ';
        if (dealer.ZipCode != null && dealer.ZipCode != '')
            address += dealer.ZipCode + ', ';
        if ((dealer.ParentID && dealer.ParentID !== 0)) {
            var actionContent = (
                <>
                    {userDetails && userDetails.length > 0 && userDetails[0] && userDetails[0].DealerID === dealer.ParentID && !isDealerAgent(tokenData) && !isAdminAgent(tokenData) ?
                        <Link to="#">
                            <span data-toggle="tooltip" data-placement="top" title="Edit"><PencilFill size="1.2em" style={{ color: "#888b8e", marginRight: "3px" }} onClick={() => handleOpenEditFormModal(dealer.ID)} /></span>
                        </Link> :

                        <span data-toggle="tooltip" data-placement="top" title="Not allowed"><IndeterminateCircleFillIcon size="1.2em" style={{ color: "#888b8e", marginRight: "3px", cursor: "not-allowed" }} /></span>
                    }


                    {dealer.IsActive === 1 && !isDealerAgent(tokenData) && !isAdminAgent(tokenData) ?
                        <Popconfirm
                            title="Are you sure to inactive this dealer?"
                            okText="Yes"
                            cancelText="No"
                            placement="topRight"
                            onConfirm={() => handleDelete(dealer.ID, 0)}
                        >
                            <Link className="deleteBtn" to="# ">
                                <span data-toggle="tooltip" data-placement="top" title="Inactive"><DeleteBin5Fill size="1.2em" style={{ color: "#f14336", marginRight: "3px" }} /></span>
                            </Link>
                        </Popconfirm>
                        : ''}

                    {
                        dealer.IsActive !== 1 && !isDealerAgent(tokenData) && !isAdminAgent(tokenData) ?
                            <Popconfirm
                                title="Are you sure to active this dealer?"
                                okText="Yes"
                                cancelText="No"
                                placement="topRight"
                                onConfirm={() => handleDelete(dealer.ID, 1)}
                            >
                                <Link className="deleteBtn" to="# ">
                                    <span data-toggle="tooltip" data-placement="top" title="Active"><CheckboxCircleFill size="1.2em" style={{ color: "#2cb549", marginRight: "3px" }} /></span>
                                </Link>
                            </Popconfirm> : ''
                    }

                    <Link to="#" onClick={() => handleShowDealerDetails(dealer)} >
                       <span data-toggle="tooltip" data-placement="top" title="View"><EyeFill size="1.2em" style={{ color: "#0094b3" }} /></span> 
                    </Link>
                </>
            );
        } else {
            actionContent = '';
        }
        return {
            key: key + 1,
            dealerName: dealer.DealerName,
            username: dealer.Username,
            contactEmailId: dealer.ContactEmailID,
            country: <Tag color="badge-primary" title={dealer.CountryName} />,
            tariffGroup: dealer.TariffGroup ? dealer.TariffGroup : "N/A",
            createDate: dealer.CreatedDtTm !== null ? moment(new Date(dealer.CreatedDtTm)).format(`${process.env.REACT_APP_DATE_FORMAT}`) : '',
            verifiedDate: dealer.VerifiedDate !== null ? moment(new Date(dealer.VerifiedDate)).format(`${process.env.REACT_APP_DATE_FORMAT}`) : '',
            status: <Tag color={`${dealer.IsActive === 1 ? "badge-success" : "badge-danger"}`} title={dealer.IsActive === 1 ? 'Active' : 'Inactive'} />,
            action: actionContent,
            accountBalance: dealer.AccountBalance !== null ? dealer.AccountBalance < 0 ? `-£${Math.abs(dealer.AccountBalance.toFixed(2))}` : `£${dealer.AccountBalance.toFixed(2)}` : `£0.00`
        }
    });

    const handleOpenEditFormModal = (dealerId) => {
        dispatch(prepareDealerForm());
        dispatch(getDealerAllowCreation(dealerId));
        dispatch(getTariffAllowCreation(dealerId));
        setFormTitle('Edit Dealer');
        setFormButton('Update');
        setEditFlag(true);
        seteditID(dealerId);
        var selectedDealer = dealerResult && dealerResult.filter(dealer => dealer.ID === dealerId);
        setselectedDealerDetails(selectedDealer);
        setDealerData(prevState => ({
            ...prevState,
            dealerName: selectedDealer[0].DealerName,
            contactPerson: selectedDealer[0].ContactPerson,
            contactNumber: selectedDealer[0].ContactNumber,
            contactEmailID: selectedDealer[0].ContactEmailID,
            address: selectedDealer[0].Address,
            countryID: selectedDealer[0].CountryID,
            zipCode: selectedDealer[0].ZipCode,
            state: selectedDealer[0].State,
            city: selectedDealer[0].City,
            tariffGroupID: selectedDealer[0].TariffGroupID,
            minimumTopUpAmount: selectedDealer[0].MinimumTopUpAmount,
            maximumTopUpAmount: selectedDealer[0].MaximumTopUpAmount,
            paypalTransactionFee: selectedDealer[0].PaypalTransactionFee,
            allowSellerCreation: selectedDealer[0].AllowSellerCreation,
            allowTariffCreation: selectedDealer[0].AllowTariffCreation,
        }));
    }

    const handleCancel = () => {
        setDealerData(prevState => ({
            ...prevState,
            dealerName: "",
            contactPerson: "",
            contactNumber: "",
            contactEmailID: "",
            address: "",
            countryID: "",
            zipCode: "",
            state: "",
            city: "",
            tariffGroupID: "",
            minimumTopUpAmount: 0,
            maximumTopUpAmount: 0,
            paypalTransactionFee: 0,
            allowSellerCreation: 0,
            allowTariffCreation: 0,
        }));
        dispatch(resetDealer());
        setEditFlag(false)
    }

    const handleOpenAddFormModal = () => {
        dispatch(prepareDealerForm());
        setFormTitle('Add Dealer');
        setFormButton('Submit');
        setEditFlag(false);
    }

    const onSubmit = (data) => {
        data.address = data.address ? data.address : '';
        data.city = data.city ? data.city : '';
        data.state = data.state ? data.state : '';
        data.zipCode = data.zipCode ? data.zipCode : '';
        const newData = {
            ...data,
            allowSellerCreation: data.allowSellerCreation ? 1 : 0,
            allowTariffCreation: data.allowTariffCreation ? 1 : 0,
        };
        // console.log(newData);
        dispatch(addDealer(newData));
    };

    const handleDelete = (ID, flag) => {
        dispatch(deleteDealer(ID, flag));
    }

    const handleUpdate = (data) => {
        const newData = {
            ...data,
            allowSellerCreation: data.allowSellerCreation ? 1 : 0,
            allowTariffCreation: data.allowTariffCreation ? 1 : 0,
        };
        dispatch(editDealer(editID, newData))
    }

    const handleFilterChange = (value) => {
        setFilterText(value);
    };

    const displayAlert = (text, type) => {
        setAlertText(text);
        setAlertType(type);
        setShowAlert(true);
        dispatch(resetDealerMessage());
    };

    const alertCancel = () => {
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
        dispatch(resetDealerMessage());
    }

    return (
        <CardLayout title="Dealers">
            {showAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}
            <div className="row">
                <div className='col-lg-6 col-md-6  od-2'>
                    <SearchBar onFilter={handleFilterChange} filterText={filterText} />
                </div>
                <div className='col-lg-6 col-md-6  text-right'>
                    {dealerLevelResult && dealerLevelResult.length > 0 && dealerLevelResult.map((data) => {
                        return (data.level <= 2 && data.AllowSellerCreation === 1) && !isDealerAgent(tokenData) && !isAdminAgent(tokenData) && <SideButtons title="Add Dealer" buttonStyle="primary" onClick={handleOpenAddFormModal} />
                    })}</div>

            </div>
            <Table columns={columns} loading={loading} data={data} pagination={true} />
            <Modal
                showModal={dealerFormModal}
                title={formTitle}
                onHide={handleCancel}
                width={700}
                showFooter={false}
            >
                <form onSubmit={handleSubmit(editFlag ? handleUpdate : onSubmit)}>
                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <label>Dealer Name</label>
                            <input type="text" placeholder="Enter dealer name" className="form-control"
                                name="dealerName" {...register("dealerName", {
                                    required: true,
                                })} />
                            {errors.dealerName?.type === "required" && (
                                <div className="invalid-feedback">Dealer name is required !</div>

                            )}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Contact Person</label>
                            <input type="text" className="form-control" placeholder="Enter contact person" name="contactPerson" {...register("contactPerson", {
                                required: true,
                            })} />
                            {errors.contactPerson?.type === "required" && (
                                <div className="invalid-feedback">Contact person is required !</div>
                            )}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Contact Number</label>
                            <Controller
                                name="contactNumber"
                                control={control}
                                rules={{
                                    required: true,
                                    minLength: 10,
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <PhoneInput
                                        value={value}
                                        onChange={onChange}
                                        defaultCountry="US"
                                        international
                                        className="form-control"
                                        countryCallingCodeEditable={false}
                                        id="contactNumber"
                                        placeholder="Enter phone number"
                                    />
                                )}
                            />
                            {errors.contactNumber?.type === "required" && (
                                <div className="invalid-feedback">Contact number is required !</div>
                            )}
                            {(errors.contactNumber?.type === "minLength" || errors.contactNumber?.type === "maxLength") && (
                                <div className="invalid-feedback">Contact number is not valid !</div>
                            )}
                            {/* <input type="number" maxLength={10} className="form-control" placeholder="Enter phone number" onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} name="contactNumber" {...register("contactNumber", {
                                required: true,
                                pattern: /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/,
                                minLength: 10, maxLength: 12
                            })} />
                            {errors.contactNumber?.type === "pattern" && (
                                <div className="invalid-feedback">Contact number is not valid !</div>
                            )} */}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Email ID</label>
                            <input type="text" className="form-control" placeholder="Enter your email" name="contactEmailID" {...register("contactEmailID", {
                                required: true,
                                pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/
                            })} />
                            {errors.contactEmailID?.type === "required" && (
                                <div className="invalid-feedback">Email is required !</div>
                            )}
                            {errors.contactEmailID?.type === "pattern" && (
                                <div className="invalid-feedback">Email is not valid !</div>
                            )}
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-8 mb-3">
                            <label>Address</label>
                            <input type="text" className="form-control" placeholder="Enter your address" name="address"
                                {...register("address", {
                                    required: false,
                                })} />
                            {errors.address?.type === "required" && (
                                <div className="invalid-feedback">Address is required !</div>

                            )}
                        </div>
                        <div className="col-md-4 mb-3">
                            <label>City</label>
                            <input type="text" className="form-control" placeholder="Enter your city" name="city"
                                {...register("city", {
                                    required: false,
                                })} />
                            {errors.city?.type === "required" && (
                                <div className="invalid-feedback">City is required !</div>
                            )}
                        </div>
                        <div className="col-md-4 mb-3">
                            <label>State</label>
                            <input type="text" className="form-control" placeholder="Enter your state" name="state"
                                {...register("state", {
                                    required: false,
                                })} />
                            {errors.state?.type === "required" && (
                                <div className="invalid-feedback">State is required !</div>
                            )}
                        </div>

                        <div className="col-md-4 mb-3">
                            <label>Country</label>
                            <select className="form-control" placeholder="Select Country" name="countryID"  {...register("countryID", {
                                required: true,
                            })} >
                                <option value="" key="0">Select</option>
                                {countryResult.length > 0 && countryResult.map(country => (
                                    <option name="countryID" value={country.ID} key={country.ID}>
                                        <ReactCountryFlag countryCode="US" svg style={{ width: '2em', height: '2em' }} title="US" />
                                        &nbsp;&nbsp; {country.CountryName}</option>

                                ))}
                            </select>
                            {errors.countryID?.type === "required" && (
                                <div className="invalid-feedback">Country is required !</div>
                            )}
                        </div>

                        <div className="col-md-4 mb-3">
                            <label>Postal Code</label>
                            <input type="number" className="form-control" placeholder="Enter your postal code" onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} name="zipCode" {...register("zipCode", {
                                required: false,
                                minLength: 4,
                            })} />
                            {errors.zipCode?.type === "required" && (
                                <div className="invalid-feedback">Postal code is required !</div>
                            )}
                            {(errors.zipCode?.type === "minLength") && (
                                <div className="invalid-feedback">Postal code should be at least 5 digit !</div>
                            )}
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <label>Minimum Top Up Amount</label>
                            <input type="number" className="form-control" placeholder="Enter minimum top up amount" onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} name="minimumTopUpAmount" {...register("minimumTopUpAmount", {
                                required: true,
                            })} />
                            {errors.minimumTopUpAmount?.type === "required" && (
                                <div className="invalid-feedback">Minimum top up amount is required !</div>

                            )}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Maximum Top Up Amount</label>
                            <input type="number" className="form-control" onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} placeholder="Enter maximum top up amount" name="maximumTopUpAmount"
                                {...register('maximumTopUpAmount', {
                                    required: true,
                                    validate: {
                                        greaterThanMin: (value) =>
                                            parseFloat(value) >= parseFloat(minimumTopUpAmount) ||
                                            'Maximum Top-Up Amount should be greater than Minimum Top-Up Amount',
                                    },
                                })}
                            />
                            {errors.maximumTopUpAmount && (
                                <div className="invalid-feedback">{errors.maximumTopUpAmount.message}</div>
                            )}
                            {errors.maximumTopUpAmount?.type === "required" && (
                                <div className="invalid-feedback">Maximum top up amount is required !</div>
                            )}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Tariff Group</label>
                            <select className="form-control" placeholder="Select tariff group" name="tariffGroupID"  {...register("tariffGroupID", {
                                required: true,
                            })} >
                                <option value="" key="0" >Select</option>
                                {tariffResult.length > 0 && tariffResult.map(tariff => (
                                    <option value={tariff.ID} key={tariff.ID}>{tariff.TariffGroup}</option>
                                ))}
                            </select>

                            {errors.tariffGroupID?.type === "required" && (
                                <div className="invalid-feedback">Tariff group is required !</div>
                            )}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Transaction Fee in (%)</label>
                            <input type="number" className="form-control" placeholder='Enter paypal Transaction fee' onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} name="paypalTransactionFee" {...register("paypalTransactionFee", {
                                required: true,
                            })} />
                            {errors.paypalTransactionFee?.type === "required" && (
                                <div className="invalid-feedback">Paypal transaction is required !</div>
                            )}
                        </div>
                        {!editFlag &&
                            <div className="col-md-6 mb-3">
                                <label>Username</label>
                                <input type="text" autoComplete="off" className="form-control" placeholder='Enter username' name="username" {...register("username", {
                                    required: true,
                                })} />

                                {errors.username?.type === "required" && (
                                    <div className="invalid-feedback">Username is required !</div>
                                )}
                            </div>}
                        {!editFlag && <div className="col-md-6 mb-3">
                            <label>Password</label>
                            <input type={passwordShow ? 'text' : 'password'} autoComplete="new-password" className="form-control" placeholder='Password' name="password" {...register("password", {
                                required: true,
                                // minLength: 4,
                                validate: (value) => {
                                    return PASSWORD_VALIDATE.test(value)
                                }
                            })} />
                            {
                                passwordShow ? <EyeLineIcon size="1.1em" style={Style.eyeIcon} onClick={() => setPasswordShow((pre) => !pre)} /> : <EyeOffLineIcon size="1.1em" style={Style.eyeIcon} onClick={() => setPasswordShow((pre) => !pre)} />
                            }
                            {errors.password?.type === "required" && (<div className="invalid-feedback">Password is required !</div>)}
                            {/* {(errors.password?.type === "minLength") && (<div className="invalid-feedback">Password should contain at least 6 characters !</div>)} */}
                            {errors.password && errors.password.type === "validate" != '' ? <div className="invalid-feedback">Invalid password format !</div> : ''}
                        </div>}

                        <div className="col-md-6">
                            <div className="custom-control custom-checkbox custom-control-inline">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="customCheck-t"
                                    disabled={dealerCreatetionFlag}
                                    {...register("allowSellerCreation", { value: "1", setValueAs: (val) => (val[0] === "1" ? "1" : "0") })}
                                />
                                <label className="custom-control-label" for="customCheck-t">Allow Dealer Creation</label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="custom-control custom-checkbox custom-control-inline">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="customCheck-f"
                                    disabled={tariffCreatetionFlag}
                                    {...register("allowTariffCreation", { value: "1", setValueAs: (val) => (val[0] === "1" ? "1" : "0") })}
                                />
                                <label className="custom-control-label" for="customCheck-f">Allow Tariff Creation</label>
                            </div>
                        </div>

                    </div>
                    <hr />
                    <div className='text-right row'>
                        <div className="col-md-12">
                            <button type="button" className="btn btn-secondary mr-2" onClick={handleCancel}>Close</button>
                            <Button title={formButton} style="minWidth:80px" buttonClass="btn btn-primary" loading={loading} buttonType='submit' />
                        </div>
                    </div>
                </form>
            </Modal>

            <DealerViewModel
                title={`Dealer Details `}
                responseData={dealerItem[0]}
                responsePlanDetailsHeading={dealerItem[1]}
                responseDetailsData={dealerItem[2]}
                showViewModal={showViewModal}
                handleCancel={handleCancelmodal}
            />

        </CardLayout>
    );
}

const Style = {
    eyeIcon: {
        position: 'absolute',
        right: '10px',
        top: '40px',
        cursor: 'pointer'
    },
    modalContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: 'center'
    }
}


export default Dealer;

