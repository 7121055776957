import actions from "./actions";
const initState = {
    loading: false,
    buttonLoading: false,
    cartError: null,
    cartMessage: null,
    checkCartFlag: false,
    cartResult: [],
    showModal: false,
    showModalLoading:false,
    showPurchaseInvoice: false,
    paymentSuccessMessage: false,
    paymentErrorMessage: false,
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.CHECK_CART_DATA:
            return {
                ...state,
                cartError: null,
                cartMessage: null,
                showModalLoading:true,
            };
        case actions.CHECK_CART_DATA_SUCCESSFUL:
            return {
                ...state,
                cartError: null,
                cartMessage: null,
                checkCartFlag: action.checkCartFlag,
                showModalLoading:false,
            };
        case actions.CHECK_CART_DATA_FAILED:
            return {
                ...state,
                cartError: action.planMessage,
                buttonLoading: false,
                showModalLoading:false,
            };
        case actions.ADD_PLAN_TO_CART:
            return {
                ...state,
                cartError: null,
                cartMessage: null,
                buttonLoading: true,
                showModal: true,
            };
        case actions.ADD_PLAN_TO_CART_SUCCESSFUL:
            return {
                ...state,
                cartError: null,
                cartMessage: action.cartMessage,
                addToCartData: action.addToCartData,
                buttonLoading: false,
                showModal: false
            };
        case actions.ADD_PLAN_TO_CART_FAILED:
            return {
                ...state,
                cartMessage: null,
                cartError: action.cartError,
                addToCartData: action.addToCartData,
                buttonLoading: false,
                showModal: false
            };
        case actions.VIEW_CART_DATA:
            return {
                ...state,
                loading: true,
            };
        case actions.VIEW_CART_DATA_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                cartError: null,
                cartResult: action.cartResult,
            };
        case actions.VIEW_CART_DATA_FAILED:
            return {
                ...state,
                loading: false,
                cartError: action.cartError,
            };
        case actions.DELETE_CART:
            return {
                ...state,
                loading: true,
                cartError: null,
            };
        case actions.DELETE_CART_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                cartError: null,
                cartResult: action.cartResult,
                cartMessage: action.message
            };
        case actions.DELETE_CART_FAILED:
            return {
                ...state,
                loading: false,
                cartError: action.cartError,
                cartMessage: null
            };

        case actions.PREPARE_VIEW_PLAN_MODAL:
            return {
                ...state,
                loading: false,
                cartError: null,
                cartMessage: null,
                showPurchasePage: true,
                showModal: true,
            };
        case actions.RESET_VIEW_PLAN_MODAL:
            return {
                ...state,
                loading: false,
                cartError: null,
                cartMessage: null,
                showPurchasePage: true,
                showModal: false
            };
        case actions.RESET_WHOLE_PAGE:
            return {
                ...state,
                loading: false,
                cartError: null,
                cartMessage: null,
                showModal: false,
                showPurchaseInvoice: false,
                paymentSuccessMessage: false,
                paymentErrorMessage: false

            }
        case actions.PREPARE_VIEW_PURCHASE_INVOICE:
            return {
                ...state,
                loading: false,
                cartError: null,
                cartMessage: null,
                showPurchaseInvoice: true,
                showModal: false,
            }
        case actions.RESET_VIEW_PURCHASE_INVOICE:
            return {
                ...state,
                loading: false,
                cartError: null,
                cartMessage: null,
                showPurchaseInvoice: false,
                showModal: false,
            }
        case actions.UPDATE_CART_QUANTITY:
            return {
                ...state,
                loading: true,
                cartError: null,
            };
        case actions.UPDATE_CART_QUANTITY_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                cartError: null,
                cartResult: action.cartResult,
                cartMessage: action.message
            };
        case actions.PURCHASE_PLAN_PAYMENT:
            return {
                ...state,
                loading: true,
                cartError: null,
                cartMessage: null
            };
        case actions.PURCHASE_PLAN_PAYMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                paymentSuccessMessage: action.paymentSuccessMessage,
                cartError: null,
            };
        case actions.PURCHASE_PLAN_PAYMENT_FAILED:
            return {
                ...state,
                loading: false,
                paymentErrorMessage: action.paymentErrorMessage,
            };
        default:
            return state;
    }

}