const actions = {
    GET_FEATURED_PLAN: "GET_FEATURED_PLAN",
    GET_FEATURED_PLAN_SUCCESS: "GET_FEATURED_PLAN_SUCCESS",
    GET_FEATURED_PLAN_FAILED: "GET_FEATURED_PLAN_FAILED",
    GET_TOP_SELLER_PLAN: "GET_TOP_SELLER_PLAN",
    GET_TOP_SELLER_PLAN_SUCCESS: "GET_TOP_SELLER_PLAN_SUCCESS",
    GET_TOP_SELLER_PLAN_FAILED: "GET_TOP_SELLER_PLAN_FAILED",
    PREPARE_DASHBOARD_MODAL: "PREPARE_DASHBOARD_MODAL",
    RESET_DASHBOARD_MODAL: "RESET_DASHBOARD_MODAL",
    GET_DASHBOARD_COUNT: "GET_DASHBOARD_COUNT",
    GET_DASHBOARD_COUNT_SUCCESS: "GET_DASHBOARD_COUNT_SUCCESS",
    GET_DASHBOARD_COUNT_FAILED: "GET_DASHBOARD_COUNT_FAILED",
    GET_SALES_AND_DEALER_COUNT: "GET_SALES_AND_DEALER_COUNT",
    GET_SALES_AND_DEALER_COUNT_SUCCESS: "GET_SALES_AND_DEALER_COUNT_SUCCESS",
    GET_SALES_AND_DEALER_COUNT_FAILED: "GET_SALES_AND_DEALER_COUNT_FAILED",


    getFeaturedPlan: () => ({
        type: actions.GET_FEATURED_PLAN,

    }),
    getFeaturedPlanSuccess: (featuredPlanResult) => ({
        type: actions.GET_FEATURED_PLAN_SUCCESS,
        featuredPlanResult
    }),
    getFeaturedPlanFailed: (dashboardError) => ({
        type: actions.GET_FEATURED_PLAN_FAILED,
        dashboardError
    }),
    getTopSellerPlan: () => ({
        type: actions.GET_TOP_SELLER_PLAN,

    }),
    getTopSellerPlanSuccess: (topSellerPlanResult) => ({
        type: actions.GET_TOP_SELLER_PLAN_SUCCESS,
        topSellerPlanResult
    }),
    getTopSellerPlanFailed: (dashboardError) => ({
        type: actions.GET_TOP_SELLER_PLAN_FAILED,
        dashboardError
    }),
    prepareDashboardModal: () => ({
        type: actions.PREPARE_DASHBOARD_MODAL
    }),
    resetDashboardModal: () => ({
        type: actions.RESET_DASHBOARD_MODAL
    }),

    getDashboardCount: () => ({
        type: actions.GET_DASHBOARD_COUNT,

    }),
    getDashboardCountSuccess: (data) => ({
        type: actions.GET_DASHBOARD_COUNT_SUCCESS,
        payload: { data }
    }),
    getDashboardCountFailed: (error) => ({
        type: actions.GET_DASHBOARD_COUNT_FAILED,
        payload: { error }
    }),
    getSalesAndDealerCount: () => ({
        type: actions.GET_SALES_AND_DEALER_COUNT,

    }),
    getSalesAndDealerCountSuccess: (data) => ({
        type: actions.GET_SALES_AND_DEALER_COUNT_SUCCESS,
        payload: { data }
    }),
    getSalesAndDealerCountFailed: (error) => ({
        type: actions.GET_SALES_AND_DEALER_COUNT_FAILED,
        payload: { error }
    }),

}
export default actions;