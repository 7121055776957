import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData, deleteData, getList, editData } from '../../helpers/fackBackend_Helper';


function* getPlanListResponse({ payload: { filterPlan } }) {
    try {
        const response = yield call(addData, '/plan', filterPlan);
        if (response.response.Status) {
            yield put(
                actions.getPlanSuccess(
                    response.response.Data
                ));
        } else {
            yield put(actions.getPlanFailed(response.response.Message));
        }

    } catch (error) {
        yield put(actions.getPlanFailed(error));
    }
}


function* addPlanDataResponse({ payload: { data, filterPlan } }) {
    try {
        const response = yield call(editData, '/plan', data);
        if (response.response.Status) {
            const responseList = yield call(addData, '/plan', filterPlan);
            if (responseList.response.Status) {
                yield put(
                    actions.addPlanSuccess(
                        response.response.PlanBundle.Message, responseList.response.Data
                    ));
            } else {
                yield put(actions.addPlanFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.addPlanFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.addPlanFailed(error));
    }
}

function* getPlanDataResponse({ data }) {
    try {
        const response = yield call(addData, '/shortCode/', data);
        if (response.response.Status) {
            yield put(actions.getPlanDataSuccess(response.response.Data));
        } else {
            yield put(actions.getPlanDataFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getPlanDataFailed(error));
    }
}

function* getPlanDayDataResponse({ data }) {
    try {
        const response = yield call(addData, '/shortCode/', data);
        if (response.response.Status) {

            yield put(actions.getPlanDaySuccess(response.response.Data));
        } else {
            yield put(actions.getPlanDayFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getPlanDayFailed(error));
    }
}

function* getBundleNameDataResponse({ data }) {
    try {
        const response = yield call(addData, '/getPlanBundleName', data);

        if (response.response.Status) {
            yield put(actions.getBundleNameDataSuccess(response.response.PlanBundle));
        } else {

            yield put(actions.getBundleNameDataFailed(response.response.Message));
        }
    } catch (error) {

        yield put(actions.getBundleNameDataFailed(error));
    }
}

function* deletePlanDataResponse({ payload: { planId, status, filterPlan } }) {
    try {
        const response = yield call(deleteData, '/plan/' + planId + '/' + status);
        if (response.response.Status) {
            const responseList = yield call(addData, '/plan', filterPlan);
            if (responseList.response.Status) {
                yield put(
                    actions.deletePlanSuccess(
                        response.response.Message, responseList.response.Data
                    ));
            } else {
                yield put(actions.addPlanFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.addPlanFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.addPlanFailed(error));
    }
}


function* checkPlanNameResponse({ payload: { planName } }) {
    try {
        const response = yield call(getList, '/checkPlanName/' + planName);
        if (response.response.Status) {
            yield put(
                actions.checkPlanNameSuccess(
                    response.response.PlanBundle.Status
                ));
        } else {
            yield put(actions.checkPlanNameFailed(response.response.PlanBundle.Message));
        }

    } catch (error) {
        yield put(actions.checkPlanNameFailed(error));
    }
}
export default function* rootSaga() {
    yield all([
        takeEvery(actions.GET_PLAN, getPlanListResponse),
        takeEvery(actions.ADD_PLAN, addPlanDataResponse),
        takeEvery(actions.GET_PLAN_DATA, getPlanDataResponse),
        takeEvery(actions.GET_PLAN_DAY, getPlanDayDataResponse),
        takeEvery(actions.GET_BUNDLE_NAME_DATA, getBundleNameDataResponse),
        takeEvery(actions.DELETE_PLAN, deletePlanDataResponse),
        takeEvery(actions.CHECK_PLAN_NAME, checkPlanNameResponse),

    ]);

}
