const actions = {
  RESET_LIST_SIM: 'RESET_LIST_SIM',
  GET_SIM_LIST: 'GET_SIM_LIST',
  GET_SIM_LIST_SUCCESS: 'GET_SIM_LIST_SUCCESS',
  GET_SIM_LIST_FAILED: 'GET_SIM_LIST_FAILED',
  GET_SIM_DETAIL: 'GET_SIM_DETAIL',
  GET_SIM_DETAIL_SUCCESS: 'GET_SIM_DETAIL_SUCCESS',
  GET_SIM_DETAIL_FAILED: 'GET_SIM_DETAIL_FAILED',

  getSimDetail: (data, simId) => ({
    type: actions.GET_SIM_DETAIL,
    payload: { data, simId }
  }),
  getSimDetailSuccess: (message, data, simId) => ({
    type: actions.GET_SIM_DETAIL_SUCCESS,
    payload: { message, data, simId },
  }),
  getSimDetailFailed: (error, simId) => ({
    type: actions.GET_SIM_DETAIL_FAILED,
    payload: { error, simId },
  }),

  getSimList: (simType, Aggregator) => ({
    type: actions.GET_SIM_LIST,
    payload: { simType, Aggregator }
  }),
  getSimListSuccess: (message, data) => ({
    type: actions.GET_SIM_LIST_SUCCESS,
    payload: { message, data },
  }),
  getSimListFailed: (error) => ({
    type: actions.GET_SIM_LIST_FAILED,
    payload: { error },
  }),

  resetListSim: () => ({
    type: actions.RESET_LIST_SIM,
  }),
};

export default actions;
