import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData, getList } from '../../helpers/fackBackend_Helper';

function* checkCartDataResponse({ payload: { planId } }) {
    try {
        const response = yield call(getList, '/checkCartData/' + planId);
        if (response.response.Status) {
            yield put(
                actions.checkCartDataSuccessful(
                    response.response.CartFlag
                ));
        } else {
            yield put(actions.checkCartDataFailed(response.response.Message));
        }

    } catch (error) {
        yield put(actions.checkCartDataFailed(error));
    }
}

function* addToCartPlanResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/addToCart', data);
        if (response.response.Status) {
            yield put(actions.addPlanToCartSuccessful(response.response.Message));
            yield put(actions.viewCartData());
        } else {
            yield put(actions.addPlanToCartFailed(response.response.Message));
        }

    } catch (error) {
        yield put(actions.addPlanToCartFailed(error));
    }
}

function* viewCartDataResponse() {
    try {
        const response = yield call(getList, '/getCartData');
        if (response.response.Status) {
            yield put(
                actions.viewCartDataSuccessful(
                    response.response.Data
                )
            );
        } else {
            yield put(actions.viewCartDataFailed());
        }
    } catch (error) {
        yield put(actions.viewCartDataFailed(error));
    }
}

function* deleteCartDataResponse({ payload: { cartId } }) {
    try {
        const response = yield call(getList, '/deleteCartData/' + cartId);
        if (response.response.Status) {
            const responseList = yield call(getList, '/getCartData');
            if (responseList.response.Status) {
                yield put(
                    actions.deleteCartSuccessful(
                        response.response.Message, responseList.response.Data
                    ));
            } else {
                yield put(actions.viewCartDataFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.viewCartDataFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.viewCartDataFailed(error));
    }
}

function* updateCartQuantityResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/updateCartData', data);
        if (response.response.Status) {
            const responseList = yield call(getList, '/getCartData');
            if (responseList.response.Status) {
                yield put(
                    actions.updateCartQuantitySuccessful(
                        response.response.Message, responseList.response.Data
                    ));
            } else {
                yield put(actions.viewCartDataFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.viewCartDataFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.viewCartDataFailed(error));
    }
}

function* purchasePlanPaymentResponse({ payload: { provideCustomerEmail } }) {
    try {
        const response = yield call(addData, '/createOrder', provideCustomerEmail);
        if (response.response.Status === 1) {
            yield put(
                actions.purchasePlanPaymentSuccess(
                    response.response.Message
                )
            );
        }
        else {
            yield put(actions.purchasePlanPaymentFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.purchasePlanPaymentFailed(error));
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.CHECK_CART_DATA, checkCartDataResponse),
        takeEvery(actions.ADD_PLAN_TO_CART, addToCartPlanResponse),
        takeEvery(actions.VIEW_CART_DATA, viewCartDataResponse),
        takeEvery(actions.DELETE_CART, deleteCartDataResponse),
        takeEvery(actions.UPDATE_CART_QUANTITY, updateCartQuantityResponse),
        takeEvery(actions.PURCHASE_PLAN_PAYMENT, purchasePlanPaymentResponse)
    ]);
}