const actions = {
    GET_ACTIVITY: "GET_ACTIVITY",
    GET_ACTIVITY_SUCCESS: "GET_ACTIVITY_SUCCESS",
    GET_ACTIVITY_FAILED: "GET_ACTIVITY_FAILED",

    getActivity: (data) => ({
        type: actions.GET_ACTIVITY,
        payload: { data }
    }),
    getActivitySuccess: (data) => ({
        type: actions.GET_ACTIVITY_SUCCESS,
        payload: { data }
    }),
    getActivityFailed: (error) => ({
        type: actions.GET_ACTIVITY_FAILED,
        payload: { error }
    }),


}
export default actions;