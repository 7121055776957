import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import { getList, addData } from '../../helpers/fackBackend_Helper';



function* getPhysicalSimAggregatorResponse() {
  try {
    const response = yield call(getList, '/getPhysicalSimAggregator');
    if (response.Status) {
      yield put(actions.getPhysicalSimAggregatorSuccess(response.Message, response.Aggregators));
    } else {
      yield put(actions.getPhysicalSimAggregatorFailed(response.Message));
    }
  } catch (error) {
    yield put(actions.getPhysicalSimAggregatorFailed(error));
  }
}
export function* watchGetPhysicalSimAggregatorResponse() {
  yield takeEvery(actions.GET_PHYSICAL_SIM_AGGREGATOR, getPhysicalSimAggregatorResponse)
}

function* getInvoiceResponse() {
  try {
    const response = yield call(getList, '/prepareInvoice');
    if (response.Status) {
      yield put(actions.getInvoiceSuccess(response.Message, response.Data));
    } else {
      yield put(actions.getInvoiceFailed(response.Message));
    }
  } catch (error) {
    yield put(actions.getInvoiceFailed(error));
  }
}
export function* watchGetInvoiceResponse() {
  yield takeEvery(actions.GET_INVOICE, getInvoiceResponse)
}

function* savePurchaseResponse({ payload: { data } }) {
  try {
    const response = yield call(addData, '/createSimPurchase', data);
    if (response.Status) {
      yield put(actions.savePurchaseSimSuccess(response.Message, response.Data));
    } else {
      yield put(actions.savePurchaseSimFailed(response.Message));
    }
  } catch (error) {
    yield put(actions.savePurchaseSimFailed(error));
  }
}
export function* watchSavePurchaseResponse() {
  yield takeEvery(actions.SAVE_PURCHASE_SIM, savePurchaseResponse)
}

function* validateSimDataResponse({ payload: { data } }) {
  try {
    const response = yield call(addData, '/validateSimPurchaseData', data);
    if (response.Status) {
      yield put(actions.validateSimDataSuccess(response.Message, response.simData));
    } else {
      yield put(actions.validateSimDataFailed(response.Message, response.simData));
    }
  } catch (error) {
    yield put(actions.validateSimDataFailed(error));
  }
}
export function* watchValidateSimDataResponse() {
  yield takeEvery(actions.VALIDATE_SIM_DATA, validateSimDataResponse)
}

function* getSimPurchaseResponse() {
  try {
    const response = yield call(getList, '/purchases');
    if (response.Status) {
      yield put(actions.getSimPurchaseSuccess(response.Message, response.Data));
    } else {
      yield put(actions.getSimPurchaseFailed(response.Message));
    }
  } catch (error) {
    yield put(actions.getSimPurchaseFailed(error));
  }
}
export function* watchGetSimPurchaseResponse() {
  yield takeEvery(actions.GET_SIM_PURCHASE, getSimPurchaseResponse)
}

function* getSimPurchaseDetailsResponse({payload : {purchaseId}}) {
  try {
    const response = yield call(getList, `/purchase/${purchaseId}`);
    if (response.Status) {
      yield put(actions.getSimPurchaseDetailsSuccess(response.Message, response.Data));
    } else {
      yield put(actions.getSimPurchaseDetailsFailed(response.Message));
    }
  } catch (error) {
    yield put(actions.getSimPurchaseDetailsFailed(error));
  }
}
export function* watchGetSimPurchaseDetailsResponse() {
  yield takeEvery(actions.GET_SIM_PURCHASE_DETAILS, getSimPurchaseDetailsResponse)
}


function* getSimTypeDataResponse({payload:data}) {
  try {
    const response = yield call(addData, '/shortCode/', data);
    if (response.response.Status) {
      yield put(actions.getSimTypeDataSuccess(response.response.Data));
    } else {
      yield put(actions.getSimTypeDataFailed(response.Message));
    }
  } catch (error) {
    yield put(actions.getSimTypeDataFailed(error));
  }
}
export function* watchGetSimTypeDataResponse() {
  yield takeEvery(actions.GET_SIM_TYPE, getSimTypeDataResponse)
}

export default function* rootSaga() {
  yield all([
    fork(watchGetPhysicalSimAggregatorResponse),
    fork(watchGetInvoiceResponse),
    fork(watchSavePurchaseResponse),
    fork(watchValidateSimDataResponse),
    fork(watchGetSimPurchaseResponse),
    fork(watchGetSimPurchaseDetailsResponse),
    fork(watchGetSimTypeDataResponse),
  ]);
}
