import actions from "./actions";

const initState = {
    loading: false,
    error: null,
    message: null,
    activityList: [],
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_ACTIVITY:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case actions.GET_ACTIVITY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
                activityList: action.payload.data
            };
        case actions.GET_ACTIVITY_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
            };

        default:
            return state;
    }
}