import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CardLayout from '../../components/utils/CardLayout'
import Table from '../../components/utils/Table';
import { Link } from 'react-router-dom';
import DeleteBin5Fill from 'remixicon-react/DeleteBin5FillIcon';
import Button from '../../components/utils/Button';
import Box from '../../components/utils/Box';
import CheckedImage from '../../assets/images/checked.png'
import ErrorImage from '../../assets/images/close.png';
import Message from '../../components/utils/Message';
import cartAction from '../../redux/cart/actions';
import planAction from '../../redux/plan/actions';
import ProgressBar from '../../components/utils/ProgressBar';
import Modal from '../../components/utils/Model';
import modalImage from '../../assets/images/modal.jpg';
import CartInvoice from './CartInvoice';
import authAction from '../../redux/auth/actions';
import NoImageAvailable from '../../assets/images/no image available.jpg';
import SimPurchaseActions from '../../redux/simPurchase/actions'

const { getSimTypeData } = SimPurchaseActions;


function Cart() {
    const dispatch = useDispatch()
    const [selectedEachPlanData, setSelectedEachPlanData] = useState(null);

    const { viewCartData, deleteCart, prepareViewPlanModal, resetViewPlanModal, resetWholePage, prepareViewPurchaseInvoice, updateCartQuantity } = cartAction;
    const { getUserDataByToken } = authAction;
    const { cartResult, showModal, showPurchaseInvoice, cartMessage, cartError, paymentSuccessMessage, paymentErrorMessage } = useSelector((state) => state.cart);
    const tokenData = useSelector((state) => state.auth.tokenData);
    const { simTypeList } = useSelector((state) => state.simPurchase);
    const [showInvoice, setShowInvoice] = useState(false);
    const [cartMappedData, setCartMappedData] = useState([]);
    const [accountBalance, setAccountBalance] = useState('0.00');
    const [customerEmail, setcustomerEmail] = useState("")
    const [showEmailError, setshowEmailError] = useState(false)
    var totalQuantity = 0;
    var totalSumAmount = 0;
    var totalPrice = 0;
    var priceCalculation = 0;

    const sendPlanSimType = {
        "tableName": "sim",
        "fieldName": "SIMType"
    };

    useEffect(() => {
        setCartMappedData(cartResult);
    }, [cartResult]);

    useEffect(() => {
        dispatch(resetWholePage());
        dispatch(getSimTypeData(sendPlanSimType))
    }, [])

    useEffect(() => {
        dispatch(getUserDataByToken());
        dispatch(viewCartData());
    }, [cartError, cartMessage])

    useEffect(() => {
        if (tokenData && tokenData !== undefined && tokenData.length > 0) {
            const balance = tokenData[0].AccountBalance !== null ? tokenData[0].AccountBalance : 0.00;
            const newBalance = balance; // .toFixed(2)
            setAccountBalance(newBalance.toFixed(2));
        }
    }, [tokenData]);


    const handleCustomerEmailChange = (e) => {
        const fValue = e.target.value;
        setcustomerEmail(fValue);
        setshowEmailError(false);
    }

    const handleDeleteCartData = (cartId) => {
        dispatch(deleteCart(cartId))
    }

    const handleViewPlanDetails = (PlanVariantID) => {
        dispatch(prepareViewPlanModal())
        const selectedPlanData = cartResult.filter(cart => cart.PlanVariantID === PlanVariantID);
        setSelectedEachPlanData(selectedPlanData)

    }
    const closeModal = () => {
        dispatch(resetViewPlanModal())
    };

    const handlePurchase = () => {
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (customerEmail !== "") {
            if (customerEmail.match(mailformat)) {
                dispatch(prepareViewPurchaseInvoice())
            } else {
                setshowEmailError(true)
            }
        } else {
            dispatch(prepareViewPurchaseInvoice())
        }
    }

    const handleChangeQuantity = (e, cartId) => {
        const fvalue = e.target.value;
        const updatedArray = [...cartResult];
        const objectToUpdate = updatedArray.find((obj) => obj.ID === cartId);
        if (objectToUpdate) {
            // Update the value of the desired key
            if (fvalue === "0") {
                objectToUpdate.Quantity = 1;
            } else {
                objectToUpdate.Quantity = fvalue;
            }
        }

        if (objectToUpdate.Quantity !== "") {
            const quantityData = {
                cartId: cartId,
                quantity: objectToUpdate.Quantity
            }
            dispatch(updateCartQuantity(quantityData));
        }
        setCartMappedData(updatedArray);
    }

    const handleIncrement = (cartId) => {
        const updatedArray = [...cartResult];
        const objectToUpdate = updatedArray.find((obj) => obj.ID === cartId);

        if (objectToUpdate) {
            objectToUpdate.Quantity += 1;
        }

        const quantityData = {
            cartId: cartId,
            quantity: objectToUpdate.Quantity
        };
        dispatch(updateCartQuantity(quantityData));

        setCartMappedData(updatedArray);
    };

    const handleDecrement = (cartId) => {
        const updatedArray = [...cartResult];
        const objectToUpdate = updatedArray.find((obj) => obj.ID === cartId);

        if (objectToUpdate && objectToUpdate.Quantity > 1) {
            objectToUpdate.Quantity -= 1;

            const quantityData = {
                cartId: cartId,
                quantity: objectToUpdate.Quantity
            };
            dispatch(updateCartQuantity(quantityData));

            setCartMappedData(updatedArray);
        }
    };


    return (
        <div>
            {!paymentSuccessMessage && !paymentErrorMessage && <div id="content-page" className="content-page">
                <div className="container-fluid">
                    <ProgressBar activeStep={1} activeStatus=' active'></ProgressBar>
                    {!showPurchaseInvoice ? <div className='confirm-order-container'>

                        <div className="iq-card confirm-order-card-style-layout">
                            <div>
                                <div className='confirm-order-header py-2'>
                                    <p><i className="ri-shopping-basket-2-fill"></i>Your Basket</p>
                                    <Link to="/plan-purchase"><p className='add-bundle-cart'><i className="ri-folder-add-line px-2"></i>Add Bundles</p></Link>
                                </div>
                                <div className='confirm-order-card-inner-style-layout'>
                                    <div className='confirm-overflow-inner'>
                                        {cartMappedData && cartMappedData.length > 0 ? cartMappedData.map((data, key) => {
                                            totalQuantity = totalQuantity + Number(data.Quantity)
                                            totalSumAmount = totalSumAmount + Number(data.PlanPrice);
                                            priceCalculation = Number(data.PlanPrice) * Number(data.Quantity);
                                            totalPrice = totalPrice + priceCalculation;
                                            return (
                                                <div className='row confirm-order-quantity-box'>
                                                    <div className='col-lg-4 col-md-4 confirm-order-plan-desc'>
                                                        <span>
                                                            <p className="data-list-add"><i className="ri-database-2-fill"></i>{data.PlanName}<br />
                                                                <span className='data-list-add' style={Styles.planName}>{data.VariantName}</span>
                                                                {simTypeList && simTypeList.length > 0 && simTypeList.filter(item => item.ID == data.PlanSIMType).map(simType => (
                                                                    <span style={{fontSize:'13px',color:'#0094b3'}} key={simType.ID}>{simType.Value}</span>
                                                                ))}</p>
                                                            <i className="ri-information-line confirm-order-plan-p" onClick={() => handleViewPlanDetails(data.PlanVariantID)} ></i>
                                                        </span>
                                                    </div>
                                                    <div className='col-lg-4 col-md-4'>
                                                        <div className='buttonbgsec'>
                                                            <span>
                                                                <button onClick={(e) => handleDecrement(data.ID)} className='increment-button decrmnet-mainus'><i className="ri-subtract-line"></i></button>
                                                                <input type='text' value={data.Quantity} style={{ width: '40px', height: '30px', textAlign: 'center', border: 'none' }} onChange={(e) => handleChangeQuantity(e, data.ID)}></input>
                                                                <button onClick={(e) => handleIncrement(data.ID)} style={{ marginRight: '10px' }} className='increment-button incrmnt-plus'><i className="ri-add-line"></i></button>
                                                                × {`£${parseFloat(data.PlanPrice).toFixed(2)} = £${parseFloat(data.Quantity * data.PlanPrice).toFixed(2)}`}

                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className=' col-lg-4 col-md-4 text-right confirm-order-quantity-button'>
                                                        <button className="btn remove-btn-cart text-white" style={{ background: '#e74c3c' }} onClick={() => handleDeleteCartData(data.ID)}>Remove</button>
                                                    </div>
                                                </div>)
                                        }) : <><div className='text-center mt-1 pt-4 pb-5'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 1024 1024"><path fill="#e3e3e3" d="M854.6 288.7L639.4 73.4c-6-6-14.2-9.4-22.7-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.6-9.4-22.6M790.2 326H602V137.8zm1.8 562H232V136h302v216a42 42 0 0 0 42 42h216zM402 549c0 5.4 4.4 9.5 9.8 9.5h32.4c5.4 0 9.8-4.2 9.8-9.4c0-28.2 25.8-51.6 58-51.6s58 23.4 58 51.5c0 25.3-21 47.2-49.3 50.9c-19.3 2.8-34.5 20.3-34.7 40.1v32c0 5.5 4.5 10 10 10h32c5.5 0 10-4.5 10-10v-12.2c0-6 4-11.5 9.7-13.3c44.6-14.4 75-54 74.3-98.9c-.8-55.5-49.2-100.8-108.5-101.6c-61.4-.7-111.5 45.6-111.5 103m78 195a32 32 0 1 0 64 0a32 32 0 1 0-64 0" /></svg>
                                            <br /> No Data

                                        </div><hr /></>}
                                    </div>
                                </div>
                            </div>
                            <div className='row pt-3'>
                                <div className='col-sm-6'>

                                    <div className='input-container'>
                                        <input type='email' name='customerEmail' className="input-cart-cart" style={{ width: '300px' }} placeholder='Customer Email' onChange={(e) => handleCustomerEmailChange(e)}></input>
                                        <div className="highlight"></div>
                                    </div>
                                    {showEmailError && <p style={{ color: 'red' }}>Enter valid customer email !</p>}
                                </div>
                                <div className='col-sm-6'>
                                    <div className='row text-center'>
                                        <div className='col-sm-4' style={{ color: 'black', fontWeight: '700' }}>
                                            Account Balance
                                        </div>
                                        <div className='col-sm-4' style={{ color: 'black', fontWeight: '700' }}>
                                            Total Quantity
                                        </div>
                                        <div className='col-sm-4' style={{ color: 'black', fontWeight: '700' }}>
                                            Total Amount
                                        </div>
                                        <div className='col-sm-4' style={{ color: 'green', fontWeight: '700' }}>
                                            {accountBalance < 0 ? `-£${Math.abs(accountBalance)}` : `£${accountBalance}`}
                                        </div><div className='col-sm-4' style={{ color: 'green', fontWeight: '700' }}>
                                            {totalQuantity && totalQuantity}
                                        </div>
                                        <div className='col-sm-4' style={{ color: 'red', fontWeight: '700' }}>
                                            £{totalPrice && totalPrice>0 ? parseFloat(totalPrice).toFixed(2) :'0.00'}
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <div div className='confirm-order-footer'>
                                <Link to="/plan-purchase"><Button title="Back" style="minWidth:80px" buttonClass="btn btn-secondary purchase-cart-b mx-2" buttonType="button" /></Link>
                                {
                                    totalPrice && parseFloat(totalPrice) > parseFloat(accountBalance) ? <Link to="/add-fund"><Button title="Add Fund" style="minWidth:80px" buttonClass="btn btn-primary  purchase-cart-b " buttonType="button" /></Link> :

                                        <Button title="Purchase" disable={totalPrice && parseFloat(totalPrice).toFixed(2) > 0 ? false : true} style="minWidth:80px" buttonClass="btn btn-primary purchase-cart-b" buttonType="button" onClick={handlePurchase} />
                                }
                                {/* { totalPrice && parseFloat(totalPrice) > parseFloat(accountBalance) ?
                                    <Link to="/add-fund"><Button title="ADD FUND" style="minWidth:80px" buttonClass="btn btn-primary  purchase-cart-b " buttonType="button" /></Link> :
                                    totalPrice && parseFloat(totalPrice).toFixed(2) > 0 ?
                                        <Button title="PURCHASE" style="minWidth:80px" buttonClass="btn btn-primary purchase-cart-b" buttonType="button" onClick={handlePurchase} /> :
                                        <Link to="/plan-purchase"><Button title="ADD PLAN TO CART" style="minWidth:80px" buttonClass="btn btn-success" buttonType="button" /></Link>
                                } */}
                            </div>
                        </div>

                    </div> : <CartInvoice customerEmail={customerEmail} />
                    }
                    <Modal
                        showModal={showModal}
                        modalWidth='800px'
                        onHide={closeModal}
                        width={800}
                        bodyClasses="model-plan-purchase "
                        showFooter={false}
                        showHeader={false}
                        alignment='modal-dialog-centered'
                    >
                        {selectedEachPlanData && selectedEachPlanData.length > 0 && selectedEachPlanData.map((data, key) => {
                            return (
                                <div className='row'>
                                    <div className='col-sm-5 plan-purchase-image-container'>
                                        <img src={data.ImageUrl ? `${process.env.REACT_APP_BASEURL}/planImages/${data.ImageUrl}` : NoImageAvailable} className='set-box'></img>
                                        <div className='image-overlay d-flex justify-content-center'>
                                            <div className='row'>
                                                <div className='col-lg-8 col-md-8 model-cart-details'>
                                                    <div className='row'>
                                                        <div className='col-lg-4 col-md-4'></div>
                                                        <div className='col-lg-4 col-md-4'></div>
                                                        <div className='col-lg-4 col-md-4'></div>
                                                    </div>
                                                    {/* <p style={{ fontFamily: 'Muli', fontWeight: '900' }}>{data.PlanName}</p> */}
                                                    <p className='text-center' style={{ fontFamily: 'Muli', fontWeight: '900', letterSpacing: '1px' }}>{data.VariantName}</p>
                                                    <p className='text-center' style={{ fontFamily: 'Muli', fontWeight: '900', letterSpacing: '1px' }}>{`${data.PlanDataValue}, ${data.PlanDaysValue}, ${data.AvailableForIDValue}`}</p>
                                                    <div className='row text-center'>
                                                        <div className='col-sm-6'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className='mt-3 mb-2' width="40" height="40" fill="currentColor"><path d="M11.9498 7.94975L10.5356 9.36396L8.00079 6.828L8.00004 20H6.00004L6.00079 6.828L3.46451 9.36396L2.05029 7.94975L7.00004 3L11.9498 7.94975ZM21.9498 16.0503L17 21L12.0503 16.0503L13.4645 14.636L16.0008 17.172L16 4H18L18.0008 17.172L20.5356 14.636L21.9498 16.0503Z"></path></svg>

                                                            <p style={{ fontSize: '14px' }}>{data.PlanDataValue}</p>
                                                        </div>
                                                        <div className='col-sm-6'>
                                                            <i className="ri-calendar-2-fill" style={{ fontSize: '40px' }}></i>
                                                            <p style={{ fontSize: '14px' }}>{data.PlanDaysValue}</p>
                                                        </div>
                                                        {/* <div className='col-sm-4'>
                                                            <i className="ri-database-2-fill" style={{ fontSize: '40px' }}></i>
                                                            <p className='text-center' style={{ fontSize: '14px' }}>{data.AvailableForIDValue}</p>
                                                        </div> */}

                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                    <div className='col-sm-7 plan-purchase-content-container'>
                                        <div className='row py-2'>
                                            <div className='col-lg-6 pt-2'><h4 style={{ fontWeight: 'bold' }}>Bundle Details</h4></div>
                                            <div className='col-lg-6'><span style={{ display: 'flex', justifyContent: 'end', fontSize: '25px', fontWeight: '900', color: 'black' }}><i className="ri-close-line" style={{ cursor: 'pointer' }} onClick={closeModal}></i></span></div>
                                        </div>
                                        <div>


                                            <p style={{ fontFamily: 'Muli', fontWeight: '500', color: '#737070' }}>{`${data.VariantName}, ${data.PlanDataValue}, ${data.PlanDaysValue}, ${data.AvailableForIDValue}, `}</p>
                                            <h5 style={{ fontWeight: 'bold' }}>Base Country</h5>
                                            <input type='text' className='base-country-input-cart' value={data.AvailableForIDValue} disabled style={{ width: '100%', borderRadius: '5px', padding: '10px 14px', border: '1px solid #e8d9d9', marginTop: '10px' }} />
                                        </div>
                                        <form >
                                            <div className='model-footer-qty' style={{ display: "flex", alignItems: 'center' }}>
                                                <label style={{ marginTop: '10px' }}>QTY</label>
                                                <input type="number" name="quantity" className='qty-input-cart' value={data.Quantity} disabled onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} style={{ width: '50px', margin: '0px 10px' }}></input>
                                                <p style={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }} >Total - £{parseFloat(data.PlanPrice).toFixed(2)}</p>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )
                        })}
                    </Modal>
                </div>
            </div>}
            {
                paymentSuccessMessage && <div id="content-page" className="content-page">
                    <ProgressBar activeStep={2} activeStatus=' active'></ProgressBar>
                    <div className='confirm-order-container'>
                        <Box style={{ width: '700px', boxShadow: 'rgb(10 160 110) 0px -26px 0px', marginTop: '60px' }}>
                            <Message link='/plan-purchase' buttonClass="btn btn-success mt-2" image={CheckedImage} message={paymentSuccessMessage} buttonTitle="Continue Shopping" content1="The order has been done successfully" content2="Thanks for being there with us." />
                        </Box>
                    </div>
                </div>
            }

            {
                paymentErrorMessage &&
                <div id="content-page" className="content-page">
                    <ProgressBar activeStep={2} activeStatus=' inActive'></ProgressBar>
                    <div className='confirm-order-container'>
                        <Box style={{ width: '700px', boxShadow: 'rgb(244 67 54) 0px -26px 0px', marginTop: '60px' }}>
                            <Message image={ErrorImage} message={paymentErrorMessage} buttonTitle="Home" content1="Hey,seems like there was some trouble." content2="We are there with you. Just hold back" />
                        </Box>
                    </div>
                </div>
            }
        </div>
    )
}

const Styles = {
    planName: {
        fontSize: "12px",
        padding: "unset"
    }
}

export default Cart