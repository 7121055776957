import actions from "./actions";

const initState = {
    orderReportListResult: [],
    loading: false,
    orderReportListError: null,
    orderReportListMessage: null,
    orderReportDetailsResult: [],
    planDetailsReportResult: [],
    orderViewModal: false,
    orderViewModalData: [],
    orderResendMailModal: false,
    resendMailLoading: false,
    resendMailError: null,
    resendMailMessage: null,
    viewQRImage: false,
    resendMailByICCIDLoading: false,
    resendMailBYICCIDError: null,
    resendMailBYICCIDMessage: null,
};

export default function reducer(state = initState, action) {

    switch (action.type) {
        case actions.RESEND_MAIL:
            return {
                ...state,
                resendMailLoading: true,
                resendMailError: null,
                resendMailMessage: null,
            };
        case actions.RESEND_MAIL_SUCCESS:
            return {
                ...state,
                resendMailLoading: false,
                resendMailError: null,
                resendMailMessage: action.payload.message,
                orderResendMailModal: false,
            };
        case actions.RESEND_MAIL_FAILED:
            return {
                ...state,
                resendMailLoading: false,
                resendMailError: action.payload.error,
                resendMailMessage: null,
            };


        case actions.OPEN_RESEND_MAIL_MODAL:
            return {
                ...state,
                orderResendMailModal: true,
            };
        case actions.CLOSE_RESEND_MAIL_MODAL:
            return {
                ...state,
                orderResendMailModal: false,
            };


        case actions.OPEN_ORDER_MODAL:
            state.orderReportListResult.map((item) => {
                let temp = item;
                if (temp.ID == action.payload.orderID) {
                    temp.loading = !temp.loading;
                }
                return temp;
            });

            return {
                ...state,
            };

        case actions.OPEN_ORDER_MODAL_SUCCESS:
            state.orderReportListResult.map((item) => {
                let temp = item;
                if (temp.ID == action.orderID) {
                    temp.loading = !temp.loading;
                }
                return temp;
            });
            return {
                ...state,
                orderViewModal: true,
                orderViewModalData: action.data
            };

        case actions.CLOSE_ORDER_MODAL:
            return {
                ...state,
                orderViewModalData: [],
            };

        case actions.GET_ORDER_REPORT_LIST:
            return {
                ...state,
                loading: true,
                orderReportListError: null,
                orderReportListMessage: null,
            };
        case actions.GET_ORDER_REPORT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                orderReportListError: null,
                orderReportListMessage: null,
                orderReportListResult: action.orderReportListResult,
            };
        case actions.GET_ORDER_REPORT_LIST_FAILED:
            return {
                ...state,
                loading: false,
                orderReportListError: action.orderReportListError,
                orderReportListMessage: null,
            };
        case actions.GET_ORDER_REPORT_DETAILS_LIST:
            return {
                ...state,
                loading: true,
                orderReportListError: null,
                orderReportListMessage: null,
            };
        case actions.GET_ORDER_REPORT_DETAILS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                orderReportListError: null,
                orderReportListMessage: null,
                orderReportDetailsResult: action.orderReportDetailsResult,
                planDetailsReportResult: action.orderReportDetailsResult,
            };
        case actions.GET_ORDER_REPORT_DETAILS_LIST_FAILED:
            return {
                ...state,
                loading: false,
                orderReportListError: action.orderReportListError,
                orderReportListMessage: null,
            };

        case actions.OPEN_QR_IMAGE:
            return {
                ...state,
                viewQRImage: true,
            };
        case actions.CLOSE_QR_IMAGE:
            return {
                ...state,
                viewQRImage: false,
            };

        case actions.RESEND_MAIL_BY_ICCID:
            return {
                ...state,
                resendMailByICCIDLoading: true,
                resendMailBYICCIDError: null,
                resendMailBYICCIDMessage: null,
            };
        case actions.RESEND_MAIL_BY_ICCID_SUCCESS:
            return {
                ...state,
                resendMailByICCIDLoading: false,
                resendMailBYICCIDError: null,
                resendMailBYICCIDMessage: action.payload.message,
            };
        case actions.RESEND_MAIL_BY_ICCID_FAILED:
            return {
                ...state,
                resendMailByICCIDLoading: false,
                resendMailBYICCIDError: action.payload.error,
                resendMailBYICCIDMessage: null,
            };

        default:
            return state;
    }
}