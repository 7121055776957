import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import DeleteBin5Fill from 'remixicon-react/DeleteBin5FillIcon';
import ErrorWarningFill from 'remixicon-react/ErrorWarningFillIcon';
import { useDispatch, useSelector } from 'react-redux';
import CardLayout from '../../../../components/utils/CardLayout'
import SideButtons from '../../../../components/utils/SideButtons'
import Card from '../../../../components/utils/Card';
import Modal from '../../../../components/utils/Model';
import Button from '../../../../components/utils/Button';
import bannerActions from '../../../../redux/banner/actions'
import SearchBar from '../../../../components/utils/SearchBar';
import Alert from '../../../../components/utils/Alert';

const baseURL = process.env.REACT_APP_BASEURL;

const { prepareBannerForm, resetBanner, addBanner, getBanner, deleteBanner, changeBannerStatus, resetBannerMessage, openConfirmationModal, closeConfirmationModal, openDeleteConfirmationModal, closeDeleteConfirmationModal } = bannerActions;

const fileToBase64 = (file, callback) => {
    const reader = new FileReader();
    reader.onloadend = () => {
        if (typeof callback === 'function') {
            callback(reader.result);
        }
    };
    reader.readAsDataURL(file);
}

const Banner = () => {
    const dispatch = useDispatch();
    const [imageUrl, setImageUrl] = useState('');
    const [imageDimensions, setImageDimensions] = useState({
        height: 0,
        width: 0
    });
    const bannerState = useSelector((state) => state.banner);
    const expectedWidth = 1300;
    const expectedHeight = 300;
    const image = new Image();
    image.src = imageUrl;

    const { register, handleSubmit, formState: { errors }, reset, watch } = useForm();
    const bannerImageUrl = register("bannerImageUrl", {
        required: true,
        validate: (value) => {
            const acceptedSize = 1024;
            const acceptedFormats = ['png', 'jpg', 'jpeg'];

            const fileExtension = value[0].name.split('.').pop().toLowerCase();
            if (!acceptedFormats.includes(fileExtension)) {
                return 'Invalid file format. Only Images are allowed!';
            }
            if (value[0].size / 1024 > acceptedSize) {
                return 'Image size should be less than 1Mb!';
            }

            if (imageDimensions.height != expectedHeight || imageDimensions.width != expectedWidth) {
                return 'Invalid image dimensions!';
            }


            return true;
        }
    });

    const [filterText, setFilterText] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');
    const [bannerStatus, setBannerStatus] = useState(0);
    const [bannerId, setBannerId] = useState(0);
    const [confirmationText, sertConfirmationText] = useState('');
    const [imagePreview, setImagePreview] = useState(false);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(false);

    useEffect(() => {
        const image = new Image();
        image.src = imageUrl;
        image.onload = () => {
            setImageDimensions({
                height: image.height,
                width: image.width
            });
        };

    }, [imageUrl]);

    useEffect(() => {
        dispatch(getBanner());
    }, []);

    useEffect(() => {
        if (bannerState.message !== null && bannerState.message !== undefined) {
            displayAlert(bannerState.message, 'alert-success');
        }
        if (bannerState.error !== null && bannerState.error !== undefined) {
            displayAlert(bannerState.error, 'alert-danger');
        }
    }, [bannerState.message, bannerState.error]);


    const handleOpenModal = () => {
        setImageUrl('');
        reset();
        dispatch(prepareBannerForm());
    }
    const handleCloseModal = () => {
        dispatch(resetBanner());
    }

    const handleBannerUpload = (data) => {
        fileToBase64(data.bannerImageUrl[0], (base64) => {
            data.bannerImageUrl = base64;
            dispatch(addBanner(data));
        });
    }

    const showBannerDeleteConfirmationBox = (id) => {
        setBannerId(id);
        sertConfirmationText('Are you sure you want to delete this banner');
        dispatch(openDeleteConfirmationModal());
    }

    const handleDelete = (id) => {
        let selectedBanner = bannerState.bannerResult.filter((item) => item.ID === id);
        let data = {
            bannerImageUrl: selectedBanner[0].BannerImageUrl,
            bannerId: selectedBanner[0].ID
        }
        dispatch(deleteBanner(data));
    }

    const showBannerStatusConfirmationBox = (status, id) => {
        setBannerStatus(Number(!status));
        setBannerId(id);
        if (status) {
            sertConfirmationText('Are you sure you want to set inactive this banner');
        } else {
            sertConfirmationText('Are you sure you want to set active this banner');
        }
        dispatch(openConfirmationModal());
    }

    const handleChangeBannerStatus = () => {
        let data = {
            status: bannerStatus,
            bannerId: bannerId
        }
        dispatch(changeBannerStatus(data));
    }

    const filteredItems = bannerState.bannerResult && bannerState.bannerResult.length > 0 && bannerState.bannerResult.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const handleFilterChange = (value) => {
        setFilterText(value);
    };


    const displayAlert = (text, type) => {
        setAlertText(text);
        setAlertType(type);
        setShowAlert(true);
        dispatch(resetBannerMessage());
    };

    const alertCancel = () => {
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
        dispatch(resetBannerMessage());
    }

    const handleImageChange = (e) => {
        if (e.target.files[0]) {
            setImageUrl(URL.createObjectURL(e.target.files[0]));
        }
    }

    return (
        <CardLayout title="Banner">
            {showAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}
            <div className='row'>
                <div className="col-lg-6 col-md-6 od-2">
                    <SearchBar onFilter={handleFilterChange}  filterText={filterText} /></div>
                <div className="col-lg-6 col-md-6  text-right">
                    <SideButtons title="Add Banner" buttonStyle="primary" onClick={handleOpenModal} /></div>
            </div>

            <Modal
                showModal={bannerState.bannerFormModal}
                title={'Upload Banner'}
                onHide={() => handleCloseModal()}
                width={600}
                showFooter={false}
            >
                <form onSubmit={handleSubmit(handleBannerUpload)}>
                    <div className="form-row">
                        <div className="col-md-12 mb-3">
                            <label>Banner Title</label>
                            <input type="text" placeholder="Enter banner title" className="form-control" name="bannerTitle" {...register("bannerTitle", {
                                required: true,
                            })} />
                            {errors.bannerTitle?.type === "required" && (
                                <div className="invalid-feedback">Banner title is required !</div>
                            )}
                        </div>
                        <div className="col-md-12 mb-3">
                            <label>Banner Image</label>
                            <input type="file" className="form-control-file" placeholder="Enter contact person" name="bannerImageUrl" {...bannerImageUrl} onChange={handleImageChange} />
                            <small>Note - Please upload only landscape images only. The image size should be less than 1 Mb, and its dimensions should be 1300 * 300 pixels.
                                The acceptable file extensions are png,jpg,jpeg</small>
                            {errors.bannerImageUrl?.type === "required" && (
                                <div className="invalid-feedback">Image is required !</div>
                            )}
                            {errors.bannerImageUrl?.type === "validate" && (
                                <div className="invalid-feedback">{errors.bannerImageUrl.message}</div>
                            )}

                            {
                                imageUrl != '' ? <div className='container-fluid mt-2'>
                                    <img src={imageUrl} height={175} width={'100%'} />
                                </div> : ''
                            }

                        </div>
                    </div>

                    <div className='text-right row'>
                        <div className="col-md-12">
                            <button type="button" className="btn btn-secondary mr-2" onClick={() => handleCloseModal()}>Close</button>
                            <Button title={'Submit'} buttonClass="btn btn-primary" loading={bannerState.loading} buttonType='submit' />
                        </div>
                    </div>
                </form>
            </Modal>

            <Modal
                showModal={imagePreview}
                title={'IMAGE PREVIEW'}
                onHide={() => setImagePreview(false)}
                width={900}
                showFooter={false}
                bodyClasses={'p-0'}
            >
                <img src={imagePreviewUrl} style={{ width: '100%', height: '500px' }} className='img-fluid' />
            </Modal>

            <Modal
                showModal={bannerState.bannerConfirmationModal}
                title="Alert"
                onHide={() => dispatch(closeConfirmationModal())}
                onSaveChanges={() => dispatch(closeConfirmationModal())}
                showFooter={false}
                alignment='modal-dialog-centered'
            >
                <div style={Style.modalContent}>
                    <ErrorWarningFill size="4em" color='#fdcb6e' />
                    <p className='text-dark'>{confirmationText}</p>
                </div>

                <div className='text-right row mt-2'>
                    <div className="col-md-12">
                        <button type="button" className="btn btn-secondary mr-2" onClick={() => dispatch(closeConfirmationModal())}>Close</button>
                        <Button title={'Submit'} buttonClass="btn btn-primary" onClick={() => handleChangeBannerStatus()} loading={bannerState.loading} buttonType='button' />
                    </div>
                </div>

            </Modal>

            <Modal
                showModal={bannerState.bannerDeleteConfirmationModal}
                title="Alert"
                onHide={() => dispatch(closeDeleteConfirmationModal())}
                onSaveChanges={() => dispatch(closeDeleteConfirmationModal())}
                showFooter={false}
                alignment='modal-dialog-centered'
            >
                <div style={Style.modalContent}>
                    <ErrorWarningFill size="4em" color='#fdcb6e' />
                    <p className='text-dark'>{confirmationText}</p>
                </div>

                <div className='text-right row mt-2'>
                    <div className="col-md-12">
                        <button type="button" className="btn btn-secondary mr-2" onClick={() => dispatch(closeDeleteConfirmationModal())}>Close</button>
                        <Button title={'Submit'} buttonClass="btn btn-primary" onClick={() => handleDelete(bannerId)} loading={bannerState.loading} buttonType='button' />
                    </div>
                </div>

            </Modal>


            <div className='row mt-3'>
                {
                    filteredItems && filteredItems.length > 0 ?
                        filteredItems.map((item, index) => {
                            return <div className='col-lg-3 col-md-6'>
                                <Card
                                    image={`${baseURL}/bannerImages/${item.BannerImageUrl}`}
                                    imageClick={(imageUrl) => {
                                        setImagePreviewUrl(imageUrl)
                                        setImagePreview(true);
                                    }}
                                    header={false}
                                    body={<h5 className="card-title mb-0 font-weight-bold">{item.BannerTitle}</h5>}
                                    footer={<>
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox" className="custom-control-input" onChange={() => showBannerStatusConfirmationBox(item.IsActive, item.ID)} checked={item.IsActive} id={`toggleStatus${index}`} />
                                            <label className="custom-control-label" for={`toggleStatus${index}`}></label>
                                        </div>

                                        <DeleteBin5Fill size='1.2rem' style={{ cursor: 'pointer' }} onClick={() => showBannerDeleteConfirmationBox(item.ID)} color='#e74c3c' />
                                    </>}
                                />
                            </div>
                        }) : ''
                }

                {
                    bannerState.bannerResult && bannerState.bannerResult.length === 0 ?
                        <>
                            <div className='text-center mt-1 mx-auto pt-5'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 1024 1024"><path fill="#e3e3e3" d="M854.6 288.7L639.4 73.4c-6-6-14.2-9.4-22.7-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.6-9.4-22.6M790.2 326H602V137.8zm1.8 562H232V136h302v216a42 42 0 0 0 42 42h216zM402 549c0 5.4 4.4 9.5 9.8 9.5h32.4c5.4 0 9.8-4.2 9.8-9.4c0-28.2 25.8-51.6 58-51.6s58 23.4 58 51.5c0 25.3-21 47.2-49.3 50.9c-19.3 2.8-34.5 20.3-34.7 40.1v32c0 5.5 4.5 10 10 10h32c5.5 0 10-4.5 10-10v-12.2c0-6 4-11.5 9.7-13.3c44.6-14.4 75-54 74.3-98.9c-.8-55.5-49.2-100.8-108.5-101.6c-61.4-.7-111.5 45.6-111.5 103m78 195a32 32 0 1 0 64 0a32 32 0 1 0-64 0" /></svg>
                                <br /> No Data

                            </div>
                            <hr className='hr-tag-line' />
                        </>
                        : ''
                }

            </div>


        </CardLayout>
    )
}

const Style = {
    modalContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: 'center'
    }
}

export default Banner