const actions = {
  GET_COUNTRY: 'GET_COUNTRY',
  GET_COUNTRY_SUCCESS: 'GET_COUNTRY_SUCCESS',
  GET_COUNTRY_FAILED: 'GET_COUNTRY_FAILED',
  GET_COUNTRY_WHO_HAS_PLAN: 'GET_COUNTRY_WHO_HAS_PLAN',
  GET_COUNTRY_WHO_HAS_PLAN_SUCCESS: 'GET_COUNTRY_WHO_HAS_PLAN_SUCCESS',
  GET_COUNTRY_WHO_HAS_PLAN_FAILED: 'GET_COUNTRY_WHO_HAS_PLAN_FAILED',

  getCountryWhoHasPlan: () => ({
    type: actions.GET_COUNTRY_WHO_HAS_PLAN,
  }),
  getCountryWhoHasPlanSuccess: (countryResult) => ({
    type: actions.GET_COUNTRY_WHO_HAS_PLAN_SUCCESS,
    countryResult
  }),
  getCountryWhoHasPlanFailed: (countryError) => ({
    type: actions.GET_COUNTRY_WHO_HAS_PLAN_FAILED,
    countryError
  }),

  getCountry: () => ({
    type: actions.GET_COUNTRY,
  }),
  getCountrySuccess: (countryResult) => ({
    type: actions.GET_COUNTRY_SUCCESS,
    countryResult
  }),
  getCountryFailed: (countryError) => ({
    type: actions.GET_COUNTRY_FAILED,
    countryError
  }),
};
export default actions;
