import React from 'react'
import AddFillIcon from 'remixicon-react/AddFillIcon';


function SideButtons({ title, onClick,style }) {
    return (
        <div  style ={style}>
            <button type="button" class={`btn btn-primary font-weight-bold`}  onClick={onClick}><AddFillIcon size={16} style={{ fontWeight: "bold" }} />{title}</button>
        </div>

    )
}

export default SideButtons